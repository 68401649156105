import * as React from 'react';
const TinyStepImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={274} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M38.308 0c3.478 1.511 6.004 3.954 7.841 7.283 1.548 2.8 4.23 4.208 7.428 4.404 2.329.145 2.184 1.237 1.092 2.578-.797.978-1.775 1.894-2.857 2.536-1.91 1.134-3.929 
2.091-5.953 3.018-1.345.616-3.08 1.164-3.141 2.681-.057 1.366 1.847 1.123 2.852 1.579 9.275 4.192 10.18 17.49 1.583 24.053-3.328 2.54-7.08 4.28-11.216 4.875-10.45 1.507-20.46 
1.02-28.52-7.106C2.283 40.725.456 34.28.13 27.298c-.12-2.536-.347-5.119.393-7.63.404-1.355.994-2.603 2.562-2.898 1.822-.341 1.972 1.232 2.531 2.319 2.376 4.612 11.92 7.417 
16.424 4.84.92-.528 1.175-.963.434-1.967-6.356-8.65-3.892-17.164 6.098-21.248.378-.155.854-.191.957-.714h8.779ZM273.292 41.19c-2.391 7.666-10.393 10.513-17.122 
6.041-.259-.17-.517-.347-1.159-.787 0 3.872-.057 7.335.026 10.792.036 1.538-.228 2.257-2.081 2.241-4.767-.051-4.767.057-4.767-4.663 
0-10.233-.036-20.471.057-30.704.01-1.02-1.056-2.702 1.061-3.1 3.199-.606 4.55.015 5.352 2.624 2.018-1.429 3.908-2.992 6.496-3.158 6.232-.398 10.409 2.614 12.137 8.753 1.015 
2.898.342 10.87 0 11.962Zm-18.281-6.288c0 .512-.057 1.03.01 1.532.243 1.884-.704 3.954.792 5.647 2.609 2.955 8.225 2.386 10.238-1.02 1.952-3.297 
1.563-10.108-.745-13.167-1.522-2.009-4.239-2.723-6.957-1.812-2.412.807-3.638 2.36-3.359 5 .129 1.258.021 2.547.021 3.82ZM233.649 37.396c-2.495 
0-4.989.062-7.479-.02-1.413-.047-1.563.553-1.278 1.702.683 2.738 3.048 4.648 6.237 4.871 2.531.176 4.994-.217 7.22-1.46 1.211-.672 2.055-.812 2.536.7.383 1.2 2.174 2.4 0 
3.617-5.103 2.852-10.497 3.453-15.828 1.103-4.948-2.18-6.946-6.548-7.329-11.76-.544-7.366 3.24-13.55 9.41-15.274 8.287-2.32 15.176 2.515 15.709 11.02.347 5.496.347 5.496-5.067 
5.496-1.377.005-2.754.005-4.131.005Zm-3.198-5.13c1.283 0 2.582-.113 3.85.027 1.729.191 2.05-.595 1.843-2.066-.342-2.401-2.107-4.202-4.534-4.513-2.723-.347-5.016 1.134-6.165 
3.97-.657 1.63-.926 2.955 1.667 2.604 1.097-.145 2.226-.021 3.339-.021ZM153.769 38.965c1.718-5.14 3.519-10.254 5.103-15.43.585-1.91 1.356-2.903 3.535-2.577 1.424.212 2.904.041 
4.705.041-.978 2.62-1.853 4.995-2.748 7.36-3.106 8.215-6.17 16.444-9.343 24.633-2.106 5.435-5.543 7.546-10.906 6.9-.869-.104-2.319-.052-1.796-1.434.476-1.258-.802-3.505 
1.988-3.68 4.068-.254 6.874-4.897 5.383-8.696-2.956-7.536-5.859-15.098-8.887-22.609-.73-1.806-.896-2.614 1.589-2.53 4.839.154 4.844.025 6.392 4.652 1.486 4.441 2.966 8.882 
4.446 13.318a17 17 0 0 0 .539.052ZM118.158 24.544c2.872-2.748 5.507-4.208 8.944-4.099 5.492.17 9.037 3.1 9.275 8.54.264 6.046.098 12.112.161 18.168.01 1.035-.259 1.455-1.419 
1.548-5.326.43-5.321.47-5.321-4.855 0-4.265.011-8.53-.005-12.795-.01-2.397-.683-4.369-3.354-5.088-2.65-.715-5.9.202-7.282 2.05-.58.776-.477 1.61-.477 2.442-.01 5.373-.067 
10.751.032 16.124.031 1.697-.508 2.148-2.19 2.132-4.663-.041-4.663.042-4.669-4.498 0-6.992.057-13.99-.041-20.983-.026-1.801.502-2.448 2.308-2.252 2.029.218 4.964-1.06 4.038 
3.566ZM181.889 49.172c-3.364.13-6.309-.575-8.871-2.552-1.465-1.128-2.511-2.541-3.049-4.322-.481-1.594-.393-2.64 1.765-2.459 2.086.176 4.105-.47 5.388 2.376 1.025 2.267 6.957 
2.37 9.012.689 1.558-1.274 1.294-3.334-.627-4.405-1.677-.932-3.582-1.154-5.419-1.563-2.107-.471-4.161-1.093-6.025-2.18-4.146-2.422-4.839-7.396-1.568-10.9 4.399-4.72 
14.653-4.674 18.975.119a8.002 8.002 0 0 1 1.377 2.133c1.102 2.551.693 3.183-2.014 3.167-1.889-.01-3.69.394-4.963-1.982-1.124-2.102-5.896-2.164-7.759-.59-1.429 1.206-1.279 
3.131.455 3.965 1.139.548 2.428.833 3.68 1.076 2.454.471 4.876 1.056 7.112 2.184 2.511 1.263 4.27 3.096 4.415 6.103.156 3.214-1.175 5.595-3.902 7.251-2.506 1.517-5.244 
2.076-7.982 1.89ZM80.052 35.14c0-2.299-.099-4.607.03-6.9.094-1.651-.418-2.438-2.137-2.107-2.417.466-2.505-.88-2.526-2.748-.02-1.822.347-2.78 2.366-2.392 2.117.41 2.329-.74 
2.298-2.469-.083-4.223 1.408-5.28 5.574-4.275.761.186.973.564.973 1.221.006.684.01 1.362.01 2.045.006 3.385.011 3.556 3.313 3.421 2.506-.103 1.89 1.45 1.998 2.806.125 
1.568-.067 2.774-2.106 2.38-2.847-.548-3.396.818-3.256 3.344.202 3.655 0 7.324.073 10.984.056 2.826.812 3.68 3.52 3.406 1.795-.187 1.588.978 1.66 1.816.094 1.108 1.072 
2.645-.952 3.142-5.186 1.273-10.849-.176-10.838-7.029.005-2.215 0-4.43 0-6.646ZM201.418 
35.145c0-2.298-.098-4.607.032-6.9.093-1.65-.42-2.437-2.138-2.106-2.417.466-2.505-.88-2.526-2.749-.021-1.822.347-2.78 2.365-2.39 2.117.408 2.33-.741 2.299-2.47-.083-4.223 
1.407-5.28 5.574-4.275.761.186.973.564.978 1.221.005.684.011 1.362.011 2.045.005 3.385.01 3.556 3.312 3.421 2.505-.103 1.89 1.45 1.998 2.806.124 1.568-.067 2.774-2.106 
2.38-2.847-.548-3.396.818-3.256 3.344.202 3.654 0 7.324.072 10.984.057 2.826.813 3.685 3.52 3.405 1.796-.186 1.589.974 1.662 1.817.093 1.108 1.071 2.645-.953 3.142-5.181 
1.273-10.849-.17-10.844-7.029v-6.646ZM104.503 34.964c0 3.918-.078 7.837.036 11.755.047 1.6-.471 1.992-2.034 1.987-4.596-.005-4.596.068-4.596-4.555 
0-6.982.062-13.97-.041-20.952-.026-1.827.564-2.277 2.329-2.246 4.306.077 4.306-.01 4.306 4.296v9.715ZM101.134 14.28c-2.076.352-3.25.145-3.365-2.666-.134-3.349 1.693-2.78 
3.748-2.97 2.536-.234 2.991.657 3.1 3.053.129 3.013-1.46 2.847-3.483 2.583Z"
		/>
	</svg>
);
export default TinyStepImg;
