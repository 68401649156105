import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const RunningDiagnostics = function () {
	const navigate = useNavigate();
	useEffect(() => {
		setTimeout(() => {
			navigate('/diagnostics/results');
		}, 5 * 1000);
	}, []);

	return (
		<div className="running-dgn-screen-container">
			<h2 className="head-1">Run Maintenance</h2>
			<div className="diagnostic-scale-container">
				<div className="all-circles-container">
					<div className="each-circle">
						<div className="content">
							<div>Checking Status</div>
							<img src={require('../../images/tick.png')} />
						</div>
					</div>
					<div className="each-circle">
						<div className="content">
							<div>Calculating spend</div>
							<img src={require('../../images/tick.png')} />
						</div>
					</div>
					<div className="each-circle">
						<div className="content">
							<div>Health</div>
							<img src={require('../../images/tick.png')} />
						</div>
					</div>
					<div className="each-circle">
						<div className="content">
							<div>Generating Reporting</div>
							<img src={require('../../images/tick.png')} />
						</div>
					</div>
				</div>
				<div className="scale-highlight"></div>
			</div>
		</div>
	);
};

export default RunningDiagnostics;
