import React from 'react';

const EachAnalysis = function ({ title, value, Icon }) {
	return (
		<div className="each-analysis">
			<h4 className="analysis-title">{title}</h4>
			<div className="analysis-content">
				<img src={Icon} />
				{/* <Icon className={'each-analysis-icon'} /> */}
				<div className={'each-analysis-value'}>{value}</div>
			</div>
		</div>
	);
};

export default EachAnalysis;
