import { useSelector } from 'react-redux';

export const ASSET_EDIT = ['on_asset', 'asset', 'edit'];
export const ASSET_DELETE = ['on_asset', 'asset', 'delete'];
export const ASSET_VIEW = ['on_asset', 'asset', 'view'];
export const ASSET_CREATE = ['on_asset', 'asset', 'create'];
export const AUDIT_VIEW = ['on_asset', 'audit', 'view'];
export const AUDIT_CREATE = ['on_asset', 'audit', 'create'];
export const UPDATE_CUSTOM_CREATE = ['on_asset', 'asset_update_custom', 'create'];
export const UPDATE_CUSTOM_EDIT = ['on_asset', 'asset_update_custom', 'edit'];
export const UPDATE_CUSTOM_DELETE = ['on_asset', 'asset_update_custom', 'delete'];
export const IMAGE_VIEW = ['on_asset', 'image', 'view'];
export const IMAGE_CREATE = ['on_asset', 'image', 'create'];
export const ACTIVITY_VIEW = ['on_asset', 'activity', 'view'];
export const DEPRECIATION_VIEW = ['on_asset', 'depreciation', 'view'];
export const DEPRECIATION_CREATE = ['on_asset', 'depreciation', 'create'];
export const ASSET_ASSIGN_UNASSIGN = ['on_asset', 'asset_assign_unassign', 'create'];
export const ARCHIEVE_VIEW = ['on_asset', 'archive_lit', 'view'];
export const COMMENT_EDIT = ['on_asset', 'comment', 'edit'];
export const COMMENT_VIEW = ['on_asset', 'comment', 'view'];
export const PROGRAMS_VIEW = ['on_asset', 'programs_view', 'view'];
export const RAM_VIEW = ['on_asset', 'ram_view', 'view'];
export const LOCATION_VIEW = ['on_asset', 'location_view', 'view'];
export const USERS_VIEW = ['on_asset', 'users_view', 'view'];
export const HEALTH_VIEW = ['on_asset', 'health_view', 'view'];
export const CREATE_REPORT = ['on_asset', 'report_generate', 'create'];
export const GENERATE_TAG = ['on_asset', 'generate_tag', 'create'];
export const FILTER_VIEW = ['on_asset', 'filter', 'view'];

export const COMPANY_INFO_VIEW = ['on_organsiation', 'company_information', 'view'];
export const COMPANY_INFO_EDIT = ['on_organsiation', 'company_information', 'edit'];
export const COMPANY_BRANCHES_CREATE = ['on_organsiation', 'company_branches', 'create'];
export const COMPANY_BRANCHES_EDIT = ['on_organsiation', 'company_branches', 'edit'];
export const COMPANY_BRANCHES_VIEW = ['on_organsiation', 'company_branches', 'view'];
export const COMPANY_BRANCHES_DELETE = ['on_organsiation', 'company_branches', 'delete'];
export const ASSET_TAG_SETUP_EDIT = ['on_organsiation', 'asset_tag_setup', 'edit'];
export const ASSET_TAG_SETUP_VIEW = ['on_organsiation', 'asset_tag_setup', 'view'];
export const ASSET_TYPES_VIEW = ['on_organsiation', 'asset_types', 'view'];
export const ASSET_TYPES_CREATE = ['on_organsiation', 'asset_types', 'create'];
export const ASSET_TYPES_DELETE = ['on_organsiation', 'asset_types', 'delete'];
export const PURCHASE_ORDER_VIEW = ['on_organsiation', 'purchase_order', 'view'];
export const PURCHASE_ORDER_DELETE = ['on_organsiation', 'purchase_order', 'delete'];
export const PURCHASE_ORDER_CREATE = ['on_organsiation', 'purchase_order', 'create'];
export const ADMIN_SETUP_VIEW = ['on_organsiation', 'admin_setup', 'view'];
export const ADMIN_SETUP_CREATE = ['on_organsiation', 'admin_setup', 'create'];
export const EMPLOYEE_SETUP_CREATE = ['on_organsiation', 'employee_setup', 'create'];
export const EMPLOYEE_SETUP_VIEW = ['on_organsiation', 'employee_setup', 'view'];
export const EMPLOYEE_SETUP_EDIT = ['on_organsiation', 'employee_setup', 'edit'];
export const EMPLOYEE_SETUP_DELETE = ['on_organsiation', 'employee_setup', 'delete'];
export const AGENT_COMPUTERS_VIEW = ['on_organsiation', 'agent_computers', 'view'];
export const ACTIVITY_INFO_VIEW = ['on_organsiation', 'activity', 'view'];
export const COMPUTER_REPORT_CREATE = ['on_organsiation', 'agent_computer_report', 'create'];
export const DOWNLOAD_AGENT_CREATE = ['on_organsiation', 'download_agent', 'create'];

export const CheckPermission = ({ children, requiredPermission }) => {
	const permissions = useSelector((state) => state.appreducer.permissions);
	const roleName = useSelector((state) => state.appreducer.roleName);

	if (verifyPermission(permissions, requiredPermission, roleName)) {
		return children;
	}
	return null;
};

export const CheckPermissionCondition = (requiredPermission) => {
	const permissions = useSelector((state) => state.appreducer.permissions);
	const roleName = useSelector((state) => state.appreducer.roleName);

	if (verifyPermission(permissions, requiredPermission, roleName)) {
		return true;
	}
	return false;
};

function verifyPermission(permissions, requiredPermission, roleName) {
	const DefaultRoles = ['owner'];

	if (DefaultRoles.includes(roleName)) {
		return true;
	}

	const [mainObjevtive, objective, action] = requiredPermission;
	for (let x = 0; x < permissions.length; x++) {
		if (permissions[x].obj === mainObjevtive) {
			for (let y = 0; y < permissions[x].permList.length; y++) {
				if (permissions[x].permList[y].obj === objective) {
					if (permissions[x].permList[y].actions.includes(action)) {
						return true;
					}
				}
			}
			return false;
		}
	}
	return false;
}
