import React from 'react';
import './index.css';
import HeaderComponent from '../../components/Header';
import FooterDashboard from '../../components/FooterDashboard';
import Paymentfailed from '../../images/Payment-Failed.png';

const PaymentFailed = function () {
	return (
		<div>
			<HeaderComponent />
			<div className="payment-failed-container">
				<div className="payment-status">Payment Failed</div>
				<img src={Paymentfailed} />
				<div className="status-msg">
					The transaction failed due to a technical error. If your money was debited, you should get a refund
					within the next 24 hours.
				</div>
				<div className="payment-timestamp">5:52 PM, 22 Mar 2022</div>
				<div className="transaction-id">Transaction ID: #34531287495998 </div>
			</div>
			<FooterDashboard />
		</div>
	);
};

export default PaymentFailed;
