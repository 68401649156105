import React from 'react';
import TipsCarousel from '../../components/Tips';
import AnalysisContent from '../../components/Analysis';
import DashboardFooter from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useNavigate } from 'react-router-dom';

const DashboardScreen = function () {
	const navigate = useNavigate();
	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<div>
				<TipsCarousel />
			</div>
			<AnalysisContent />
			<DashboardFooter />
		</div>
	);
};

export default DashboardScreen;
