import React from 'react';
import AsyncSelect from 'react-select/async';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { searchInvoice } from '../../services/assets';
import { Descriptions } from 'antd';
import { format } from 'date-fns';

export const SearchInvoicePO = ({ setInvoiceId, defaultInvoiceInfo, selectedInvoice }) => {
	const navigate = useNavigate();
	const promiseOptions = async (inputValue) => {
		const invoiceList = await searchInvoice(inputValue);
		const resp = invoiceList.map((el) => ({ value: el._id, label: el.invoiceId }));
		return resp;
	};

	console.log(defaultInvoiceInfo, 'defaultInvoiceInfo');

	if (!defaultInvoiceInfo) {
		return null;
	}

	console.log(selectedInvoice, 'selectedInvoice');

	return (
		<div className="search-invocice-container">
			<div className="head-1">PO/Invoice Number</div>
			<AsyncSelect
				placeholder={'Search PO/Invoice'}
				cacheOptions
				defaultOptions
				onChange={(data) => setInvoiceId(data.value)}
				loadOptions={promiseOptions}
				className="search-invoice-select"
				defaultValue={defaultInvoiceInfo}
			/>
			<div className="create-new-container">
				Unable to find PO/invoice?{' '}
				<span
					onClick={() => navigate('/account-settings?view=master-setup&tab=purchase-order')}
					className="highlighter"
				>
					Create Invoice
				</span>
			</div>

			<ShowSelectedInvoice selectedInvoice={selectedInvoice} />
		</div>
	);
};

const ShowSelectedInvoice = ({ selectedInvoice }) => {
	if (!selectedInvoice) {
		return null;
	}

	const items = [
		{
			key: '1',
			children: selectedInvoice.vendorName,
			label: 'Vendor Name',
		},
		{
			key: '2',
			children: selectedInvoice.invoiceId,
			label: 'Invoice Id',
		},
		{
			key: '3',
			children: (
				<a href={selectedInvoice.invoiceURL} target="new">
					Download
				</a>
			),
			label: 'Download Invoice',
		},
		{
			key: '4',
			children: <span> {format(new Date(selectedInvoice.date), 'dd MMM yyyy')} </span>,
			label: 'Invoice/Purchase Date',
		},
	];

	return (
		<div style={{ marginTop: '4vh' }}>
			<Descriptions title="PO/Invoice Info" items={items} />
		</div>
	);
};
