import * as React from 'react';
const MeddoImg = (props) => (
	<svg width="236" height="60" viewBox="0 0 236 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clip-path="url(#clip0_6219_6739)">
			<path
				d="M235.748 34.7374C235.533 35.0832 235.588 35.4872 235.51 35.8622C234.335 41.4744 229.775 45.8021 224.079 46.7002C217.382 47.7552 210.886 44.003 208.625 37.638C206.907 32.8017 207.872 26.9947 212.488 22.824C217.222 18.5428 224.407 18.3743 229.054 21.3243C232.865 23.7424 235.167 27.1691 235.652 31.7176C235.661 31.8106 235.637 31.9182 235.748 31.9763C235.748 32.8947 235.748 33.816 235.748 34.7374ZM230.821 33.2754C230.819 28.0265 226.851 23.9052 221.794 23.8965C216.821 23.8877 212.784 27.9654 212.738 33.0487C212.691 38.0536 216.879 42.2824 221.896 42.297C226.782 42.3086 230.827 38.2222 230.821 33.2754Z"
				fill="#888888"
			/>
			<path
				d="M27.4277 3.94691C28.166 1.84848 29.6628 0.610347 31.8019 0.101724C31.8949 0.0813796 32.0053 0.107537 32.0606 0C33.2783 0 34.499 0 35.7168 0C35.711 0.148227 35.8098 0.238326 35.8999 0.334238C36.7602 1.25557 37.6118 2.18562 38.4779 3.10114C39.7713 4.47006 41.0763 5.83317 42.3783 7.19628C43.1601 8.01298 43.9449 8.82968 44.7296 9.64348C44.8575 9.77717 44.9476 9.93121 45.0086 10.0998C44.7936 10.3236 44.5843 10.5532 44.3634 10.7741C43.7385 11.399 43.0497 11.9686 42.451 12.6109C41.364 13.7764 40.2276 14.8837 39.0825 15.9911C38.9459 15.8719 38.8005 15.7644 38.6785 15.6336C37.1468 14.0118 35.6238 12.3784 34.0892 10.7624C32.5953 9.19008 31.0898 7.63224 29.5901 6.06568C29.4448 5.91165 29.3227 5.73435 29.1716 5.58613C28.6077 5.02228 28.1311 4.36543 27.4277 3.94691Z"
				fill="#888888"
			/>
			<path
				d="M45.0118 10.0998C44.9478 9.92831 44.8606 9.77717 44.7328 9.64348C43.9451 8.82968 43.1633 8.01589 42.3815 7.19628C41.0794 5.83317 39.7773 4.47297 38.4811 3.10114C37.615 2.18562 36.7634 1.25557 35.9031 0.334238C35.813 0.238326 35.7142 0.148227 35.72 0C42.21 0 48.7 0 55.19 0C55.1755 0.0552219 55.161 0.11335 55.1464 0.168572C54.975 0.215075 54.882 0.357489 54.7657 0.470839C51.621 3.57779 48.4791 6.68766 45.3373 9.79752C45.2327 9.90215 45.1193 9.99806 45.0118 10.0998Z"
				fill="#888888"
			/>
			<path
				d="M86.9572 24.1921C87.7593 22.8843 88.6894 21.7624 89.913 20.9137C91.7963 19.6029 93.9151 19.2164 96.156 19.3559C98.8066 19.5215 101.108 20.4603 102.925 22.4599C104.34 24.0178 105.021 25.8866 105.291 27.9327C105.404 28.7814 105.407 29.6359 105.407 30.4933C105.407 35.5911 105.401 40.6889 105.419 45.7868C105.422 46.2489 105.288 46.3623 104.843 46.3506C103.675 46.3187 102.504 46.339 101.335 46.339C100.664 46.339 100.664 46.339 100.664 45.6909C100.664 40.5117 100.673 35.3295 100.658 30.1503C100.652 28.3716 100.207 26.7382 98.9141 25.4216C97.7458 24.2299 96.2693 23.8027 94.6766 23.9335C91.4824 24.1951 89.7066 26.0232 89.3579 29.2261C89.2939 29.8015 89.2707 30.3741 89.2707 30.9467C89.2678 35.8963 89.2649 40.8459 89.2794 45.7984C89.2794 46.2257 89.1835 46.3652 88.7388 46.3536C87.5036 46.3216 86.2683 46.3245 85.0331 46.3536C84.6175 46.3623 84.5158 46.2373 84.5158 45.8333C84.5303 40.7355 84.5303 35.6376 84.5187 30.5398C84.5158 29.5167 84.3966 28.5024 84.0362 27.5374C83.2486 25.4332 81.714 24.2357 79.4848 23.9771C77.1596 23.7068 75.2618 24.4595 73.9771 26.4737C73.2185 27.6653 73.0703 29.0371 73.0645 30.409C73.0413 35.5243 73.0354 40.6366 73.0442 45.7519C73.0442 46.2169 72.9395 46.3739 72.4512 46.3594C71.216 46.3245 69.9808 46.3361 68.7456 46.3565C68.3968 46.3623 68.3038 46.2518 68.3067 45.9118C68.3212 40.4361 68.3038 34.9604 68.3387 29.4818C68.3503 27.6421 68.6904 25.843 69.5652 24.2009C71.062 21.3904 73.4917 19.9023 76.5958 19.4576C78.2408 19.2222 79.8829 19.2832 81.4844 19.7628C83.6933 20.4196 85.3673 21.7885 86.6171 23.701C86.7217 23.8434 86.8206 23.9916 86.9572 24.1921Z"
				fill="#888888"
			/>
			<path
				d="M163.009 27.1896C162.504 26.7885 162.053 26.4107 161.582 26.059C159.853 24.7744 157.946 23.9054 155.761 23.9083C152.677 23.9112 150.16 25.158 148.376 27.6866C144.795 32.7554 147.114 40.3934 153.561 41.9629C158.868 43.2534 163.512 39.9982 164.591 34.9439C164.852 33.7174 164.971 32.4764 164.971 31.2266C164.98 23.4956 164.98 15.7674 164.965 8.03634C164.965 7.57712 165.093 7.46377 165.538 7.4754C166.773 7.50446 168.008 7.49574 169.244 7.47831C169.566 7.4754 169.706 7.53353 169.706 7.90264C169.697 16.1423 169.732 24.382 169.683 32.6217C169.662 35.9844 168.805 39.1408 166.605 41.7856C163.419 45.6105 159.298 47.2758 154.354 46.7817C148.931 46.2411 144.99 43.4045 142.92 38.4432C139.238 29.6077 144.914 21.4669 152.171 19.7056C156.043 18.7668 159.615 19.4818 162.78 21.9697C162.957 22.1092 163.082 22.2371 163.082 22.4899C163.07 23.9867 163.076 25.4835 163.073 26.9803C163.065 27.0268 163.041 27.0733 163.009 27.1896Z"
				fill="#888888"
			/>
			<path
				d="M196.119 27.2451C195.014 26.2744 193.925 25.4693 192.692 24.8589C187.161 22.1211 180.648 25.6088 179.892 31.721C179.36 36.0225 181.485 39.9839 185.272 41.5272C190.489 43.6576 196.221 40.9082 197.595 35.2348C197.909 33.9357 198.034 32.6133 198.034 31.2763C198.037 23.5452 198.04 15.8171 198.023 8.08603C198.023 7.57741 198.153 7.45534 198.647 7.46987C199.865 7.50766 201.086 7.49313 202.304 7.47569C202.623 7.47278 202.763 7.5251 202.763 7.89712C202.748 16.317 202.865 24.7398 202.699 33.1568C202.583 39.0161 199.827 43.4949 194.326 45.7793C186.31 49.1101 177.666 44.8086 175.44 36.9206C172.932 28.0328 178.933 21.3974 184.816 19.8134C188.792 18.7439 192.477 19.3687 195.782 21.9089C196.026 22.0979 196.13 22.2868 196.128 22.6036C196.107 24.1178 196.119 25.6291 196.119 27.2451Z"
				fill="#888888"
			/>
			<path
				d="M45.0115 10.1002C45.119 10.0014 45.2324 9.90255 45.337 9.80082C48.4789 6.69096 51.6207 3.584 54.7654 0.474142C54.8817 0.360792 54.9747 0.215471 55.1462 0.171875C55.1607 0.366605 55.1898 0.561334 55.1898 0.75897C55.1927 7.90875 55.1927 15.0614 55.1898 22.2112C55.1898 25.3036 52.5537 27.9281 49.4554 27.931C42.1778 27.9339 34.903 27.931 27.6253 27.9281C27.4568 27.9281 27.262 28.0153 27.1196 27.838C27.4568 27.6055 27.7619 27.3352 28.0526 27.0446C31.3281 23.769 34.6066 20.4993 37.885 17.2267C38.2919 16.8227 38.6872 16.4042 39.0853 15.9944C40.2276 14.89 41.3669 13.7797 42.4539 12.6142C43.0526 11.9719 43.7414 11.4023 44.3663 10.7774C44.5843 10.5536 44.7964 10.324 45.0115 10.1002Z"
				fill="#BBBBBB"
			/>
			<path
				d="M115.83 32.7604C115.719 37.7071 119.431 41.4942 122.936 42.3312C127.191 43.3455 131.51 41.8778 133.928 37.492C134.454 36.5387 134.87 35.5389 135.166 34.4926C135.256 34.1758 135.352 34.109 135.66 34.2892C136.526 34.792 137.416 35.2599 138.293 35.7453C138.459 35.8383 138.633 35.8877 138.54 36.1783C137.683 38.8551 136.541 41.3692 134.413 43.299C131.321 46.1095 127.638 47.1529 123.523 46.7896C118.722 46.3653 115.164 43.9385 112.676 39.8985C111.15 37.4165 110.7 34.6525 110.964 31.7896C111.424 26.84 113.97 23.2157 118.283 20.8383C120.701 19.5042 123.351 19.1438 126.078 19.3909C131.112 19.8472 134.466 22.7362 136.921 26.9156C137.142 27.2905 136.878 27.369 136.672 27.5027C133.102 29.8249 129.528 32.1471 125.958 34.4665C124.139 35.6494 122.314 36.8265 120.503 38.0239C120.186 38.2332 120.029 38.239 119.826 37.8844C119.32 37.0008 118.794 36.126 118.239 35.2744C118.021 34.9402 118.097 34.8007 118.402 34.6001C122.448 31.9204 126.485 29.2291 130.524 26.5378C131.007 26.2151 131.01 26.2151 130.597 25.7763C129.257 24.3609 127.551 23.7505 125.659 23.5965C123.104 23.3872 120.803 23.9917 118.855 25.7443C116.853 27.5492 115.83 29.8859 115.83 32.7604Z"
				fill="#888888"
			/>
			<path
				d="M51.6589 59.9506C51.4642 59.968 51.2695 59.9971 51.0748 59.9971C44.8231 60 38.5685 60 32.3168 59.9971C29.3813 59.9971 27.1172 57.7446 27.1172 54.8295C27.1172 48.6185 27.1172 42.4075 27.1172 36.1965C27.1172 36.0017 27.1404 35.8041 27.1521 35.6094C27.3352 35.6268 27.4282 35.775 27.5444 35.8884C29.5295 37.8241 31.4652 39.815 33.4677 41.7332C35.5545 43.7328 37.5687 45.808 39.6148 47.8483C39.7397 47.9733 39.8386 48.1389 40.0362 48.168C40.0769 48.3191 40.1844 48.4179 40.2891 48.5226C41.379 49.6096 42.466 50.7053 43.5646 51.7836C43.8523 52.0684 44.0877 52.4143 44.4656 52.6003C44.6022 52.763 44.7301 52.9345 44.8783 53.0856C47.0145 55.2248 49.1565 57.3581 51.2927 59.4972C51.4293 59.6367 51.6066 59.7442 51.6589 59.9506Z"
				fill="#BBBBBB"
			/>
			<path
				d="M0.0408092 54.7681C0.0349963 53.2712 0.0233707 51.7774 0.0233707 50.2805C0.0204643 46.7144 0.0844053 43.1482 0.000119364 39.582C-0.0551025 37.2278 1.9852 35.3765 4.16792 35.4026C9.07394 35.4578 13.98 35.4201 18.886 35.4201C19.0662 35.4201 19.2464 35.4433 19.4266 35.4549C19.4091 35.638 19.2609 35.731 19.1476 35.8444C17.2642 37.7219 15.378 39.5966 13.4975 41.477C12.4657 42.5088 11.4427 43.5522 10.4167 44.5898C9.33843 45.6216 8.2398 46.6359 7.19059 47.6938C6.56861 48.3187 5.87979 48.8855 5.35955 49.6092C4.81605 50.1265 4.26964 50.638 3.73195 51.1612C2.49673 52.3644 1.27022 53.5677 0.0408092 54.7681Z"
				fill="#BBBBBB"
			/>
			<path
				d="M40.0331 48.1705C39.8355 48.1415 39.7367 47.9729 39.6117 47.8508C37.5627 45.8105 35.5514 43.7353 33.4646 41.7357C31.4621 39.8146 29.5264 37.8266 27.5413 35.8909C27.4251 35.7776 27.3321 35.6293 27.149 35.6119C27.1228 35.3096 27.3582 35.4201 27.489 35.4201C28.3929 35.4114 29.2968 35.4143 30.2007 35.4143C35.7084 35.4143 41.2189 35.3968 46.7266 35.423C48.6826 35.4317 50.1852 36.3123 51.1531 38.0417C50.9147 38.5154 50.441 38.7537 50.0748 39.0967C49.2058 39.9163 48.328 40.7243 47.4445 41.5294C45.6919 43.125 43.9335 44.7148 42.1722 46.3017C41.4689 46.9353 40.7481 47.5485 40.0331 48.1705Z"
				fill="#888888"
			/>
			<path
				d="M39.0857 15.9914C38.6846 16.4012 38.2894 16.8198 37.8854 17.2238C34.6099 20.4964 31.3285 23.7661 28.053 27.0416C27.7623 27.3323 27.4572 27.5997 27.12 27.8351C27.1171 23.5191 27.1113 19.2001 27.1113 14.8841C27.1084 11.8469 27.1113 8.8097 27.1084 5.77249C27.1084 5.14471 27.1782 4.53145 27.431 3.94727C28.1344 4.36579 28.611 5.02264 29.1749 5.58648C29.326 5.73471 29.4452 5.91491 29.5934 6.06604C31.0931 7.6326 32.5986 9.19044 34.0925 10.7628C35.63 12.3817 37.1501 14.0122 38.6817 15.634C38.8038 15.7647 38.9491 15.8723 39.0857 15.9914Z"
				fill="#888888"
			/>
			<path
				d="M3.59814 11.922C3.74347 11.8029 3.91494 11.8552 4.07189 11.8552C8.05368 11.8523 12.0326 11.8494 16.0143 11.8552C17.3397 11.8581 18.4034 12.3725 19.1329 13.5031C19.4439 13.9856 19.6009 14.5233 19.6009 15.0871C19.6096 19.2084 19.6067 23.3326 19.6038 27.4539C19.6038 27.5527 19.5834 27.6486 19.5718 27.7445C19.3712 27.7184 19.2724 27.5527 19.1475 27.4277C16.9182 25.2305 14.689 23.0333 12.4627 20.836C12.3813 20.7575 12.2883 20.6878 12.204 20.6122C10.8961 19.1561 9.379 17.9122 8.07111 16.4531C8.03333 16.4095 7.95776 16.3979 7.89964 16.3718C7.81244 16.2555 7.73688 16.1247 7.63515 16.023C6.59175 14.9738 5.54253 13.9333 4.49913 12.8811C4.19105 12.5731 3.83356 12.3057 3.59814 11.922Z"
				fill="#BBBBBB"
			/>
			<path
				d="M10.4136 44.5921C11.4395 43.5545 12.4626 42.5111 13.4944 41.4794C15.3748 39.5989 17.264 37.7243 19.1444 35.8467C19.2578 35.7334 19.406 35.6404 19.4235 35.4573C19.7286 35.4485 19.5978 35.684 19.6007 35.8089C19.6153 36.5007 19.6066 37.1895 19.6066 37.8812C19.6066 42.206 19.6124 46.5307 19.6007 50.8554C19.5978 51.9483 19.2404 52.9132 18.4149 53.6718C18.4149 53.4799 18.2609 53.3927 18.1534 53.2736C17.7784 52.8638 17.3977 52.4569 17.0257 52.0413C15.6248 50.4834 14.2152 48.9343 12.8346 47.3619C12.0295 46.4348 11.1431 45.5803 10.4136 44.5921Z"
				fill="#888888"
			/>
			<path
				d="M40.0332 48.1708C40.7453 47.5488 41.4661 46.9327 42.1694 46.2991C43.9307 44.7122 45.6891 43.1224 47.4416 41.5268C48.3252 40.7246 49.2029 39.9137 50.072 39.0941C50.4382 38.7482 50.9119 38.5128 51.1502 38.0391C51.5746 38.6494 51.7955 39.3499 51.8071 40.0706C51.8391 42.108 51.8129 44.1454 51.81 46.1857C51.1473 46.3601 50.8015 46.9472 50.3306 47.3541C48.6682 48.8015 47.0696 50.3216 45.4391 51.8067C45.1281 52.0916 44.7852 52.3386 44.4568 52.606C44.0789 52.42 43.8435 52.0712 43.5558 51.7893C42.4572 50.711 41.3702 49.6153 40.2802 48.5283C40.1843 48.4208 40.0739 48.322 40.0332 48.1708Z"
				fill="#888888"
			/>
			<path
				d="M10.4136 44.5938C11.1431 45.5848 12.0295 46.4364 12.8375 47.3578C14.2181 48.933 15.6277 50.4792 17.0286 52.0371C17.4006 52.4498 17.7813 52.8567 18.1563 53.2694C18.2638 53.3886 18.4207 53.4757 18.4178 53.6676C17.8366 54.3477 17.0693 54.7604 16.209 54.836C14.1483 55.0132 12.079 54.9609 10.0154 54.8912C10.0183 54.618 9.83811 54.4523 9.67245 54.2663C8.83249 53.3362 8.0216 52.3771 7.14968 51.4761C6.54805 50.8571 6.05687 50.1305 5.35352 49.6102C5.87376 48.8865 6.56258 48.3198 7.18456 47.6949C8.23668 46.6399 9.3353 45.6255 10.4136 44.5938Z"
				fill="#888888"
			/>
			<path
				d="M12.2069 20.6152C12.2941 20.6879 12.3842 20.7576 12.4656 20.839C14.6948 23.0363 16.9212 25.2335 19.1504 27.4308C19.2753 27.5557 19.3742 27.7214 19.5747 27.7476C19.5544 28.0644 19.3073 27.922 19.1707 27.922C17.5112 27.9336 15.8516 27.9278 14.192 27.9278C11.7274 27.9278 9.26275 27.9336 6.79811 27.9249C5.94944 27.922 5.18796 27.6662 4.57471 27.0442C5.31294 26.4252 6.04826 25.8032 6.78649 25.187C7.91418 24.2453 9.0564 23.324 10.1696 22.3649C10.8497 21.7807 11.5879 21.2663 12.2069 20.6152Z"
				fill="#888888"
			/>
			<path
				d="M44.4653 52.6058C44.7938 52.3413 45.1367 52.0914 45.4477 51.8065C47.0782 50.3214 48.6767 48.8042 50.3392 47.3539C50.8071 46.947 51.1559 46.357 51.8186 46.1855C51.8244 50.673 51.8302 55.1606 51.8273 59.651C51.8273 59.7556 51.9523 59.9823 51.6616 59.9503C51.6093 59.744 51.4291 59.6364 51.2954 59.4998C49.1592 57.3607 47.0172 55.2274 44.8809 53.0883C44.7269 52.9401 44.6019 52.7686 44.4653 52.6058Z"
				fill="#888888"
			/>
			<path
				d="M12.2069 20.6145C11.5879 21.2655 10.8496 21.7829 10.1724 22.3671C9.06219 23.3262 7.91706 24.2475 6.78937 25.1892C6.05114 25.8054 5.31582 26.4273 4.57759 27.0464C3.96725 26.5668 3.58941 25.9158 3.52256 25.1718C3.36271 23.3959 3.40631 21.6114 3.47897 19.8298C3.61266 19.8414 3.73473 19.8356 3.84808 19.7397C4.49331 19.1904 5.12981 18.6323 5.78957 18.1034C6.49873 17.5337 7.14396 16.8827 7.90253 16.377C7.96066 16.4031 8.03332 16.4147 8.07401 16.4583C9.38189 17.9115 10.899 19.1584 12.2069 20.6145Z"
				fill="#888888"
			/>
			<path
				d="M5.35685 49.6133C6.0602 50.1335 6.55429 50.8572 7.15301 51.4792C8.02493 52.3802 8.83292 53.3393 9.67578 54.2694C9.84144 54.4525 10.0216 54.621 10.0187 54.8942C9.42873 54.9059 8.83582 54.9262 8.24582 54.9262C5.67946 54.9291 3.116 54.9291 0.549638 54.9175C0.383972 54.9175 0.145647 55.0396 0.0410156 54.7751C1.27043 53.5718 2.49693 52.3686 3.72925 51.1711C4.26694 50.6422 4.81335 50.1306 5.35685 49.6133Z"
				fill="#888888"
			/>
			<path
				d="M7.90259 16.3755C7.14401 16.8841 6.49879 17.5351 5.78962 18.1019C5.12987 18.6308 4.49046 19.1889 3.84814 19.7382C3.73479 19.8341 3.61272 19.8399 3.47903 19.8283C3.4674 18.3344 3.45287 16.8376 3.44415 15.3437C3.43834 14.3584 3.43834 13.3702 3.44706 12.3849C3.44996 12.2251 3.35114 12.01 3.59528 11.9199C3.8307 12.3036 4.18819 12.571 4.49918 12.8849C5.54258 13.9341 6.59179 14.9775 7.6352 16.0267C7.73983 16.1284 7.81539 16.2592 7.90259 16.3755Z"
				fill="#888888"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6219_6739">
				<rect width="235.748" height="60" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default MeddoImg;
