import React, { useState } from 'react';

const AssetzeProsList = () => {
	const [showAllFeature, toggleFeature] = useState(false);
	return (
		<div className="featurelist-container">
			<div className="head-1">What you can expect with IT managed services</div>
			<div className="feature-row">
				<div className="each-feature-container">
					<div className="image-container">
						<img src={require('../../../images/new-landing/proslist/zero-downtime.png')} />
					</div>
					<div className="head-1">Zero Downtime</div>
					<div className="content-1">Replacement of laptop, in case of breakdown across PAN India</div>
				</div>
				<div className="each-feature-container">
					<div className="image-container">
						<img src={require('../../../images/new-landing/proslist/hardware-support.png')} />
					</div>
					<div className="head-1">Hardware Support </div>
					<div className="content-1">
						On-call hardware support enables you to function without any hiccups
					</div>
				</div>
				<div className="each-feature-container">
					<div className="image-container">
						<img src={require('../../../images/new-landing/proslist/health.png')} />
					</div>
					<div className="head-1">Asset Health</div>
					<div className="content-1">
						100% Asset health, Assetze enables IT devices to function at their 100% health performance
					</div>
				</div>
			</div>
			{showAllFeature ? (
				<>
					<div className="feature-row">
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/proslist/it-management.png')} />
							</div>
							<div className="head-1">IT Management</div>
							<div className="content-1">
								New joining employees or exiting employees. Assetze will take care of the IT shipment &
								management.
							</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/proslist/repairs.png')} />
							</div>
							<div className="head-1">Repairs </div>
							<div className="content-1">Provide service for repairs of devices</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/proslist/shipment.png')} />
							</div>
							<div className="head-1">Shipment</div>
							<div className="content-1">We take care of IT asset shipments across PAN India.</div>
						</div>
					</div>
					<div className="feature-row">
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/proslist/asset-management.png')} />
							</div>
							<div className="head-1">Asset Management </div>
							<div className="content-1">Efficiently track the IT assets using Assetze platform</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/proslist/incident-management.png')} />
							</div>
							<div className="head-1">Incident Management </div>
							<div className="content-1">
								Assetze has incident management system where are all the key stakeholders can check and
								track the IT tickets
							</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/proslist/health.png')} />
							</div>
							<div className="head-1">Warehousing</div>
							<div className="content-1">Assetze helps secure your IT inventory at a safe location</div>
						</div>
					</div>
				</>
			) : null}

			<button onClick={() => toggleFeature(!showAllFeature)} className="view-feature-btn">
				View Feature
			</button>
		</div>
	);
};

export default AssetzeProsList;
