/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import Slider from 'react-slick';
import EarlyAccessModel from '../../components/EarlyAccess';
import LandingScreenFooter from '../../components/LandingFooter';
import LandingPageHeader from '../../components/LandingpageHeader';
import './index.css';
import FeatureList from '../NewLandingDesign/featureList';

const settings = {
	infinite: true,
	speed: 500,
	autoplay: true,
	autoplaySpeed: 5000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
};

const IdfcConnect = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />

			<div className={'idfc-concect-container'}>
				<div className={'section-1'}>
					<div className="left-part">
						<h2 className="heading">
							Improve your <br /> company’s productivity
						</h2>
						<div className="paragraph">
							Automate IT management and increase device efficiency with Assetze. IDFC first bank
							customers get 50% discount for the first year.
						</div>
						<div className="actions">
							{/* <EarlyAccessModel className="get-started" content="Claim Offer" /> */}
							<EarlyAccessModel className="get-started" content="Request Demo" />
							{/* <button className="get-started">Get Started</button>
							<button className="request-demo">Request Demo</button> */}
						</div>
					</div>
					<div>
						<img src={require('../../images/idfc-connect-1.png')} className={'idfc-connect-image-1'} />
					</div>
				</div>
				<div className="section-2">
					<div className="each-feature">
						<img className="feature-img" src={require('../../images/idfc/manage-assets.png')} />

						<div className="heading">Manage Assets</div>
						<div className="info">
							Keep track of IT/Non-IT assets without any hassle. Manage IT tickets and keep track of every
							device issues raised.
						</div>
					</div>
					<div className="each-feature">
						<img className="feature-img" src={require('../../images/idfc/check-devicehealth.png')} />

						<div className="heading">Manage Assets</div>
						<div className="info">
							Keep track of IT/Non-IT assets without any hassle. Manage IT tickets and keep track of every
							device issues raised.
						</div>
					</div>
					<div className="each-feature">
						<img className="feature-img" src={require('../../images/idfc/generate-reports.png')} />

						<div className="heading">Manage Assets</div>
						<div className="info">
							Keep track of IT/Non-IT assets without any hassle. Manage IT tickets and keep track of every
							device issues raised.
						</div>
					</div>
				</div>
				<FeatureList />
				<div className="section-3">
					<div className="each-feature">
						<div className="image-container">
							<img src={require('../../images/idfc/admins.png')} />
						</div>
						<div className="content-container">
							<div className="heading">Multiple Admins</div>
							<div className="info">Provide role based access to admins.</div>
						</div>
					</div>
					<div className="each-feature reverse">
						<div className="image-container">
							<img src={require('../../images/idfc/it-reports.png')} />
						</div>
						<div className="content-container">
							<div className="heading">IT Reports</div>
							<div className="info">Financial reports and device reports at the fingertips</div>
						</div>
					</div>
					<div className="each-feature">
						<div className="image-container">
							<img style={{ width: '250px' }} src={require('../../images/idfc/employee-signoff.png')} />
						</div>
						<div className="content-container">
							<div className="heading">Employee Sign-off</div>
							<div className="info">
								Now taking exit sign-off from different departments is much easier.
							</div>
						</div>
					</div>
					<div className="each-feature reverse">
						<div className="image-container">
							<img src={require('../../images/idfc/diagnostic.png')} />
						</div>
						<div className="content-container">
							<div className="heading">Diagnostic Tool</div>
							<div className="info">
								Automatically fetch the device details from the backend to the suite.
							</div>
						</div>
					</div>
					<div className="each-feature">
						<div className="image-container">
							<img src={require('../../images/idfc/device-history.png')} />
						</div>
						<div className="content-container">
							<div className="heading">Device History</div>
							<div className="info">Track the device change-overs between employees</div>
						</div>
					</div>
					<div style={{ marginTop: '80px' }} className="each-feature reverse">
						<div className="image-container">
							<img src={require('../../images/idfc/dashboard.png')} />
						</div>
						<div className="content-container">
							<div className="heading main">Easy,Secure, and Smart</div>
						</div>
					</div>
				</div>
				<div className={'section-4'}>
					<img className="idfc-logo" src={require('../../images/idfc/idfc.png')} />
					<div className="heading">Exclusive offer to IDFC First Bank Customers</div>
					<div className="content">
						50% off on the first year <br /> Use code : IDFC50
					</div>
					<EarlyAccessModel content="Claim Offer" />
					{/* <button>Claim Offer</button> */}
				</div>

				{/* <div className="section-5">
					<div className="heading">Loved by the community</div>
					<div className="info">Loved and used by some of the best service companies in the world.</div>
					<Slider {...settings}>
						<div className="community-review">
							<div className="image-container">
								<img src={require('../../images/testimonial-1.png')} />
							</div>
							<div className="contents">
								<div className="info">
									Assetze has helped us in streamlining the process of IT management, ticketing
									resolution, and majorly the productivity of the entire organization
								</div>
								<div className="company-info">
									<div className="name">
										<b>Brahmanand Alapati</b> - Director, Datafoundry
									</div>
									<img src={require('../../images/testimonial-33.png')} />
								</div>
							</div>
						</div>
						<div className="community-review">
							<div className="image-container">
								<img src={require('../../images/testimonial-2.png')} />
							</div>
							<div className="contents">
								<div className="info">
									Assetze has simplified our complex IT management, as we are spread across PAN India.
									Managing all the local and central assets and IT management was a massive problem,
									which got streamlined thanks to Assetze.
								</div>
								<div className="company-info">
									<div className="name">
										<b>Omprakash R</b> - IT Admin lead, Loadshare
									</div>
									<img src={require('../../images/testimonial-22.png')} />
								</div>
							</div>
						</div>
					</Slider>
				</div> */}
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default IdfcConnect;
