import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

function AssetsTopHeader() {
	const location = useLocation();
	const navigate = useNavigate();
	const companyRoles = useSelector((state) => state.appreducer.companyRoles);

	const isIncidentsScreen = location.pathname.includes('incidents');
	const gotoPath = function (path) {
		if (path !== location.pathname) {
			navigate(path);
		}
	};
	return (
		<div className="management-header">
			<div onClick={() => gotoPath('/assets')} className={isIncidentsScreen ? '' : 'active'}>
				Asset Management
			</div>
			{companyRoles.includes('incident-management') ? (
				<div onClick={() => gotoPath('/incidents')} className={isIncidentsScreen ? 'active' : ''}>
					Incident Management
				</div>
			) : null}
		</div>
	);
}

export default AssetsTopHeader;
