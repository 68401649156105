import * as React from 'react';
const CytivaImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={135} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M4.91 0c4.277.701 7.97 2.459 11.101 5.327 3.781 3.461 6.11 7.715 6.807 12.832.837 6.141-.615 11.685-4.468 16.517-3.4 4.265-7.85 6.887-13.26 
7.785-5.505.917-10.663-.132-15.294-3.194-5.279-3.486-8.514-8.404-9.51-14.718-.907-5.762.343-11.055 3.722-15.763 2.273-3.165 5.305-5.393 9.371-5.506 3.31-.091 6.462 1.753 7.732 
5.422 1.25 3.614.12 6.65-2.522 9.244-1.668 1.637-3.626 2.902-5.49 4.288-1.847 1.374-3.447 2.904-4.066 5.25-1.192 4.514 1.555 8.485 6.256 9.407 5.659 1.108 9.33-3.903 
9.434-7.867.181-6.928.036-13.856.077-20.786.015-2.45 0-4.9.007-7.35C4.806.598 4.725.29 4.91 0ZM128.863 13.37c.202-1.662.202-1.662 1.89-1.662 1.045 0 2.091.024 
3.135-.01.518-.017.732.09.73.678-.022 5.972-.022 11.943 0 17.915.002.577-.185.692-.714.68a70.065 70.065 0 0 
0-4.209.003c-.648.023-.84-.192-.772-.803.047-.41.009-.827.009-1.218-.17-.119-.245-.02-.319.072-3.061 3.775-8.349 2.634-10.772-.544a9.055 9.055 0 0 
1-1.691-3.8c-.611-3.139-.543-6.23.958-9.145 1.61-3.125 5.038-4.87 8.318-4.27 1.388.256 2.513.984 3.437 2.105Zm-7.347 8.142a9.977 9.977 0 0 0 .271 2.652c.389 1.64 1.652 2.79 
3.27 2.997a3.392 3.392 0 0 0 3.567-2.16c.994-2.539.983-5.127-.282-7.605-.609-1.195-1.671-1.764-3.007-1.713-1.374.052-2.425.662-3.091 1.919-.64 1.203-.746 2.493-.728 
3.91ZM52.09 11.655c1.842 0 3.574.024 5.305-.014.522-.01.63.256.77.655 1.257 3.533 2.532 7.059 3.803 10.587.05.138.117.269.246.566.456-1.415.87-2.7 1.282-3.985.765-2.38 
1.545-4.756 2.276-7.147.162-.527.399-.687.938-.674 1.613.036 3.225.012 4.99.012-.486 1.247-.94 2.418-1.4 3.586-2.271 5.757-4.484 11.534-6.882 17.24-.77 1.832-1.876 3.38-3.935 
3.826-1.683.364-3.381.188-5.036-.323-.323-.1-.524-.215-.424-.62.25-1.012.49-2.029.68-3.053.09-.48.326-.466.696-.386.405.09.821.142 1.236.18 2.102.19 3.553-1.799 2.711-3.722C57 
23.013 54.647 17.646 52.3 12.276c-.067-.162-.111-.332-.21-.62ZM49.096 18.1c-.298 
0-.599-.028-.895.004-1.11.115-2.007.061-2.443-1.303-.294-.924-1.287-1.145-2.243-1.134-1.197.014-2.063.565-2.547 1.638-.956 2.118-.98 4.33-.518 6.566.095.46.261.913.439 1.35.44 
1.076 1.2 1.761 2.407 1.82 1.341.067 2.426-.387 2.947-1.7.199-.5.44-.654.963-.64 1.372.038 2.746.029 4.12.004.466-.01.523.11.416.556-1.123 4.666-4.569 6.69-9.515 
6.184-4.746-.486-7.029-3.688-7.596-7.705-.35-2.486-.234-4.936.727-7.291 1.329-3.255 3.763-5.081 7.265-5.345 2.626-.197 5.124.155 7.061 2.248 1.107 1.196 1.778 2.579 2.088 
4.171.106.54-.1.581-.53.567-.715-.023-1.432-.007-2.148-.007a.05.05 0 0 1 .002.016ZM105.735 25.61c1.277-4.415 2.51-8.576 3.666-12.757.248-.895.599-1.245 1.564-1.17 1.306.103 
2.626.04 3.939.019.516-.008.762.009.52.669a2638.29 2638.29 0 0 0-6.53 
18.123c-.138.386-.303.508-.691.504-1.702-.016-3.404-.034-5.104.007-.567.015-.592-.353-.717-.705l-4.407-12.256c-.683-1.902-1.34-3.814-2.059-5.7-.215-.567-.072-.651.457-.644 
1.582.021 3.166.03 4.748-.005.516-.011.706.156.843.65 1.094 3.955 2.224 7.901 3.345 11.848.111.4.238.795.426 1.417ZM73.624 
16.665c0-2.955.014-5.91-.012-8.865-.006-.58.14-.784.746-.766 1.43.042 2.866.036 4.299.002.529-.012.649.178.635.667-.033 1.075.025 2.15-.024 3.223-.027.605.117.825.768.8 
1.4-.054 2.807.005 4.21-.027.51-.012.668.147.652.655a55.148 55.148 0 0 0-.002 3.223c.013.44-.14.55-.561.543-1.462-.023-2.925.007-4.389-.018-.459-.007-.687.041-.68.605.034 
2.536-.005 5.073.03 7.611.022 1.767.984 2.615 2.73 2.495.476-.032.985-.009 1.414-.177.943-.375 1.291-.045 1.385.887.08.798.236 1.591.412 
2.375.093.418.072.62-.409.762-2.204.646-4.44.913-6.715.55-2.073-.33-3.433-1.535-4.01-3.576-.393-1.39-.483-2.814-.48-4.248.005-2.244.001-4.482.001-6.72ZM93.626 21.355c0 
2.984-.015 5.967.012 8.951.005.537-.106.718-.678.7A87.169 87.169 0 0 0 88.573 31c-.482.01-.67-.093-.669-.63.02-4.982-.009-9.964.022-14.946.012-2.138 1.626-3.673 3.773-3.715 
1.927-.035 1.927-.035 1.927 1.86v7.786ZM94.027 6.96c.002 1.744-1.497 3.21-3.276 3.202-1.742-.007-3.218-1.506-3.225-3.275-.007-1.78 1.52-3.266 3.34-3.244 1.76.02 3.16 1.488 
3.161 3.318ZM111.792 48.074c.034-.023.02-.059.02-.088.009-.312.054-.62.095-.93.057-.43.145-.854.256-1.272.097-.364.214-.723.348-1.074.271-.71.608-1.389 
1.01-2.034.161-.259.332-.51.515-.752a12.003 12.003 0 0 1 1.205-1.367 11.238 11.238 0 0 1 
6.842-3.126c.223-.02.445-.026.667-.039.189-.01.377-.02.565-.013.302.013.603.026.904.058a11.358 11.358 0 0 1 3.162.787 11.542 11.542 0 0 1 2.633 1.523 11.065 11.065 0 0 1 1.18 
1.058c.413.42.784.872 1.126 1.35.354.496.664 1.014.931 1.557a10.937 10.937 0 0 1 .99 
3.058c.051.305.094.612.117.92.016.203.029.406.038.608.009.181.019.364.014.546-.009.25-.016.502-.038.753-.034.382-.079.764-.15 1.14-.076.393-.167.78-.28 1.165a11.196 11.196 0 0 
1-1.93 3.746c-.21.267-.438.52-.667.771a10.783 10.783 0 0 1-1.93 1.649 11.294 11.294 0 0 1-5.164 1.867c-.17.016-.34.04-.512.037-.017 0-.033 
0-.04.018h-1.212c-.024-.034-.06-.02-.088-.02-.162-.005-.321-.025-.481-.039a9.746 9.746 0 0 1-1.248-.2c-.413-.092-.822-.202-1.222-.343a12.289 12.289 0 0 
1-1.11-.445c-.74-.348-1.44-.766-2.089-1.265a11.305 11.305 0 0 1-3.377-4.17 11.778 11.778 0 0 1-.782-2.21 9.731 9.731 0 0 
1-.176-.948c-.031-.242-.068-.482-.086-.726-.007-.1.014-.208-.034-.305-.002-.413-.002-.829-.002-1.245Zm.536 1.227c.056 2.52 1.173 5.178 3.464 7.294 2.292 2.118 5.032 3.065 
8.138 2.836 2.818-.208 5.212-1.38 7.117-3.47 2.045-2.246 2.988-4.913 2.8-7.946-.181-2.918-1.385-5.377-3.557-7.328-2.234-2.009-4.878-2.91-7.879-2.748-5.328.285-10.081 
4.762-10.083 11.362Z"
		/>
		<path
			fill="#888"
			d="M123.089 58.773c-.957-.003-1.904-.102-2.828-.373-.104-.03-.206-.07-.308-.107-.034-.013-.074-.025-.06-.072.018-.052.06-.034.097-.021.811.25 1.641.37 
2.491.353.481-.009.951-.088 1.385-.31.208-.108.389-.248.522-.443.226-.337.206-.746-.05-1.03-.241-.267-.648-.326-.997-.145a1.538 1.538 0 0 
0-.345.242c-.588.542-1.289.739-2.077.717-.715-.02-1.402-.174-2.08-.38-2.5-.762-4.177-2.409-5.14-4.81a9.586 9.586 0 0 1-.619-2.557 10.513 10.513 0 0 1 
.409-4.235c.012-.043.005-.134.088-.106.071.025.014.092.001.133a8.104 8.104 0 0 0-.338 2.407c.007.498.082.983.321 
1.43.139.263.328.483.615.596.317.126.622.1.873-.145s.276-.55.162-.871c-.07-.192-.199-.352-.325-.508-.309-.38-.524-.803-.599-1.291a4.295 4.295 0 0 1-.022-1.16 8.996 8.996 0 0 1 
.874-2.98c.705-1.417 1.772-2.49 3.144-3.267 1.028-.583 2.134-.939 3.3-1.116a9.894 9.894 0 0 1 4.575.353.776.776 0 0 1 
.09.034c.034.015.082.024.061.077-.02.047-.059.026-.092.015a6.062 6.062 0 0 
0-.588-.174c-.732-.172-1.471-.253-2.222-.183-.493.045-.967.17-1.358.498-.228.19-.373.43-.382.736-.014.47.373.82.872.775.247-.024.445-.151.633-.298.212-.167.421-.336.664-.458.383-.191.794-.261 
1.217-.272.802-.022 1.57.152 2.33.38 1.34.4 2.5 1.09 3.454 2.118.89.958 1.496 2.079 1.885 3.322.226.72.364 1.458.429 2.21.059.697.056 1.395-.027 2.09a9.767 9.767 0 0 1-.403 
1.884l-.017.045c-.01.036-.03.057-.069.044-.04-.012-.031-.044-.024-.071.022-.08.047-.16.068-.24a7.956 7.956 0 0 0 
.294-2.25c-.008-.437-.073-.868-.247-1.273-.106-.244-.244-.466-.457-.631-.234-.181-.495-.253-.786-.18-.368.092-.594.463-.538.863.029.212.124.387.248.554.118.16.243.314.355.48.251.369.367.785.407 
1.224.07.791-.074 1.555-.285 2.312-.25.891-.598 1.736-1.127 2.5-.825 1.193-1.915 2.064-3.224 2.672a9.673 9.673 0 0 
1-2.748.795c-.495.066-.987.1-1.482.099Zm-1.709-10.866c-.03.192-.061.355-.082.52-.007.055-.031.084-.077.107-.208.106-.415.216-.621.325-.698.368-1.392.74-2.028 
1.209-.477.351-.919.74-1.233 1.254-.321.525-.525 1.077-.453 1.71a1.701 1.701 0 0 0 1.65 1.543c.8.034 1.476-.248 2.043-.796.697-.677 1.097-1.521 
1.311-2.458.166-.733.224-1.48.269-2.229.005-.086.032-.136.109-.18a28.834 28.834 0 0 0 
2.705-1.707c.032-.023.066-.071.105-.001.072.122.162.23.271.32-.007.008-.01.015-.016.019a1.21 1.21 0 0 1-.084.046c-.669.372-1.227.861-1.607 1.532-.465.818-.596 1.698-.456 
2.618.11.716.398 1.36.908 1.884.836.859 1.865 1.12 3.013.868 1.573-.346 2.794-2.052 2.091-3.912-.495-1.31-1.723-1.57-2.643-1.216-.971.371-1.407 1.431-.965 
2.34.175.366.45.61.873.652.312.03.527-.18.488-.476-.016-.118-.086-.211-.162-.301-.069-.084-.145-.165-.204-.255a.725.725 0 0 1 .386-1.114c.45-.152.939.067 
1.169.531.219.443.249.91.136 1.387-.254 1.08-1.298 1.707-2.353 1.408-.604-.172-1.024-.574-1.296-1.125-.463-.941-.444-1.903-.042-2.853.311-.734.865-1.227 1.618-1.492a.39.39 0 0 
1 .188-.017c.285.043.574.063.863.043.215-.014.429-.039.613-.165a.38.38 0 0 0 .042-.617.89.89 0 0 0-.35-.175c-.402-.115-.8-.074-1.193.043a.159.159 0 0 1-.15-.022 1.082 1.082 0 
0 
1-.357-.455c-.027-.056-.02-.086.032-.126.342-.258.676-.53.985-.828.389-.375.746-.779.964-1.281.146-.332.226-.676.16-1.039-.102-.556-.59-.895-1.175-.84-.437.039-.801.23-1.113.528-.486.467-.773 
1.048-.933 1.694a3.624 3.624 0 0 0-.057 1.544c.01.059-.024.079-.06.106-.565.412-1.153.789-1.748 1.151-.226.138-.454.274-.691.418 0-.052-.003-.088 
0-.126.018-.213.049-.427.079-.638a.578.578 0 0 1 .162-.325c.231-.253.446-.52.608-.825.08-.15.139-.308.156-.48.025-.287-.164-.474-.451-.44-.235.027-.407.162-.556.332a2.352 
2.352 0 0 0-.525 1.024.898.898 0 0 1-.314.497l-.043.038c-.256.216-.531.401-.87.47-.314.062-.547-.072-.66-.372-.023-.06-.02-.091.049-.115.114-.037.226-.091.331-.15.833-.472 
1.457-1.143 1.9-1.988.22-.42.355-.864.358-1.341.004-.465-.235-.814-.633-.94a1.393 1.393 0 0 0-.692-.038c-.499.1-.884.383-1.205.757-.707.824-1.075 1.785-1.13 
2.866-.004.076-.032.085-.093.074-.241-.041-.393-.192-.488-.406-.115-.252-.124-.52-.09-.79.027-.217.099-.424.129-.639.031-.21-.089-.334-.299-.316a.509.509 0 0 0-.314.151.94.94 
0 0 0-.203.29c-.161.358-.208.732-.161 1.118.045.382.19.723.466 1 .265.268.588.417.96.462.086.01.136.03.154.127.021.113.075.217.133.317.285.51.79.784 1.336.725.38-.05.737-.167 
1.063-.44Z"
		/>
		<path
			fill="#888"
			d="M121.179 49.512c-.159 1.049-.351 2.071-.887 
2.991-.221.379-.503.705-.897.913-.275.144-.567.223-.879.181-.351-.046-.583-.303-.608-.662-.032-.473.147-.877.411-1.251.358-.51.827-.908 1.327-1.268a10.61 10.61 0 0 1 
1.474-.881.334.334 0 0 0 .059-.023ZM119.717 46.203c.013-.334.086-.644.19-.947.178-.518.415-1.006.757-1.437.092-.114.196-.222.318-.305a.59.59 0 0 1 .236-.1.294.294 0 0 1 
.347.264c.021.192-.029.371-.108.54a4.748 4.748 0 0 1-1.627 1.931.606.606 0 0 1-.075.043c-.007.002-.018.004-.038.011ZM125.64 45.727a2.82 2.82 0 0 1 
.097-.753c.116-.457.305-.883.615-1.245.093-.108.197-.203.326-.264.119-.057.241-.073.354.013.114.088.123.21.1.339-.03.167-.106.316-.192.457-.341.551-.785 1.01-1.264 
1.439-.007.005-.018.007-.036.014Z"
		/>
	</svg>
);
export default CytivaImg;
