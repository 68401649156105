import React, { useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/IT-tracking/Header';
import Logo from '../../../images/assetze-logo.png';
import LoadingBar from 'react-top-loading-bar';

const ITLogin = function () {
	const navigate = useNavigate();
	const [progress, setProgress] = useState(50);
	const [view, setView] = useState('login');
	// gs: get started
	return (
		<div>
			{view === 'login' ? (
				<div className="register-container">
					<Header />
					<img className="logo" src={Logo} />
					<div className="tagline">Just one simple step to have </div>
					<div className="inputs">
						<input placeholder="Employee ID" />
						<input placeholder="Business Mail" />
						<input placeholder="OTP" />
					</div>
					<button
						onClick={() => {
							setView('fetching-details');
						}}
					>
						Log In
					</button>
					<hr />
				</div>
			) : null}
			{view === 'fetching-details' ? (
				<div>
					<Header />
					<div>
						<LoadingBar
							className="loading-bar"
							color="#55FC9D"
							progress={progress}
							onLoaderFinished={() => setProgress(50)}
						/>
						<div className="fetch-details">Fetching Details...</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default ITLogin;
