import { v4 as uuidv4 } from 'uuid';
import { Strings } from '../screens/vendors/config';
import {
	LOGIN_FAILED,
	LOGIN_MODAL,
	SAVE_USER_INFO,
	INCREATE_ASSETS_PAGENUMBER,
	LOGIN_SUCCESS,
	SET_ASSETS_RESULTS,
	LOGOUT_USER,
	ADD_NEW_ASSETS_PAGINATION,
	SET_SEARCH_RESULTS,
	SET_FILTER_TEXT,
	ADD_FILTER_TABLE,
	RESET_ASSETS_TABLE,
	UPDATE_ASSET_INFO,
	UPDATE_COMPANY_ROLES,
	SET_COMPANY_LOGO,
	REMOVE_ASSET,
	ADD_NEW_QUESTION,
	VENDOR_FORM_DATA,
	VENDOR_CUSTOM_DATA,
	UPDATE_QUESTION,
	CHANGE_QUESTION_TYPE,
	UPDATE_CHOICE,
	ADD_NEW_CHOICE,
	DELETE_SECTION,
	DELETE_QUESTION,
	ADD_QUESTIONS,
	ADD_VENDOR_DETAILS,
	HANDLE_INPUT_CHANGES,
	UPDATE_QUESTIONS,
	UPDATE_SECTION,
	UPDATE_UPLOAD,
	MOVE_QUESTION_UP,
	MOVE_QUESTION_DOWN,
	ADD_CHOICES,
	DELETE_CHOICE,
	SAVE_SECTION_ID,
	ADD_NEW_SECTION,
	MOVE_SECTION_UP,
	MOVE_SECTION_DOWN,
	SHOW_TEMPLATE,
	SHOW_CUSTOM_FIELD,
	UPDATE_MULTIPLE_CHOICE_QUESTION,
	UPDATE_SECTIONS,
	RESET_FORM,
	UPDATE_QUESTION_ANSWER,
	UPDATE_MCQ_ANSWER,
	UPDATE_FILE_UPLOAD,
	SET_SECTION_ERROR,
	SET_QUESTION_ERROR,
	SET_UPlOAD_ERROR,
	CHECKED_MCQ_ANS,
	SET_ASSET_TABLS_COLUMNS,
	PERMISSIONS_SUCCESSFUl,
	ROLENAME_SUCCESSFUL,
} from './actions';
import { addExtraColums, defaultColumns } from './addExtraColums';
import { actionTypes } from 'react-redux-snackbar/dist/state/snackbarReducer';

const initialState = {
	loggedIn: null,
	login: {
		showModal: false,
	},
	initialLoading: true,
	assets: [],
	user: {},
	assetsPageNumber: 0,
	filterText: '',
	companyRoles: ['asset-management', 'it-products', 'it-services', 'saas-products', 'incident-management'],
	assetsTableColumns: defaultColumns,
	resetAssetsTable: '123123',
	companyLogo: null,
	vendorFormData: {
		vendorName: '',
		vendorType: '',
		pointOfContact: '',
		email: '',
		contactNumber: '',
		website: '',
		sections: [
			{
				_id: uuidv4(),
				validSection: true,
				qtype: Strings.SECTION,
				questions: [],
			},
		],
	},
	customFieldErrors: {
		isSection: false,
		isQuestion: false,
		isUpload: false,
	},
	totalAssetsCount: 0,
	assetsTableLimit: 10,
	assetsTablePageNum: 0,
	roleName: '',
	permissions: [
		{
			obj: 'on_asset',
			permList: [
				{ obj: 'asset', actions: ['create', 'view', 'delete', 'edit'], label: 'Asset View' },
				{
					obj: 'asset_update_custom',
					actions: ['create', 'delete', 'edit'],
					label: 'Update Asset Custom Field',
				},
				{ obj: 'audit', actions: ['view', 'create'], label: 'Asset Audit' },
				{ obj: 'image', actions: ['view', 'create'], label: 'Asset Image' },
				{ obj: 'activity', actions: ['view'], label: 'Asset Activity' },
				{ obj: 'depreciation', actions: ['view', 'create'], label: 'Asset Depreciation' },
				{ obj: 'asset_assign_unassign', actions: ['create'], label: 'Asset Assign/UnAssign' },
				{ obj: 'archive_lit', actions: ['view'], label: 'Asset Archive' },
				{ obj: 'comment', actions: ['edit', 'view'], label: 'Asset Comment' },
				{ obj: 'programs_view', actions: ['view'], label: 'Programs Installed' },
				{ obj: 'ram_view', actions: ['view'], label: 'Asset RAM View' },
				{ obj: 'location_view', actions: ['view'], label: 'Asset Location View' },
				{ obj: 'users_view', actions: ['view'], label: 'Asset Users View' },
				{ obj: 'health_view', actions: ['view'], label: 'Asset Health View' },
				{ obj: 'report_generate', actions: ['create'], label: 'Asset Report' },
				{ obj: 'generate_tag', actions: ['create'], label: 'Asset Tag ' },
				{ obj: 'filter', actions: ['view'], label: 'Asset Filter' },
			],
		},
		{
			obj: 'on_organsiation',
			permList: [
				{ obj: 'presonal_information', actions: ['view', 'edit'], label: 'Personal Information' },
				{ obj: 'company_information', actions: ['edit', 'view'], label: 'Organisation Information' },
				{
					obj: 'company_branches',
					actions: ['create', 'edit', 'view', 'delete'],
					label: 'Organisation Branches',
				},
				{ obj: 'asset_tag_setup', actions: ['edit', 'view'], label: 'Organisation Setup Tag' },
				{ obj: 'asset_types', actions: ['view', 'create', 'delete'], label: 'Organisation Asset Types' },
				{ obj: 'purchase_order', actions: ['view', 'create', 'delete'], label: 'Organisation Invoices' },
				{ obj: 'admin_setup', actions: ['create', 'view'], label: 'Oraganisation Admin' },
				{
					obj: 'employee_setup',
					actions: ['create', 'view', 'edit', 'delete'],
					label: 'Organisation Employee',
				},
				{ obj: 'agent_computers', actions: ['view'], label: 'Organisation Agent' },
				{ obj: 'activity', actions: ['view'], label: 'Activity' },
				{ obj: 'agent_computer_report', actions: ['create'], label: 'Organisation Agent Report' },
				{ obj: 'download_agent', actions: ['create'], label: 'Organisation Download Agent Report' },
			],
		},
	],
};

function reducerFunction(state, action) {
	if (typeof state === 'undefined') {
		return initialState;
	}

	switch (action.type) {
		case SET_COMPANY_LOGO:
			return {
				...state,
				companyLogo: action.payload,
			};
		case LOGIN_SUCCESS:
			return {
				...state,
				loggedIn: true,
				initialLoading: false,
			};
		case UPDATE_COMPANY_ROLES:
			return {
				...state,
				companyRoles: action.payload,
			};
		case PERMISSIONS_SUCCESSFUl:
			return {
				...state,
				permissions: action.payload,
			};
		case ROLENAME_SUCCESSFUL:
			return {
				...state,
				roleName: action.payload,
			};
		case LOGIN_FAILED:
			return {
				...state,
				loggedIn: false,
				initialLoading: false,
			};
		case SET_FILTER_TEXT:
			return {
				...state,
				filterText: action.payload,
			};
		case SET_ASSET_TABLS_COLUMNS:
			console.log('set_ass', action, state.assetsTableColumns);
			const assetsToAdd = [];
			for (let x = 0; x < action.payload.length; x += 1) {
				const found = state.assetsTableColumns.find((el) => el.mapId === action.payload[x].mapId);
				if (!found) {
					assetsToAdd.push(action.payload[x]);
				}
			}
			return {
				...state,
				assetsTableColumns: [...state.assetsTableColumns, ...assetsToAdd],
			};

		case SET_SEARCH_RESULTS:
			return {
				...state,
				assets: action.payload,
				// assetsTableColumns: addExtraColums(action.payload, state.assetsTableColumns),
				assetsPageNumber: 0,
				filterText: action.filterText,
			};

		case SET_ASSETS_RESULTS:
			return {
				...state,
				assets: action.payload,
				totalAssetsCount: action.totalAssetsCount,
				assetsTableLimit: action.limit,
				assetsTablePageNum: action.pageNumber,
				// assetsTableColumns: addExtraColums(action.payload, state.assetsTableColumns),
			};
		case RESET_ASSETS_TABLE:
			return {
				...state,
				resetAssetsTable: action.payload,
			};
		case ADD_NEW_ASSETS_PAGINATION:
			return {
				...state,
				assets: state.assets.concat(action.payload),
				// assetsTableColumns: addExtraColums(state.assets.concat(action.payload), state.assetsTableColumns),
			};
		case LOGOUT_USER:
			return {
				...state,
				loggedIn: false,
			};
		case SAVE_USER_INFO:
			return {
				...state,
				user: action.payload,
			};
		case LOGIN_MODAL:
			return {
				...state,
				login: {
					...state.login,
					showModal: action.payload,
				},
			};

		case INCREATE_ASSETS_PAGENUMBER:
			return {
				...state,
				assetsPageNumber: state.assetsPageNumber + 1,
			};
		case ADD_FILTER_TABLE:
			return {
				...state,
				assetsTableColumns: action.payload,
			};
		case UPDATE_ASSET_INFO:
			return {
				...state,
				assets: state.assets.map((el) => {
					if (el._id === action.payload._id) {
						return action.payload;
					}
					return el;
				}),
			};
		case REMOVE_ASSET:
			return {
				...state,
				assets: state.assets.filter((el) => {
					return el._id !== action.payload;
				}),
			};

		case ADD_NEW_QUESTION:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData?.sections?.map((section) => {
						const { sectionId, _id, qtype, qIndex, deleteCount } = action.payload;
						if (section._id === sectionId && state.customFieldErrors.isSection === false) {
							const data = { _id, qtype, validQuestion: true };
							section.questions.splice(qIndex, deleteCount, data);
							return {
								...section,
								questions: [...section.questions],
							};
						}
						return section;
					}),
				},
			};

		case ADD_NEW_SECTION:
			return {
				...state,

				vendorFormData: {
					...state.vendorFormData,
					sections: [
						...state.vendorFormData.sections,

						{
							_id: action.payload._id,
							validSection: true,
							qtype: action.payload.qtype,
							questions: [],
						},
					],
				},
			};

		case UPDATE_QUESTION:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										if (question.qtype === 'question-answer') {
											return {
												...question,
												validQuestion: true,
												question: action.payload.question,
											};
										}
										// else if (question.qtype === Strings.MULTIPLE_CHOICE) {
										//   return {
										//     ...question,
										//     question: action.payload.question,
										//   };
										// }
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case UPDATE_MULTIPLE_CHOICE_QUESTION:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										if (question.qtype === Strings.MULTIPLE_CHOICE) {
											return {
												...question,
												validQuestion: true,
												question: action.payload.question,
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case CHANGE_QUESTION_TYPE:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (
										question._id === action.payload.qId &&
										action.payload.qtype === Strings.QUESTION_ANSWER
									) {
										return {
											...question,
											qtype: action.payload.qtype,
										};
									} else if (
										question._id === action.payload.qId &&
										action.payload.qtype === Strings.MULTIPLE_CHOICE
									) {
										return {
											...question,
											qtype: action.payload.qtype,
											mcqAnswer: [],
											choices: [{ _id: uuidv4(), choice: '' }],
										};
									}

									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case ADD_CHOICES:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										if (action.payload.qtype === Strings.MULTIPLE_CHOICE) {
											return {
												...question,
												qtype: action.payload.qtype,
												choices: question.choices.concat({
													_id: uuidv4(),
													choice: '',
												}),
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case UPDATE_CHOICE:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										if (action.payload.qtype === Strings.MULTIPLE_CHOICE) {
											return {
												...question,
												choices: question.choices.map((choice) => {
													if (choice._id === action.payload.choiceId) {
														return {
															...choice,
															choice: action.payload.choice,
														};
													}
													return choice;
												}),
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case DELETE_CHOICE:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										if (question.qtype === Strings.MULTIPLE_CHOICE) {
											return {
												...question,
												choices: question.choices.filter(
													(choice) => choice._id !== action.payload.choiceId
												),
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case UPDATE_SECTION:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((el) => {
						if (el._id === action.payload.sectionId) {
							if ((el.qtype = 'section')) {
								if (action.payload.updateFeild === 'name') {
									return {
										...el,
										validSection: true,
										name: action.payload.name,
									};
								} else if (action.payload.updateFeild === 'description') {
									return {
										...el,
										validSection: true,
										description: action.payload.desc,
									};
								}
							}
						}
						return el;
					}),
				},
			};

		case UPDATE_QUESTION_ANSWER:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections?.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions?.map((question) => {
									if (question._id === action.payload.qId) {
										if (question.qtype === 'question-answer') {
											return {
												...question,
												answer: action.payload.answer,
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case UPDATE_MCQ_ANSWER:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections?.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions?.map((question) => {
									if (question._id === action.payload.qId) {
										if (question.qtype === Strings.MULTIPLE_CHOICE) {
											if (question?.mcqAnswer?.includes(action.payload.mcqAnswer)) {
												return {
													...question,
													mcqAnswer: question.mcqAnswer.filter(
														(mcq) => !mcq.includes(action.payload.mcqAnswer)
													),
												};
											}
											if (!question.mcqAnswer) {
												return {
													...question,
													mcqAnswer: [action.payload.mcqAnswer],
												};
											}
											return {
												...question,
												mcqAnswer: question?.mcqAnswer?.concat([action.payload.mcqAnswer]),
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case UPDATE_FILE_UPLOAD:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										if (question.qtype === Strings.UPLOAD_FILE) {
											return {
												...question,
												answer: action.payload.answer,
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case UPDATE_UPLOAD:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										if (question.qtype === 'file-question') {
											return {
												...question,
												validQuestion: true,
												question: action.payload.question,
											};
										}
									}
									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		case MOVE_QUESTION_UP:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							const { qIndex } = action.payload;
							const qns = section.questions;
							[qns[qIndex - 1], qns[qIndex]] = [qns[qIndex], qns[qIndex - 1]];

							return {
								...section,
								questions: [...qns],
							};
						}
						return section;
					}),
				},
			};

		case MOVE_QUESTION_DOWN:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							const { qIndex } = action.payload;
							const qns = section.questions;
							[qns[qIndex + 1], qns[qIndex]] = [qns[qIndex], qns[qIndex + 1]];

							return {
								...section,
								questions: [...qns],
							};
						}
						return section;
					}),
				},
			};

		case MOVE_SECTION_UP:
			const { sectionIndex } = action.payload;
			const sections = state.vendorFormData.sections;
			console.log('sectionIndex', sectionIndex, 'sections', sections);
			[sections[sectionIndex - 1], sections[sectionIndex]] = [sections[sectionIndex], sections[sectionIndex - 1]];
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: [...sections],
				},
			};

		case MOVE_SECTION_DOWN:
			const { sectionDownIndex } = action.payload;
			const section = state.vendorFormData.sections;
			[section[sectionDownIndex + 1], section[sectionDownIndex]] = [
				section[sectionDownIndex],
				section[sectionDownIndex + 1],
			];
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: [...section],
				},
			};

		case UPDATE_SECTIONS:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: action.payload?.sections,
				},
			};

		case SHOW_TEMPLATE:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: action.payload,
				},
			};

		case SHOW_CUSTOM_FIELD:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: [
						{
							_id: uuidv4(),
							validSection: true,
							qtype: Strings.SECTION,
							questions: [],
						},
					],
				},
			};

		case HANDLE_INPUT_CHANGES:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					[action.payload?.name]: action.payload?.value,
				},
			};
		case DELETE_SECTION:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.filter(
						(section) => section._id !== action.payload.sectionId
					),
				},
			};
		case DELETE_QUESTION:
			return {
				...state,
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.filter((q) => q._id !== action.payload.qId),
							};
						}
						return section;
					}),
				},
			};
		case RESET_FORM:
			return {
				...state,
				vendorFormData: {
					vendorName: '',
					vendorType: '',
					pointOfContact: '',
					email: '',
					contactNumber: '',
					website: '',
					sections: [{ _id: uuidv4(), qtype: Strings.SECTION, questions: [] }],
				},
			};
		case SET_SECTION_ERROR:
			return {
				...state,
				customFieldErrors: {
					...state.customFieldErrors,
					isSection: action.payload.isSection,
				},
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								validSection: false,
							};
						}
						return section;
					}),
				},
			};
		case SET_QUESTION_ERROR:
			return {
				...state,
				customFieldErrors: {
					...state.customFieldErrors,
					isQuestion: action.payload.isQuestion,
				},
				vendorFormData: {
					...state.vendorFormData,
					sections: state.vendorFormData.sections.map((section) => {
						if (section._id === action.payload.sectionId) {
							return {
								...section,
								questions: section.questions.map((question) => {
									if (question._id === action.payload.qId) {
										return {
											...question,
											validQuestion: false,
										};
									}

									return question;
								}),
							};
						}
						return section;
					}),
				},
			};

		// case SET_UPlOAD_ERROR:
		//   return {
		//     ...state,
		//     customFieldErrors: {
		//       ...state.customFieldErrors,
		//       isUpload: action.payload.isUpload,
		//     },
		//     vendorFormData: {
		//       ...state.vendorFormData,
		//       sections: state.vendorFormData.sections.map((section) => {
		//         if (section._id === action.payload.sectionId) {
		//           return {
		//             ...section,
		//             questions: section.questions.map((question) => {
		//               if (question._id === action.payload.qId && question.qtype === Strings.UPLOAD_FILE) {
		//                 return {
		//                   ...question,
		//                   validQuestion: false,
		//                 };
		//               }

		//               return question;
		//             }),
		//           };
		//         }
		//         return section;
		//       }),
		//     },
		//   };

		default:
			return state;
	}
}

export default reducerFunction;
