import * as React from 'react';
const MobilyImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={268} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M32.972.001c8.707 0 17.413-.003 26.12 0 3.07 0 5.739 1.866 6.751 4.704.248.688.389 1.4.4 2.134.004.282.01.565.01.847v32.968c0 .18.002.357-.01.536a1.762 1.762 0 0 
1-2.286 1.584 4.425 4.425 0 0 
1-.536-.215c-3.732-1.69-7.464-3.385-11.196-5.072-1.125-.509-1.896-1.31-2.175-2.534-.08-.347-.086-.706-.086-1.062V20.08c0-.539-.038-1.066-.247-1.572a3.543 3.543 0 0 
0-3.311-2.22c-4.203-.005-8.41-.002-12.612-.002-4.621 0-9.242.003-13.86 0-.913 0-1.728.262-2.417.865-.762.668-1.204 1.513-1.21 2.534-.011 3.491-.07 6.982.018 10.47.07 2.793 
1.498 4.833 3.988 6.078 1.445.724 2.94 1.35 4.415 2.019l12.333 5.59 13.792 6.25c4.07 1.846 8.138 3.695 12.209 5.54.689.312 1.38.621 2.07.936.77.356 1.159.992 1.112 
1.807-.056.98-1.004 1.77-1.975 
1.601-1.025-.176-2.02-.482-3.026-.738l-18.987-4.807c-4.589-1.162-9.178-2.322-13.763-3.485-4.386-1.112-8.771-2.228-13.16-3.338-3.338-.844-6.675-1.683-10.01-2.537C2.5 44.352.46 
42.035.066 39.142a7.234 7.234 0 0 1-.065-.978c0-10.337-.003-20.67 0-31.007C.001 3.604 2.468.69 5.964.1c.457-.076.919-.1 1.38-.1h25.628ZM217.794 4.992c.031.525-.054 1.18-.208 
1.829-.332 1.435-1.22 2.338-2.632 2.67a8.152 8.152 0 0 1-3.928-.046c-1.343-.347-2.146-1.212-2.478-2.524a8.537 8.537 0 0 1 0-4.16c.34-1.373 1.204-2.222 2.601-2.539 1.158-.262 
2.315-.3 3.473-.07 1.86.371 2.647 1.19 3.041 3.065.116.555.131 1.096.131 1.775ZM102.366 25.61c.625-1.382 1.142-2.509 1.651-3.636 3.149-6.992 6.313-13.976 
9.439-20.976.316-.703.664-1.027 1.466-.996 1.482.062 2.964.039 4.438 0 .61-.015 1.057.17 1.374.687l.069.093c1.49 1.481 1.93 3.218 1.914 5.348-.1 12.773-.054 25.546-.054 38.319 
0 1.481.201 1.435-1.458 1.427-2.185 0-4.361.008-6.545-.007-1.019-.008-1.034-.023-1.034-1.058-.008-6.19 0-12.379 0-18.576V21.21c0-.324.054-.671-.17-1.188-.486 1.034-.895 
1.89-1.289 2.763-2.153 4.708-4.33 9.4-6.444 14.131-.348.772-.772 1.034-1.582.996a37.945 37.945 0 0 
0-3.975.007c-.857.047-1.227-.3-1.551-1.041-2.208-5.11-4.461-10.196-6.707-15.29-.185-.416-.378-.833-.625-1.389-.363.494-.255.919-.255 1.305-.008 7.633-.008 15.265 0 22.898 0 
1.598.062 1.474-1.435 1.474-2.223 0-4.438.008-6.66-.007-.896-.008-.912-.023-.92-.919-.015-1.204 0-2.415 0-3.62V1.5c0-1.452-.254-1.483 1.428-1.483 2.069 0 4.13.039 
6.198-.015.826-.023 1.235.262 1.574 1.011 3.512 7.81 7.054 15.605 10.597 23.4.123.317.278.625.556 1.197ZM178.184 13.852c1.289-.255 2.57-.587 3.882-.718 2.331-.224 4.662-.347 
6.985.07 4.306.771 7.362 3.156 9.199 7.123.973 2.114 1.474 4.345 1.675 6.645.278 3.195.247 6.375-.648 9.493-1.49 5.17-4.986 8.196-10.118 
9.47-3.288.817-6.638.787-9.987.617-2.686-.14-5.356-.363-8.011-.834-.154-.03-.309-.038-.463-.061-1.058-.193-1.065-.193-1.065-1.243V1.202c0-.193.007-.386 
0-.586-.016-.379.162-.595.548-.595 1.674-.007 3.349-.007 5.024-.007.347 0 .571.223.764.47.594.772 1.173 1.56 1.791 2.308.308.37.285.787.285 1.212 0 2.84-.008 5.68 0 
8.528.008.432-.092.864.139 1.32Zm-.139 16.408c-.008 2.763-.008 5.525-.008 8.288 0 1.042.008 1.058 1.065 1.22a26.51 26.51 0 0 0 5.002.332c3.666-.124 6.158-2.138 
7.007-5.704a18.03 18.03 0 0 0 .433-5.68c-.155-1.868-.402-3.712-1.282-5.41-1.157-2.238-3.002-3.404-5.495-3.55a14.388 14.388 0 0 0-5.742.802c-.964.348-.98.348-.98 1.413-.008 
2.763 0 5.526 0 8.289ZM146.318 12.944c1.636-.008 3.265.115 4.87.47 5.063 1.112 8.397 4.137 9.81 9.1 1.373 4.816 1.373 9.693-.024 14.517-1.451 5.032-4.877 8.019-9.963 
9.091a22.985 22.985 0 0 1-9.624-.023c-5.055-1.088-8.39-4.129-9.802-9.107-1.358-4.777-1.366-9.616-.015-14.401 1.505-5.34 5.155-8.343 10.558-9.323 1.389-.263 2.786-.324 
4.19-.324Zm7.178 16.678c-.07-1.93-.193-3.86-.795-5.735-.733-2.284-2.346-3.488-4.662-3.85a9.53 9.53 0 0 0-1.042-.116c-4.437-.162-6.652 1.327-7.501 5.572-.371 1.875-.432 
3.774-.332 5.688.085 1.559.247 3.095.779 4.576.695 1.96 2.061 3.172 4.083 3.597 1.22.255 2.454.285 3.705.131 2.716-.347 4.368-1.837 
5.093-4.43.51-1.767.61-3.589.672-5.433ZM238.236 13.67h4.716c1.011 0 2.022-.007 3.033 0 .965.008.972.008 1.273.965.633 2.045 1.251 4.09 1.868 6.136 1.335 4.391 2.67 8.775 4.013 
13.166.07.217.17.433.255.649.363-.178.363-.517.455-.795 2.099-6.306 4.199-12.619 6.306-18.924.447-1.335.254-1.204 1.698-1.212 1.011-.008 2.022.023 3.033-.008.524-.015.879.17 
1.18.587.433.602.849 1.22 1.351 1.76.533.578.502 1.119.239 1.805-1.366 3.558-2.693 7.132-4.036 10.697l-11.438 30.347c-.432 1.15-.432 1.157-1.628 
1.157h-6.761c-.162-.54.131-.903.286-1.273 1.566-3.936 3.141-7.872 
4.754-11.793.316-.772.332-1.45.061-2.238-3.465-9.91-6.907-19.827-10.357-29.744-.139-.355-.332-.695-.301-1.281ZM225.016 22.907V1.305c0-1.243-.185-1.297 1.25-1.29 1.328.009 
2.648.016 3.975-.007.517-.008.911.147 1.181.587.062.1.139.193.216.27 1.497 1.443 1.86 3.18 1.845 5.256-.093 12.765-.047 25.53-.047 38.295 0 
.232-.015.47.008.703.039.532-.162.779-.733.771a531.522 531.522 0 0 0-7.008 0c-.486 0-.725-.177-.694-.687.015-.27 0-.548 0-.818.007-7.162.007-14.316.007-21.478ZM207.666 
29.713v-14.47c0-.27.016-.549 0-.819-.031-.532.17-.771.741-.764 1.559.024 3.11.016 4.669 0 .425 0 .764.1.988.479.039.07.085.131.139.185 1.605 1.52 2.007 3.357 1.976 5.557-.124 
8.01-.046 16.03-.046 24.04v.587c0 1.366 0 1.366-1.313 1.366h-6.073c-1.065 0-1.073-.007-1.073-1.111-.016-5.009-.008-10.033-.008-15.05Z"
		/>
	</svg>
);
export default MobilyImg;
