import React from 'react';
import LandingPageHeader from '../../components/LandingpageHeader';
import LandingScreenFooter from '../../components/LandingFooter';
import EarlyAccessModel from '../EarlyAccess';
import SignupToAssetze from '../SignupToAssetze';

import './index.css';
import { IntegrationsComponent } from '../../screens/LandingScreen/IntegrationsComponent';

const IncideneManagment = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />
			<div className="feature-incident-container">
				<div className="content-container">
					<div className="content-text-container">
						<h1>Incident Management</h1>
						<div className="description">
							A tool like never before that simplifies the IT ticketing system. Raise ticket, assign,
							track and resolve tickets seamlessly.
						</div>
						<EarlyAccessModel className="signup-button" content="Sign Up" />
					</div>
					<div className="image-container">
						<img src={require('../../images/features/incidentManagment.png')} />
					</div>
				</div>
				<div style={{ backgroundColor: '#F9F9F9' }} className="content-container">
					<div className="content-text-container">
						<h1>
							Raising Ticket is <br /> sooo simple
						</h1>
						<div className="description">
							End-users can reach to support via add-ons integrated into your work mail IDs. <br />
							Automic log of service requests email as tickets
						</div>
					</div>
					<div className="image-container">
						<img src={require('../../images/features/emailWorkspace.png')} />
					</div>
				</div>
				<div className="content-container">
					<div className="image-container">
						<img src={require('../../images/features/ticket-placeholder.png')} />
					</div>
					<div style={{ paddingLeft: '50px' }} className="content-text-container">
						<h1>Know your ticket priorities</h1>
						<div className="description">
							Our user centric dashboard helps you focus on the most important tasks. <br /> Know the
							common tickets and automate it
						</div>
					</div>
				</div>
				<div style={{ backgroundColor: '#fafafa' }} className="features-highlights">
					<div className="each-highlight">
						<img src={require('../../images/features/reporting.png')} />
						<h2>Reporting</h2>
						<div className="description">
							Assetze reports help you make smart decisions and to reduce the repititive process. <br />{' '}
							<br />
							Helps you analyse your organisation productivity and performace
						</div>
					</div>
					<div className="each-highlight">
						<img src={require('../../images/features/automate.png')} />
						<h2>Automate</h2>
						<div className="description">
							Automate the common tasks with our tool, helps reduce consumed by repetitive tasks
						</div>
					</div>
				</div>
				<SignupToAssetze />
				<IntegrationsComponent />
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default IncideneManagment;
