import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/IT-tracking/Header';
import Mac from '../../../images/macbook.png';
import Dell from '../../../images/dell.png';
import { BiDotsVertical } from 'react-icons/bi';
const MyDevices = function () {
	const navigate = useNavigate();

	// gs: get started
	return (
		<div className="mydevices-container">
			<Header />
			<div className="heading">Your Devices</div>
			<div className="devices">
				<div className="dev1">
					<div className="dev-img">
						<img src={Mac} />
					</div>
					<div className="dev-details">
						<div>Asset type: Macbook Pro A1398</div>
						<div>Serial No. : C02STQ8VASG31</div>
						<div>Processor : Intel i7 2.2Ghz</div>
						<div>Ram : Apple 16GB 3200Mhz</div>
						<div>HDD : Samsung 512GB</div>
					</div>
					<div>
						<BiDotsVertical className="dots" />
					</div>
				</div>

				<div className="dev1">
					<div className="dev-img">
						<img src={Mac} />
					</div>{' '}
					<div className="dev-details">
						<div>Asset type: Dell Optiplex</div>
						<div>Serial No. : C02STQ8VASG31</div>
						<div>Processor : Intel i7 2.2Ghz</div>
						<div>Ram : Kingston 16GB 3200Mhz</div>
						<div>HDD : WD 512GB</div>
					</div>
					<div>
						<BiDotsVertical className="dots" />
					</div>
				</div>

				<div className="dev1">
					<div className="dev-img">
						<img src={Mac} />
					</div>{' '}
					<div className="dev-details">
						<div>Asset type: Macbook Pro A1398</div>
						<div>Serial No. : C02STQ8VASG31</div>
						<div>Processor : Intel i7 2.2Ghz</div>
						<div>Ram : Apple 16GB 3200Mhz</div>
						<div>HDD : Samsung 512GB</div>
					</div>
					<div>
						<BiDotsVertical className="dots" />
					</div>
				</div>

				<div className="dev1">
					<div className="dev-img">
						<img src={Mac} />
					</div>{' '}
					<div className="dev-details">
						<div>Asset type: Macbook Pro A1398</div>
						<div>Serial No. : C02STQ8VASG31</div>
						<div>Processor : Intel i7 2.2Ghz</div>
						<div>Ram : Apple 16GB 3200Mhz</div>
						<div>HDD : Samsung 512GB</div>
					</div>
					<div>
						<BiDotsVertical className="dots" />
					</div>
				</div>
			</div>
			<button>Other Requests</button>
		</div>
	);
};

export default MyDevices;
