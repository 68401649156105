import * as React from 'react';
const AlmaBaseImg = (props) => (
	<svg width="207" height="60" viewBox="0 0 207 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M102.497 59.3956C101.197 57.732 100.086 57.8521 97.9283 57.8109C77.3075 57.4096 56.8822 55.0601 36.6146 51.3489C26.9799 49.5859 17.4138 47.4799 7.98148 44.8183C7.09655 44.5679 6.35912 44.4959 5.45018 45.0824C3.54999 46.3103 1.94821 45.6998 0 43.3057C0 42.3968 0 41.4878 0 40.5789C0.775167 40.1193 1.09415 39.1966 1.95164 38.7816C3.7112 37.931 5.89607 38.4969 6.77414 40.3354C7.26805 41.3644 7.99863 41.6731 8.95901 41.9337C24.9871 46.2589 41.2519 49.3699 57.7054 51.5513C71.5658 53.3897 85.4742 54.5559 99.4581 54.8166C100.408 54.8338 101.125 54.7103 101.849 53.9111C103.272 52.3367 105.567 52.3436 106.994 53.9214C107.717 54.7206 108.441 54.8406 109.388 54.8166C121.88 54.4805 134.32 53.4721 146.73 52.0418C156.755 50.8893 166.74 49.483 176.659 47.6412C183.77 46.3206 190.866 44.9178 197.733 42.5854C198.594 42.2939 199.094 41.9475 199.355 40.9288C199.877 38.8879 202.216 37.8933 204.164 38.6478C205.875 39.3133 206.874 41.433 206.284 43.1479C205.605 45.1202 203.44 46.132 201.43 45.3191C200.696 45.0241 200.099 45.0035 199.352 45.2608C190.76 48.2174 181.852 49.8672 172.931 51.4312C161.588 53.4172 150.166 54.8818 138.71 56.0205C129.175 56.9672 119.619 57.708 110.039 57.7972C108.033 57.8144 107.697 58.0682 106.483 59.1898C105.862 60.1776 103.354 60.2805 102.497 59.3956Z"
			fill="#888888"
		/>
		<path
			d="M49.3054 13.7426C55.1912 9.46892 59.2317 9.77076 63.0115 14.7373C64.5035 12.5799 66.4414 11.1805 69.1133 10.8718C74.3303 10.2681 78.1169 13.1938 78.3605 18.4485C78.5834 23.2642 78.4222 28.0969 78.4599 32.9229C78.4668 33.7461 78.1375 33.969 77.3555 34.0273C73.977 34.2743 73.9873 34.2983 73.9736 30.9164C73.9564 26.9445 74.0182 22.9657 73.8501 19.0007C73.7472 16.562 72.3855 15.1592 70.3207 14.7956C67.9197 14.3703 65.7486 15.3684 64.6681 17.3818C64.085 18.4691 63.9513 19.6387 63.9513 20.8426C63.9581 24.6464 63.8724 28.4537 63.9924 32.254C64.0405 33.8112 63.5191 34.3223 62.0511 34.0925C61.7733 34.0479 61.4748 34.0513 61.197 34.0925C59.8731 34.2846 59.4237 33.8044 59.4478 32.4152C59.5164 27.9906 59.4958 23.5591 59.386 19.1345C59.3277 16.833 58.0655 15.3478 56.131 14.8574C53.8398 14.2777 51.4663 15.1661 50.307 17.0765C49.6107 18.2221 49.3569 19.4878 49.3603 20.822C49.374 24.6807 49.3088 28.5428 49.3946 32.4015C49.422 33.7289 49.0756 34.3257 47.6899 34.0925C47.3572 34.0376 47.0005 34.041 46.6644 34.0925C45.3335 34.2983 44.9082 33.8112 44.9254 32.4324C45.0008 26.0184 45.0008 19.601 44.9254 13.187C44.9082 11.7773 45.2752 11.3657 46.7124 11.3794C48.3725 11.3931 49.8542 11.2594 49.3054 13.7426Z"
			fill="#888888"
		/>
		<path
			d="M112.581 31.7326C112.008 34.2193 112.008 34.1438 109.264 34.185C107.964 34.2021 107.793 33.6568 107.8 32.5661C107.841 26.838 107.817 21.11 107.817 15.3786C107.817 10.7276 107.855 6.0766 107.789 1.4256C107.776 0.290287 108.126 0.0056015 109.23 0.0158913C112.279 0.0399009 112.279 -0.0115486 112.279 3.05139C112.279 6.59795 112.279 10.1479 112.279 14.2124C114.68 11.6983 117.225 10.7585 120.209 10.8614C124.634 11.0123 127.937 13.2966 129.381 17.4709C130.863 21.7514 130.671 25.9634 128.383 29.9422C124.905 35.9926 116.296 35.9823 113.137 32.0001C113.003 31.8286 112.869 31.6194 112.581 31.7326ZM125.611 22.41C125.587 22.0498 125.577 21.4805 125.509 20.9214C125.073 17.2582 122.71 14.8675 119.407 14.7338C115.785 14.5863 113.267 16.682 112.464 20.3966C112.08 22.1665 112.187 23.8986 112.646 25.6067C113.469 28.6765 115.929 30.6007 118.947 30.6281C121.965 30.6556 124.143 28.9475 125.169 25.7988C125.512 24.7561 125.584 23.6928 125.611 22.41Z"
			fill="#888888"
		/>
		<path
			d="M189.511 23.9087C187.117 23.9087 184.719 23.9807 182.329 23.8813C181.063 23.8298 180.885 24.334 181.08 25.3802C181.571 28.0041 183.749 30.1032 186.527 30.5765C189.6 31.1013 192.09 29.9831 193.541 27.5239C193.809 27.0677 193.768 26.0456 194.683 26.3611C195.74 26.7247 196.961 26.831 197.764 27.6782C198.144 28.083 197.654 28.6283 197.424 29.0742C195.486 32.8197 192.286 34.6273 188.167 34.7165C183.725 34.8159 180.178 32.9981 177.942 29.0948C174.138 22.4647 177.071 13.7286 183.852 11.2797C191.418 8.54599 199.482 15.1658 198.192 23.0204C198.041 23.9327 197.513 23.9087 196.865 23.9087C194.416 23.9053 191.964 23.9087 189.511 23.9087ZM187.144 20.4376C188.798 20.4376 190.454 20.3553 192.101 20.4616C193.497 20.5542 193.62 19.9574 193.308 18.8564C192.526 16.085 190.159 14.5621 186.846 14.737C184.184 14.8742 181.777 17.0008 181.296 19.4944C181.121 20.393 181.53 20.4445 182.185 20.441C183.838 20.4342 185.491 20.441 187.144 20.4376Z"
			fill="#888888"
		/>
		<path
			d="M148.187 31.7818C145.844 33.9632 143.334 35.0059 140.302 34.8173C136.584 34.584 133.74 32.063 133.384 28.5233C133.03 25.0351 135.078 22.3803 138.83 21.3891C141.097 20.7888 143.444 20.6722 145.735 20.2434C146.609 20.0788 147.683 20.1406 147.937 19.0018C148.197 17.8391 147.923 16.7552 147.055 15.8634C144.671 13.4041 139.643 14.4194 138.432 17.5921C138.237 18.11 138.446 19.1321 137.523 18.9401C136.32 18.6897 135.006 18.6794 133.96 17.9179C133.658 17.6984 133.946 17.2765 134.032 16.9644C134.903 13.7197 137.225 11.9464 140.312 11.0992C142.373 10.5333 144.514 10.6259 146.578 11.1575C150.444 12.1488 152.526 14.8481 152.612 18.9984C152.708 23.7043 152.694 28.4136 152.759 33.1229C152.77 33.8192 152.625 34.2205 151.84 34.1587C151.5 34.1313 151.151 34.1107 150.814 34.1622C149.113 34.4194 147.968 34.0387 148.187 31.7818ZM141.89 30.9757C145.731 30.9586 147.93 28.6502 148.057 24.6235C148.088 23.6322 147.882 23.3064 146.846 23.4916C145.008 23.8243 143.145 24.0335 141.3 24.3217C139.026 24.6784 137.733 26.1155 137.935 28.0294C138.117 29.765 139.756 30.986 141.89 30.9757Z"
			fill="#888888"
		/>
		<path
			d="M97.6571 31.6029C95.4825 34.0347 92.8929 35.0157 89.806 34.8099C86.1908 34.5698 83.3474 32.0453 82.953 28.6085C82.5585 25.1752 84.6336 22.3043 88.3414 21.4365C90.6635 20.8946 93.0473 20.6202 95.4037 20.2292C96.3023 20.0783 97.3278 20.0508 97.5885 18.9464C97.8526 17.8351 97.5096 16.8027 96.7516 15.9486C94.5667 13.4893 89.3361 14.2576 88.2213 17.3274C87.7069 18.744 87.2472 19.0973 85.7552 18.6891C83.0216 17.9448 82.9118 17.9071 84.2152 15.4032C85.203 13.5031 86.7602 12.2031 88.753 11.4862C91.8262 10.3784 94.9132 10.4573 97.8972 11.8052C100.374 12.9268 101.897 14.9059 102.023 17.6053C102.212 21.5669 102.143 25.5387 102.188 29.5072C102.198 30.2995 102.288 31.0987 102.236 31.8876C102.188 32.625 102.929 33.7431 102.02 34.0244C100.933 34.3571 99.6088 34.2988 98.4872 34.0141C97.6297 33.7946 98.0996 32.601 97.6571 31.6029ZM97.6674 24.6572C97.4479 24.3245 98.1305 23.2681 96.8168 23.4636C94.6319 23.7895 92.423 24.0124 90.2725 24.4823C88.2419 24.9248 87.333 26.3413 87.5868 28.1764C87.8063 29.7438 89.2572 30.8894 91.1334 30.9718C95.0264 31.1467 97.592 28.7286 97.6674 24.6572Z"
			fill="#888888"
		/>
		<path
			d="M23.9648 31.5888C20.7235 34.8953 17.1495 35.715 13.1708 34.0309C10.3925 32.8579 8.96225 30.1997 9.22635 27.3048C9.48703 24.4751 11.3083 22.328 14.2204 21.4945C16.7482 20.7708 19.3893 20.661 21.9617 20.174C23.9031 19.807 24.4141 18.8397 23.639 17.0013C22.7643 14.9227 20.943 14.5626 18.9845 14.5489C16.7619 14.5317 15.119 15.6259 14.3575 17.4163C14.1209 17.9719 14.3301 19.1004 13.404 18.9426C12.1933 18.7368 10.8899 18.6476 9.80944 17.9719C9.40128 17.7147 9.79572 17.2379 9.89519 16.8778C10.8727 13.3072 14.2581 10.9337 18.6107 10.7553C23.3303 10.5633 26.7053 12.4806 27.9847 16.058C28.3346 17.039 28.3997 18.0508 28.4237 19.073C28.5472 23.7754 28.1734 28.4882 28.6536 33.1837C28.681 33.441 28.633 33.9006 28.5026 33.9486C27.357 34.3705 26.1497 34.1373 24.9732 34.1133C24.0814 34.0961 24.3078 33.2146 24.1535 32.6624C24.0849 32.4017 24.0574 32.1239 23.9648 31.5888ZM17.6572 30.9749C21.4815 30.9989 23.8756 28.5739 23.958 24.6981C23.9785 23.6622 23.7762 23.2746 22.6409 23.4942C20.8024 23.8474 18.9331 24.0327 17.0844 24.3311C14.8858 24.6843 13.6167 26.0941 13.7779 27.9359C13.9357 29.7264 15.51 30.9611 17.6572 30.9749Z"
			fill="#888888"
		/>
		<path
			d="M164.685 34.687C161.358 34.4777 158.247 33.2018 156.478 29.6175C155.932 28.5131 155.949 27.7928 157.376 27.5801C157.819 27.515 158.265 27.3538 158.669 27.1548C159.722 26.6369 160.154 26.8839 160.583 28.0569C161.568 30.746 165.56 31.8573 167.964 30.2658C168.908 29.6415 169.306 28.7669 169.199 27.6487C169.079 26.4037 168.253 25.7005 167.193 25.3507C165.9 24.9219 164.552 24.6578 163.221 24.3663C158.861 23.4059 156.659 21.1387 156.783 17.7053C156.91 14.1656 159.883 11.2845 163.975 10.7391C167.961 10.2075 171.772 12.2208 173.151 15.4999C173.555 16.4671 173.432 16.9919 172.365 17.2286C171.926 17.3246 171.487 17.4927 171.093 17.7122C170.098 18.261 169.594 18.0655 169.11 16.9473C168.098 14.6218 164.898 13.7334 162.706 15.0265C161.708 15.6165 161.081 16.4603 161.17 17.671C161.269 18.9916 162.147 19.6844 163.327 20.0034C164.534 20.3258 165.773 20.5282 166.99 20.8163C171.717 21.9344 174.114 24.6098 173.651 28.2318C173.144 32.1145 169.645 34.7659 164.685 34.687Z"
			fill="#888888"
		/>
		<path
			d="M34.1316 16.8749C34.1316 11.8294 34.1693 6.7874 34.1041 1.74195C34.087 0.486595 34.3168 -0.0450459 35.7505 0.00297328C38.6762 0.102442 38.6797 0.0166931 38.6797 2.98359C38.6797 12.7315 38.6454 22.4794 38.714 32.2273C38.7243 33.7365 38.3195 34.3436 36.8138 34.0863C36.6491 34.0589 36.4674 34.0589 36.3027 34.0863C34.6426 34.3539 34.0321 33.8222 34.0835 32.0043C34.2242 26.9658 34.1316 21.9169 34.1316 16.8749Z"
			fill="#888888"
		/>
	</svg>
);
export default AlmaBaseImg;
