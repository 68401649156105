import React, { useState } from 'react';
import { MdOutlinePhone } from 'react-icons/md';
import { IoMailOutline } from 'react-icons/io5';
import { IoLocationOutline } from 'react-icons/io5';
import { HiOutlinePhone } from 'react-icons/hi2';
import Helmet from 'react-helmet';

import { Input, Form, Row, Col, Button, Modal } from 'antd';
import './index.css';
import HeaderComponent from '../../components/Header';
import LandingScreenFooter from '../../components/LandingFooter';
import { postApi } from '../../services/api';
import { AiOutlineUser } from 'react-icons/ai';
const { TextArea } = Input;

const ContactUsPage = () => {
	const [modalIsOpen, setModalState] = useState(false);
	const [form] = Form.useForm();
	const onSubmit = async function (data) {
		try {
			const values = { ...data, name: `${data.firstname} ${data.lastname}` };
			console.log(values);
			const resp = await postApi(values, '/contact-us-form');
			if (resp.type === 'success') {
				setModalState(true);
				form.resetFields();
			}
		} catch (e) {
			console.error(e);
		}
	};
	return (
		<div className="contact-us-container">
			<Helmet>
				<title>Contact Us - Leading IT Asset Management (ITAM) Software | Assetze</title>
				<meta
					name="description"
					content={`Get in touch with Assetze, the leading provider of IT Asset Management (ITAM) solutions, E-Waste Dispose and IT Service, support queries, and more. Contact us today!`}
				/>
			</Helmet>

			<Modal
				open={modalIsOpen}
				footer={null}
				onCancel={() => {
					setModalState(false);
				}}
			>
				<div style={{ fontSize: '17px' }}>
					Thank you for contacting Assetze, <br /> we will get back to you.
				</div>
			</Modal>
			<HeaderComponent />
			<div className="contact-outerdiv">
				<div className="contact-leftdiv">
					<h1>Contact Us</h1>
					{/* <h5>We’d love to hear from you</h5> */}
					<p>
						Have questions about our products, features, trials, or pricing? Need a demo? Our teams will
						help you.
					</p>
					<div>
						<img src={require('../../images/contact-us.png')} alt="contact-us" />
					</div>
					<div className="contact-infodiv">
						{/* <div className='flexdiv'>
                            <div className='icons'><MdOutlinePhone /></div>
                            <div className='info'>+91 8456843895</div>
                        </div> */}
						<div className="flexdiv">
							<div className="icons">
								<IoMailOutline />
							</div>
							<div className="info">sales@assetze.com /support@assetze.com</div>
						</div>
						<div className="flexdiv">
							<div className="icons">
								<HiOutlinePhone />
							</div>
							<div className="info">+91 7483026159</div>
						</div>
						<div className="flexdiv">
							<div className="icons">
								<IoLocationOutline />
							</div>
							<div className="info">
								4, Khata 4/1, F CRS, 4th A Main Road, Bengaluru Urban, Karnataka, 560072
							</div>
						</div>
					</div>
				</div>
				<div className="contact-rightdiv">
					<div className="formdiv">
						<h5>Get in touch with us..</h5>
						<Form form={form} onFinish={onSubmit} layout="vertical">
							{/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
							<Row>
								<Col span={12}>
									<Form.Item name="firstname" label="First Name">
										<Input prefix={<AiOutlineUser />} size="large" placeholder="First Name" />
									</Form.Item>
								</Col>
								<Col style={{ paddingLeft: '5px' }} span={12}>
									<Form.Item name="lastname" label="Last Name">
										<Input prefix={<AiOutlineUser />} size="large" placeholder="Last Name" />
									</Form.Item>
								</Col>
							</Row>
							{/* </div> */}

							<Form.Item
								name="email"
								label="Email Address"
								rules={[
									{
										type: 'email',
										message: 'Provide valid Email',
									},
									{
										required: true,
										message: 'Provide Email Address',
									},
								]}
							>
								<Input prefix={<IoMailOutline />} size="large" placeholder="Email Address" />
							</Form.Item>

							<Form.Item
								name="phone"
								label="Phone Number"
								rules={[
									{
										validator: (_, value) => {
											if (value.length === 0) return Promise.resolve();
											if (!/^\+\d+$/.test(value)) {
												return Promise.reject(
													new Error(
														'Mobile Number must begin with Country Code followed by 10 digits.'
													)
												);
											}
											return Promise.resolve();
										},
									},
								]}
							>
								<Input prefix={<MdOutlinePhone />} size="large" placeholder="Phone Number" />
							</Form.Item>

							<Form.Item
								name="message"
								label="Message"
								rules={[
									{
										required: true,
										message: 'Provide Your Message',
									},
								]}
							>
								<TextArea rows={4} size="large" placeholder="Write Your Message Here" />
							</Form.Item>

							<Button
								size="large"
								className="ant-design-button"
								htmlType="submit"
								onClick={() => {
									form.submit();
								}}
							>
								Send Message
							</Button>
						</Form>
					</div>
				</div>
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default ContactUsPage;
