import React, { useEffect, useState } from 'react';
import './index.css';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { display } from '@mui/system';
import useVendor from './useVendor';
import { useDispatch, useSelector } from 'react-redux';
import { Strings } from '../../screens/vendors/config';
import DescriptiveComponent from './DescriptiveComponent';
import McqComponent from './McqComponent';
import UploadComponent from './UploadComponent';
import ArrowButton from './ArrowButton';
import { CHECKED_MCQ_ANS, UPDATE_MCQ_ANSWER } from '../../redux/actions';

const MultipleChoiceComponent = ({ sectionData, nextHandler, backHandler, dataShare, error, setError }) => {
	const [answerList, setAnswerList] = useState([]);
	const [clickedMcq, setClickedMcq] = useState(false);
	const questionssss = useSelector((state) => state.appreducer.vendorformData?.sections?.questions);

	console.log('dataShare', dataShare);
	console.log(sectionData);
	const {
		question,
		questions,
		next,
		back,
		section,
		currentSectionIndex,
		currentQuestionIndex,
		isFirstStep,
		isLastStep,
		sections,
		mcqClicked,
	} = dataShare;
	const [choiceValue, setChoiceValue] = useState([]);

	const dispatch = useDispatch();

	console.log(currentQuestionIndex);
	console.log('currentSectionIndex, currentQuestionIndex', currentSectionIndex, currentQuestionIndex);
	console.log(questions, question);
	console.log(choiceValue);
	return (
		<div className="descriptiveContainer">
			<div className="sectionDetailsContainer">
				<div className="sectionCountIndex">
					Section
					<span>
						{currentSectionIndex + 1}/{sections?.length}
					</span>
				</div>
				<div className="sectionName">{section.name}</div>
				<div className="sectionDescription">{section.description}</div>
			</div>
			<div className="descriptiveForm">
				<h4>{question?.question}</h4>
				<div className="question-arrow">
					<div className="labelContainer">
						{question?.choices?.map((choice) => {
							console.log(choice);
							console.log(choice.choice);
							console.log(question.mcqAnswer);
							// console.log(answerList);
							// console.log(mcqClicked?.mcqClicked);

							return (
								<div
									className={
										question.mcqAnswer.find((el) => el === choice.choice)
											? 'responseChoices selectedChoice'
											: 'responseChoices'
									}
									onClick={(e) => {
										console.log('hello');

										dispatch({
											type: UPDATE_MCQ_ANSWER,
											payload: {
												sectionId: section._id,
												qId: question._id,
												qtype: question.qtype,
												mcqAnswer: choice.choice,
											},
										});
										setChoiceValue((prev) => [...prev, choice.choice]);
									}}
									style={
										error && choiceValue.length === 0
											? { border: '0.5px solid #C64343', color: '#C64343' }
											: null
									}
								>
									{choice?.choice}
								</div>
							);
						})}
					</div>

					<ArrowButton
						isFirstStep={isFirstStep}
						isLastStep={isLastStep}
						nextHandler={() => {
							nextHandler();
							setChoiceValue([]);
						}}
						backHandler={backHandler}
					/>
				</div>
				<button
					className="next-btn"
					onClick={() => {
						nextHandler();
						setChoiceValue([]);
					}}
				>
					{isLastStep ? 'Submit' : 'Next'}
				</button>
			</div>
			{error && choiceValue.length === 0 ? (
				<h6 className="responseErrorMessage">Please answer this question before heading to next</h6>
			) : null}

			<div className="progressiveContainer">
				<p>
					Questions {currentQuestionIndex + 1} of {questions?.length}
				</p>
				<div className="progressExtension">
					<div
						className="progress"
						style={{
							backgroundColor: '#33D7DB',
							width: ((currentQuestionIndex + 1) / questions.length) * 100 + '%',
						}}
					></div>
				</div>
			</div>
		</div>
	);
};

export default MultipleChoiceComponent;
