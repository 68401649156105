import * as React from 'react';

const AssetsListSvg = (props) => {
	return (
		<svg width={34} height={26} fill={props.fill} xmlns="http://www.w3.org/2000/svg" {...props}>
			<g clipPath="url(#a)" fill={props.fill}>
				<path d="M2.59 20.24V2.8c0-.75.231-1.41.742-1.95.5-.53 1.121-.82 1.862-.85h23.433c.62 0 1.181.15 1.672.51.701.51 1.052 1.2 1.122 2.05.01.09.01.19.01.28v17.41h1.081c.29.01.521.23.531.52 0 .69.02 1.39-.02 2.08-.07 1.34-1.182 2.51-2.504 2.69-.2.03-.4.04-.61.04H4.143c-.951 0-1.753-.32-2.393-1.04-.521-.54-.761-1.2-.771-1.95v-1.74c0-.38.22-.59.59-.59h.972c.01 0 .02-.01.05-.02Zm27.76.01V2.6c-.03-.42-.191-.79-.502-1.08-.35-.34-.78-.47-1.261-.47H5.414c-.28 0-.55.03-.79.15-.661.33-.962.87-.962 1.6v17.44c8.893.01 17.785.01 26.687.01Zm-10.776 1.09h-5.147c.26.6.691.99 1.332 1.02a25.3 25.3 0 0 0 2.463 0c.651-.02 1.082-.41 1.352-1.02Z" />
				<path d="M17 18.12H6.838c-.09 0-.18 0-.26-.02-.221-.07-.311-.24-.331-.45V3.79c0-.08.01-.16.02-.23.04-.19.2-.33.39-.34.07-.02.13-.02.2-.02h20.299c.12 0 .25.02.35.06.15.07.24.21.24.39V17.54c0 .08 0 .16-.02.23a.45.45 0 0 1-.42.33h-.18c-3.375.02-6.75.02-10.124.02Zm10.265-.62V3.83H6.737V17.5h20.528Z" />
				<path d="M23.92 5.3c.25.03.45.18.521.4.08.23 0 .47-.22.62-.441.31-.892.62-1.332.92-4.136 2.86-8.282 5.72-12.417 8.58-.12.09-.28.15-.431.17-.22.02-.42-.15-.49-.37a.508.508 0 0 1 .17-.56c.32-.24.64-.46.97-.69L23.55 5.49c.11-.08.25-.13.37-.19ZM10.562 11.2c.03-.24.16-.38.33-.5 1.352-.95 2.704-1.89 4.056-2.83.4-.28.801-.56 1.212-.84.18-.13.38-.14.57-.03.17.1.27.25.27.45 0 .22-.11.37-.28.49-1.141.8-2.283 1.59-3.424 2.39l-1.803 1.26c-.28.19-.56.18-.751-.04-.08-.1-.12-.23-.18-.35ZM22.849 9.57c.27.03.46.16.54.41.07.21-.01.45-.21.59-1.772 1.24-3.535 2.48-5.307 3.71-.27.19-.6.13-.771-.12-.17-.25-.1-.57.19-.77l2.804-1.96c.811-.56 1.612-1.13 2.423-1.69.09-.07.21-.11.33-.17Z" />
			</g>
			<defs>
				<clipPath id="a">
					<path fill={props.fill} transform="translate(.979)" d="M0 0h32.044v25.6H0z" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default AssetsListSvg;
