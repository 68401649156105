import React from 'react';
import EarlyAccessModel from '../EarlyAccess';
import './index.css';

const SignupToAssetze = function () {
	return (
		<div className="features-signup-container">
			<div className="head-1">Sign Up to Assetze Now!</div>
			<div className="head-2">Start with the free plan today</div>
			<EarlyAccessModel className="signup-button-feature" content="Sign Up Now" />
		</div>
	);
};

export default SignupToAssetze;
