export function sanitizeFileName(fileName) {
	// Define a regular expression to match special characters not allowed in file names
	const regex = /[\\/:\*\?"<>\|]/g; // Add more characters as needed

	// Replace special characters with an underscore
	const sanitizedFileName = fileName.split('.')[0].replace(/([^a-z0-9]+)/gi, '_');

	const extension = fileName.split('.').pop().toLowerCase();

	return sanitizedFileName + '.' + extension;
}

export function escapeRegExp(string) {
	return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}
