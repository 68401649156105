import React, { useEffect, useState } from 'react';
import { deleteApi, download, getApi, postApi, uploadToS3 } from '../../services/api';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { FaWindows, FaApple, FaLinux } from 'react-icons/fa';
import { Button, Drawer, Input, Table, Tag, Tabs, Select, Form } from 'antd';
import { PlusOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons';
import { GoDownload } from 'react-icons/go';
import { AiFillMail, AiOutlineEye, AiOutlineLink } from 'react-icons/ai';
import { IoMdCopy } from 'react-icons/io';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { PiLaptopThin } from 'react-icons/pi';
import { COMPUTER_REPORT_CREATE, CheckPermission, DOWNLOAD_AGENT_CREATE } from '../../utils/Permissions';
import { IoIosRefresh } from 'react-icons/io';
import { escapeRegExp } from '../../utils/sanitizeFileName';
import { useDispatch } from 'react-redux';
import { triggerShowSnack } from '../../utils/snack';

const { Search: AntdSearch } = Input;

const AgentContent = function ({ companyData }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [agentDrawer, setAgentdrawer] = useState(false);
	const [agentSupportedPlatforms, setAgentSupportedPlatforms] = useState([]);
	const [agentPlatformCount, setAgentPlatformInfo] = useState([]);
	const [agentLink, setAgentLink] = useState(null);
	// const [filterText, setFilterText] = useState('');
	const [releaseInfo, setReleaseInfo] = useState({});
	const [tabActiveKey, setTabActiveKey] = useState('1');
	const [pagination, setPagination] = useState({
		current: 1,
		pageSize: 10,
		total: 0,
	});
	const [agentInstalledDevices, setAgentDevices] = useState([]);
	const [emailOpen, setEmailOpen] = useState(false);
	const [form] = Form.useForm();
	useEffect(() => {
		const getCompany = async function () {
			const resp = await getApi('/users/company');
			if (resp.type === 'success') {
				setAgentSupportedPlatforms(resp.company.agentSupportedPlatforms);
				setAgentPlatformInfo(resp.company.agentInfoCount);
			}
		};
		getCompany();
		fetchAgentInstatlled(pagination.current, pagination.pageSize);
	}, []);

	const handleTableChange = (pagination) => {
		fetchAgentInstatlled(pagination.current, pagination.pageSize);
	};

	const onSearch = (value) => {
		const filterText = escapeRegExp(value);
		fetchAgentInstatlled(pagination.current, pagination.pageSize, filterText);
	};

	const downloadReportAgent = async () => {
		const resp = await postApi({}, '/assetze/asset-history/agent-assets-report');
		if (resp.type === 'success') {
			window.open(resp.url, '_blank', 'noopener,noreferrer');
		} else {
			triggerShowSnack(dispatch, resp.error);
		}
	};

	const fetchAgentInstatlled = async (page, limit, filterText) => {
		try {
			console.log('fetchAgentInstatlled');
			const resp = await postApi({ limit, page, filterText }, '/assetze/asset-history/agent-assets');
			if (resp.type === 'success') {
				setAgentDevices(resp.data);
				setPagination({
					total: resp.total,
					current: page,
					pageSize: limit,
				});
			}
		} catch (e) {
			console.error(e);
		}
	};

	const refreshAgentInstatlled = () => {
		fetchAgentInstatlled(pagination.current, pagination.pageSize);
	};

	const openDrawer = async (os) => {
		try {
			const resp = await postApi({ os, companyId: companyData._id }, '/assetze/agent-links/create');
			if (resp.type === 'success') {
				setAgentdrawer(true);
				setAgentLink(resp.url);
				setReleaseInfo(resp.data);
			} else {
				triggerShowSnack(dispatch, resp.error);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const rowSelection = {
		onChange: (selectedRowKeys, selectedRows) => {
			console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
		},
	};

	const sendEmail = (value) => {
		console.log(value);
	};

	const ComputerColumn = [
		{
			title: 'Computer Name',
			dataIndex: 'computerName',
			align: 'center',
		},
		{
			title: 'Version',
			dataIndex: 'version',
			align: 'center',
		},
		{
			title: 'Last Contact Time',
			dataIndex: 'pingTime',
			align: 'center',
			render: (pingTime) => {
				if (pingTime) {
					return <span>{format(new Date(pingTime), 'dd MMM yyyy hh:mm a')}</span>;
				}
				return 'NA';
			},
		},
		{
			title: 'Serial Number',
			dataIndex: 'serialNumber',
			align: 'center',
		},
		{
			title: 'Agent Installed Time',
			dataIndex: 'createdAt',
			align: 'center',
			render: (createdAt) => {
				if (createdAt) {
					return <span>{format(new Date(createdAt), 'dd MMM yyyy hh:mm')}</span>;
				}

				return 'NA';
			},
		},
	];

	const agentTabOptions = [
		{
			label: (
				<div
					onClick={() => {
						setTabActiveKey('1');
					}}
					className="agent-options"
				>
					Overview
				</div>
			),
			key: '1',
			children: (
				<div className="agent-overview">
					<div className="heading">Overview</div>
					<div className="overview-div">
						<div className="overview-upperdiv">
							{agentSupportedPlatforms?.map((data) => {
								if (data.os === 'windows') {
									return (
										<div className="card">
											<div className="label">Computer Agent</div>
											<div className="overview-icon">
												<FaWindows />
											</div>
											<div>
												<CheckPermission requiredPermission={DOWNLOAD_AGENT_CREATE}>
													<Button
														onClick={() => {
															openDrawer(data.os);
														}}
													>
														Download Agent
													</Button>
												</CheckPermission>
											</div>
										</div>
									);
								} else if (data.os === 'mac') {
									return (
										<div className="card">
											<div className="label">Computer Agent</div>
											<div className="overview-icon">
												<FaApple />
											</div>
											<div>
												<CheckPermission requiredPermission={DOWNLOAD_AGENT_CREATE}>
													<Button
														onClick={() => {
															openDrawer(data.os);
														}}
													>
														Download Agent
													</Button>
												</CheckPermission>
											</div>
										</div>
									);
								}
								return (
									<div className="card">
										<div className="label">Computer Agent</div>
										<div className="overview-icon">
											<FaLinux />
										</div>
										<div>
											<CheckPermission requiredPermission={DOWNLOAD_AGENT_CREATE}>
												<Button
													onClick={() => {
														openDrawer(data.os);
													}}
												>
													Download Agent
												</Button>
											</CheckPermission>
										</div>
									</div>
								);
							})}
						</div>
						<div className="overview-lowerdiv">
							{
								agentSupportedPlatforms &&
									agentSupportedPlatforms.map((data) => {
										// if (data.os === 'windows') {
										return (
											<div
												onClick={() => {
													setTabActiveKey('2');
												}}
												className="card"
											>
												<div className="flex-div">
													<div className="overview-icon">
														<PiLaptopThin />
													</div>
													<div className="val">
														{getCountForOs(data.os, agentPlatformCount)}
													</div>
												</div>
												<div className="label">Installed Computers</div>
											</div>
										);
									})
								// } else if (data.os === 'mac') {
								// 	return (
								// 		<div className="card">
								// 			<div className="flex-div">
								// 				<div className="overview-icon">
								// 					<PiLaptopThin />
								// 				</div>
								// 				<div className="val">81</div>
								// 			</div>
								// 			<div className="label">Installed Computers</div>
								// 		</div>
								// 	);
								// }
								// return (
								// 	<div className="card">
								// 		<div className="flex-div">
								// 			<div className="overview-icon">
								// 				<PiLaptopThin />
								// 			</div>
								// 			<div className="val">82</div>
								// 		</div>
								// 		<div className="label">Installed Computers</div>
								// 	</div>
								// );
								// })
							}
						</div>
					</div>
				</div>
			),
		},
		{
			label: (
				<div
					onClick={() => {
						setTabActiveKey('2');
						fetchAgentInstatlled(pagination.current, pagination.pageSize);
					}}
					className="agent-options"
				>
					Computers
				</div>
			),
			key: '2',
			children: (
				<div className="agent-computers">
					<div className="icon-bar">
						<div className="search-input">
							{/* <Input prefix={<SearchOutlined />} /> */}
							<AntdSearch
								placeholder="search with serial number or computer name"
								enterButton="Search"
								onSearch={onSearch}
								style={{ width: 300 }}
							/>
						</div>
						<CheckPermission requiredPermission={COMPUTER_REPORT_CREATE}>
							<div onClick={downloadReportAgent} className="export-icon">
								<GoDownload />
							</div>
						</CheckPermission>
						<div onClick={refreshAgentInstatlled} className="export-icon">
							<IoIosRefresh />
						</div>
						{/* <div className="export-icon">
							<IoFilterCircleOutline />
						</div> */}
					</div>
					<Table
						style={{ width: '881px' }}
						pagination={pagination}
						columns={ComputerColumn}
						dataSource={agentInstalledDevices}
						onChange={handleTableChange}
					/>
				</div>
			),
		},
	];
	return (
		<div className="agent-tab">
			<Drawer
				className="agent-tab-drawer"
				width={700}
				title="Download Agent"
				onClose={() => {
					setAgentdrawer(false);
				}}
				open={agentDrawer}
			>
				<div className="agent-drawer">
					<div className="div1">
						<div className="text">Platform</div>
						<div className="icon-div">
							<FaWindows />
						</div>
						<div style={{ alignItems: 'center', justifyContent: 'center', display: 'flex' }}>
							<Button target="_blank" type="link" href={agentLink}>
								Link{'   '} <AiOutlineLink style={{ marginLeft: '10px' }} />
							</Button>
						</div>
					</div>
					<div className="div1">
						<div className="text">Version</div>
						<Tag color="#32D7DB">v{releaseInfo.version}</Tag>
					</div>
					<div className="div2">
						<div className="text">Copy Link</div>
						<div>
							<div>
								<Input
									readOnly
									value={agentLink}
									addonAfter={
										<IoMdCopy
											onClick={() => {
												navigator.clipboard.writeText(agentLink);
											}}
										/>
									}
								/>
							</div>
							<div className="innerdiv">
								<div className="icon-div">
									<AiOutlineFieldTime />
								</div>
								<div className="download-text">Download link expires in 14 days</div>
							</div>
						</div>
					</div>
					<div className="div4">
						{false ? (
							<>
								{!emailOpen ? (
									<Button
										onClick={() => {
											setEmailOpen(true);
										}}
									>
										Send Email
									</Button>
								) : (
									<div>
										<Form layout={'vertical'} form={form} onFinish={sendEmail}>
											<Form.Item name="emails" label="Provide Emails :-">
												<Select
													allowClear
													mode="tags"
													style={{
														width: '100%',
													}}
												/>
											</Form.Item>
											<div className="btn-div">
												<Button
													onClick={() => {
														setEmailOpen(false);
													}}
												>
													Cancel
												</Button>
												<Button
													type="primary"
													onClick={() => {
														form.submit();
													}}
												>
													Submit
												</Button>
											</div>
										</Form>
									</div>
								)}
							</>
						) : null}
					</div>
					<div className="div3">
						<div>How to install?</div>
						<div className="innerdiv">
							<p>
								1. Download the file on the system or copy the link and paste it on the browser to
								download
							</p>
							<p>2. Right click on the agent file and run as administrator</p>
							<p>3. Follow the steps of installation shown on screen</p>
						</div>
					</div>
				</div>
			</Drawer>
			<div className="agent-tab-bar">
				<Tabs defaultActiveKey="1" activeKey={tabActiveKey} tabPosition="left" items={agentTabOptions} />
			</div>
		</div>
	);
};

function getCountForOs(os, allPlatformCount) {
	for (let x = 0; x < allPlatformCount.length; x += 1) {
		if (allPlatformCount[x]._id === os) {
			return allPlatformCount[x].count;
		}
	}
	return 0;
}

export default AgentContent;
