import React from 'react';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

const ProductLanding = function () {
	const navigate = useNavigate();
	return (
		<div className="products-container">
			<div className="landing-content">
				<div className="content">
					<div className="head-1">
						Right Machine <br /> For your <br /> Organisation
					</div>
					<div className="head-2">
						All the products listed are <br /> best suited for business use.
					</div>
					<button
						className="shopnow"
						onClick={() => {
							navigate('/products/shop');
						}}
					>
						Shop Now
					</button>
				</div>
				<div className="image-container">
					<img src={require('../../images/footer/product-1.png')} />
				</div>
			</div>
			<div className="landing-content">
				<div>
					<img src={require('../../images/footer/product-2.png')} />
				</div>
				<div className="content">
					<div className="head-1">High Productivity High Growth</div>
					<div className="head-2">
						Products are categorized according to the department that’ll need it the most. Products that
						perform best under pressure
					</div>
					<button className="shopnow">Shop Now</button>
				</div>
			</div>
			<div className="rates-like">
				<div className="head-1">Rates like No where Else</div>
				<div className="head-2">Explore</div>
				<ProductListCarousel />
			</div>
			<div className="landing-content-2">
				<div className="head-1">Why Us?</div>
				<div className="contents">
					<div>
						<img src={require('../../images/footer/product-3.png')} />
						<div className="head-1">Low Cost</div>
						<div className="content">All the products are sold at low prices compared to any store </div>
					</div>
					<div>
						<img src={require('../../images/footer/product-4.png')} />
						<div className="head-1">Lease</div>
						<div className="content">
							Our tie-ups with banks helps you procure devices at no-strings attached. <br /> <br /> You
							won’t have the hassle of the product maintenance & over head cost
						</div>
					</div>
					<div>
						<img src={require('../../images/footer/product-5.png')} />
						<div className="head-1">Shipment</div>
						<div className="content">We take care of pan-India shipment of devices.</div>
					</div>
				</div>
			</div>
		</div>
	);
};

const settings = {
	infinite: true,
	speed: 500,
	autoplay: false,
	autoplaySpeed: 2000,
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
};

const productList = [
	{
		name: 'Apple Macbook Pro 14” M1 Pro',
		image: require('../../images/footer/product-6.png'),
		price: '₹ 188,800',
	},
	{
		name: 'Apple Macbook Pro 16” M1 Pro',
		image: require('../../images/footer/product-7.png'),
		price: '₹ 225,500',
	},
	{
		name: 'Apple Macbook Pro 13” M1',
		image: require('../../images/footer/product-8.png'),
		price: '₹ 112,000',
	},
];

const ProductListCarousel = function () {
	return (
		<Slider {...settings}>
			{productList.map((el, index) => (
				<EachProduct key={index} product={el} />
			))}
		</Slider>
	);
};

const EachProduct = function ({ product }) {
	return (
		<div>
			<div className="each-product-container">
				<div className="image-container">
					<img src={product.image} />
				</div>
				<div className="product-name">{product.name}</div>
				<div className="product-actions">
					<div className="price">{product.price}</div>
					<button>Buy Now</button>
				</div>
			</div>
		</div>
	);
};

export default ProductLanding;
