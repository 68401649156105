import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import configureStore from './redux/store';
import { Snackbar } from 'react-redux-snackbar';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import ScrollToTop from './components/ScrollTop';
import { Portal } from '@mui/base';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
import { ConfigProvider } from 'antd';
import { hydrate, render } from 'react-dom';

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
	// dev code
} else {
	LogRocket.init('czdo70/az-ui');
	setupLogRocketReact(LogRocket);
	console.log('logrocket init done');
}

const snackStyles = {
	snack: {
		zIndex: 1300,
	},
};

const AppComponenet = () => {
	return (
		<ReduxProvider store={configureStore}>
			<BrowserRouter>
				<React.StrictMode>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<ScrollToTop />
						<ConfigProvider
							theme={{
								token: {
									colorPrimary: '#33D7DB',
								},
							}}
						>
							<App />
						</ConfigProvider>
					</MuiPickersUtilsProvider>
				</React.StrictMode>
			</BrowserRouter>
			<Portal>
				<Snackbar customStyles={snackStyles} />
			</Portal>
		</ReduxProvider>
	);
};

const rootElement = document.getElementById('root');
if (rootElement.hasChildNodes()) {
	hydrate(<AppComponenet />, rootElement);
} else {
	render(<AppComponenet />, rootElement);
}

// ReactDOM.render(AppComponenet, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
