import { Divider } from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Box, Chip, createMuiTheme, Grid, Stack, TextField, ThemeProvider, Typography } from '@mui/material';
import { teal } from '@mui/material/colors';
import { add, format, sub } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { useLocation, useParams } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import { postApi } from '../../services/api';
import UnassignDevicesModal, { UnassignSuccessfulModal } from './ExitEmployeeModal';
import './index.css';
// import { withStyles } from '@mui/styles';

const defaultMaterialTheme = createMuiTheme({
    palette: {
        primary: teal,
    },
});

const ExitEmployee = () => {
    const {
        register,
        handleSubmit,
        watch,
        control,
        formState: { errors },
        reset,
        setValue,
    } = useForm({
        defaultValues: {
            name: '',
            empId: '',
            department: '',
            reportingManager: '',
            dateOfJoining: '',
            dateOfResignation: '',
        },
    });

    useEffect(() => {
        const tomorrow = add(new Date(), {
            days: 1,
        });

        const yesterday = sub(new Date(), {
            days: 1,
        });

        setValue('dateOfJoining', yesterday);
        setValue('dateOfResignation', tomorrow);
    }, []);

    const [open, setOpen] = useState(false);
    const [isSuccessfull, setIsSuccessfull] = useState(false);
    const dispatch = useDispatch();

    const { state } = useLocation();

    const [assetsList, setAssetsList] = useState([]);

    const { employeeId } = useParams();

    useState(() => {
        setValue('name', state?.name);
        setValue('empId', state?.empId);
        setValue('department', state?.department);
    }, []);

    useEffect(() => {
        const fetchEmployeeAssets = async () => {
            try {
                const resp = await postApi({ employeeId }, `/assets/employee-assets`);
                if (resp.type === 'success') {
                    setAssetsList(resp?.assets);
                } else {
                    dispatch(
                        showSnack('FILE_UPLOAD_ERROR', {
                            label: resp.error,
                            timeout: 7000,
                        })
                    );
                }
            } catch (e) {
                console.log(e);
                dispatch(
                    showSnack('FILE_UPLOAD_ERROR', {
                        label: 'Something went wrong',
                        timeout: 7000,
                    })
                );
            }
        };

        fetchEmployeeAssets();
    }, []);

    const resetExitEmployeeForm = () => {
        return reset({
            name: '',
            empId: '',
            department: '',
            reportingManager: '',
            dateOfJoining: sub(new Date(), {
                days: 1,
            }),
            dateOfResignation: add(new Date(), {
                days: 1,
            }),
        });
    };

    const onSubmit = async (data) => {
        try {
            const unassign = {
                ...data,
                dateOfJoining: data?.dateOfJoining.toISOString(),
                dateOfResignation: data?.dateOfResignation.toISOString(),
            };

            const resp = await postApi(unassign, '/assets/unassign-assets');
            if (resp.type === 'success') {
                dispatch(
                    showSnack('UNASSIGN_ASSETS', {
                        label: 'Successfully Unassigned',
                        timeout: 7000,
                    })
                );
                setIsSuccessfull(true);
                setOpen(false);
                resetExitEmployeeForm();
            } else {
                dispatch(
                    showSnack('ERROR_MESSAGE', {
                        label: resp.error,
                        timeout: 7000,
                    })
                );
            }
        } catch (e) {
            console.error(e);
            dispatch(
                showSnack('ERROR_MESSAGE', {
                    label: e,
                    timeout: 7000,
                })
            );
        }
    };
    const submitRef = useRef();
    return (
        <>
            <HeaderComponent />

            <form style={{ display: 'flex', flexDirection: 'column' }} onSubmit={handleSubmit(onSubmit)}>
                <Box
                    sx={{
                        background: '#F9F9F9',
                        height: 80,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    }}
                >
                    <p
                        style={{
                            fontWeight: 800,
                            marginX: '30px',
                            paddingLeft: '30px',
                            fontSize: '30px',
                            color: '#333333',
                        }}
                    >
                        Exit Sign-off
                    </p>
                </Box>
                <Box sx={{ padding: 1, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    <Box>
                        <p style={{ fontWeight: 800, fontSize: '21px', paddingLeft: '20px', color: '#333333' }}>
                            New Sign-Off
                        </p>
                    </Box>

                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            marginTop: 2,
                        }}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    height: '420px',
                                    overflowY: 'scroll',
                                    scrollbarColor: '#ccc',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    position: 'relative',
                                }}
                            >
                                <div
                                    style={{
                                        position: 'absolute',
                                        display: 'flex',
                                        width: '95%',
                                        justifyContent: 'center',
                                        gap: '15px',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                    }}
                                >
                                    {assetsList?.map((asset) => (
                                        <ExitEmployeeAsset key={asset._id} asset={asset} />
                                    ))}
                                </div>
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                sx={{
                                    padding: 3,
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'flex-start',
                                }}
                            >
                                <Grid container spacing={2} sx={{ width: 500 }}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            autoFocus
                                            required
                                            id="name"
                                            name="name"
                                            label="Name"
                                            type="text"
                                            {...register('name')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="empId"
                                            name="empId"
                                            label="Employee Id"
                                            type="text"
                                            {...register('empId')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="department"
                                            name="department"
                                            label="Department"
                                            type="text"
                                            {...register('department')}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            required
                                            id="reportingManager"
                                            name="reportingManager"
                                            label="Reporting Manager"
                                            type="text"
                                            {...register('reportingManager')}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dateOfJoining"
                                            render={({ field }) => {
                                                return (
                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            inputVariant="outlined"
                                                            clearable
                                                            name="dateOfJoining"
                                                            label="Date Of Joining"
                                                            value={field.value}
                                                            placeholder="10/10/2018"
                                                            onChange={(date) => field.onChange(date)}
                                                            maxDate={sub(new Date(), {
                                                                days: 1,
                                                            })}
                                                            format="dd/MM/yyyy"
                                                        />
                                                    </ThemeProvider>
                                                );
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Controller
                                            control={control}
                                            name="dateOfResignation"
                                            render={({ field }) => {
                                                return (
                                                    <ThemeProvider theme={defaultMaterialTheme}>
                                                        <KeyboardDatePicker
                                                            fullWidth
                                                            inputVariant="outlined"
                                                            clearable
                                                            name="dateOfResignation"
                                                            label="Date Of Resignation"
                                                            value={field.value}
                                                            placeholder="10/10/2018"
                                                            onChange={(date) => field.onChange(date)}
                                                            minDate={add(new Date(), {
                                                                days: 1,
                                                            })}
                                                            format="dd/MM/yyyy"
                                                        />
                                                    </ThemeProvider>
                                                );
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: 3 }}>
                        <button onClick={() => setOpen(true)} type="button" className="unassign__button">
                            Unassign
                        </button>
                        <input type="submit" hidden ref={submitRef} />
                    </Box>
                    <Box>
                        <UnassignDevicesModal
                            open={open}
                            setOpen={setOpen}
                            setIsSuccessfull={setIsSuccessfull}
                            submitRef={submitRef}
                        />
                    </Box>
                    <Box>
                        <UnassignSuccessfulModal isSuccessfull={isSuccessfull} setIsSuccessfull={setIsSuccessfull} />
                    </Box>
                </Box>
            </form>

            <FooterDashboard />
        </>
    );
};

export default ExitEmployee;

const ExitEmployeeAsset = ({ asset }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                width: '500px',
                gap: 1,
                border: '1px solid #888888',
                height: '150px',
                padding: 2,
                borderRadius: 5,
            }}
        >
            <Box sx={{ display: 'flex' }}>
                <Box sx={{ flex: 2 }}>
                    <Stack direction="row" spacing={2}>
                        <Chip style={{ backgroundColor: '#33D7DB' }} label={asset.type} />
                        <Chip style={{ backgroundColor: '#50EFB5' }} label={asset.name} />
                    </Stack>
                </Box>
                <Box sx={{ flex: 1 }}>
                    <Typography
                        sx={{
                            textAlign: 'right',
                            color: '#555555',
                            paddingRight: 2,
                            fontSize: '18px',
                            fontWeight: 600,
                        }}
                    >
                        {asset.id}
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Typography sx={{ color: '#888888', fontWeight: 400 }}>{asset.serialNumber}</Typography>
            </Box>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                    <AssestLeftConfiguration config={asset.custom} />
                </Box>
                <Box sx={{ flex: 1, display: 'flex', gap: 2, flexDirection: 'column' }}>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <Typography sx={{ color: '#555555', fontWeight: 600 }}>Assigned On :</Typography>
                        <Typography sx={{ color: '#888888', paddingLeft: 1 }}>
                            {format(new Date(asset.employee?.dateOfAssign), 'dd MMM yyyy')}
                        </Typography>
                    </Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                        <StarRatings
                            rating={asset.healthScore ?? 0}
                            starEmptyColor="rgb(204,204,204)"
                            starRatedColor="rgb(253,211,5)"
                            starDimension="20px"
                            starSpacing="5px"
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

const AssestLeftConfiguration = ({ config }) => {
    return (
        <>
            {config.slice(0, 3).map((conf, index) => (
                <Box sx={{ display: 'flex', flexDirection: 'row' }} key={index}>
                    <Typography sx={{ color: '#555555', fontWeight: 600 }}>{conf.key} :</Typography>
                    <Typography sx={{ color: '#888888', paddingLeft: 1 }}> {conf.value}</Typography>
                </Box>
            ))}
        </>
    );
};
