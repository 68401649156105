export const serverUrl = process.env.REACT_APP_server;
export const assetTagUrl = process.env.REACT_APP_ASSET_TAG_SERVER;

export const DEFAULT_PERMISSION = [
	{
		obj: 'on_asset',
		permList: [
			{ obj: 'asset', actions: ['create', 'view', 'delete', 'edit'], label: 'Asset View' },
			{
				obj: 'asset_update_custom',
				actions: ['create', 'delete', 'edit'],
				label: 'Update Asset Custom Field',
			},
			{ obj: 'audit', actions: ['view', 'create'], label: 'Asset Audit' },
			{ obj: 'image', actions: ['view', 'create'], label: 'Asset Image' },
			{ obj: 'activity', actions: ['view'], label: 'Asset Activity' },
			{ obj: 'depreciation', actions: ['view', 'create'], label: 'Asset Depreciation' },
			{ obj: 'asset_assign_unassign', actions: ['create'], label: 'Asset Assign/UnAssign' },
			{ obj: 'archive_lit', actions: ['view'], label: 'Asset Archive' },
			{ obj: 'comment', actions: ['edit', 'view'], label: 'Asset Comment' },
			{ obj: 'programs_view', actions: ['view'], label: 'Programs Installed' },
			{ obj: 'ram_view', actions: ['view'], label: 'Asset RAM View' },
			{ obj: 'location_view', actions: ['view'], label: 'Asset Location View' },
			{ obj: 'users_view', actions: ['view'], label: 'Asset Users View' },
			{ obj: 'health_view', actions: ['view'], label: 'Asset Health View' },
			{ obj: 'report_generate', actions: ['create'], label: 'Asset Report' },
			{ obj: 'generate_tag', actions: ['create'], label: 'Asset Tag ' },
			{ obj: 'filter', actions: ['view'], label: 'Asset Filter' },
		],
	},
	{
		obj: 'on_organsiation',
		permList: [
			{ obj: 'presonal_information', actions: ['view', 'edit'], label: 'Personal Information' },
			{ obj: 'company_information', actions: ['edit', 'view'], label: 'Organisation Information' },
			{
				obj: 'company_branches',
				actions: ['create', 'edit', 'view', 'delete'],
				label: 'Organisation Branches',
			},
			{ obj: 'asset_tag_setup', actions: ['edit', 'view'], label: 'Organisation Setup Tag' },
			{ obj: 'asset_types', actions: ['view', 'create', 'delete'], label: 'Organisation Asset Types' },
			{ obj: 'purchase_order', actions: ['view', 'create', 'delete'], label: 'Organisation Invoices' },
			{ obj: 'admin_setup', actions: ['create', 'view'], label: 'Oraganisation Admin' },
			{
				obj: 'employee_setup',
				actions: ['create', 'view', 'edit', 'delete'],
				label: 'Organisation Employee',
			},
			{ obj: 'agent_computers', actions: ['view'], label: 'Organisation Agent' },
			{ obj: 'activity', actions: ['view'], label: 'Activity' },
			{ obj: 'agent_computer_report', actions: ['create'], label: 'Organisation Agent Report' },
			{ obj: 'download_agent', actions: ['create'], label: 'Organisation Download Agent Report' },
		],
	},
];
