import * as React from 'react';
const IncrefImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={201} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M9.252 8.375a.935.935 0 0 1 .386-.37c.293-.068.451-.291.597-.525.054-.047.109-.093.16-.143.255-.049.385-.24.51-.44l.002-.034.034-.006a.405.405 0 0 0 
.368-.275l.078-.07c.168.006.282-.116.391-.205 1.108-.9 2.31-1.657 3.547-2.363a28.906 28.906 0 0 1 6.367-2.67c1.94-.557 3.92-.967 5.954-1.035.365-.013.734-.07 1.105-.07.106 0 
.192-.023.212-.145 2.11-.091 4.196.083 6.274.438 2.859.49 5.588 1.37 8.172 2.675 6.188 3.124 10.785 7.822 13.779 14.07a28.612 28.612 0 0 1 2.412 7.689c.338 2.024.517 4.058.431 
6.107a30.906 30.906 0 0 1-1.08 7.015c-1.517 5.456-4.358 10.105-8.488 13.963a29.327 29.327 0 0 1-9.788 6.069c-2.572.967-5.215 1.592-7.955 
1.81-.181.016-.389-.026-.54.135h-3.216c-.091-.12-.218-.16-.361-.166a29.92 29.92 0 0 1-3.785-.397 27.885 27.885 0 0 1-3.778-.9c-2.002-.628-3.935-1.442-5.762-2.49a29.156 29.156 
0 0 1-3.32-2.195c-.486-.374-.976-.74-1.464-1.108-.03-.026-.062-.05-.09-.075-.161-.21-.302-.442-.59-.504a.709.709 0 0 1-.319-.303 8.444 8.444 0 0 0-1.343-1.344.708.708 0 0 
1-.304-.32c.008-.142-.096-.217-.182-.308a22.476 22.476 0 0 
1-1.694-2.055c-1.842-2.506-3.292-5.222-4.278-8.182-.77-2.315-1.292-4.67-1.466-7.106-.036-.514.076-1.074-.228-1.557v-1.813c.306-.556.184-1.176.23-1.76.066-.775.164-1.543.278-2.311.172-1.175.439-2.332.771-3.469a28.62 
28.62 0 0 1 1.455-3.915c.851-1.852 1.832-3.626 
3.054-5.266.257-.345.602-.641.73-1.077l.07-.078c.163-.067.261-.186.272-.365l.005-.034.033-.003c.2-.124.395-.254.441-.508l.073-.078c.241-.19.511-.353.592-.68.096-.158.207-.298.376-.384.347-.236.638-.524.872-.869Zm46.942 
22.283c.366-14.175-10.782-26.33-25.331-26.802-14.402-.467-26.4 10.769-27 25.033C3.248 43.51 14.71 55.712 29.257 56.153c14.323.436 26.537-10.855 26.937-25.495Z"
		/>
		<path
			fill="#888"
			d="M7.845 50.199c-.908-.859-1.642-1.863-2.36-2.877a29.364 29.364 0 0 1-4.364-9.145 29.43 29.43 0 0 1-.973-5.122c-.008-.09.072-.27-.146-.251V30.99c.304.483.192 1.043.229 
1.557.174 2.439.695 4.794 1.466 7.106.985 2.96 2.436 5.674 4.278 8.183a21.328 21.328 0 0 0 1.694 2.054c.08.091.181.169.176.309ZM27.228 60c.008-.251-.192-.137-.293-.147a29.07 
29.07 0 0 1-4.722-.862c-4.11-1.115-7.827-3.004-11.132-5.69-.21-.17-.454-.305-.59-.557.488.368.979.734 1.464 1.108a29.158 29.158 0 0 0 3.32 2.195c1.827 1.048 3.76 1.863 5.763 
2.49 1.232.387 2.493.69 3.777.9 1.256.208 2.514.354 3.785.398.143.005.27.046.361.166h-1.733ZM6.517 11.377c-.127.436-.472.732-.729 1.077-1.224 1.64-2.203 3.414-3.054 
5.266a28.995 28.995 0 0 0-1.455 3.918 25.08 25.08 0 0 0-.77 3.468 38.209 38.209 0 0 0-.278 2.312c-.05.586.073 1.206-.231 1.759V27.28c.241-.007.132-.2.143-.303C.72 21.28 2.776 
16.17 6.263 11.63c.072-.096.127-.213.254-.254ZM94.059 21.536V9.062c0-.906.342-1.25 1.235-1.253.591-.003 1.183-.005 1.774.002.732.008 1.126.397 1.126 1.119.003 6.247.003 12.494 
0 18.744 0 .698-.392 1.097-1.095 1.11-.77.013-1.538.003-2.309.003-.47 
0-.788-.234-1.058-.605-2.42-3.323-4.849-6.641-7.275-9.962-.783-1.072-1.57-2.14-2.363-3.227-.182.145-.112.319-.112.467-.005 3.995-.002 7.99-.002 11.988 0 .973-.366 1.339-1.342 
1.341h-1.484c-.998-.002-1.323-.329-1.325-1.34V9.198c0-1.094.295-1.385 1.398-1.388.605 0 1.212.019 1.813-.005.53-.02.911.174 1.225.602 1.77 2.423 3.554 4.839 5.334 7.257 1.406 
1.909 2.81 3.821 4.216 5.73.047.063.104.117.156.177.025-.013.057-.023.088-.036ZM124.636 18.3V9.238c0-1.097.335-1.437 1.419-1.437 2.846 0 5.692-.01 8.538.002 1.847.01 3.596.377 
5.093 1.557 1.429 1.126 2.195 2.613 2.374 4.392.15 1.495.039 2.963-.722 4.312-.62 1.103-1.533 1.878-2.682 2.392-.535.239-.532.244-.208.74 1.248 1.914 2.499 3.829 3.744 
5.746.192.298.407.6.327.986-.109.53-.501.85-1.1.861-.771.016-1.539.003-2.309.003-.522 
0-.856-.28-1.123-.708-1.279-2.058-2.577-4.105-3.853-6.167-.195-.317-.413-.447-.794-.439-1.346.024-2.696.024-4.042 0-.413-.008-.511.14-.508.524.015 1.814.007 3.625.007 5.438 0 
.986-.358 1.347-1.333 1.352-.522.002-1.046.005-1.567 0-.862-.01-1.258-.405-1.261-1.269-.003-3.072 0-6.146 0-9.223Zm7.534-6.426v-.02c-.988 
0-1.98.007-2.968-.006-.298-.005-.412.086-.41.397a266.79 266.79 0 0 1 0 4.818c-.002.34.119.417.431.415 1.593-.013 3.188-.01 4.781-.003.607.003 1.207-.023 1.801-.155 2.163-.475 
2.628-2.294 1.87-3.947-.475-1.037-1.476-1.406-2.54-1.48-.983-.074-1.977-.019-2.965-.019ZM111.815 
29.228c-2.838.016-5.318-.874-7.293-2.955-1.546-1.629-2.418-3.588-2.739-5.811-.25-1.723-.218-3.438.238-5.106.939-3.427 2.976-5.98 6.447-7.038 3.529-1.074 6.906-.623 9.882 1.722 
1.458 1.15 2.423 2.667 3.033 4.41.184.533-.226 1.184-.789 
1.207-.864.034-1.73.024-2.597.003-.402-.01-.638-.306-.788-.641-.662-1.456-1.752-2.428-3.28-2.898-.77-.236-1.564-.267-2.366-.207-1.756.13-3.287.716-4.408 2.145-.747.955-1.149 
2.047-1.286 3.24-.221 1.9-.031 3.72 1.05 5.368.929 1.414 2.286 2.138 3.918 2.371 1.022.146 2.049.19 3.064-.109 1.497-.443 2.576-1.38 3.24-2.768.275-.576.662-.833 
1.284-.814.605.018 1.209-.003 1.814.005.955.013 1.385.607 1.051 1.489-1.095 2.887-3.093 4.86-6.035 5.85-.35.117-.706.22-1.066.304-.781.184-1.578.223-2.374.233ZM172.741 
16.535h5.9c1.035 0 1.393.356 1.395 1.378v1.484c-.002.955-.355 1.3-1.325 1.302h-4.25c-1.442 0-2.887.016-4.33-.01-.433-.008-.527.137-.524.542.018 2.169.01 4.34.008 6.51 0 
.9-.374 1.273-1.271 1.276-.563.002-1.129.002-1.692 0-.843-.003-1.222-.384-1.227-1.238-.005-.947 0-1.896-.003-2.843v-6.922c0-1.154.319-1.476 1.461-1.476 1.954-.003 3.905-.003 
5.858-.003ZM191.607 16.548c1.98 0 3.959-.003 5.939 0 1.02 0 1.354.337 1.359 1.36.003.52.006 1.042 0 1.564-.01.864-.368 1.224-1.235 1.224-2.887.003-5.772 0-8.66.003-.547 0-.547 
0-.547.537v6.59c0 .812-.368 1.183-1.173 1.188-.646.003-1.292.008-1.938-.003-.625-.01-1.037-.41-1.04-1.03-.005-3.46-.008-6.919 0-10.38.003-.687.423-1.05 1.193-1.053 2.032-.003 
4.066 0 6.102 0ZM71.042 18.254v-9.1c0-1.015.342-1.353 1.364-1.355h1.443c.978.002 1.354.37 1.357 1.336.002.892 0 1.785 0 2.677v15.484c0 1.188-.32 1.502-1.526 1.502-.467 
0-.934.005-1.4-.003-.852-.01-1.236-.392-1.236-1.237-.005-3.1-.002-6.201-.002-9.304ZM153.924 29.014c-2.281 0-4.561.002-6.842 0-.861 
0-1.227-.36-1.229-1.214-.003-.618-.008-1.235.002-1.853.013-.648.41-1.05 1.061-1.05 4.657-.006 9.314-.006 13.971 0 .612 0 1.027.36 1.061.967.039.724.037 1.456.003 
2.18-.029.614-.457.967-1.103.967-2.309.005-4.615.003-6.924.003ZM153.916 7.812h6.764c.954 0 1.281.332 1.287 1.294.002.563.01 1.126-.003 1.69-.016.746-.376 1.12-1.121 
1.12-4.618.005-9.238.005-13.856 0-.766 0-1.126-.384-1.131-1.147-.006-.563-.003-1.126 0-1.689.002-.931.337-1.268 1.26-1.268 2.265-.003 4.533 0 6.8 0ZM173.306 11.92c-2.184 
0-4.369.002-6.553 0-1.004 0-1.328-.328-1.331-1.334-.003-.55-.008-1.098.003-1.648.013-.729.402-1.123 1.126-1.126 4.532-.005 9.067-.005 13.599 0 .735 0 1.142.41 1.157 
1.15.013.589.011 1.18 0 1.77-.015.796-.402 1.18-1.201 1.182-2.267.01-4.532.005-6.8.005ZM192.246 11.921c-2.213 0-4.426.003-6.639 0-.976 0-1.298-.329-1.3-1.312 
0-.576-.005-1.155.002-1.73.011-.657.39-1.046 1.051-1.062.618-.015 1.238-.005 1.855-.005 3.902 0 7.807.003 11.709-.003.469 0 .858.112 1.115.548.083.14.122.27.122.42 0 .714.005 
1.427 0 2.14a.988.988 0 0 1-.869.979c-.176.023-.355.023-.534.023-2.169.002-4.341.002-6.512.002ZM153.262 20.454c-2.088 0-4.177.003-6.265 0-.76 0-1.139-.37-1.144-1.123a81.422 
81.422 0 0 1 .002-1.977c.011-.622.41-1.03 1.038-1.03 4.247-.005 8.491-.005 12.738 0 .654 0 1.074.436 1.082 1.092.011.646.008 1.29 0 1.936-.008.692-.415 1.1-1.102 
1.1-2.117.005-4.232.002-6.349.002ZM138.365 49.43c-.934 0-1.87-.01-2.804.005-.291.006-.423-.057-.418-.38.008-.582-.01-.592.589-.657.296-.031.26-.231.26-.418.002-1.193 0-2.39 
0-3.583 0-.822-.013-1.647.007-2.47.008-.324-.093-.47-.42-.415-.508.083-.415-.275-.431-.568-.015-.306.003-.514.423-.508 1.868.02 3.739.013 
5.607.002.295-.002.425.073.415.39a31.779 31.779 0 0 0 0 1.852c.008.277-.101.35-.363.342-1.015-.026-.846.133-.862-.838-.002-.124-.005-.246 
0-.37.008-.208-.091-.302-.295-.302-.867 0-1.731.003-2.597 0-.234 0-.307.117-.304.335.008.726.01 1.455-.003 2.182-.005.272.13.319.358.319.646-.005 1.295.026 
1.938-.01.467-.026.41.249.429.544.02.338-.052.509-.444.49-.631-.028-1.264.003-1.897-.012-.29-.005-.386.109-.381.389.013.742.013 1.484 0 2.223-.005.296.093.428.4.423.796-.01 
1.595-.01 2.392 0 .303.005.43-.124.407-.42a2.533 2.533 0 0 1 0-.247c.01-.983-.153-.85.931-.864.218-.002.291.1.291.299-.003.659-.008 1.318.002 
1.977.006.288-.166.29-.376.288l-2.854.002ZM108.177 44.308c0 1.196.011 2.39-.007 
3.585-.006.369.096.555.485.5.516-.072.394.3.415.585.026.316-.047.49-.423.462-.397-.032-.796-.008-1.196-.008-.176 0-.358.036-.405-.229-.036-.21-.186-.114-.28-.046-1.479 
1.037-3.943.07-4.164-2.24-.101-1.06-.005-2.064.758-2.887.799-.861 2.14-1.133 3.308-.659.339.138.402.065.391-.264a19.27 19.27 0 0 1 
0-1.443c.013-.324-.101-.464-.425-.42-.563.075-.376-.34-.415-.618-.047-.34.109-.425.423-.407.384.023.77.018 1.154 0 .288-.013.413.093.376.381-.002.013 0 .029 0 .042.005 
1.222.005 2.444.005 3.666Zm-1.11 1.998c0-.467-.003-.932.003-1.399.002-.174-.039-.306-.19-.423-.916-.71-2.444-.345-2.926.7-.26.561-.247 1.142-.169 1.731.171 1.32 1.495 2.063 
2.704 1.505.381-.177.646-.43.588-.924-.046-.392-.01-.794-.01-1.19ZM114.617 
45.332c0-1.206-.008-2.415.005-3.622.005-.355-.114-.519-.478-.467-.537.078-.402-.306-.435-.602-.042-.37.135-.438.454-.422.384.02.77.026 
1.152-.003.342-.026.415.114.407.425-.018.906-.005 1.811-.008 2.717 0 .236.029.384.327.218 1.03-.569 2.078-.649 3.056.08 1.03.768 1.287 1.884 1.134 3.085-.156 1.237-.752 
2.202-2.006 2.612a2.87 2.87 0 0 1-2.091-.083c-.194-.085-.301-.155-.5.073-.192.218-.579.106-.864.057-.255-.044-.153-.316-.153-.493-.006-1.188 0-2.382 0-3.575Zm1.092 1.074c0 
.426.015.851-.005 1.274-.013.3.109.483.368.617 1.321.693 2.67.04 2.989-1.481.262-1.238-.389-2.942-2.198-2.63-.723.124-1.281.391-1.162 1.276.044.308.008.63.008.944ZM173.444 
46.336c0-.535-.013-1.072.005-1.606.011-.296-.07-.454-.389-.4-.563.096-.431-.303-.462-.612-.036-.366.127-.444.454-.426.384.024.771.013 
1.155.003.246-.008.397.044.376.337-.023.312.111.273.311.127.768-.563 1.604-.752 2.525-.425.622.22.991.67 1.133 1.302.234 1.033.089 2.081.12 
3.121.018.566.002.574.55.623.197.018.308.086.301.298-.006.15-.006.301 0 .454.007.203-.07.304-.288.304-.771-.005-1.539-.005-2.309 
0-.236.002-.301-.12-.312-.335-.033-.64-.041-.643.594-.718.179-.021.294-.089.291-.275-.013-.934.052-1.866-.039-2.8-.096-1.004-1.04-1.232-1.699-1.074a3.033 3.033 0 0 
0-.465.166c-.485.203-.747.5-.7 1.095.062.804.026 1.619.01 2.428-.008.322.068.506.431.46.529-.07.423.306.444.612.023.337-.094.462-.444.449a30.322 30.322 0 0 0-2.063 
0c-.298.007-.399-.104-.371-.382a1.26 1.26 0 0 0 0-.288c-.038-.314.104-.433.405-.389.348.052.457-.098.444-.438-.026-.54-.008-1.077-.008-1.611ZM81.373 46.552c0 .467.018.934-.006 
1.401-.015.32.096.47.418.436.568-.062.407.35.441.654.042.394-.195.402-.485.397a43.788 43.788 0 0 0-2.021.002c-.272.008-.382-.086-.355-.353.01-.096.01-.192 
0-.288-.037-.295.067-.451.386-.402.363.057.48-.093.47-.456a23.557 23.557 0 0 1 0-2.182c.08-1.39-1.033-1.873-2.162-1.375-.472.207-.762.48-.713 1.082.067.832.026 1.673.01 
2.51-.005.32.102.47.428.424.543-.078.405.314.436.61.037.35-.111.446-.446.435a30.36 30.36 0 0 0-2.06 
0c-.407.016-.397-.225-.394-.503.003-.278-.055-.589.389-.54.381.044.498-.122.49-.498a96.549 96.549 0 0 
1-.002-3.128c.005-.304-.063-.454-.408-.444-.48.013-.454-.008-.48-.61-.015-.386.174-.43.478-.417.316.012.633.007.947.002.293-.005.573-.054.58.405.006.249.206.111.328.034.514-.333 
1.048-.576 1.689-.553 1.224.041 1.974.747 2.026 1.969.018.467.003.934.003 1.398.002-.01.007-.01.013-.01ZM189.239 52.213c-.288 
0-.579-.013-.867.002-.332.021-.407-.16-.376-.436.028-.256-.135-.653.353-.617.353.026.449-.174.591-.433.444-.82.415-1.583.013-2.436-.563-1.199-.999-2.457-1.484-3.692-.047-.12-.075-.234-.223-.262-.14-.026-.348.036-.371-.172a2.91 
2.91 0 0 1 .008-.734c.021-.145.187-.135.311-.135.563-.002 1.126.006 1.692-.002.283-.005.379.137.332.384-.047.239.194.654-.312.64-.381-.007-.368.161-.269.426.373.999.744 2 
1.118 3 .031.082.018.215.153.201.106-.01.143-.114.179-.204.386-1.01.77-2.019 
1.162-3.026.104-.27.075-.399-.262-.394-.511.008-.267-.402-.314-.64-.046-.242.042-.395.327-.39.563.01 1.126.013 1.692 0 
.314-.008.384.164.342.418-.039.241.174.64-.34.615-.251-.013-.358.114-.443.334a894.776 894.776 0 0 1-2.411 
6.064c-.132.329-.072.461.294.433.503-.04.342.345.365.6.029.277-.02.49-.389.46-.293-.023-.581-.004-.871-.004ZM98.91 46.497c-.617 
0-1.237.01-1.854-.002-.294-.005-.35.122-.31.379.188 1.17 1.044 1.803 2.235 1.655.425-.052.84-.14 
1.211-.358.397-.234.478.098.636.327.2.283.005.368-.197.487-1.007.592-2.068.815-3.197.436-1.162-.389-1.901-1.68-1.818-3.116.054-.952.251-1.847 1.025-2.514.83-.716 1.803-.796 
2.791-.524.981.27 1.414 1.054 1.585 2.011.218 1.222.226 1.222-.996 1.222-.368-.003-.74-.003-1.11-.003Zm-.562-.853h1.279c.166 0 
.293-.02.285-.247-.029-.78-.636-1.336-1.481-1.34-.805-.004-1.541.624-1.58 1.327-.013.213.08.263.262.26.41-.003.822 0 1.235 0ZM128.585 46.496c-.618 
0-1.238.013-1.855-.005-.286-.008-.348.1-.33.363.068 1.001.916 1.751 1.964 1.697.509-.026 1.017-.102 
1.461-.374.397-.244.485.067.646.303.184.276.021.38-.192.504-.885.514-1.814.76-2.838.526-1.163-.264-1.798-1.024-2.055-2.15-.254-1.121-.158-2.195.519-3.145 1.037-1.455 
3.427-1.468 4.356-.044.415.636.469 1.362.521 2.089.018.249-.171.238-.34.238-.622-.002-1.24-.002-1.857-.002Zm-.602-.854h.864c.773 0 .887-.197.516-.9a1.203 1.203 0 0 
0-.514-.516c-.957-.496-2.096.044-2.35 1.097-.06.247.01.327.249.322.412-.008.822-.003 1.235-.003ZM168.932 46.496c-.617 0-1.237.008-1.855-.003-.303-.005-.373.107-.321.398.212 
1.193 1.019 1.784 2.236 1.634.425-.055.835-.148 1.211-.36.408-.232.462.132.626.347.207.275-.008.35-.182.46a4.016 4.016 0 0 
1-2.182.63c-1.427-.008-2.537-.921-2.765-2.328-.146-.902-.12-1.8.29-2.656.807-1.676 2.96-1.78 4.001-1.092.783.518.996 1.346 1.102 
2.202.096.765.073.768-.679.768h-1.482Zm-.558-.853c.413 0 .825-.008 1.238.002.252.005.35-.067.291-.34-.182-.838-.659-1.25-1.487-1.25-.771 0-1.391.482-1.564 
1.211-.058.247-.026.395.285.38.412-.016.825-.003 1.237-.003ZM148.948 46.29c0 .55.013 1.1-.005 
1.647-.011.33.085.49.443.464.161-.013.405-.096.467.133.07.262.076.544-.018.81-.036.103-.161.087-.254.087-.784 
0-1.567-.007-2.351.006-.282.005-.352-.122-.347-.374.018-.617-.086-.703.586-.675.296.013.369-.134.366-.407-.01-1.084-.005-2.169-.005-3.256 
0-.534-.003-.555-.545-.526-.348.018-.477-.06-.483-.444-.005-.418.172-.503.519-.457.395.055.535-.106.519-.506-.021-.461-.021-.926.145-1.38.249-.68.742-1.045 1.425-1.157a3.477 
3.477 0 0 1 1.707.163c.176.063.399.151.22.403-.122.174-.02.57-.464.446-1.497-.423-2.197.233-1.92 1.777.029.158.099.252.265.254.192.003.384.003.578.003.542 0 
.677.184.555.726-.038.174-.147.172-.275.169-.246 0-.495.01-.742-.008-.28-.02-.396.078-.386.374.013.573 0 1.149 0 1.728ZM144.543 46.292c0 .589.005 1.18-.003 
1.77-.003.243.08.334.34.326.612-.018.773.226.62.838-.044.177-.158.203-.306.203-.797 
0-1.593-.003-2.39.002-.233.003-.324-.104-.319-.322.008-.576-.103-.81.618-.726.215.023.306-.062.306-.273a745.503 745.503 0 0 1 0-3.663c0-.197-.088-.272-.275-.257-.068.006-.138 
0-.205 0-.49 0-.677-.275-.514-.747.073-.207.244-.145.382-.145.622-.003.614.078.612-.638a3.849 3.849 0 0 1 .124-1.098c.213-.76.716-1.193 1.469-1.312a3.422 3.422 0 0 1 
1.707.163c.148.052.412.114.249.356-.13.192-.031.653-.529.482a2.337 2.337 0 0 0-.976-.111c-.462.044-.801.306-.864.744-.057.392-.031.794-.036 
1.19-.003.183.122.224.278.221.275-.002.55 0 .825 0 .373-.002.298.273.311.498.013.229-.008.416-.314.4-.259-.013-.521.01-.783-.008-.257-.018-.335.088-.33.335.01.591.003 
1.183.003 1.772ZM180.636 46.543c.002-1.655.679-2.758 1.94-3.253.978-.384 1.915-.241 
2.761.39.171.126.332.217.555.212.462-.008.311.355.334.6.024.25-.007.456-.355.461-.438.008-.778-.05-1.118-.42-.742-.81-2.198-.509-2.703.526-.387.789-.41 1.614-.047 2.424.584 
1.297 2.184 1.46 3.046.329.077-.104.098-.332.316-.252.254.096.548.169.703.392.114.163-.088.324-.184.462-1.206 1.71-3.866 
1.523-4.846-.332-.291-.542-.41-1.126-.402-1.539ZM155.125 46.414c0-1.492.698-2.623 1.949-3.119.96-.38 1.883-.251 
2.729.353.143.101.262.236.462.247.15.008.379-.096.428.124.062.278.096.581-.026.849-.078.173-.999.116-1.147-.076-.547-.71-1.276-.874-2.057-.57-.812.316-1.126 1.037-1.191 
1.87-.054.708.073 1.37.573 1.912.758.82 2.032.74 2.688-.16.086-.12.148-.338.306-.286.255.08.532.169.714.379.101.12-.011.254-.091.37-.677.997-1.632 1.425-2.802 
1.262-1.214-.172-1.992-.921-2.363-2.076-.117-.368-.208-.747-.172-1.08ZM83.344 46.385c.003-1.464.708-2.6 1.938-3.088.96-.381 1.884-.257 
2.732.345.156.11.285.27.503.247.628-.07.384.402.428.69.058.379-.197.358-.464.373-.394.024-.674-.075-.96-.391-.703-.784-2.104-.592-2.66.355-.52.89-.552 1.837-.04 2.745.645 1.15 
2.184 1.217 2.952.15.215-.298.493-.298.913.024.28.212.05.394-.068.555-.684.944-1.631 1.339-2.765 1.167-1.196-.181-1.961-.926-2.332-2.062-.128-.374-.218-.768-.177-1.11ZM71.89 
44.881c0-.975-.013-1.948.008-2.924.008-.34-.101-.493-.444-.446-.498.07-.42-.275-.438-.579-.018-.334.065-.51.451-.498.672.024 1.347.018 2.019 0 .314-.008.474.08.43.415a.977.977 
0 0 0 0 .247c.04.327-.098.459-.423.412-.316-.046-.43.078-.43.41a466.86 466.86 0 0 1 0 
6.011c-.003.33.083.496.436.45.513-.07.394.303.415.588.023.309-.034.488-.415.472-.7-.028-1.401-.023-2.102-.002-.37.01-.402-.19-.384-.467.018-.27-.104-.639.384-.59.384.04.503-.119.496-.495-.016-1.001-.003-2.003-.003-3.004ZM92.025 
46.797c0 .384.013.768-.005 1.152-.016.314.09.441.42.434.591-.014.783.29.61.876-.047.161-.156.169-.283.169-.825 
0-1.648-.002-2.473.003-.23.002-.306-.107-.316-.327-.034-.633-.042-.639.586-.727.184-.026.267-.111.267-.293 0-1.165.003-2.332.008-3.497 
0-.273-.187-.25-.368-.262-.166-.01-.405.083-.462-.15a1.465 1.465 0 0 1 .013-.771c.031-.099.153-.106.249-.106.467-.003.934.002 
1.401-.003.213-.003.298.096.293.3-.002.079-.013.177.075.219.117.057.174-.047.234-.114.534-.597 1.25-.475 
1.925-.457.311.01.223.32.197.49-.047.296.228.753-.19.851-.295.07-.78.273-.993-.257-.047-.116-.163-.057-.257-.033-.467.114-.85.591-.918 1.15-.005.04-.01.082-.01.121 0 .41 0 
.822-.003 1.232.002 0 .002 0 0 0ZM123.331 44.262c0 1.21.013 2.416-.007 3.625-.006.376.111.55.495.49.54-.083.415.298.444.6.036.363-.094.477-.459.461a29.944 29.944 0 0 
0-2.102-.002c-.412.013-.389-.236-.384-.509.005-.272-.065-.6.381-.545.421.05.54-.124.538-.537a325.79 325.79 0 0 1 
0-6.094c.002-.394-.12-.566-.517-.508-.485.067-.384-.275-.404-.556-.021-.306.028-.498.409-.472.41.029.825.021 1.235.003.294-.013.379.098.374.381-.01 1.22-.003 2.442-.003 
3.663ZM163.739 45.866c0 .688.015 1.375-.006 
2.06-.01.337.096.483.447.457.163-.013.407-.086.469.135.073.26.068.547-.01.812-.036.127-.184.096-.288.096-.755.002-1.513-.008-2.268.005-.277.005-.347-.114-.352-.368-.011-.628-.021-.639.576-.685.269-.021.306-.166.306-.384-.003-1.098-.011-2.198.002-3.295.003-.29-.096-.392-.381-.382-.164.006-.407.104-.472-.116a1.466 
1.466 0 0 1 .002-.812c.037-.122.185-.096.291-.096.454-.003.908.007 1.362-.005.259-.008.329.111.327.35-.011.747-.005 1.486-.005 2.228ZM112.061 45.824c0 .714.011 1.427-.005 
2.14-.005.291.07.423.389.418.496-.01.753.447.535.906-.068.142-.192.137-.314.14-.771.002-1.539-.005-2.309.005-.215.002-.301-.083-.293-.29a2.55 2.55 0 0 
0-.003-.372c-.034-.306.091-.44.4-.394.363.054.474-.099.469-.46a88.172 88.172 0 0 1 
0-3.17c.005-.319-.083-.477-.415-.417-.521.093-.438-.257-.462-.576-.026-.36.086-.496.46-.465.381.032.77.024 1.154.003.304-.018.413.08.405.394-.026.711-.011 1.425-.011 
2.138ZM153.278 45.817c0 .714.01 1.43-.005 2.143-.008.317.088.457.423.426.161-.016.404-.088.459.15.06.262.075.548-.026.81-.039.104-.161.085-.257.085-.77 
0-1.541-.002-2.309.003-.187 0-.285-.07-.288-.262 0-.15.005-.304-.005-.454-.016-.283.137-.376.384-.337.42.065.503-.133.495-.511a76.021 76.021 0 0 1 
0-3.132c.006-.327-.088-.433-.417-.415-.472.023-.449 0-.485-.64-.024-.429.233-.387.5-.385.384.003.771.01 1.155-.002.28-.01.389.088.381.381-.018.71-.005 1.427-.005 
2.14ZM28.961.024c-.02.122-.106.146-.213.146-.37 0-.739.057-1.105.07-2.034.07-4.016.477-5.954 1.035a28.906 28.906 0 0 0-6.367 2.67c-1.237.705-2.438 1.463-3.546 
2.363-.109.088-.223.213-.392.205.615-.633 1.37-1.085 2.096-1.57 2.315-1.551 4.818-2.724 7.47-3.567C23.394.6 25.908.175 28.466.014c.166-.008.332.008.495.01ZM163.969 
41.14a.795.795 0 0 1-.799.767.785.785 0 0 1-.763-.763c-.007-.41.371-.77.799-.765.408.005.768.363.763.76ZM152.728 40.38a.743.743 0 0 1 .742.76.754.754 0 0 
1-.781.764c-.444-.01-.758-.352-.74-.804a.742.742 0 0 1 .779-.72ZM111.462 41.905a.769.769 0 0 1-.727-.825.77.77 0 0 1 .81-.7.754.754 0 0 1 .721.783.75.75 0 0 1-.804.742ZM9.251 
8.375a3.297 3.297 0 0 1-.869.87c.22-.359.517-.642.87-.87ZM8.009 9.63c-.08.328-.353.491-.592.68a1.6 1.6 0 0 1 .592-.68ZM10.235 7.48c-.145.234-.304.457-.597.525a1.25 1.25 0 0 1 
.597-.525ZM7.342 10.389c-.05.254-.241.384-.441.508.083-.228.246-.381.44-.508ZM10.904 6.896c-.124.2-.254.392-.508.442.134-.187.277-.364.508-.442ZM6.862 
10.934c-.013.181-.109.298-.272.365.01-.181.137-.277.272-.365ZM11.309 6.586a.405.405 0 0 1-.369.275c.091-.135.192-.254.369-.275ZM9.493 51.861a6.408 6.408 0 0 
1-1.344-1.343c.498.396.947.845 1.344 1.343ZM10.4 52.67a1.295 1.295 0 0 1-.588-.504c.285.062.428.293.589.503ZM42.088 
44.585c.01.482-.207.744-.547.923-.402.21-.752.088-1.053-.197-.696-.664-1.375-1.341-2.063-2.01-2.597-2.528-5.194-5.054-7.793-7.579-.545-.526-.932-.526-1.484.01-2.343 
2.276-4.683 4.556-7.023 6.834-.934.91-1.866 1.824-2.81 2.722-.591.563-1.323.415-1.59-.301a.87.87 0 0 1 .02-.641c.709-1.837 1.412-3.676 2.123-5.513.908-2.345 1.823-4.69 
2.731-7.036l2.904-7.498c1.017-2.625 2.03-5.253 3.048-7.879.182-.472.36-.944.542-1.416a.984.984 0 0 1 .67-.618.73.73 0 0 1 .897.449c.851 2.426 1.834 4.8 2.753 7.2 1.064 2.783 
2.148 5.56 3.225 8.338.962 2.485 1.927 4.97 2.892 7.456.836 2.153 1.674 4.31 2.504 6.465.047.117.047.247.054.29Z"
		/>
	</svg>
);
export default IncrefImg;
