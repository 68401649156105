import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { postApi } from '../../services/api';

const Middleware = function (params) {
	useEffect(() => {
		// const getCountryInfo = async function () {
		// 	const resp = await postApi({}, '/acess-geoip');
		// 	if (resp.type === 'success') {
		// 		localStorage.setItem('countryInfo', JSON.stringify(resp.data));
		// 	}
		// };
		// const countryCodeExist = localStorage.getItem('countryInfo');
		// if (!countryCodeExist) {
		// 	getCountryInfo();
		// }
	}, []);
	return <></>;
};

export default Middleware;
