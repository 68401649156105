import React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { showSnack } from 'react-redux-snackbar';
import DashboardFooter from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import ProductLanding from './productsLanding';
import { postApi } from '../../services/api';
import './index.css';

import SaaSProductLanding from './saasProduct';
import { useDispatch, useSelector } from 'react-redux';

const ProductScreen = function () {
	const [activePage, setActivePage] = useState('it-product');
	const companyRoles = useSelector((state) => state.appreducer.companyRoles);
	return (
		<div className="dashboard-container">
			<HeaderComponent />

			<div className="product-options">
				{companyRoles.includes('it-products') ? (
					<div
						className={activePage === 'it-product' ? 'active' : ''}
						onClick={() => setActivePage('it-product')}
					>
						IT Products
					</div>
				) : null}
				{companyRoles.includes('saas-products') ? (
					<div
						className={activePage === 'saas-product' ? 'active' : ''}
						onClick={() => setActivePage('saas-product')}
					>
						SaaS Products
					</div>
				) : null}

				{companyRoles.includes('it-services') ? (
					<div
						className={activePage === 'it-service' ? 'active' : ''}
						onClick={() => setActivePage('it-service')}
					>
						IT service
					</div>
				) : null}
			</div>
			{activePage === 'saas-product' && companyRoles.includes('saas-products') ? <SaaSProductLanding /> : null}
			{activePage === 'it-product' && companyRoles.includes('it-products') ? <ProductLanding /> : null}
			{activePage === 'it-service' && companyRoles.includes('it-services') ? <ITServiceSection /> : null}
			<DashboardFooter />
		</div>
	);
};

const ITServiceSection = function () {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
	} = useForm({
		defaultValues: {
			fullName: '',
			email: '',
			issueInfo: '',
		},
	});
	const dispatch = useDispatch();
	const onSubmit = async function (data) {
		console.log(data);
		const resp = await postApi(data, '/users/create-service');
		if (resp.type === 'success') {
			reset({
				fullName: '',
				email: '',
				issueInfo: '',
			});
			dispatch(
				showSnack('VALID_IT_FORM', {
					label: 'Successfully created IT Service, we will get back to you',
					timeout: 7000,
				})
			);
		} else {
			dispatch(
				showSnack('INVALID_IT_FORM', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	};
	return (
		<div className="it-service-container">
			<div className="head-1">Pay Per Use</div>
			<div className="head-2">Get IT Support based on your requirement</div>
			<div className="question-1">Don’t have IT support at your organisation?</div>
			<div className="question-1">Do not worry! We’ve got your back</div>
			<div className="question-1">
				You can now avail IT services on Pay per service i.e., only when there is an requirement you can raise a
				service request and you’ll be charged for the same.
			</div>
			<div className="question-1">Simple, Easy, & Flexible!</div>
			<div className="content">
				<div className="section-1">
					<img src={require('../../images/ecorework-it-support.png')} alt="" />
					<div className="head-3">Pay Per Use</div>
					<ul>
						<li>No overhead cost</li>
						<li>Pay only when you have a request</li>
						<li>Flexibility</li>
						<li>Choice of service</li>
						<li>Different rates of pay</li>
						<li>Other varying requirements</li>
					</ul>
				</div>
				<div className="section-1">
					<img src={require('../../images/it-support-fulltime.png')} alt="" />
					<div className="head-3">Full-time</div>
					<ul>
						<li>Overhead cost</li>
						<li>Continuous Struggle For Improved Services</li>
						<li>No flexibility</li>
						<li>Single disciplinary specialist</li>
						<li>Monthly pay</li>
						<li>Hire multiple people for various requirements</li>
					</ul>
				</div>
			</div>
			<div className="service-form">
				<div className="head-4">Request Service</div>
				<form onSubmit={handleSubmit(onSubmit)}>
					<input className="input" type={'text'} placeholder="Full Name" {...register('fullName')} />
					<input className="input" type={'text'} placeholder="Email ID" {...register('email')} />
					<textarea className="input" type={'text'} placeholder="Issue Facing" {...register('issueInfo')} />
					<button>Submit</button>
				</form>
			</div>
		</div>
	);
};

export default ProductScreen;
