import axios from 'axios';
const { serverUrl } = require('./constants');

export const postApi = async (body, path, url = serverUrl) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${url}${path}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
		body: JSON.stringify(body),
	});
	return await rawResponse.json();
};

export const fileUploadApi = async (body, path) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${serverUrl}${path}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			// 'Content-Type': 'multipart/form-data',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
		body: body,
	});
	return await rawResponse.json();
};

export const putApi = async (body, path) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${serverUrl}${path}`, {
		method: 'PUT',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
		body: JSON.stringify(body),
	});
	return await rawResponse.json();
};

export const patchApi = async (path, data, url = serverUrl) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${url}${path}`, {
		method: 'PATCH',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
		body: JSON.stringify(data),
	});
	return await rawResponse.json();
};

export const deleteApi = async (path, data) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${serverUrl}${path}`, {
		method: 'DELETE',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
		body: JSON.stringify(data),
	});
	return await rawResponse.json();
};

export const getApi = async (path, url = serverUrl) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${url}${path}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
	});
	return await rawResponse.json();
};

export const verifyAuth = async (path) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${serverUrl}${path}`, {
		method: 'GET',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
	});
	return await rawResponse.json();
};

export async function uploadToS3(presignedUrl, fileType, fileObject) {
	console.log(fileObject, 'fileObject');
	const requestOptions = {
		method: 'PUT',
		headers: {
			'Content-Type': fileObject.type,
		},
		body: fileObject,
	};
	const response = await fetch(presignedUrl, requestOptions);
	return await response;
}
export function download(url, filename) {
	fetch(url)
		.then((response) => response.blob())
		.then((blob) => {
			const link = document.createElement('a');
			link.href = URL.createObjectURL(blob);
			link.download = filename;
			link.click();
		})
		.catch(console.error);
}
