import * as React from 'react';
const ErithImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={192} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M43.884 0c-.019.3-.062.606-.062.906-.007 10.478-.007 20.961.006 31.438 0 .675-.113 1.294-.369 1.926a7171.375 7171.375 0 0 0-10.008 
24.942c-.232.588-.52.782-1.15.775a7551.538 7551.538 0 0 0-31.5 0C.174 59.994 0 59.85 0 59.2.019 39.67.013 20.142.019.613.019.406.03.206.044 0h43.84ZM31.038 
41.796c-.207-.012-.332-.025-.457-.025-3.675 0-7.357-.012-11.033.013-.544.006-.682-.144-.67-.675a96.673 96.673 0 0 0 0-4.664c-.012-.55.107-.744.707-.731 1.738.044 3.476-.007 
5.208.03.562.014.85-.124 1.062-.693.588-1.6 1.244-3.17 1.913-4.738.232-.544.15-.676-.45-.67-2.557.026-5.107 0-7.664.026-.556.006-.806-.082-.781-.732.056-1.55.05-3.113 
0-4.663-.02-.6.187-.707.731-.7 2.782.025 5.57 0 8.352.025.487.006.719-.144.887-.594.62-1.613 1.257-3.22 1.945-4.807.256-.6.181-.744-.494-.744-5.595.025-11.19.018-16.779 0-.569 
0-.775.1-.775.737.025 9.665.025 19.323 0 28.988 0 .637.213.725.77.725 4.7-.019 9.401-.032 14.096.012.756.007 1.112-.187 1.375-.906.638-1.744 1.357-3.45 2.057-5.214ZM85.424 
0c.169.238-.031.419-.106.613-.988 2.481-2 4.95-2.963 7.439-.2.519-.463.662-1 .662-6.49-.018-12.978 0-19.467-.03-.694-.007-.825.18-.825.843.031 5.126.025 10.252 0 
15.372-.006.625.15.806.794.8 5.288-.025 10.577-.006 15.866-.031.681-.006.75.144.5.744a384.716 384.716 0 0 0-2.932 7.282c-.188.482-.444.62-.957.62-4.138-.026-8.276 
0-12.415-.026-.637-.006-.862.094-.856.813.037 5.151.031 10.302.006 15.453-.006.613.175.744.763.744 7.151-.019 14.303-.012 21.46-.012.844 0 .844 0 .526.787-.963 2.407-1.945 
4.801-2.876 7.214-.206.526-.444.707-1.031.707-8.94-.025-17.885-.025-26.825 0-.65 0-.775-.163-.775-.788.006-19.735 0-39.47 0-59.206h33.113ZM191.659 59.975c-2.632 
0-5.264-.018-7.896.013-.575.006-.706-.138-.706-.706.025-6.708.019-13.41.013-20.117 
0-1.407-.15-2.794-.551-4.151-.643-2.157-2.044-3.47-4.275-3.863-1.682-.3-3.351-.22-4.989.293-2.6.82-3.713 2.851-4.27 5.314-.412 1.82-.587 3.676-.593 5.532-.025 5.658-.019 
11.322.006 16.985 0 .557-.106.725-.7.713a208.273 208.273 0 0 0-7.127 0c-.644.012-.787-.156-.787-.794.018-19.635.018-39.264.012-58.9 
0-.1-.019-.2-.025-.3h.156c.019.213.219.256.344.363 2.494 2.1 4.982 4.207 7.495 6.289.444.368.638.743.632 1.337-.025 6.14-.013 12.278-.013 
18.423v.744c.294-.013.407-.206.557-.325 3.394-2.807 7.251-3.957 11.658-3.451 5.308.606 9.265 4.063 10.459 9.246.275 1.206.319 2.438.606 3.638-.006 7.908-.006 15.816-.006 
23.717ZM139.667 9.496c2.844 2.394 5.526 4.67 8.22 6.926.269.226.319.482.319.8-.012 1.532.038 3.064-.025 4.59-.031.712.213.837.856.818 1.763-.044 3.526 0 
5.289-.025.431-.006.556.113.55.544-.019 2.525-.025 5.051.006 7.57.007.507-.175.575-.618.57-1.763-.026-3.526.024-5.289-.026-.656-.019-.794.188-.788.806.019 9.002.007 18.004.032 
27 0 .725-.151.931-.907.913-2.3-.057-4.601-.05-6.895 0-.669.012-.769-.194-.769-.8.019-9.002.006-18.004.031-27 
0-.731-.169-.944-.912-.919-1.738.063-3.476.006-5.214.031-.469.007-.606-.13-.6-.6.025-2.5.025-4.994 0-7.495-.006-.481.131-.6.6-.594 1.813.025 3.626-.012 
5.439.025.543.013.681-.15.675-.681-.025-3.851-.013-7.702-.013-11.553.013-.237.013-.487.013-.9ZM99.064 26.5c1.757-1.738 3.407-3.344 5.745-3.925 3.226-.8 6.264-.276 9.121 
1.394.281.162.331.287.206.593-1.05 2.588-2.088 5.189-3.138 7.82-.769-.412-1.457-.893-2.219-1.218-3.945-1.682-7.633-.019-8.933 4.057-.588 1.838-.77 3.732-.776 5.651-.006 
6.12-.012 12.234.013 18.354.006.656-.181.781-.8.769a270.876 270.876 0 0 0-7.127-.006c-.494.006-.694-.057-.694-.638.02-11.828.02-23.655.007-35.482 0-.494.112-.638.619-.632 
2.45.031 4.9.025 7.351 0 .488-.006.663.106.631.619-.037.838-.006 1.675-.006 2.644ZM119.487 23.273c2.826 2.276 5.539 4.47 8.264 6.64.263.212.288.437.288.724-.006 9.584-.006 
19.167.006 28.744 0 .438-.062.613-.569.606a368.204 368.204 0 0 0-7.432-.006c-.438.006-.613-.056-.607-.563.019-11.902.013-23.798.013-35.7.006-.095.018-.188.037-.445ZM118.206 
7.765c-.006-3.107 2.463-5.601 5.551-5.595 3.101.006 5.601 2.494 5.589 5.576-.013 3.107-2.432 5.564-5.495 5.57-3.219.006-5.639-2.376-5.645-5.551Z"
		/>
	</svg>
);
export default ErithImg;
