import { isArray } from 'lodash';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './index.css';

const FooterNew = () => {
	const location = useLocation();
	const navigate = useNavigate();

	const goToPath = function (toPath) {
		if (toPath === '/settings') {
			return;
		}

		if (isArray(toPath)) {
			for (let x = 0; x < toPath.length; x++) {
				if (!location.pathname.includes(toPath[x])) {
					navigate(toPath[x]);
					break;
				}
			}
		} else if (!location.pathname.includes(toPath)) {
			navigate(toPath);
		}
	};
	return (
		<div>
			<div className="footer-container">
				<div className="content">
					<div className="image-container">
						<img src={require('../../images/new-landing/assetze-logo.png')} />
					</div>
					<div className="text-content">
						<div className="each-content">
							<div className="head-1">ITAM</div>
							<div onClick={() => goToPath('/assetManagment')} className="content-1 action">
								Asset Management
							</div>
							<div onClick={() => goToPath('/assetManagment')} className="content-1 action">
								Asset Verification
							</div>
							<div onClick={() => goToPath('/reporting')} className="content-1 action">
								IT Reports
							</div>
						</div>
						<div className="each-content">
							<div className="head-1 action">Incident Manamgement</div>
							<div onClick={() => goToPath('/incideneManagment')} className="content-1 action">
								Incident Management
							</div>
							<div onClick={() => goToPath('/incideneManagment')} className="content-1 action">
								Help Desk
							</div>
						</div>
						<div className="each-content">
							<div className="head-1">Resources</div>
							<div onClick={() => goToPath('/privacy')} className="content-1 action">
								Privacy Policy
							</div>
							<div onClick={() => goToPath('/terms')} className="content-1 action">
								Terms & Conditions
							</div>
							<div onClick={() => goToPath('/faqs')} className="content-1 action">
								FAQs
							</div>
							<div onClick={() => goToPath('/refund-policy')} className="content-1 action">
								Refund Policy
							</div>
						</div>
						<div className="each-content">
							<div className="head-1">Contact</div>
							<div onClick={() => goToPath('/contact-us')} className="content-1 action">
								Contact Us
							</div>
							<div className="content-1 action">Get Started</div>
							<div className="content-1 action">Become A Partner</div>
							<div className="content-1 sales-container">
								<div style={{ paddingRight: '10px' }}>
									<b>For Sales:</b>
								</div>
								<div>
									+91 74830 26159 <br />
									sales@assetze.com
								</div>
							</div>
							<div className="content-1">
								<b>For Support:</b> support@assetze.com
							</div>
							<div className="content-1">
								BCC No 303, RPC layout , First floor, chord road 150 west, Vijayanagar, Attiguppe,
								Bengaluru, Karnataka 560040
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="copyright-new">© 2023 SP Ecorework Private Limited All Rights Reserved</div>
		</div>
	);
};

export default FooterNew;
