import React, { useEffect, useState, useMemo } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useForm, Controller } from 'react-hook-form';
import { getApi, postApi } from '../../services/api';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import ThanksForSigningImg from '../../images/Thankyou.png';

const ThanksForSigning = function () {
	const { register, handleSubmit, reset, control } = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	return (
		<div className="newsignoff-container">
			<HeaderComponent />
			<div className="thanks-container">
				<img src={ThanksForSigningImg} />
				<div>Thank you for signing the document</div>
				<div>For any queries or more information, kindly contact your admin</div>
			</div>
			<FooterDashboard />
		</div>
	);
};

export default ThanksForSigning;
