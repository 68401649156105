import * as React from 'react';
const OneCodeImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={126} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M93.126 24.628c0-4.314-.002-8.167.001-12.02.002-1.759 1.164-2.543 2.786-1.884.843.343.527 1.1.528 1.681.02 8.63.01 17.26.006 25.89 0 1.585-.059 3.172.012 4.755.059 
1.31-.77 1.18-1.613 1.185-.865.005-1.659.072-1.55-1.225.07-.832.15-1.712-.198-2.616-6.69 6.646-13.546 4.69-17.67.35-4.536-4.774-4.134-13.018.497-17.395 3.989-3.768 
12.066-4.844 17.2 1.28Zm.014 7.78c.014-5.48-3.49-9.265-8.619-9.312-5.23-.047-8.899 3.78-8.88 9.263.02 5.444 3.547 9.16 8.748 9.22 5.142.058 8.737-3.71 8.75-9.171ZM115.426 
33.347c-2.875-.003-5.748.032-8.621-.021-1.074-.02-1.267.357-1.104 1.359.69 4.23 5.023 7.27 9.871 6.873 2.167-.178 4.175-.792 5.734-2.353.873-.875 1.229-.26 1.751.315.538.594 
1.085 1.058.181 1.855-1.44 1.27-3.065 2.11-4.896 2.657-5.473 1.635-10.116.097-13.573-4.19-3.321-4.116-3.375-8.872-1.072-13.575 2.09-4.269 7.195-6.734 11.954-5.933 5.06.852 
8.724 4.446 9.493 9.308.586 3.707.586 3.706-3.208 3.706-2.169.002-4.339.002-6.51-.001Zm-1.475-2.458c2.405 0 4.811-.01 7.217.006.772.006 1.187-.125 
1.028-1.093-.613-3.762-4.094-6.684-8.12-6.727-4.143-.045-7.606 2.653-8.347 6.41-.217 1.098.032 1.463 1.18 1.428 2.345-.074 4.694-.024 7.042-.024ZM68.828 32.231c-.094 
8.594-5.742 12.012-12.57 12.547-5.878.462-11.484-5.792-11.546-12.292-.067-6.988 4.888-12.15 11.762-12.254 7.19-.108 12.26 4.815 12.354 
12Zm-3.265.106c.01-5.415-3.534-9.179-8.7-9.241-5.132-.063-8.92 3.866-8.925 9.256-.004 5.402 3.72 9.25 8.935 9.23 5.125-.02 8.681-3.803 8.69-9.245ZM20.361 32.505c-.066-5.244 
2.602-9.464 7.135-11.285 4.77-1.916 10.152-1.028 13.232 2.245.468.496 1.62 1.039.548 1.91-.737.601-1.39 1.626-2.678.251-2.827-3.02-7.438-3.363-10.973-1.138-3.477 2.19-4.96 
6.957-3.486 11.218 2.126 6.154 10.053 7.984 14.594 3.27 1.199-1.244 1.69-.138 2.417.3 1.164.7.2 1.309-.218 1.793-1.916 2.218-4.51 3.002-7.305 3.43-7.713 
1.183-13.66-5.834-13.266-11.995ZM26.28.053C30.754.054 33.966 3.3 34 7.856c.033 4.608-3.222 7.934-7.778 7.95-4.438.018-7.73-3.33-7.704-7.832.025-4.716 3.168-7.923 
7.761-7.921Zm6.144 7.914c-.006-3.842-2.482-6.48-6.11-6.512-3.696-.032-6.253 2.67-6.225 6.582.025 3.67 2.579 6.316 6.12 6.347 3.62.03 6.22-2.655 6.215-6.417ZM66.365 8.286c-1.7 
0-3.404.064-5.1-.02-1.322-.067-1.483.514-1.204 1.599 1.036 4.016 6.18 5.88 9.877 3.56.248-.156.483-.332.71-.517.38-.309.753-.627 1.176-.067.406.541.015.876-.348 1.175-1.991 
1.64-4.3 2.039-6.784 1.658-3.094-.474-5.639-2.937-6.181-5.95-.68-3.771.627-7.01 3.51-8.696 2.738-1.6 6.088-1.318 8.528.748 1.553 1.317 2.303 3.04 2.553 5.043.132 1.063-.161 
1.518-1.285 1.484-1.815-.057-3.634-.017-5.452-.017Zm-.685-1.175v-.005c1.524 0 3.05-.053 4.571.015 1.344.061 1.448-.572 
1.136-1.631-.713-2.427-3.09-4.13-5.736-4.072-2.739.06-5.033 1.923-5.61 4.461-.23 1.007.182 1.252 1.069 1.238 1.521-.022 3.046-.006 4.57-.006ZM39.29 
7.84c0-2.114-.013-4.227.01-6.341.006-.544-.172-1.281.736-1.319.81-.033.727.62.747 1.125.057 1.522.624.959 1.311.373C44.04.018 46.34-.265 48.735.212c2.52.504 4.254 2.591 4.4 
5.47.153 2.987.057 5.987.067 8.981.002.474.035.957-.662 1.008-.742.055-.895-.365-.897-.973-.007-2.526-.007-5.05-.035-7.575-.041-3.876-1.58-5.623-4.94-5.65-3.507-.026-5.674 
2.053-5.79 5.675-.078 2.404-.019 4.814-.044 7.222-.005.553.137 1.278-.777 1.276-.939 0-.739-.766-.745-1.29-.032-2.172-.014-4.345-.014-6.518-.002.002-.005.002-.007.002ZM29.398 
53.81c1.595-.006 3 1.395 3.03 3.025.034 1.718-1.378 3.175-3.063 3.163-1.638-.011-2.992-1.367-3.022-3.03-.032-1.774 1.297-3.15 3.055-3.157ZM3.01 31.36c1.64 0 3.006 1.387 3.002 
3.047-.005 1.682-1.34 3.041-2.996 3.048C1.381 37.462.013 36.08 0 34.408c-.012-1.67 1.35-3.049 3.012-3.049ZM11.102 41.85c.243.166.495.304.707.49 2.937 2.583 5.873 5.167 8.796 
7.768.354.315 1.062.663.509 1.236-.588.608-.928-.24-1.238-.508-2.88-2.47-5.72-4.985-8.551-7.512-.31-.277-.895-.48-.65-1.096.052-.138.242-.221.427-.378ZM9.404 
26.652c-.193-.1-.38-.148-.49-.265-.268-.282-.083-.527.123-.75 2.74-2.967 5.48-5.935 8.226-8.893.247-.267.598-.448.932-.175.406.332.085.616-.15.87a5985.402 5985.402 0 0 1-8.115 
8.764c-.152.165-.344.296-.526.449Z"
		/>
		<path
			fill="#888"
			d="M29.212 7.945c-.043 1.608-1.267 2.765-2.833 2.678-1.438-.079-2.659-1.37-2.621-2.771a2.758 2.758 0 0 1 2.887-2.67c1.531.082 2.607 1.24 2.567 2.763Z"
		/>
	</svg>
);
export default OneCodeImg;
