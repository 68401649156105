import React from 'react';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { format } from 'date-fns';
import { Descriptions } from 'antd';

import { searchOfficeLocation } from '../../services/assets';
import './index.css';

export const SearchOfficeLoc = ({ setOfficeLocation, defaultOfficeInfo, selectedOffice }) => {
	const navigate = useNavigate();
	const promiseOptions = async (inputValue) => {
		const officeList = await searchOfficeLocation(inputValue);
		const resp = officeList.map((el) => ({ value: el._id, label: el.fullAddres }));
		return resp;
	};

	if (!defaultOfficeInfo) {
		return null;
	}

	console.log(defaultOfficeInfo, 'defaultOfficeInfo');

	return (
		<div className="search-office-container">
			<div className="head-1">Compay Branch/Location</div>
			<AsyncSelect
				placeholder={'Search By Company Address'}
				cacheOptions
				defaultOptions
				onChange={(data) => setOfficeLocation(data.value)}
				loadOptions={promiseOptions}
				className="search-office-select"
				defaultValue={defaultOfficeInfo}
			/>
			<div className="create-new-container">
				Unable to find Office Location?{' '}
				<span
					onClick={() => navigate('/account-settings?view=master-setup&tab=company-branch')}
					className="highlighter"
					style={{ cursor: 'pointer' }}
				>
					Add Office Location
				</span>
			</div>
			<ShowSelectedOffice selectedOffice={selectedOffice} />
		</div>
	);
};

const ShowSelectedOffice = ({ selectedOffice }) => {
	if (!selectedOffice) {
		return null;
	}

	const items = [
		{
			key: '6',
			children: selectedOffice.branchId,
			label: 'Branch Id',
		},
		{
			key: '1',
			children: selectedOffice.address1,
			label: 'Address1',
		},
		{
			key: '2',
			children: selectedOffice.address2,
			label: 'Address2',
		},
		{
			key: '3',
			children: selectedOffice.address3,
			label: 'Address3',
		},
		{
			key: '4',
			children: selectedOffice.city,
			label: 'City',
		},
		{
			key: '5',
			children: selectedOffice.state,
			label: 'State',
		},
		{
			key: '6',
			children: selectedOffice.pincode,
			label: 'PIN Code',
		},
	];

	return (
		<div style={{ marginTop: '4vh' }}>
			<Descriptions title="Office Info" items={items} />
		</div>
	);
};
