import React from 'react';
import DashboardFooter from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import DiagnosticPage from '../../components/Diagnostics';

const RunDiagnosticScreen = function () {
	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<DiagnosticPage />
			<DashboardFooter />
		</div>
	);
};

export default RunDiagnosticScreen;
