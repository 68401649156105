import React from 'react';
import DiagnosticResults from '../../components/Diagnostics/DiagnosticResults';
import DashboardFooter from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';

const DiagnosticResultsScreen = function () {
	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<DiagnosticResults />
			<DashboardFooter />
		</div>
	);
};

export default DiagnosticResultsScreen;
