import React from 'react';
import { Link } from 'react-router-dom';
import LandingScreenFooter from '../../components/LandingFooter';
import LandingPageHeader from '../../components/LandingpageHeader';

const RefundPolicy = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />

			<div class="terms-container">
				<h2>
					<strong>Assetze's Customer Satisfaction Policy</strong>
				</h2>
				<p>
					At Assetze we strive to create quality software that you enjoy using for your business or
					professional life. You have a number of choices and we appreciate you giving us your business. Thank
					You. We have created this policy that details what we will do should we fail to meet your
					expectations.{' '}
				</p>
				<p>
					Monthly Subscriptions. If at anytime during your first month using our service you are dissatisfied,
					please contact us. We will do our best to address your issue, provide a work around or give a
					timeline for a solution that will meet your needs. If you are not satisfied, we will gladly offer
					you a FULL REFUND for your purchase, and downgrade your account to the free plan for that service.{' '}
				</p>
				<p>
					Annual Subscriptions. Assetze doesn't force you into an annual subscription as a condition to use
					our services. We prefer to give you the flexibility to choose. In exchange for you signing up for an
					annual up-front commitment, we offer you a significant discount over the already-low monthly
					subscription cost. If at anytime during your first 45 days using our service you are dissatisfied,
					please contact us. We will do our best to address your issue, provide a work around or give a
					timeline for a solution that will meet your needs. If you are not satisfied, we will gladly offer
					you a FULL REFUND for your purchase, and downgrade your account to the free plan.{' '}
				</p>
				<p>
					We want you to be happy with our service throughout your entire contract, not only the first 30 days
					(in case of monthly subscription) and the first 45 days (in case of annual subscription). So we go
					beyond that. If at anytime during your contract we remove, break or discontinue functionality that
					was available at the time you signed up for your contract, we ask you to notify us immediately. If
					we fail to address it on a timely manner to your entire satisfaction, we will offer you a PRO-RATED
					REFUND for the reminder of your contract.{' '}
				</p>
				<p>
					Auto-Renewal. For your convenience, your monthly and yearly subscriptions will auto-renew until you
					cancel the service. Every time before your subscription auto-renews, we will send a mail specifying
					the amount that will be charged to your credit card. Similarly, after each renewal we will send you
					a receipt via e-mail specifying the amount that has been deducted together with the next renewal
					date and the next renewal amount.{' '}
				</p>
				<p>
					We know that sometimes customers forget to cancel an account they no longer want until it is has
					already been charged. That's why you can cancel your monthly/annual subscription even five business
					days after your renewal date, and we will still process your cancellation and give you a FULL
					REFUND.{' '}
				</p>
				<p>For questions, please e-mail support@assetze.com </p>
				<p>Exception to our Refund Policy </p>
				<p>
					Please note that we will not entertain a request for refund (FULL or PRO-RATED) when we have
					suspended or terminated your access to Assetze Services due to a violation of our{' '}
					<Link to={'/terms'}> Terms of Service.</Link>
				</p>
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default RefundPolicy;
