import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { useNavigate, useParams } from 'react-router-dom';
import { UPDATE_QUESTION_ANSWER, UPDATE_SECTIONS } from '../../redux/actions';
import { Strings } from '../../screens/vendors/config';
import CertificationForm from './CertificationForm';
import DescriptiveComponent from './DescriptiveComponent';
import { postApi, putApi } from '../../services/api';

import './index.css';
import McqComponent from './McqComponent';
import QuestionWrapper from './QuestionWrapper';
import ResponseForm from './ResponseForm';
import UploadComponent from './UploadComponent';
import useVendor from './useVendor';
import DefaultQuestions from './DefaultQuestions';
const vendorData = {
	vendorName: '',
	vendorType: '',
	PointOfContact: '',
	Email: '',
	contactNumber: '',
	website: '',
	isVendorDetailsSubmitted: false,
};
const VendorForm = () => {
	const { token } = useParams();
	const dispatch = useDispatch();
	const [vendorFormDetails, setVendorFormDetails] = useState(vendorData);
	const [vendorId, setVendorId] = useState('');
	const [wrap, setWrap] = useState(false);
	const navigate = useNavigate();
	useEffect(() => {
		console.log(`${process.env.REACT_APP_rough_server}/vendorForm/verify_link/${token}`);
		const vendorResponseForm = async (data) => {
			try {
				const resp = await postApi({ link: token }, '/vendorForm/verify_link');
				if (resp.type === 'success') {
					console.log(resp);
					console.log(resp?.sectionData);
					setVendorFormDetails(resp?.vendorDetails);
					setVendorId(resp?.vendorId);
					dispatch({
						type: UPDATE_SECTIONS,
						payload: { sections: resp?.sectionData },
					});

					// dispatch(
					//   showSnack("VENDOR_RESPONSE_FORM_ADDED", {
					//     label: "Vendor Form Submitted Successfully",
					//     timeout: 7000,
					//   })
					// );
				}
				if (resp.type === 'error' && resp.errorCode === 'FORM_ALREADY_SUBMITTED') {
					navigate('/responseSubmitted');
				}
				// else {
				//   return dispatch(
				//     showSnack("VENDOR_RESPONSE_FORM_ADDED", {
				//       label: resp.error,
				//       timeout: 7000,
				//     })
				//   );
				// }
			} catch (error) {
				return dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: error && error.message,
						timeout: 7000,
					})
				);
			}
		};
		vendorResponseForm();
	}, [wrap]);

	// const { vendorName, vendorType, pointOfContact, email, website, contactNumber } = vendorFormDetails;
	console.log('vendorFormDetails', vendorFormDetails);

	return (
		<>
			<h3 className="vendorFormHeading">Vendor Registration - Details</h3>
			{vendorFormDetails?.vendorName === '' ||
			vendorFormDetails?.vendorType === '' ||
			vendorFormDetails?.PointOfContact === '' ||
			vendorFormDetails?.Email === '' ||
			vendorFormDetails?.contactNumber === '' ||
			vendorFormDetails?.website === '' ||
			!vendorFormDetails?.isVendorDetailsSubmitted ? (
				<DefaultQuestions
					vendorFormDetails={vendorFormDetails}
					setVendorFormDetails={setVendorFormDetails}
					vendorId={vendorId}
					setWrap={setWrap}
				/>
			) : (
				<Wrap vendorId={vendorId} />
			)}
		</>
	);
};

export default VendorForm;

export const Wrap = ({ vendorId }) => {
	const sectionData = useSelector((state) => state.appreducer.vendorFormData.sections);

	const [error, setError] = useState(false);
	const [certificationForm, setCertificationForm] = useState(false);
	const [questionTypeComponent, setQuestionTypeComponent] = useState();
	const navigate = useNavigate();

	const {
		section,
		next,
		back,
		question,
		currentQuestionIndex,
		currentSectionIndex,
		isFirstStep,
		isLastStep,
		questions,
		isSectionLastQuestion,
		isSectionLastSection,
		sections,
		mcqClicked,
	} = useVendor(sectionData);
	// console.log("question?._id", question?._id?.answer);

	const dispatch = useDispatch();

	console.log('answerr', question);

	const dataShare = {
		section,
		next,
		back,
		question,
		sections,
		currentQuestionIndex,
		currentSectionIndex,
		isFirstStep,
		isLastStep,
		questions,
		isSectionLastQuestion,
		isSectionLastSection,
		mcqClicked,
		questionId: question?._id,
	};

	const nextHandler = () => {
		console.log('answerrrrrr', question.answer);
		console.log(
			!question.hasOwnProperty('answer') || question?.answer === undefined,

			!question.hasOwnProperty('answer'),
			question?.answer === undefined
		);
		if (question.qtype === Strings.QUESTION_ANSWER || question.qtype === Strings.UPLOAD_FILE) {
			if (!question.hasOwnProperty('answer') || question?.answer === undefined) {
				setError(true);
				console.log(error);
				return;
			}
		} else if (question.qtype === Strings.MULTIPLE_CHOICE) {
			if (!question.hasOwnProperty('mcqAnswer') || question?.mcqAnswer.length === 0) {
				setError(true);
				console.log(error);
				return;
			}
		}
		setError(false);

		console.log('after');
		next(currentSectionIndex, currentQuestionIndex);
		const data = {
			sectionId: section._id,
			questions,
			isAnswered: true,
		};

		if (isSectionLastQuestion) {
			const vendorSubmitForm = async () => {
				try {
					const resp = await putApi(data, `/vendorForm/custom-fields/${vendorId}`);

					if (resp.type === 'success') {
						console.log('hello', resp);
						// dispatch(
						//   showSnack("VENDOR_RESPONSE_FORM_ADDED", {
						//     label: "Vendor Form Submitted Successfully",
						//     timeout: 7000,
						//   })
						// );

						if (isSectionLastSection && isSectionLastSection) {
							navigate(`/certification-form/${vendorId}`);
						}
					}
					// else {
					//   return dispatch(
					//     showSnack("VENDOR_RESPONSE_FORM_ADDED", {
					//       label: resp.error,
					//       timeout: 7000,
					//     })
					//   );
					// }
				} catch (error) {
					return dispatch(
						showSnack('VENDOR_RESPONSE_FORM_ADDED', {
							label: error && error.message,
							timeout: 7000,
						})
					);
				}
			};
			vendorSubmitForm();
		}
	};

	const backHandler = () => {
		back(currentSectionIndex, currentQuestionIndex);
	};

	const myData = () => {
		console.log(certificationForm);
		if (question?.qtype === Strings.QUESTION_ANSWER) {
			setQuestionTypeComponent(
				<DescriptiveComponent
					sectionData={sectionData}
					nextHandler={nextHandler}
					backHandler={backHandler}
					dataShare={dataShare}
					error={error}
					setError={setError}
				/>
			);
		} else if (question?.qtype === Strings.MULTIPLE_CHOICE) {
			setQuestionTypeComponent(
				<McqComponent
					sectionData={sectionData}
					nextHandler={nextHandler}
					backHandler={backHandler}
					dataShare={dataShare}
					error={error}
					setError={setError}
				/>
			);
		} else if (question?.qtype === Strings.UPLOAD_FILE) {
			setQuestionTypeComponent(
				<UploadComponent
					sectionData={sectionData}
					nextHandler={nextHandler}
					backHandler={backHandler}
					dataShare={dataShare}
					error={error}
					setError={setError}
				/>
			);
		}
	};

	useEffect(() => {
		myData();
	}, [sectionData, currentQuestionIndex, currentSectionIndex, error]);
	console.log(error);

	return <>{questionTypeComponent ? questionTypeComponent : <div></div>}</>;
};
