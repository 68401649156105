import { showSnack } from 'react-redux-snackbar';

export const triggerShowSnack = (dispatch, message, label = 'SNACK_MESSAGE') => {
	dispatch(
		showSnack(label, {
			label: message,
			timeout: 7000,
		})
	);
};
