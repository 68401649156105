import React, { useState } from 'react';
import Faq from 'react-faq-component';
import { withStyles } from '@material-ui/core/styles';
import HeaderComponent from '../../components/Header';
import './index.css';
import TextField from '@mui/material/TextField';

const profileData = {
	title: <div className="faq-title">Profile</div>,
	rows: [
		{
			id: 1,
			title: 'How to change Password?',
			content: (
				<ul>
					<li>Click on the profile icon on the dashboard</li>
					<li>In the profile settings, enter your old password and then enter the new password </li>
					<li>Confirm the new password and then click on save</li>
				</ul>
			),
		},
		{
			id: 2,
			title: 'How to change profile picture?',
			content: (
				<ul>
					<li>Click on the profile icon on the dashboard</li>
					<li>In the profile settings, you can find profile picture</li>
					<li>Click on the icon and select the </li>
				</ul>
			),
		},
		{
			id: 3,
			title: 'Can I change my organization email?',
			content: (
				<ul>
					<li>Once registered with organisation email ID it is not possible to change</li>
				</ul>
			),
		},
	],
};

const priceData = {
	title: <div className="faq-title">Price</div>,
	rows: [
		{
			id: 1,
			title: 'Which all countries can access Assetze?',
			content: (
				<ul>
					<li>Currently we are operating in 5 countries India, US, UK, Canada & UAE.</li>
				</ul>
			),
		},
		// {
		// 	id: 2,
		// 	title: 'Pricing for Assetze?',
		// 	content: (
		// 		<ul>
		// 			<li>Checkout our “Pricing” page to know more about prices.</li>
		// 		</ul>
		// 	),
		// },
		{
			id: 3,
			title: 'How do I take enterprise plan?',
			content: (
				<ul>
					<li>You can reach out to us at sales@assetze.com and our team will get in touch with you</li>
				</ul>
			),
		},
	],
};

const disposableData = {
	title: <div className="faq-title">Disposable</div>,
	rows: [
		{
			id: 1,
			title: 'What all products do you take as disposable?',
			content: (
				<ul>
					<li>Currently we are taking disposables from India</li>
					<li>
						All kinds of IT Asset tools
						{/* Coffee cups, Printing papers, magazines, cartridges, newspapers, cables, alumnium cans,
						batteries, etc., */}
					</li>
				</ul>
			),
		},
		{
			id: 2,
			title: 'How to reach out to dispose?',
			content: (
				<ul>
					<li>Reach us out via “Contact Us” section or mail us at sales@assetze.com</li>
				</ul>
			),
		},
	],
};

const assetData = {
	title: <div className="faq-title">Asset</div>,
	rows: [
		{
			id: 1,
			title: 'How to add an asset?',
			content: (
				<ul>
					<li>Go to main dashboard</li>
					<li> Click on the '+' button on the top or you can click on “Add new” button.</li>
					<li>You can manually add every field</li>
				</ul>
			),
		},
		{
			id: 2,
			title: 'How to move my asset database to Assetze',
			content: (
				<ul>
					<li>You can upload your database to Assetze platform by using CSV file </li>
					<li>
						Download the template provided in the website, segregate your data according the columns
						provided.{' '}
					</li>
					<li>Upload your database, your data will be visible on the dashboard</li>
				</ul>
			),
		},
		{
			id: 3,
			title: 'How to add custom inputs?',
			content: (
				<ul>
					<li>In the add asset option, you can find various preset fields</li>
					<li>Scroll down to find a button “Custom Field”</li>
					<li>A dialog box appears where you can provide custom field name and also set input for it. </li>
					<li>You can add more fields by clicking on “Add field” and click on save.</li>
				</ul>
			),
		},
		{
			id: 4,
			title: 'How to check assets by date?',
			content: (
				<ul>
					<li>On main dashboard</li>
					<li>Click on the 'Filter' button and select "By Date"</li>
					<li>The data will be sorted accordingly by datewise</li>
				</ul>
			),
		},
		{
			id: 5,
			title: 'How to update health?',
			content: (
				<div>
					<ul>
						<li>Click on health option when you are adding an asset</li>
						<li>Fill the details in the form.</li>
						<li>Click on save and the health will be shown in the dashboard</li>
					</ul>
					<p> Note: Health updation has to be done manually for each asset</p>
				</div>
			),
		},
	],
};

const CssTextField = withStyles({
	root: {
		'& label.Mui-focused': {
			color: 'black',
		},
		'& .MuiInput-underline:after': {
			borderBottomColor: 'white',
		},
		'& .MuiOutlinedInput-root': {
			'& fieldset': {
				borderColor: 'white',
			},
			'&:hover fieldset': {
				borderColor: 'white',
			},
			'&.Mui-focused fieldset': {
				borderColor: 'white',
			},
		},
	},
})(TextField);

const allQuestions = [...assetData.rows, ...profileData.rows, ...priceData.rows, ...disposableData.rows];

const FAQScreen = function () {
	const [highlightButton, setHighlightButton] = useState('');
	const [inputText, setInputText] = useState('');
	let inputHandler = (e) => {
		var lowerCase = e.target.value.toLowerCase();
		setInputText(lowerCase);
		const filteredData = allQuestions.filter((el) => {
			return el.title.toLowerCase().includes(lowerCase);
		});
		if (filteredData.length > 0) setFaqState({ title: 'Search Results', rows: filteredData });
		else setFaqState({ title: 'No Results', rows: filteredData });
	};
	const [faqs, setFaqState] = useState(assetData);

	const styles = {
		bgColor: 'white',
		titleTextColor: 'black',
		rowTitleColor: 'black',
		arrowColor: 'grey',
		rowContentPaddingTop: '10px',
		rowContentPaddingBottom: '10px',
		rowContentPaddingLeft: '50px',
		rowContentPaddingRight: '150px',
	};
	return (
		<div className="faq-container">
			<HeaderComponent />
			<div className="faq--body">
				<div className="h3">Frequently Asked Questions (FAQs)</div>
				<div className="h2">How can we help?</div>
				<CssTextField
					className="search-bar"
					id="outlined-basic"
					onChange={inputHandler}
					variant="outlined"
					fullWidth
					placeholder="Search"
				/>
				<div className="faq-main">
					<div className="faq-options">
						<button
							className={highlightButton === 'asset' ? 'active' : ''}
							onClick={() => {
								setFaqState(assetData);
								setHighlightButton('asset');
							}}
						>
							Asset
						</button>
						<button
							className={highlightButton === 'profile' ? 'active' : ''}
							onClick={() => {
								setFaqState(profileData);
								setHighlightButton('profile');
							}}
						>
							Profile
						</button>
						<button
							className={highlightButton === 'price' ? 'active' : ''}
							onClick={() => {
								setFaqState(priceData);
								setHighlightButton('price');
							}}
						>
							Price
						</button>
						<button
							className={highlightButton === 'disposable' ? 'active' : ''}
							onClick={() => {
								setFaqState(disposableData);
								setHighlightButton('disposable');
							}}
						>
							Disposable
						</button>
					</div>
					<div className="vert-line">
						<hr />
					</div>
					<div className="faq-data">
						<Faq data={faqs} styles={styles}></Faq>
					</div>
				</div>
			</div>
		</div>
	);
};

export default FAQScreen;
