import React, { useEffect } from 'react';
import format from 'date-fns/format';
import HeaderComponent from '../../components/Header';
import { Space, Table, Tag, Pagination } from 'antd';
import './index.css';
import { getApi, postApi } from '../../services/api';
import DownloadIcon from '../../images/svgs/DownloadSvg';
import { Link } from 'react-router-dom';
import { getCompanyJobs } from '../../services/barcode';

const columns = [
	{
		title: 'Job Type',
		dataIndex: 'jobType',
		key: 'jobType',
	},
	{
		title: 'Created',
		dataIndex: 'createdAt',
		key: 'createdAt',
		render: (createdAt) => {
			return <span>{format(new Date(createdAt), 'dd MMM yyyy H:mm:ss')}</span>;
		},
	},
	{
		title: 'Percentage',
		key: 'response',
		render: (_, { response, status }) => {
			if (response.total === 0) return <span>NA</span>;
			return (
				<span>
					{response.completed} completed of {response.total}
				</span>
			);
		},
	},
	{
		title: 'Status',
		key: 'tags',
		dataIndex: 'tags',
		render: (_, { tags, status }) => {
			let color2 =
				status === 'PENDING' || status === 'STARTED'
					? 'geekblue'
					: status === 'CANCELLED' || status === 'FAILED'
					? 'volcano'
					: 'green';

			return (
				<Tag color={color2} key={status}>
					{status}
				</Tag>
			);
		},
	},
	{
		title: 'Action',
		key: 'action',
		render: (_, record) => (
			<Space size="middle">
				<a href={record.response.url}>
					<DownloadIcon fill="#33D7DB" width={24} height={24} />
				</a>
			</Space>
		),
	},
];

const data = [
	{
		key: '1',
		name: 'John Brown',
		age: 32,
		address: 'New York No. 1 Lake Park',
		tags: ['nice', 'developer'],
		jobType: 'ASSET_TAG_GENERATE',
		status: 'PENDING',
		response: { total: 40, completed: 7 },
	},
	{
		key: '2',
		name: 'Jim Green',
		age: 42,
		address: 'London No. 1 Lake Park',
		tags: ['loser'],
		jobType: 'ASSET_TAG_GENERATE',
		status: 'COMPLETED',
		response: { total: 40, completed: 7 },
	},
	{
		key: '3',
		name: 'Joe Black',
		age: 32,
		address: 'Sydney No. 1 Lake Park',
		tags: ['cool', 'teacher'],
		jobType: 'ASSET_TAG_GENERATE',
		status: 'CANCELLED',
		response: { total: 40, completed: 7 },
	},
	{
		key: '4',
		name: 'Joe Black',
		age: 32,
		address: 'Sydney No. 1 Lake Park',
		tags: ['cool', 'teacher'],
		jobType: 'ASSET_TAG_GENERATE',
		status: 'FAILED',
		response: { total: 40, completed: 7 },
	},
	{
		key: '5',
		name: 'Joe Black',
		age: 32,
		address: 'Sydney No. 1 Lake Park',
		tags: ['cool', 'teacher'],
		jobType: 'ASSET_TAG_GENERATE',
		status: 'STARTED',
		response: { total: 40, completed: 7 },
	},
];

export default function JobsInfo() {
	const [page, setPage] = React.useState(1);
	const [totalRecords, setTotalRecords] = React.useState(1);
	const [records, setRecords] = React.useState([]);
	const setIntervalRef = React.useRef();

	useEffect(() => {
		const getAudits = async function () {
			try {
				const resp = await getCompanyJobs(page);
				if (resp.type === 'success') {
					console.log(resp);
					setTotalRecords(resp.totalJobs);
					setRecords(resp.jobList);
				}
			} catch (e) {}
		};
		const intervalID = setInterval(() => {
			getAudits();
		}, 5 * 1000);
		getAudits();

		// this is where the interval ID is saved in the ref object
		setIntervalRef.current = intervalID;
		return () => {
			clearInterval(setIntervalRef.current);
		};
	}, [page, setTotalRecords]);

	const onPageChange = (page, pageSize) => {
		console.log(page, pageSize);
		setPage(page);
	};

	return (
		<div className="account-settings">
			<HeaderComponent />
			<div className="header-activity">
				<div className="header-full">
					<Link className="jobs-info" to={`/activity-info`}>
						<h5>Activity Log</h5>
					</Link>
					<h4>Jobs Info</h4>
				</div>
			</div>
			<div className="activity-table-container">
				<Table pagination={false} columns={columns} dataSource={records} />
				<div style={{ marginTop: '10px', float: 'right' }}>
					<Pagination onChange={onPageChange} pageSize={10} defaultCurrent={page} total={totalRecords} />
				</div>
			</div>
		</div>
	);
}
