import React, { useState } from 'react';
import './index.css';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { display } from '@mui/system';
import useVendor from './useVendor';
import { useDispatch, useSelector } from 'react-redux';
import { Strings } from '../../screens/vendors/config';
import McqComponent from './McqComponent';
import UploadComponent from './UploadComponent';
import ArrowButton from './ArrowButton';
import { UPDATE_QUESTION_ANSWER } from '../../redux/actions';

const DescriptiveComponent = ({ sectionData, nextHandler, backHandler, dataShare, error: globalError, setError }) => {
	const {
		section,
		sections,
		question,
		questions,
		isFirstStep,
		isLastStep,
		next,
		back,
		currentQuestionIndex,
		currentSectionIndex,
		questionId,
	} = dataShare;
	console.log(section, question);
	const dispatch = useDispatch();
	const [answer, setAnswer] = useState('');
	console.log(globalError, question, currentQuestionIndex, questions, 'answer', answer, question?.answer);

	return (
		<>
			<div className="descriptiveContainer">
				<div className="sectionDetailsContainer">
					<div className="sectionCountIndex">
						Section
						<span>
							{currentSectionIndex + 1}/{sections?.length}
						</span>
					</div>
					<div className="sectionName">{section.name}</div>
					<div className="sectionDescription">{section.description}</div>
				</div>
				<div className="descriptiveForm">
					<h4>{question?.question}</h4>
					<div className="question-arrow">
						<input
							type="text"
							placeholder="Enter the answer here"
							value={question.answer ? question.answer : ''}
							className={globalError ? 'responseError' : ''}
							onChange={(e) => {
								dispatch({
									type: UPDATE_QUESTION_ANSWER,
									payload: {
										sectionId: section?._id,
										qId: question?._id,
										qtype: question?.qtype,
										answer: e.target.value,
									},
								});
								setAnswer(e.target.value);
								console.log(question?.answer);
								setError(false);
								if (answer === '') {
									setError(true);
								}
							}}
							required
						/>
						<ArrowButton
							isFirstStep={isFirstStep}
							isLastStep={isLastStep}
							nextHandler={() => {
								nextHandler();
								setAnswer('');
							}}
							backHandler={backHandler}
						/>
					</div>
					<button
						className="next-btn"
						onClick={() => {
							nextHandler();
							setAnswer('');
						}}
					>
						{isLastStep ? 'Submit' : 'Next'}
					</button>
					{globalError && answer === '' ? (
						<h6 className="responseErrorMessage">Please answer this question before heading to next</h6>
					) : null}
				</div>

				<div className="progressiveContainer">
					<p>
						Questions {currentQuestionIndex + 1} of {questions?.length}
					</p>
					<div className="progressExtension">
						<div
							className="progress"
							style={{
								backgroundColor: '#33D7DB',
								width: ((currentQuestionIndex + 1) / questions.length) * 100 + '%',
							}}
						></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DescriptiveComponent;
