import * as React from 'react';
const LearnBay = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={72} height={60} fill="none" {...props}>
		<g fill="#888" clipPath="url(#a)">
			<path
				d="M34.17 21.18c0-1.24-.006-2.454.003-3.668.003-.31-.18-.42-.425-.524-.6-.254-1.132-.604-1.564-1.105-.918-1.066-.877-2.531.076-3.564 1.577-1.71 4.837-2.073 
6.905.035 1.003 1.024.953 2.623-.072 3.636a4.91 4.91 0 0 1-1.51 1.002c-.323.14-.416.343-.39.663.087 1.04.172 2.081.245 3.122.03.444.191.487.507.204.665-.594 1.344-1.173 
1.998-1.778.278-.257.466-.34.8-.04.848.76 1.737 1.475 2.624 2.188.262.21.262.331.01.556a1400.57 1400.57 0 0 0-7.384 6.599c-.233.209-.367.229-.6.011a1677.307 1677.307 0 0 
0-7.092-6.582c-.266-.245-.23-.422.006-.654.798-.784 1.589-1.577 2.373-2.376.239-.243.425-.231.675-.007.762.685 1.54 1.351 2.316 2.022.124.106.217.269.499.26ZM46.677 
33.95c-7.237-2.04-14.472-1.896-21.717.016 1.284-.914 2.82-1.221 4.26-1.768-8.616-.148-17.168.314-25.578 2.373.74-.512 1.56-.85 2.397-1.132a54.07 54.07 0 0 1 
8.988-2.189c5.122-.799 10.273-.97 15.445-.72 1.67.08 3.328.284 4.993.405 1.043.077 2.073-.188 3.11-.279 3.128-.275 6.258-.287 9.391-.178 3.819.134 7.612.55 11.364 1.251 
2.645.495 5.253 1.163 7.732 2.247.455.2.907.42 1.284.777-8.369-2.484-16.973-2.535-25.59-2.576 1.328.543 2.714.936 3.92 1.773ZM57.27 
39.676c-.98-.286-1.974-.491-2.974-.677-2.387-.444-4.788-.767-7.204-1.027-5.908-.638-11.825-.622-17.748-.386-3.793.152-7.575.442-11.321 
1.094-1.03.179-2.026.482-3.011.823-.14.049-.264.15-.448.033 1.785-1.106 3.728-1.747 5.744-2.198 5.791-1.298 11.666-1.752 17.585-1.604 4.616.114 9.192.645 13.676 1.8 1.612.415 
3.22.862 4.751 1.534.342.15.68.304.95.608ZM48.28 50.124c.28-.545.52-1.01.756-1.477.099-.196.264-.251.47-.25.759.005 1.518.005 2.277.008.134 0 .296-.009.363.117.32.605.874 
1.09.854 1.868-.03 1.177-.004 2.353-.01 3.53-.005.794-1.026 1.808-1.805 1.808-1.462 
0-2.923-.007-4.385.005-.313.002-.491-.13-.712-.352-.717-.713-.87-1.546-.856-2.526.048-3.17.031-6.34.003-9.51-.005-.52.148-.659.632-.603.412.047.962-.126 
1.212.082.26.216.077.782.077 1.194.007 3.228.01 6.454-.005 9.682-.002.415.062.594.54.573.984-.042 1.973-.023 
2.96-.006.314.006.435-.08.43-.41-.016-1.1-.018-2.203.005-3.303.008-.373-.149-.441-.472-.433-.74.016-1.481.003-2.334.003ZM35.893.026c.68-.097 1.206.19 1.648.637 1.656 1.672 
3.346 3.31 4.944 5.04.25.27.22.384-.024.611-.472.439-.92.905-1.355 
1.382-.215.236-.367.257-.58.005-.909-1.075-1.947-2.023-2.953-3.003-1.1-1.07-2.29-1.047-3.409.029-.985.947-1.958 1.905-2.848 
2.943-.29.34-.451.306-.73-.019-.43-.502-.914-.963-1.397-1.418-.186-.176-.252-.275-.04-.485C30.861 4.052 32.56 2.34 34.274.644c.444-.441.97-.736 1.619-.618Z"
			/>
			<path
				d="M21.73 14.092c-.005-.497.239-.958.582-1.378 1.278-1.559 2.81-2.872 4.201-4.321.341-.356.705-.69 1.04-1.05.176-.19.274-.168.442.017.485.53.981 1.05 1.499 
1.547.229.22.254.346-.005.562-1.018.854-1.93 1.821-2.86 2.768-1.158 1.183-1.155 2.472.009 3.64.923.926 1.849 1.852 2.783 2.768.237.232.319.385.004.653-.515.44-.986.935-1.453 
1.428-.175.183-.272.253-.483.036-1.657-1.694-3.338-3.367-4.986-5.071-.402-.415-.783-.886-.772-1.599ZM50.017 14.099a2.417 2.417 0 0 1-.713 1.578c-1.662 1.663-3.33 3.32-4.983 
4.992-.256.26-.397.301-.66-.002-.435-.498-.92-.955-1.405-1.406-.226-.208-.193-.335.008-.531.975-.955 1.94-1.921 2.901-2.888 1.082-1.086 1.088-2.482.004-3.579a167.984 167.984 0 
0 0-2.833-2.797c-.277-.267-.306-.428.017-.7.503-.425.962-.903 1.412-1.386.2-.214.306-.25.528-.02a831.363 831.363 0 0 0 4.867 4.947c.484.486.817 1.04.857 1.792ZM10.593 
52.913c.352-.498.596-.903.902-1.254.273-.313.682-.14 1.028-.153.658-.024 
1.001-.595.71-1.207-.09-.186-.247-.155-.39-.156-.873-.006-1.745.003-2.618-.008-.292-.004-.404.106-.4.4.01 1.082.015 2.164-.002 3.245-.005.386.155.475.511.472 1.462-.016 
2.923.006 4.384-.015.425-.007.606.082.586.556-.047 
1.106.132.942-.926.95-1.65.012-3.302-.008-4.953.01-.386.005-.605-.145-.843-.44-.554-.685-.777-1.42-.726-2.306.062-1.116.019-2.238.002-3.358-.005-.299.102-.478.338-.66.62-.48 
1.275-.751 2.088-.707 1.04.057 2.086.013 3.13.027.978.014 1.896.94 1.896 1.888 0 .76-.01 1.52.003 2.277.005.298-.064.447-.408.444-1.385-.014-2.77-.005-4.312-.005ZM54.713 
50.044c.397-.492.803-.985 1.196-1.486.145-.186.323-.233.547-.232 1.385.006 2.77.016 4.155-.006.343-.005.497.225.716.394.684.528 1.024 1.155.949 2.082-.097 1.186-.023 
2.388-.025 3.583-.002.59-.686 1.338-1.27 1.343a368.16 368.16 0 0 
1-4.896-.001c-.47-.002-1.181-.708-1.193-1.178-.013-.588.002-1.176-.004-1.765-.002-.181.024-.34.18-.451.563-.404 1.07-.874 1.875-.77.672.087 1.365.04 
2.047.01.39-.019.47.126.468.487-.003 1.209.01 1.209-1.207 1.209-.36 0-.722.016-1.082-.005-.342-.02-.558-.002-.54.457.018.398.076.573.53.554a39.62 39.62 0 0 1 
2.732-.002c.38.01.48-.12.473-.484a62.359 62.359 0 0 1 .002-3.131c.013-.425-.107-.55-.536-.543-1.613.025-3.226.012-4.84.008-.086.001-.188.045-.277-.073ZM35.734 
51.981c0-1.064.013-2.126-.007-3.19-.006-.352.06-.51.46-.504 1.804.024 3.607.026 5.41.02.623-.003.954.5 1.428.751.535.284.689.697.665 1.298-.05 1.289-.03 2.582-.005 
3.872.007.381-.115.598-.458.744-.4.17-.796.363-1.172.582-.394.23-.433.048-.43-.295.008-1.519-.011-3.038.016-4.557.008-.457-.136-.594-.584-.576-.777.033-1.558.042-2.333-.002-.53-.03-.63.175-.62.654.028 
1.481-.002 2.962.019 4.443.006.397-.112.543-.513.517-.454-.03-.912-.03-1.366 0-.395.025-.53-.105-.52-.51.025-1.082.01-2.164.01-3.247-.002 0 0 0 0 0ZM17.15 
50.141c.542-.606.974-1.082 1.398-1.564.168-.19.37-.231.611-.23 1.253.007 2.506.009 3.76-.001.265-.002.454.085.657.256.718.61 1.112 1.297 1.02 2.308-.103 1.13-.024 2.276-.026 
3.416 0 .722-.6 1.407-1.312 1.416a212.68 212.68 0 0 1-4.898 0c-.479-.005-1.068-.647-1.078-1.136-.01-.494-.007-.987-.002-1.48.01-.905.623-1.547 1.534-1.565.854-.017 1.708.017 
2.563.008.293-.004.391.096.407.39.064 1.184.073 1.184-1.092 1.183-.285 0-.575.034-.853-.008-.566-.085-.674.188-.645.675.018.311.107.439.432.433a68.648 68.648 0 0 1 
2.563.003c.336.007.476-.096.47-.452a92.87 92.87 0 0 1 0-3.189c.009-.399-.176-.47-.518-.467-1.405.011-2.81.004-4.215.004h-.777ZM71.734 53.776v4.84c0 .62-.688 1.372-1.3 
1.376-1.955.011-3.911 0-5.867.009-.347 0-.423-.119-.23-.39.41-.575.902-1.011 1.673-1.002 1.006.011 2.015-.044 3.017.019.612.038.696-.179.691-.723-.026-3 
.001-6-.026-9-.004-.496.144-.615.6-.569.45.046 1.049-.139 1.321.09.285.238.082.855.086 1.307.011 1.348.004 2.696.004 4.045.008-.002.02-.002.031-.002ZM.01 
48.863c0-1.878.016-3.757-.01-5.635-.007-.501.19-.54.58-.417.65.207 1.04.774 1.043 1.59.009 2.277.003 4.554.006 6.83.002.816.013 1.632.012 2.447 0 .453.203.638.666.627 
1.005-.022 2.01.008 3.016-.013.379-.008.543.08.528.498-.04 1.147.131.942-.972.949-1.195.008-2.391.004-3.586.003-.966 
0-1.283-.318-1.285-1.303-.003-1.86-.001-3.72-.001-5.579l.003.003ZM34.293 50.128h-2.385c-.911 0-1.823.019-2.734-.008-.436-.012-.596.112-.587.574.027 1.499-.004 3 .018 
4.5.006.407-.079.607-.529.547-.519-.07-1.192.233-1.527-.128-.3-.325-.082-.98-.087-1.489-.015-1.29-.014-2.582-.015-3.873 0-.83 1.065-1.908 1.889-1.91 1.272-.004 2.545.009 
3.816-.01.373-.006.652.09.892.381.371.45.77.876 1.249 1.416Z"
			/>
			<path
				d="M66.21 51.275c0 .853.018 1.707-.009 2.559-.011.363.113.451.454.438.643-.026 1.29.007 1.933-.013.305-.009.427.057.433.4.017 1.078.034 1.079-1.064 
1.078-.777-.002-1.554-.008-2.331-.007-.251 0-.472-.048-.702-.172-.53-.288-.754-.65-.734-1.303.058-1.818.024-3.639.002-5.458-.004-.367.084-.512.467-.472.504.052 1.179-.207 
1.47.105.25.267.072.936.078 1.426.007.472.002.946.002 1.42Z"
			/>
		</g>
		<defs>
			<clipPath id="a">
				<path fill="#fff" d="M0 0h71.763v60H0z" />
			</clipPath>
		</defs>
	</svg>
);
export default LearnBay;
