import React, { useEffect, useState, useMemo } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useForm, Controller } from 'react-hook-form';
import { getApi, postApi } from '../../services/api';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import TextField from '@material-ui/core/TextField';

const NewSignOff = function () {
	const { register, handleSubmit, reset, control } = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const [date, setDate] = useState(new Date());
	useEffect(() => {
		console.log('fetch saved company info');
		const getCompany = async function () {
			const resp = await getApi('/users/company');
			if (resp.type === 'success') {
				const { currencyCode, assetTag, depreciationValue, depreciationDuration } = resp.company;
				reset({
					currencyCode,
					assetTag,
					depreciationValue,
					depreciationDuration,
				});
			}
		};
		getCompany();
	}, [reset]);
	const onSubmit = async (data) => {
		console.log(data);
		const resp = await postApi(data, '/users/update-companyInfo');
		console.log(resp);
		if (resp.type === 'success') {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: 'Successfully Updated Company Info',
					timeout: 3000,
				})
			);
		} else {
			dispatch(
				showSnack('COMPANY_INFO_UPDATE_FAILED', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	};

	return (
		<div className="newsignoff-container">
			<HeaderComponent />
			<div className="account-page-title">
				<div className="account-page-title-container">Exit Sign-Off</div>
			</div>
			<div className="sign-off-note">New Sign - Off</div>
			<form className="master-setup" onSubmit={handleSubmit(onSubmit)}>
				<div className="tag-and-currency">
					<div className="currency">
						<div className="master-heading">Name </div>
						<input {...register('workPhone')} type="text" placeholder="Puneeth" />
					</div>
					<div className="currency">
						<div className="master-heading">Employee ID</div>
						<input {...register('workPhone')} type="text" placeholder="ERW-003" />
					</div>
				</div>
				<div className="deprecValue-and-value">
					<div className="asset-tag">
						<div className="master-heading">Department</div>
						<input {...register('domain')} type="text" placeholder="Design" />
					</div>
					<div className="currency">
						<div className="master-heading">Reporting Manager</div>
						<div className="duration-months">
							<input {...register('companyName')} type="text" placeholder="Self" />
						</div>
					</div>
				</div>
				<div className="office-details">
					<div className="asset-tag">
						<div className="master-heading">Date of Joining</div>
						<input {...register('headOfficeLocation')} type="date" />
					</div>
					<div className="currency">
						<div className="master-heading">Date of Resignation</div>
						<input {...register('officeBranch')} type="text" />
					</div>
				</div>

				{/* <div className="office-headquarter">
				<div className="office-headquarter-text">Office Headquarter</div>
				<div className="upload-img">
					<div className="upload-ways">
						<button>Upload Logo</button>
						<p>Or just drag and drop</p>
					</div>
					<p>(Height 30px, proportional width) </p>
				</div>
			</div> */}

				<button
					className="save-next"
					onClick={() => {
						navigate('/department-sign-off');
					}}
				>
					Save and Next
				</button>
			</form>
			<FooterDashboard />
		</div>
	);
};

export default NewSignOff;
