const currencyList = [
	{ label: 'AFN', value: 'AFN' },

	{ label: 'EUR', value: 'EUR' },
	{ label: 'ALL', value: 'ALL' },
	{ label: 'DZD', value: 'DZD' },
	{ label: 'USD', value: 'USD' },
	{ label: 'AOA', value: 'AOA' },
	{ label: 'XCD', value: 'XCD' },
	{ label: 'ARS', value: 'ARS' },
	{ label: 'AMD', value: 'AMD' },
	{ label: 'AWG', value: 'AWG' },
	{ label: 'AUD', value: 'AUD' },
	{ label: 'AZN', value: 'AZN' },
	{ label: 'BSD', value: 'BSD' },
	{ label: 'BHD', value: 'BHD' },
	{ label: 'BDT', value: 'BDT' },
	{ label: 'BBD', value: 'BBD' },
	{ label: 'BYN', value: 'BYN' },
	{ label: 'BZD', value: 'BZD' },
	{ label: 'XOF', value: 'XOF' },
	{ label: 'BMD', value: 'BMD' },
	{ label: 'INR', value: 'INR' },
	{ label: 'BTN', value: 'BTN' },
	{ label: 'BOB', value: 'BOB' },
	{ label: 'BOV', value: 'BOV' },
	{ label: 'BAM', value: 'BAM' },
	{ label: 'BWP', value: 'BWP' },
	{ label: 'NOK', value: 'NOK' },
	{ label: 'BRL', value: 'BRL' },
	{ label: 'BND', value: 'BND' },
	{ label: 'BGN', value: 'BGN' },
	{ label: 'BIF', value: 'BIF' },
	{ label: 'CVE', value: 'CVE' },
	{ label: 'KHR', value: 'KHR' },
	{ label: 'XAF', value: 'XAF' },
	{ label: 'CAD', value: 'CAD' },
	{ label: 'KYD', value: 'KYD' },
	{ label: 'CLP', value: 'CLP' },
	{ label: 'CLF', value: 'CLF' },
	{ label: 'CNY', value: 'CNY' },
	{ label: 'COP', value: 'COP' },
	{ label: 'COU', value: 'COU' },
	{ label: 'KMF', value: 'KMF' },
	{ label: 'CDF', value: 'CDF' },
	{ label: 'NZD', value: 'NZD' },
	{ label: 'CRC', value: 'CRC' },
	{ label: 'HRK', value: 'HRK' },
	{ label: 'CUP', value: 'CUP' },
	{ label: 'CUC', value: 'CUC' },
	{ label: 'ANG', value: 'ANG' },
	{ label: 'CZK', value: 'CZK' },
	{ label: 'DKK', value: 'DKK' },
	{ label: 'DJF', value: 'DJF' },
	{ label: 'DOP', value: 'DOP' },
	{ label: 'EGP', value: 'EGP' },
	{ label: 'SVC', value: 'SVC' },
	{ label: 'ERN', value: 'ERN' },
	{ label: 'SZL', value: 'SZL' },
	{ label: 'ETB', value: 'ETB' },
	{ label: 'FKP', value: 'FKP' },
	{ label: 'FJD', value: 'FJD' },
	{ label: 'XPF', value: 'XPF' },
	{ label: 'GMD', value: 'GMD' },
	{ label: 'GEL', value: 'GEL' },
	{ label: 'GHS', value: 'GHS' },
	{ label: 'GIP', value: 'GIP' },
	{ label: 'GTQ', value: 'GTQ' },
	{ label: 'GBP', value: 'GBP' },
	{ label: 'GNF', value: 'GNF' },
	{ label: 'GYD', value: 'GYD' },
	{ label: 'HTG', value: 'HTG' },
	{ label: 'HNL', value: 'HNL' },
	{ label: 'HKD', value: 'HKD' },
	{ label: 'HUF', value: 'HUF' },
	{ label: 'ISK', value: 'ISK' },
	{ label: 'IDR', value: 'IDR' },
	{ label: 'XDR', value: 'XDR' },
	{ label: 'IRR', value: 'IRR' },
	{ label: 'IQD', value: 'IQD' },
	{ label: 'ILS', value: 'ILS' },
	{ label: 'JMD', value: 'JMD' },
	{ label: 'JPY', value: 'JPY' },
	{ label: 'JOD', value: 'JOD' },
	{ label: 'KZT', value: 'KZT' },
	{ label: 'KES', value: 'KES' },
	{ label: 'KPW', value: 'KPW' },
	{ label: 'KRW', value: 'KRW' },
	{ label: 'KWD', value: 'KWD' },
	{ label: 'KGS', value: 'KGS' },
	{ label: 'LAK', value: 'LAK' },
	{ label: 'LBP', value: 'LBP' },
	{ label: 'LSL', value: 'LSL' },
	{ label: 'ZAR', value: 'ZAR' },
	{ label: 'LRD', value: 'LRD' },
	{ label: 'LYD', value: 'LYD' },
	{ label: 'CHF', value: 'CHF' },
	{ label: 'MOP', value: 'MOP' },
	{ label: 'MKD', value: 'MKD' },
	{ label: 'MGA', value: 'MGA' },
	{ label: 'MWK', value: 'MWK' },
	{ label: 'MYR', value: 'MYR' },
	{ label: 'MVR', value: 'MVR' },
	{ label: 'MRU', value: 'MRU' },
	{ label: 'MUR', value: 'MUR' },
	{ label: 'XUA', value: 'XUA' },
	{ label: 'MXN', value: 'MXN' },
	{ label: 'MXV', value: 'MXV' },
	{ label: 'MDL', value: 'MDL' },
	{ label: 'MNT', value: 'MNT' },
	{ label: 'MAD', value: 'MAD' },
	{ label: 'MZN', value: 'MZN' },
	{ label: 'MMK', value: 'MMK' },
	{ label: 'NAD', value: 'NAD' },
	{ label: 'NPR', value: 'NPR' },
	{ label: 'NIO', value: 'NIO' },
	{ label: 'NGN', value: 'NGN' },
	{ label: 'OMR', value: 'OMR' },
	{ label: 'PKR', value: 'PKR' },
	{ label: 'PAB', value: 'PAB' },
	{ label: 'PGK', value: 'PGK' },
	{ label: 'PYG', value: 'PYG' },
	{ label: 'PEN', value: 'PEN' },
	{ label: 'PHP', value: 'PHP' },
	{ label: 'PLN', value: 'PLN' },
	{ label: 'QAR', value: 'QAR' },
	{ label: 'RON', value: 'RON' },
	{ label: 'RUB', value: 'RUB' },
	{ label: 'RWF', value: 'RWF' },
	{ label: 'SHP', value: 'SHP' },
	{ label: 'WST', value: 'WST' },
	{ label: 'STN', value: 'STN' },
	{ label: 'SAR', value: 'SAR' },
	{ label: 'RSD', value: 'RSD' },
	{ label: 'SCR', value: 'SCR' },
	{ label: 'SLL', value: 'SLL' },
	{ label: 'SGD', value: 'SGD' },
	{ label: 'XSU', value: 'XSU' },
	{ label: 'SBD', value: 'SBD' },
	{ label: 'SOS', value: 'SOS' },
	{ label: 'SSP', value: 'SSP' },
	{ label: 'LKR', value: 'LKR' },
	{ label: 'SDG', value: 'SDG' },
	{ label: 'SRD', value: 'SRD' },
	{ label: 'SEK', value: 'SEK' },
	{ label: 'CHE', value: 'CHE' },
	{ label: 'CHW', value: 'CHW' },
	{ label: 'SYP', value: 'SYP' },
	{ label: 'TWD', value: 'TWD' },
	{ label: 'TJS', value: 'TJS' },
	{ label: 'TZS', value: 'TZS' },
	{ label: 'THB', value: 'THB' },
	{ label: 'TOP', value: 'TOP' },
	{ label: 'TTD', value: 'TTD' },
	{ label: 'TND', value: 'TND' },
	{ label: 'TRY', value: 'TRY' },
	{ label: 'TMT', value: 'TMT' },
	{ label: 'UGX', value: 'UGX' },
	{ label: 'UAH', value: 'UAH' },
	{ label: 'AED', value: 'AED' },
	{ label: 'USN', value: 'USN' },
	{ label: 'UYU', value: 'UYU' },
	{ label: 'UYI', value: 'UYI' },
	{ label: 'UYW', value: 'UYW' },
	{ label: 'UZS', value: 'UZS' },
	{ label: 'VUV', value: 'VUV' },
	{ label: 'VES', value: 'VES' },
	{ label: 'VND', value: 'VND' },
	{ label: 'YER', value: 'YER' },
	{ label: 'ZMW', value: 'ZMW' },
	{ label: 'ZWL', value: 'ZWL' },
	{ label: 'XBA', value: 'XBA' },
	{ label: 'XBB', value: 'XBB' },
	{ label: 'XBC', value: 'XBC' },
	{ label: 'XBD', value: 'XBD' },
	{ label: 'XTS', value: 'XTS' },
	{ label: 'XXX', value: 'XXX' },
	{ label: 'XAU', value: 'XAU' },
	{ label: 'XPD', value: 'XPD' },
	{ label: 'XPT', value: 'XPT' },
	{ label: 'XAG', value: 'XAG' },
	{ label: 'AFA', value: 'AFA' },
	{ label: 'FIM', value: 'FIM' },
	{ label: 'ALK', value: 'ALK' },
	{ label: 'ADP', value: 'ADP' },
	{ label: 'ESP', value: 'ESP' },
	{ label: 'FRF', value: 'FRF' },
	{ label: 'AOK', value: 'AOK' },
	{ label: 'AON', value: 'AON' },
	{ label: 'AOR', value: 'AOR' },
	{ label: 'ARA', value: 'ARA' },
	{ label: 'ARP', value: 'ARP' },
	{ label: 'ARY', value: 'ARY' },
	{ label: 'RUR', value: 'RUR' },
	{ label: 'ATS', value: 'ATS' },
	{ label: 'AYM', value: 'AYM' },
	{ label: 'AZM', value: 'AZM' },
	{ label: 'BYB', value: 'BYB' },
	{ label: 'BYR', value: 'BYR' },
	{ label: 'BEC', value: 'BEC' },
	{ label: 'BEF', value: 'BEF' },
	{ label: 'BEL', value: 'BEL' },
	{ label: 'BOP', value: 'BOP' },
	{ label: 'BAD', value: 'BAD' },
	{ label: 'BRB', value: 'BRB' },
	{ label: 'BRC', value: 'BRC' },
	{ label: 'BRE', value: 'BRE' },
	{ label: 'BRN', value: 'BRN' },
	{ label: 'BRR', value: 'BRR' },
	{ label: 'BGJ', value: 'BGJ' },
	{ label: 'BGK', value: 'BGK' },
	{ label: 'BGL', value: 'BGL' },
	{ label: 'BUK', value: 'BUK' },
	{ label: 'HRD', value: 'HRD' },
	{ label: 'CYP', value: 'CYP' },
	{ label: 'CSJ', value: 'CSJ' },
	{ label: 'CSK', value: 'CSK' },
	{ label: 'ECS', value: 'ECS' },
	{ label: 'ECV', value: 'ECV' },
	{ label: 'GQE', value: 'GQE' },
	{ label: 'EEK', value: 'EEK' },
	{ label: 'XEU', value: 'XEU' },
	{ label: 'GEK', value: 'GEK' },
	{ label: 'DDM', value: 'DDM' },
	{ label: 'DEM', value: 'DEM' },
	{ label: 'GHC', value: 'GHC' },
	{ label: 'GHP', value: 'GHP' },
	{ label: 'GRD', value: 'GRD' },
	{ label: 'GNE', value: 'GNE' },
	{ label: 'GNS', value: 'GNS' },
	{ label: 'GWE', value: 'GWE' },
	{ label: 'GWP', value: 'GWP' },
	{ label: 'ITL', value: 'ITL' },
	{ label: 'ISJ', value: 'ISJ' },
	{ label: 'IEP', value: 'IEP' },
	{ label: 'ILP', value: 'ILP' },
	{ label: 'ILR', value: 'ILR' },
	{ label: 'LAJ', value: 'LAJ' },
	{ label: 'LVL', value: 'LVL' },
	{ label: 'LVR', value: 'LVR' },
	{ label: 'LSM', value: 'LSM' },
	{ label: 'ZAL', value: 'ZAL' },
	{ label: 'LTL', value: 'LTL' },
	{ label: 'LTT', value: 'LTT' },
	{ label: 'LUC', value: 'LUC' },
	{ label: 'LUF', value: 'LUF' },
	{ label: 'LUL', value: 'LUL' },
	{ label: 'MGF', value: 'MGF' },
	{ label: 'MVQ', value: 'MVQ' },
	{ label: 'MLF', value: 'MLF' },
	{ label: 'MTL', value: 'MTL' },
	{ label: 'MTP', value: 'MTP' },
	{ label: 'MRO', value: 'MRO' },
	{ label: 'MXP', value: 'MXP' },
	{ label: 'MZE', value: 'MZE' },
	{ label: 'MZM', value: 'MZM' },
	{ label: 'NLG', value: 'NLG' },
	{ label: 'NIC', value: 'NIC' },
	{ label: 'PEH', value: 'PEH' },
	{ label: 'PEI', value: 'PEI' },
	{ label: 'PES', value: 'PES' },
	{ label: 'PLZ', value: 'PLZ' },
	{ label: 'PTE', value: 'PTE' },
	{ label: 'ROK', value: 'ROK' },
	{ label: 'ROL', value: 'ROL' },
	{ label: 'STD', value: 'STD' },
	{ label: 'CSD', value: 'CSD' },
	{ label: 'SKK', value: 'SKK' },
	{ label: 'SIT', value: 'SIT' },
	{ label: 'RHD', value: 'RHD' },
	{ label: 'ESA', value: 'ESA' },
	{ label: 'ESB', value: 'ESB' },
	{ label: 'SDD', value: 'SDD' },
	{ label: 'SDP', value: 'SDP' },
	{ label: 'SRG', value: 'SRG' },
	{ label: 'CHC', value: 'CHC' },
	{ label: 'TJR', value: 'TJR' },
	{ label: 'TPE', value: 'TPE' },
	{ label: 'TRL', value: 'TRL' },
	{ label: 'TMM', value: 'TMM' },
	{ label: 'UGS', value: 'UGS' },
	{ label: 'UGW', value: 'UGW' },
	{ label: 'UAK', value: 'UAK' },
	{ label: 'SUR', value: 'SUR' },
	{ label: 'USS', value: 'USS' },
	{ label: 'UYN', value: 'UYN' },
	{ label: 'UYP', value: 'UYP' },
	{ label: 'VEB', value: 'VEB' },
	{ label: 'VEF', value: 'VEF' },
	{ label: 'VNC', value: 'VNC' },
	{ label: 'YDD', value: 'YDD' },
	{ label: 'YUD', value: 'YUD' },
	{ label: 'YUM', value: 'YUM' },
	{ label: 'YUN', value: 'YUN' },
	{ label: 'ZRN', value: 'ZRN' },
	{ label: 'ZRZ', value: 'ZRZ' },
	{ label: 'ZMK', value: 'ZMK' },
	{ label: 'ZWC', value: 'ZWC' },
	{ label: 'ZWD', value: 'ZWD' },
	{ label: 'ZWN', value: 'ZWN' },
	{ label: 'ZWR', value: 'ZWR' },
	{ label: 'XFO', value: 'XFO' },
	{ label: 'XRE', value: 'XRE' },
	{ label: 'XFU', value: 'XFU' },
];

export default currencyList;
