import React from 'react';
import RunningDiagnostics from '../../components/Diagnostics/RunningDiagnostics';
import DashboardFooter from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';

const RunningDiagnosticsScreen = function () {
	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<RunningDiagnostics />
			<DashboardFooter />
		</div>
	);
};

export default RunningDiagnosticsScreen;
