import * as React from 'react';
const VahanImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={382} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M68.966 0c1.988.624 4 1.193 5.843 2.2.559.305 1.427.482 1.326 1.294-.094.756-.934.884-1.555 1.061-1.09.306-2.196.552-3.306.764-3.116.6-4.584 2.314-4.63 5.513-.117 
7.953-.082 15.902.285 23.852.118 2.522.438 5.041.288 7.574-.392 6.756-3.834 11.534-9.712 14.674-3.261 1.742-6.762 2.585-10.478 
2.571-7.284-.027-14.567.014-21.846-.014-6.211-.024-12.422-.083-18.633-.166-1.603-.02-3.192-.23-4.67-.94C.7 57.817-.046 56.977.003 55.593c.045-1.36.971-1.988 2.071-2.405 
1.999-.756 4.112-.822 6.215-.84 6.88-.065 13.744.51 20.624.43.364-.003.732 0 1.37 0a53.945 53.945 0 0 
1-5.135-3.722c-4.635-3.779-9.035-7.804-12.807-12.46-3.747-4.626-5.784-9.844-5.34-15.864.35-4.768 2.735-8.467 6.315-11.485 1.718-1.447 3.64-2.582 5.628-3.612.5-.26.833-.275 
1.305.16 9.934 9.139 20.042 18.09 30.118 27.074 1.988 1.773 3.987 3.543 6.024 5.26 1.134.958 1.627.76 1.936-.676.51-2.35.614-4.747.773-7.134.5-7.367.892-14.74 
1.652-22.089.25-2.432.899-4.695 2.714-6.489.88-.87 1.957-1.35 3.091-1.745C67.363 0 68.165 0 68.966 0ZM222.325 0c.229.396.656.177.982.298 1.645.625 2.523 1.954 2.526 4.012.007 
6.991 0 13.98.007 20.971 0 .344-.111.711.066.986 4.816-5.688 10.93-7.02 17.842-5.094 5.59 1.558 8.473 5.676 9.642 11.148.313 1.468.438 2.96.438 4.459.003 6.29.007 12.582-.004 
18.872-.003 1.94-1.065 3.411-2.717 3.855-2.349.632-4.583-.95-4.743-3.383-.049-.763-.024-1.53-.024-2.297 
0-5.492.01-10.985-.004-16.478-.01-4.545-2.415-8.16-6.19-9.282-2.526-.75-5.028-.461-7.46.424-4.372 1.589-6.842 5.204-6.853 9.982-.01 5.66-.007 11.319 0 16.978.004 1.29-.309 
2.443-1.343 3.293-1.117.923-2.397 1.152-3.751.645-1.446-.541-2.203-1.662-2.394-3.168-.055-.426-.034-.864-.034-1.297 0-16.544.031-33.089-.035-49.633-.007-2.55.628-4.455 
3.248-5.291h.801ZM381.697 56.936c-.604 1.162-1.336 2.196-2.689 2.571-2.453.68-4.75-1.065-4.757-3.668-.017-6.023-.004-12.047-.007-18.074 
0-1.235-.128-2.46-.423-3.657-1.19-4.847-5.087-7.235-10.195-6.266-5.506 1.04-8.535 4.854-8.553 10.818-.017 5.757.007 11.517-.01 17.277-.007 1.967-1.43 3.494-3.366 
3.688-1.873.187-3.553-1.013-3.99-2.89-.083-.35-.087-.726-.087-1.09-.003-10.284-.003-20.569-.003-30.854 0-2.331 1.526-3.993 3.664-4.014 2.224-.02 3.775 1.613 3.785 
3.987.004.548 0 1.1 0 1.96 1.413-2.325 3.161-3.869 5.354-4.861 4.508-2.037 9.084-2.082 13.56-.031 4.07 1.863 6.104 5.368 7.124 9.57.222.916.177 1.887.59 2.761.003 7.589.003 
15.18.003 22.773ZM182.728 53.574c-2.669 3.713-6.152 5.59-10.292 6.187-10.687 1.544-19.525-4.573-21.811-14.296-1.579-6.71-.562-13.04 3.955-18.5 6.052-7.319 18.196-8.824 
25.424-3.22.975.756 1.783 1.668 2.724 2.64 0-.503-.004-1.006 0-1.51.02-2.425 1.516-4.09 3.688-4.1 2.231-.015 3.799 1.672 3.799 4.094V55.62c0 2.36-1.561 4.012-3.771 4.012-2.148 
0-3.671-1.628-3.713-3.973-.01-.594-.003-1.19-.003-2.086Zm-.098-13.431c0-7.18-5.364-12.474-12.637-12.47-7.12.003-12.345 5.218-12.387 12.369-.042 7.162 5.34 12.696 12.346 12.7 
7.46 0 12.678-5.184 12.678-12.6ZM312.099 26.678c0-.92-.02-1.603.004-2.283.073-2.078 1.704-3.643 3.751-3.619 2.047.024 3.692 1.634 3.692 3.685.01 10.482.01 20.968 0 31.45-.004 
2.117-1.645 3.727-3.744 3.724-2.048 0-3.647-1.576-3.703-3.665-.017-.697-.003-1.394-.003-2.088-.361-.146-.396.194-.51.34-2.96 3.758-6.936 5.444-11.624 
5.714-3.883.226-7.575-.413-10.972-2.383-5.732-3.321-8.799-8.383-9.507-14.858-.593-5.434.469-10.524 3.831-14.983 5.864-7.776 18.119-9.76 25.742-4.188 1.135.826 2.041 1.863 
3.043 3.154Zm-.135 13.487c0-7.206-5.326-12.487-12.602-12.494-7.169-.007-12.412 5.263-12.388 12.45.025 7.175 5.334 12.616 12.308 12.619 7.477 0 12.682-5.16 
12.682-12.575ZM110.996 50.176c3.373-8.62 6.739-17.21 10.101-25.802.194-.496.371-.999.614-1.47.989-1.913 2.998-2.634 4.906-1.788 1.721.764 2.474 2.814 1.676 4.757-1.988 
4.851-4.007 9.692-6.016 14.535-2.2 5.302-4.39 10.611-6.61 15.906-1.038 2.47-2.634 3.466-5.216 
3.317-2.428-.142-3.723-1.693-4.583-3.747-3.151-7.533-6.291-15.066-9.438-22.6-1-2.39-2.006-4.774-2.991-7.168-.881-2.14-.125-4.288 1.745-5.038 2.089-.836 4.14.059 5.031 2.276 
1.635 4.063 3.224 8.144 4.83 12.22 1.836 4.66 3.671 9.324 5.514 13.984.08.202.087.469.437.618Z"
		/>
	</svg>
);
export default VahanImg;
