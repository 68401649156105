import React, { useEffect, useState, useMemo } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useForm, Controller } from 'react-hook-form';
import { getApi, postApi } from '../../services/api';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';

const departments = [
	{ value: 'hr', label: 'Human Resource' },
	{ value: 'finance', label: 'Finance' },
	{ value: 'it', label: 'IT Department' },
	{ value: 'repoting', label: 'Reporting Manager' },
];

const DepartmentSignOff = function () {
	const { register, handleSubmit, reset, control } = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [emails, setEmail] = useState();
	const [department, setDepartment] = useState('IT');
	return (
		<div className="newsignoff-container">
			<HeaderComponent />
			<div className="account-page-title">
				<div className="account-page-title-container">Exit Sign-Off</div>
			</div>
			<div className="sign-off-note">New Sign - Off</div>
			<div className="dept-division">
				<ReactMultiEmail
					className="input"
					placeholder="To"
					emails={emails}
					getLabel={(email, index) => {
						return (
							<div data-tag key={index}>
								{email}
								<span data-tag-handle>×</span>
							</div>
						);
					}}
				/>
				<div className="company-dept">
					<div>Department</div>
					<Select
						value={department}
						placeholder={'Department'}
						options={departments}
						width="20vw"
						onChange={(value) => setDepartment(value)}
						styles={{
							control: (_, { selectProps: { width } }) => ({
								width: width,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								border: '1px solid #808080',
								borderRadius: '5px',
							}),
							menu: (provided, state) => ({
								...provided,
								width: state.selectProps.width,
							}),
						}}
					/>
				</div>
				<div className="exit-checklist">
					<input type="checkbox" className="round-checkbox" id="c1" />
					<label for="c1">Notice period served?</label>
					<br />
					<input type="checkbox" className="round-checkbox" id="c2" />
					<label for="c2">ID card submitted?</label>
					<br />
					<input type="checkbox" className="round-checkbox" id="c3" />
					<label for="c3">Insurance credentials?</label>
					<br />
					<input type="checkbox" className="round-checkbox" id="c4" />
					<label for="c4">Deactivate lattice access</label>
					<br />
					<input type="checkbox" className="round-checkbox" id="c5" />
					<label for="c5">Deactivate work email</label>
					<br />
					<input type="checkbox" className="round-checkbox" id="c6" />
					<label for="c6">Whatsapp groups</label>
					<br />
					<input type="checkbox" className="round-checkbox" id="c7" />
					<label for="c7">Remove from trackstar</label>
					<br />
				</div>
			</div>
			<button
				className="save-next"
				onClick={() => {
					navigate('/sign-off-suceess');
				}}
			>
				Next
			</button>
			<FooterDashboard />
		</div>
	);
};

export default DepartmentSignOff;
