import * as React from 'react';

const DashboardSvg = (props) => (
	<svg width={33} height={29} fill={props.fill} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M16 0c3.79.08 7.18 1.26 10.11 3.66 2.97 2.43 4.87 5.56 5.58 9.33.87 4.56-.07 8.77-2.76 12.56a14.93 14.93 0 0 1-3.07 3.18c-.5.38-1.15.27-1.57-.25-.28-.35-.57-.71-.88-1.03-1.33-1.37-2.91-2.31-4.76-2.8-1.62-.43-3.26-.46-4.9-.09-2.39.55-4.34 1.8-5.87 3.71-.16.2-.33.42-.55.54-.44.25-.88.17-1.28-.16a15.79 15.79 0 0 1-2.74-2.8C1.67 23.7.62 21.29.22 18.61c-.54-3.5-.01-6.84 1.62-9.99.97-1.88 2.28-3.49 3.9-4.85C7.98 1.9 10.54.7 13.42.25 14.26.14 15.13.09 16 0Zm9.26 26.41c4.15-3.6 5.92-9.97 3.54-15.64-2.44-5.79-8.2-9.18-14.4-8.48-6.18.69-11.21 5.54-12.12 11.79-.43 2.97.05 5.79 1.42 8.45a13.15 13.15 0 0 0 3.03 3.89c5.02-5.64 13.57-5.69 18.53-.01Z"
			fill={props.fill}
		/>
		<path
			d="M19.72 13.93c.16.47.36.93.46 1.41.37 1.64-.44 3.48-1.89 4.39-2.4 1.5-5.47.4-6.35-2.29-.79-2.42.65-4.96 3.21-5.51.95-.2 1.88-.07 2.75.39.18.1.28.09.43-.06 1.44-1.46 2.89-2.91 4.34-4.37.35-.35.75-.53 1.25-.35.69.25.93 1.1.47 1.67-.07.08-.14.15-.21.23l-4.34 4.34c-.06.06-.13.1-.19.16.02-.01.04-.01.07-.01ZM16 18.27c1.18 0 2.13-.96 2.13-2.15A2.16 2.16 0 0 0 16 13.98c-1.18 0-2.14.96-2.13 2.15 0 1.2.94 2.14 2.13 2.14Z"
			fill={props.fill}
		/>
	</svg>
);

export default DashboardSvg;
