import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { showSnack } from 'react-redux-snackbar';
import { ADD_NEW_QUESTION, ADD_VENDOR_DETAILS, RESET_FORM, SHOW_CUSTOM_FIELD } from '../../redux/actions';
import { postApi } from '../../services/api';
import CustomField from './CustomField';
import VendorDetails from './VendorDetails';
import CustomFieldComponent from './CustomFieldComponent';
import Modal from 'react-modal';
import { RiSendPlaneFill } from 'react-icons/ri';
import { Strings } from './config';
import axios from 'axios';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useNavigate } from 'react-router';
import HeaderComponent from '../../components/Header';
import { Autocomplete, TextField } from '@mui/material';
import { height } from '@mui/system';
import { withStyles } from '@mui/styles';

const VendorRegForm = () => {
	const vendorFormData = useSelector((state) => state.appreducer.vendorFormData);
	const [sendModal, setSendModal] = useState(false);
	const [link, setLink] = useState('');
	const [copy, setCopy] = useState(false);
	const [email, setEmail] = useState([]);
	const [emails, setEmails] = useState(['omi@gmail.com','omi98949@gmail.com','123@gmail.com']);
	const [inputValue, setInputValue] = useState([]);
	const [description, setDescription] = useState('');
	console.log(emails);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const customStyles = {
		content: {
			top: '50%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			maxWidth: '750px',
			transform: 'translate(-50%, -50%)',
			zIndex: '30',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	const sendVendorForm = async () => {
		vendorFormData?.sections.forEach((section) => {
			section.questions?.forEach((question) => {
				if (question?.qtype === Strings.MULTIPLE_CHOICE) {
					question?.choices?.forEach((choice) => {
						return delete choice?._id;
					});
				}
				return delete question?._id;
			});
			return delete section._id;
		});

		console.log(vendorFormData);

		try {
			const resp = await postApi(vendorFormData, "/vendorForm/create");
			// const resp = { type: 'success' ,data:"123455link"};
			if (resp.type === 'success') {
				console.log(resp);
				dispatch({ type: RESET_FORM });
				setSendModal(true);
				setLink(resp?.data);
				dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: 'Vendor Form Created Successfully',
						timeout: 7000,
					})
				);
				dispatch({ type: SHOW_CUSTOM_FIELD });
			} else {
				return dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (error) {
			return dispatch(
				showSnack('VENDOR_RESPONSE_FORM_ADDED', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};
	const sendEmail = async () => {
		console.log('link', link);
		console.log(emails);
		console.log(inputValue);
		const data = {
			registrationLink: link,
			emails,
			description,
		};
		try {
			const resp = await postApi(data, '/vendorForm/send_email');
			// const resp = { type: 'success' };
			if (resp.type === 'success') {
				console.log(resp);

				setLink(resp?.data);
				dispatch(
					showSnack('EMAIL_SENT_ADDED', {
						label: 'Email sent successfully',
						timeout: 7000,
					})
				);
				setSendModal(false);
				setLink('');
				setDescription('');
				// setEmails([]);
			} else {
				return dispatch(
					showSnack('EMAIL_SENT_ADDED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (error) {
			return dispatch(
				showSnack('EMAIL_SENT_ADDED', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};

	const CssTextField = withStyles({
		root: {
			'& label.Mui-focused': {
				color: 'cyan',
			},
			'& .MuiInput-underline:after': {
				borderBottomColor: '#888888',
			},
			'& .MuiOutlinedInput-root': {
				'& fieldset': {
					borderColor: '#eee',
				},
				'&:hover fieldset': {
					borderColor: 'red',
				},
				'&.Mui-focused fieldset': {
					borderColor: '#888888',
				},
			},
		},
	})(TextField);
	return (
		<>
			<HeaderComponent />
			<div className="vendorContainer">
				<div className="vendor-registration-container">
					<h3>Vendor Registration Form</h3>
					<button className="custom-send-btn" type="button" onClick={sendVendorForm}>
						Send
					</button>
					<VendorDetails />
					<CustomFieldComponent />

					<Modal
						isOpen={sendModal}
						onRequestClose={() => setSendModal(false)}
						style={customStyles}
						contentLabel="Send Modal"
						ariaHideApp={true}
					>
						<div className="sendModalContainer">
							<h3>Vendor Registration Form</h3>

							<div className="sendMailContainer">
								<div className="autoCompleteContainer">
									<h3>To:</h3>
									<Autocomplete
										multiple
										freeSolo
										// autoSelect
										limitTags={2}
										id="multiple-limit-tags"
										options={emails}
										getOptionLabel={(option) => option}
										onChange={(event, newValue) => {
											setInputValue(newValue);
										}}
										value={inputValue}
										// inputValue={inputValue}
										// onInputChange={(event, newInputValue) => {
										// 	setInputValue(newInputValue);
										// }}
										className="autoCompleteInput"
										renderInput={(params) => (
											<CssTextField {...params} variant="standard" placeholder="Email" />
										)}
										sx={{ width: '400px', border: 'none', outline: 'none' }}
									/>
								</div>

								<div className="sendTo">
									<div></div>
								</div>
								<div className="noteContainer">
									<textarea value={description} onChange={(e) => setDescription(e.target.value)}>
										Note
									</textarea>
									<div onClick={sendEmail} className="sendMailBtn">
										<RiSendPlaneFill style={{ fontSize: '21px', marginBottom: '5px' }} />
										<h5>Send</h5>
									</div>
								</div>
								<h5>
									<span>Or send link</span>
								</h5>
								<div className="sendLink">
									<input
										type="text"
										value={link}
										onChange={(e) => {
											// setLink(e.target.value);
											setCopy(false);
										}}
									/>
									<CopyToClipboard text={link} onCopy={() => setCopy(true)}>
										<button onClick={() => setCopy(!copy)}>Copy Link </button>
									</CopyToClipboard>
								</div>
							</div>
						</div>
					</Modal>
				</div>
			</div>
		</>
	);
};

export default VendorRegForm;
