import * as React from 'react';
const LegaEImag = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={203} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M13.182 23.226c0-7.056.054-14.111-.04-21.167-.024-1.681.537-2.056 2.114-2.03 5.505.085 11.014.065 16.52.01 1.222-.01 1.67.328 1.668 1.608a3570.95 3570.95 0 0 0 0 
30.07c.006 1.243-.369 1.729-1.635 
1.634-1.172-.087-2.361-.067-3.54-.006-1.469.077-1.955-.595-1.948-2.003.037-7.559-.01-15.121.047-22.68.01-1.219-.237-1.671-1.547-1.587-1.084.07-2.189.165-3.313-.159-.963-.28-1.267.422-1.257 
1.368.037 3.87.014 7.734.014 11.598 0 5.992.04 11.983-.03 17.975-.017 1.378.432 1.888 1.783 1.878 5.336-.03 10.676-.038 16.012.003 1.57.01 1.814-.837 
1.81-2.138-.023-11.703-.013-23.405-.013-35.108 0-2.425.003-2.425 2.37-2.425.957 0 1.93.118 2.861-.027 1.399-.22 1.696.598 1.838 1.648.054.385.01.784.01 1.175 0 13.608-.007 
27.216.007 40.823.003 2.669-.601 2.81-2.72 2.793-9.72-.064-19.44-.06-29.16.004-1.513.01-1.895-.463-1.881-1.915.068-7.116.03-14.23.03-21.342Z"
		/>
		<path
			fill="#888"
			d="M.027 30.024c0-9.352.027-18.704-.027-28.06C-.007.544.314-.155 1.861.047c.72.095 1.46.017 2.192.017 2.55 0 3.573 1.118 3.003 3.597-.244 1.06-.27 2.084-.274 3.127-.01 
14.56-.007 29.124-.007 43.684 0 2.25.108 2.354 2.405 2.354h34.565c2.965 0 3.276.334 3.171 3.337-.017.453-.169.858-.27 1.284-.18.736.135 1.854-.277 
2.215-.686.598-1.817.21-2.756.213-13.993.017-27.989-.01-41.982.044-1.367.007-1.63-.399-1.624-1.669.047-9.406.02-18.815.02-28.225ZM177.089 
29.978c0-5.154.033-10.305-.027-15.455-.014-1.105.297-1.382 1.388-1.375 7.305.047 14.611.05 21.916-.003 1.135-.01 1.493.33 1.351 1.401-.064.497-.01 1.007-.01 1.51 0 3.084 0 
3.117-3.158 3.158-3.371.04-6.745 0-10.116.017-.567.003-1.074-.183-1.611-.304-1.695-.382-2.651.419-2.651 2.094 0 1.121.098 2.25-.023 3.357-.166 1.51.52 1.932 1.898 1.919 
3.819-.041 7.643.033 11.463-.038 1.553-.03 2.229.453 2.202 2.098-.061 3.637 0 3.637-3.645 3.637-3.485 0-6.967.03-10.453-.017-1.101-.017-1.499.362-1.462 1.46a90.065 90.065 0 0 
1 0 5.88c-.037 1.12.409 1.445 1.486 1.435 4.945-.04 9.892.017 14.837-.044 1.175-.014 1.773.152 1.591 1.506-.196 1.476.621 3.469-.335 
4.323-.82.733-2.769.24-4.221.24-6.238.01-12.477-.03-18.715.03-1.327.014-1.756-.357-1.736-1.708.071-5.04.031-10.082.031-15.121ZM130.882 42.747c-3.536 2.911-7.231 3.084-11.071 
1.341-3.827-1.74-5.438-4.931-5.823-8.984-.297-3.12.125-5.981 1.75-8.714 2.975-5.012 10.649-6.035 15.134-2.141-.166-1.287.334-1.787 1.55-1.689 1.226.102 2.476.088 3.705.004 
1.219-.085 1.53.432 1.52 1.57-.041 6.046.125 12.098-.105 18.134-.071 1.877-.088 3.813-.516 5.718-.716 3.171-2.625 4.985-5.651 5.907-4.404 1.34-8.676 
1.492-12.919-.69-2.252-1.158-3.33-3.032-4.036-5.272-.172-.543-.027-.83.615-.824 1.797.02 3.594.02 5.394 0 .446-.006.713.196.973.51 1.756 2.115 4.184 2.804 6.724 1.713 
2.145-.922 2.709-2.858 2.759-4.999.007-.446-.003-.895-.003-1.584Zm-5.026-3.026c2.567.324 5.354-2.067 5.337-5.988-.017-3.584-2.115-6.461-5.458-6.451-3.205.01-5.306 2.52-5.306 
6.387 0 3.624 2.182 6.055 5.427 6.052ZM101.88 36.698c-1.911-.003-3.823.047-5.731-.02-1.159-.04-1.179.5-.885 1.324 1.672 4.65 6.454 5.532 9.74 1.8.22-.25.466-.432.855-.429 
1.743.02 3.485-.037 5.225.024 1.432.05.628 1.104.452 1.52-1.31 3.1-3.59 5.13-6.907 5.998-4.961 
1.3-9.392.348-13.053-3.243-2.09-2.046-3.053-4.711-3.195-7.663-.16-3.246.195-6.309 2.175-9.082 2.34-3.276 5.404-4.826 9.45-5.076 7.856-.483 12.111 6.204 12.348 12.652.05 
1.35-.696 1.678-1.787 1.854-2.884.476-5.789.31-8.687.341Zm-6.731-5.123c3.144.29 6.15-.196 9.139.256.389.058 
1.003-.054.966-.804-.098-2.05-3.455-4.492-5.532-4.032-2.479.543-4.026 1.979-4.573 4.58ZM163.174 46.78c-1.814 0-3.226-.104-4.611.027-1.766.17-2.556-.661-2.833-2.499-1.615 
2.135-3.881 2.959-6.208 3.043-2.074.075-4.242-.26-6.147-1.384-1.945-1.149-2.901-2.753-3.094-5.06-.418-5.036 1.608-6.96 5.951-8.217 2.503-.723 5.134-.925 7.647-1.672.888-.263 
1.55-.642 1.597-1.554.051-1.003-.483-1.715-1.415-2.212-2.283-1.212-4.59-.662-6.221 1.53-.736.99-1.462 1.317-2.597 
1.206-.777-.078-1.571-.014-2.358-.017-1.638-.007-1.891-.365-1.432-1.969.906-3.161 3.212-4.944 6.215-5.674 3.185-.773 6.403-.625 9.602.425 2.898.953 4.451 3.76 4.657 6.306.402 
5.006.328 10.018.304 15.03-.006.922.291 1.702.943 2.692Zm-7.333-10.166c-.365-.391.608-1.786-.608-1.506-2.516.581-5.133 1.06-7.393 2.405-1.699 1.01-1.472 3.53.291 4.441 3.59 
1.851 7.707-.935 7.71-5.34ZM64.107 29.841c0-5.042.017-10.081-.013-15.124-.007-.983.151-1.496 1.34-1.607.98-.095 1.96-.277 2.956-.274 2.772.003 3.546 1.05 2.884 
3.857-.088.375-.081.773-.085 1.162-.003 6.944.024 13.891-.023 20.835-.01 1.513.537 2.087 2.063 2.064 4.047-.064 8.093.013 12.139-.044 1.189-.017 1.695.257 1.699 1.574.01 
4.495.06 4.495-4.479 4.495-5.566 0-11.128-.044-16.694.03-1.446.02-1.807-.584-1.8-1.847.027-5.039.013-10.082.013-15.12ZM165.957 
29.988c0-5.032.037-10.061-.027-15.094-.017-1.29.304-1.867 1.692-1.743 1.168.105 2.357.088 3.529.004 1.213-.088 1.547.547 1.547 1.567.004 10.173 0 20.349.004 30.522 0 1.033-.49 
1.543-1.53 1.54-1.233-.003-2.473-.064-3.702.017-1.196.077-1.55-.392-1.537-1.55.054-5.09.024-10.177.024-15.263Z"
		/>
	</svg>
);
export default LegaEImag;
