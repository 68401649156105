import { postApi, uploadToS3 } from '../services/api';
import { sanitizeFileName } from './sanitizeFileName';

export const uploadAssetImgs = async (fileList, assetUid) => {
	console.log(fileList, 'fileList');
	const payload = [];

	const urlList = [];
	for (let x = 0; x < fileList.length; x += 1) {
		if (!fileList[x].alreadyUploaded) {
			const sanitizedFileName = sanitizeFileName(fileList[x].name);
			payload.push({
				filename: sanitizedFileName,
				mimetype: fileList[x].type,
				filesize: fileList[x].size,
			});
			fileList[x].sanitizedFileName = sanitizedFileName;
		} else {
			urlList.push(fileList[x].url);
		}
	}

	const resp = await postApi(
		{
			payload,
			assetId: assetUid,
			actionType: 'asset-files',
		},
		'/assetze/utils/generate-upload-url'
	);

	if (resp.type !== 'success') {
		throw new Error(resp.error);
	}
	for (let x = 0; x < resp.urls.length; x += 1) {
		const eachUrl = resp.urls[x];

		const fileFound = fileList.find((el) => el.sanitizedFileName === eachUrl.filename);

		if (fileFound) {
			await uploadToS3(eachUrl.url, fileFound.type, fileFound.originFileObj);
		}
	}

	return urlList.concat(resp.urls.map((el) => el.actualUrl));
};
