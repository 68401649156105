import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import AccountTabs from './AccountTabs';
import HeaderComponent from '../../components/Header';
// import { DatePicker } from '@mui/x-date-pickers';
import { download, postApi, uploadToS3 } from '../../services/api';
import { ApiPost } from '../../services/agentConfig';
import _ from 'lodash';
import { triggerShowSnack } from '../../utils/snack';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { parseISO, format } from 'date-fns';
import { Drawer, Input, Col, Button, Form, Space, Upload, DatePicker, Table, Popover, Select } from 'antd';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { CiSearch } from 'react-icons/ci';
import { showSnack } from 'react-redux-snackbar';
import { MdOutlineFileDownload } from 'react-icons/md';
import { sanitizeFileName } from '../../utils/sanitizeFileName';
import { CheckPermission, PURCHASE_ORDER_CREATE } from '../../utils/Permissions';
const { Dragger } = Upload;

export const CreatePO = () => {
	const [form] = Form.useForm();
	const [formData, setFormData] = useState({});
	const [fileInfo, setInvoiceDoc] = useState(null);
	const [openDrawer, setOpenDrawer] = useState(false);
	const [PoData, setPoData] = useState([]);
	const [total, setTotal] = useState(1);
	const dispatch = useDispatch();
	const onDrop = useCallback((acceptedFiles) => {
		setInvoiceDoc(acceptedFiles[0]);
	}, []);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	useEffect(() => {
		getPoData();
	}, []);
	const getPoData = async (page, limit) => {
		try {
			const resp = await postApi({ page, limit }, '/assetze/purchase-order/get');
			if (resp.type === 'success') {
				setPoData(resp.purchaseOrderList);
				setTotal(resp.total);
			} else {
				throw new Error(resp.error);
			}
		} catch (e) {
			dispatch(
				showSnack('ERROR_MESSAGE', {
					label: e.message,
					timeout: 7000,
				})
			);
		}
	};

	const onSave = async (data) => {
		try {
			// if (!fileInfo) {
			// 	throw new Error('Please Add Invoice Proof Of Attachement');
			// }
			if (fileInfo) {
				console.log({ filename: fileInfo.name.replaceAll(' ', ''), mimetype: fileInfo.type });

				if (fileInfo.size > 1e6) {
					return dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'File Size should be smaller than 1MB',
							timeout: 7000,
						})
					);
				}

				let fileName = sanitizeFileName(fileInfo.name);
				const resp = await postApi(
					{ filename: fileName, mimetype: fileInfo.type },
					'/assetze/purchase-order/get-uploadurl'
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, fileInfo.type, fileInfo);
				}

				// const { vendorName, date, invoiceId, serialNumbers } = formData;
				const { vendorName, date, invoiceId, serialNumbers } = data;

				// if (!vendorName) {
				// 	throw new Error('Please Add Vendor Name');
				// }
				// if (!date) {
				// 	throw new Error('Please Add Purchase Order Date');
				// }
				// if (!invoiceId) {
				// 	throw new Error('Please Provide invoiceId');
				// }

				const resp2 = await postApi(
					{ vendorName, date, invoiceId, invoiceURL: resp.url, serialNumbers: serialNumbers },
					'/assetze/purchase-order'
				);
				if (resp2.type !== 'success') {
					throw new Error(resp2.error);
				}
			} else {
				const { vendorName, date, invoiceId, serialNumbers } = data;

				const resp2 = await postApi(
					{ vendorName, date, invoiceId, serialNumbers: serialNumbers },
					'/assetze/purchase-order'
				);
				if (resp2.type !== 'success') {
					throw new Error(resp2.error);
				}
			}

			setOpenDrawer(false);
			getPoData();
			form.resetFields();
			setInvoiceDoc('');
			triggerShowSnack(dispatch, 'Successfulyy Updated');
		} catch (e) {
			console.error(e, 'e');
			triggerShowSnack(dispatch, e.message);
		}
	};
	const props = {
		name: 'file',
		multiple: false,
		onChange(info) {
			const { status } = info.file;
			console.log(status);
			if (status === 'error') {
				console.log(info.file, info.fileList);
				setInvoiceDoc(info.file.originFileObj);
			}
			if (status === 'removed') {
				setInvoiceDoc('');
			}
		},
		beforeUpload: (file) => {
			setInvoiceDoc(file);

			return false;
		},

		onDrop(e) {
			console.log('Dropped files', e.dataTransfer.files);
		},
	};

	const downloadReport = async (invoiceURL) => {
		if (invoiceURL) {
			const pathname = new URL(invoiceURL).pathname;
			const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
			await download(invoiceURL, fileName);
		}
	};

	const PurchaseOrderData = PoData.map((data) => {
		return {
			Date: format(new Date(data?.date), 'dd MM yyyy'),
			VendorName: data?.vendorName,
			SerialNumbers: (
				<div>
					<Popover
						key={data._id}
						content={data.serialNumbers.map((data, index) => (
							<p key={index}>{data}</p>
						))}
						title="Serial No."
					>
						{data.serialNumbers.length > 0 && (
							<>
								<span>
									{data?.serialNumbers[0]} , {data?.serialNumbers[1]}
								</span>
							</>
						)}
					</Popover>
				</div>
			),
			action: data.invoiceURL && (
				<MdOutlineFileDownload
					style={{ cursor: 'pointer', fontSize: '18px' }}
					onClick={() => {
						downloadReport(data.invoiceURL);
					}}
					key={data._id}
				/>
			),
			InvoiceId: data?.invoiceId,
		};
	});
	const PurchaseOrderColumn = [
		{
			title: 'Vendor Name',
			dataIndex: 'VendorName',
			key: '1',
		},
		{
			title: 'Serial Numbers',
			dataIndex: 'SerialNumbers',
			key: '2',
		},
		{
			title: 'Date',
			dataIndex: 'Date',
			key: '3',
		},

		{
			title: 'InvoiceId',
			dataIndex: 'InvoiceId',
			key: '4',
		},
		{
			title: 'Action',
			dataIndex: 'action',
			key: '5',
			align: 'center',
		},
	];

	const searchInvoiceId = async (filterText) => {
		try {
			if (filterText && filterText.length >= 3) {
				const resp = await ApiPost({ filterText }, '/assetze/purchase-order/search');
				console.log(resp);
				if (resp.type === 'success') {
					setPoData(resp.purchaseOrderList);
				}
			} else {
				if (filterText.length === 0) {
					getPoData();
				}

				// setEmpList([]);
			}
		} catch (e) {
			console.error(e);
		}
		// setSearchEmpLoading(false);
	};

	return (
		<div className="account-settings">
			<Drawer
				title="Invoice / PO Updation"
				width={500}
				destroyOnClose={true}
				onClose={() => {
					setOpenDrawer(false);
				}}
				open={openDrawer}
				styles={{
					body: {
						paddingBottom: 80,
					},
				}}
				extra={
					<Space className="mobile-hide-btns">
						<Button
							onClick={() => {
								setOpenDrawer(false);
							}}
						>
							Cancel
						</Button>
						<Button
							onClick={() => {
								form.submit();
							}}
							className="ant-design-button"
						>
							Submit
						</Button>
					</Space>
				}
			>
				<Form form={form} onFinish={onSave} layout="vertical" hideRequiredMark>
					<Col span={20} style={{ margin: 'auto' }}>
						<Form.Item
							name="vendorName"
							label="Vendor Name"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Provide Vendor Name',
							// 	},
							// ]}
						>
							<Input placeholder={'Vendor Name'} />
						</Form.Item>
						<Form.Item
							name="invoiceId"
							label="PO/ Invoice Number"
							rules={[
								{
									required: true,
									message: 'Provide PO/ Invoice Number',
								},
							]}
						>
							<Input placeholder={'PO/ Invoice Number'} />
						</Form.Item>
						<Form.Item
							name="date"
							label="Date"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Provide Date',
							// 	},
							// ]}
						>
							<DatePicker
								placeholder="Provide Date"
								style={{
									width: '100%',
								}}
							/>
						</Form.Item>
						<Form.Item
							name="serialNumbers"
							label="Serial Numbers purchased in this PO"
							// rules={[
							// 	{
							// 		required: true,
							// 		message: 'Provide Serial Numbers',
							// 	},
							// ]}
						>
							<Select mode="tags" placeholder={'Serial Numbers'} tokenSeparators={[',']} />
						</Form.Item>

						<Dragger accept="image/png,image/jpeg,application/pdf" maxCount={1} {...props}>
							<p className="ant-upload-drag-icon">
								<IoCloudUploadOutline />
							</p>
							<p className="ant-upload-text">Click or drag to Upload Invoice Image/PDF.</p>
						</Dragger>
					</Col>
					<div className="drawer-mobile-btns">
						<Button
							style={{ height: '40px', width: '100px', borderRadius: '3px' }}
							size="large"
							onClick={() => {
								setOpenDrawer(false);
							}}
						>
							Cancel
						</Button>
						<Button
							type="primary"
							onClick={() => {
								form.submit();
							}}
							size="large"
							className="ant-design-button"
							style={{ height: '40px', width: '100px' }}
						>
							Submit
						</Button>
					</div>
				</Form>
			</Drawer>
			{/* <HeaderComponent />
			<div className="account-page-title">
				<div className="account-page-title-container">Account Settings</div>
			</div>
			<AccountTabs /> */}
			<div className="po-size-div">
				<div className="invoice-heading-div">
					<p className="asset-tag-head">Invoice / PO Updation</p>
					<div className="innerdiv">
						<div className="search-input">
							<Input
								onChange={_.debounce(function (e) {
									searchInvoiceId(e.target.value);
								}, 1000)}
								prefix={<CiSearch />}
							/>
						</div>
						<CheckPermission requiredPermission={PURCHASE_ORDER_CREATE}>
							<Button
								onClick={() => {
									setOpenDrawer(true);
								}}
								className="ant-design-button-small"
							>
								Create
							</Button>
						</CheckPermission>
					</div>
				</div>
				<div className="po-table">
					<Table
						pagination={{
							total: total,
							pageSize: 10,
							onChange: (page, pageSize) => {
								getPoData(page, pageSize);
							},
						}}
						scroll={{ x: 900 }}
						dataSource={PurchaseOrderData}
						columns={PurchaseOrderColumn}
					/>
				</div>
			</div>

			{/* <Table dataSource={PoData} columns={PoColumns}/> */}

			{/* <div className="tag-generation-container">
				<div className="input-container">
					<div className="each-input">
						<label for="company-name">Vendor Name*</label>
						<input
							onChange={(e) => setFormData({ ...formData, vendorName: e.target.value })}
							id="company-name"
							type={'text'}
							maxLength={30}
							value={formData.vendorName}
						/>
					</div>
					<div className="each-input">
						<label for="company-address">PO/ Invoice Number**</label>
						<input
							onChange={(e) => setFormData({ ...formData, invoiceId: e.target.value })}
							id="company-address"
							type={'text'}
							maxLength={100}
							value={formData.address}
						/>
					</div>

					<div className="each-input">
						<label for="invoice-date">Data**</label>
						<input
							onChange={(e) => {
								console.log('sasas', e.target.value);
								setFormData({ ...formData, date: new Date(e.target.value) });
							}}
							id="invoice-date"
							type={'date'}
							maxLength={100}
							value={formData.address}
						/>
					</div>
					<div className="each-input">
						<label for="note">Serial Numbers purchased in this PO, comma seperated</label>
						<input
							onChange={(e) => setFormData({ ...formData, serialNumbers: e.target.value })}
							id="note"
							type={'text'}
							maxLength={30}
							value={formData.serialNumbers}
						/>
					</div>
					<div className="tag-logo-drop" {...getRootProps()}>
						<input {...getInputProps()} accept={'image/*,application/pdf'} />
						{isDragActive ? (
							<p className="tag-highlight">uploading.</p>
						) : (
							<p style={{ marginBottom: '0px' }}>
								<span className="tag-highlight">Upload Invoice Image/PDF.</span>
							</p>
						)}
					</div>
				</div>
			</div> */}
			{/* <div className="actions">
				<button className="cancel">Cancel</button>
				<button onClick={() => onSave()} className="save">
					Save
				</button>
			</div> */}
		</div>
	);
};
