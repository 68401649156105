import * as React from 'react';
const HcoImg = (props) => (
	<svg width="199" height="60" viewBox="0 0 199 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M160.54 0C161.319 0.626984 162.28 0.310562 163.148 0.454124C176.968 2.74525 186.604 13.0407 187.858 26.931C189.258 42.4269 177.012 56.6512 161.346 57.7235C145.407 58.8134 132.091 47.8324 130.196 32.0318C128.309 16.2928 140.541 1.503 156.447 0.287123C156.749 0.263685 157.15 0.457054 157.323 0C158.395 0 159.468 0 160.54 0ZM158.961 11.5113C149.289 11.5406 141.473 19.3984 141.484 29.0786C141.496 38.7265 149.363 46.4759 159.119 46.4583C168.685 46.4407 176.566 38.5536 176.572 28.9965C176.578 19.4277 168.577 11.482 158.961 11.5113Z"
			fill="#888888"
		/>
		<path
			d="M0 1.16602C3.63592 1.48537 7.28063 1.28028 10.9195 1.27149C12.1969 1.26856 12.6305 1.52053 12.6129 2.90341C12.528 9.35782 12.5983 15.8152 12.5543 22.2696C12.5485 23.2628 12.7741 23.5792 13.8229 23.5704C20.3037 23.5206 26.7845 23.5089 33.2624 23.5763C34.4519 23.588 34.5808 23.1544 34.5749 22.1524C34.5339 15.698 34.5896 9.24063 34.5251 2.78621C34.5134 1.5381 34.8972 1.25391 36.0809 1.28321C39.1982 1.36231 42.3185 1.3711 45.4358 1.28028C46.7161 1.24512 47.1234 1.52638 47.1205 2.90341C47.0648 19.5507 47.0882 36.2009 47.0765 52.8512C47.0765 54.1022 47.6068 55.7224 46.8597 56.5017C46.1038 57.2928 44.4631 56.7215 43.215 56.742C40.7305 56.7801 38.2431 56.7097 35.7615 56.7742C34.7976 56.8006 34.531 56.5105 34.5368 55.5525C34.5779 49.4848 34.5222 43.4171 34.5867 37.3494C34.5984 36.1335 34.2702 35.8318 33.0602 35.8435C26.679 35.9079 20.2949 35.9021 13.9108 35.8464C12.8063 35.8376 12.5426 36.1248 12.5514 37.2088C12.6071 43.2765 12.5514 49.3442 12.6041 55.4118C12.6129 56.49 12.3639 56.8006 11.2505 56.7801C7.5033 56.7185 3.75311 56.783 0.00292983 56.7976C0 38.2577 0 19.7118 0 1.16602Z"
			fill="#888888"
		/>
		<path
			d="M98.9901 56.9562C95.1754 56.9562 91.3432 56.9181 87.511 56.9767C85.9992 57.0001 84.6105 57.0119 83.8341 55.3858C83.3946 54.4658 82.791 55.1016 82.2725 55.3477C75.4079 58.5705 68.4232 58.8899 61.5674 55.5938C54.2838 52.0927 50.0941 46.2067 49.8187 38.05C49.6166 32.0937 52.1831 27.1393 56.6189 23.1987C57.5623 22.3608 57.741 21.7894 57.2078 20.5911C52.9859 11.1278 59.871 0.641928 70.269 0.586261C78.0184 0.545243 83.3389 4.40676 84.965 11.2479C85.3722 12.9619 85.4074 14.7022 85.3986 16.4542C85.3957 17.0168 85.2228 17.2072 84.6515 17.2013C81.3379 17.1808 78.0242 17.1808 74.7106 17.2013C74.1305 17.2043 73.943 17.0021 73.9752 16.4454C74.0103 15.8155 74.0191 15.1797 73.9547 14.5557C73.7408 12.499 72.3755 11.368 70.4067 11.5731C68.5228 11.7694 67.2893 13.2754 67.4358 15.209C67.5471 16.6886 68.3587 17.8371 69.2406 18.9504C75.4518 26.7965 81.666 34.6426 87.8772 42.4887C91.4341 46.9831 94.9938 51.4774 98.5418 55.9806C98.7352 56.2208 99.0809 56.4054 98.9901 56.9562ZM76.1491 45.5767C72.4634 40.9476 68.7718 36.3302 65.1125 31.6923C64.5177 30.9393 64.298 31.4872 63.9434 31.8827C60.7236 35.4366 60.6093 39.72 63.6505 43.2915C66.6125 46.7692 72.5161 47.8767 76.1491 45.5767Z"
			fill="#888888"
		/>
		<path
			d="M127.067 17.2821C119.08 9.10203 107.777 10.3443 101.469 16.787C95.0347 23.3615 94.8091 33.7507 100.845 40.5684C106.684 47.1693 118.371 49.5044 127.099 40.8087C128.438 44.1692 130.128 47.3187 132.44 50.0904C133.014 50.7789 132.66 51.0601 132.162 51.4586C128.37 54.4939 124.087 56.4745 119.311 57.3505C114.94 58.1533 110.595 57.9628 106.291 56.8114C105.553 56.6151 105.011 56.2694 104.527 55.66C98.2722 47.7231 91.9935 39.8067 85.7149 31.8903C85.3457 31.4244 85.0908 30.9644 85.0821 30.3257C84.9151 18.1845 90.0628 8.89695 100.865 3.39765C111.427 -1.97859 121.849 -0.809584 131.57 6.06966C132.692 6.86364 132.953 7.40859 131.939 8.60689C129.797 11.1353 128.318 14.0915 127.067 17.2821Z"
			fill="#888888"
		/>
		<path
			d="M191.769 43.6155C196.501 43.6566 199.776 48.0572 198.517 52.8269C197.661 56.0702 195.431 58.2383 192.534 59.797C191.869 60.1544 191.517 60.0724 191.585 59.2462C191.672 58.1328 190.925 57.9102 190.052 57.708C186.302 56.8379 184.049 53.5301 184.524 49.6539C184.954 46.1557 188.04 43.5833 191.769 43.6155Z"
			fill="#888888"
		/>
	</svg>
);
export default HcoImg;
