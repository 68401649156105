import * as React from 'react';
const ZenEquityImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={234} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M0 26.419c.513-.341.38-.922.458-1.39C2.848 10.482 15.583-.167 30.424 0 45.167.167 57.69 11.111 59.75 25.635c2.337 16.468-9.162 31.653-25.682 
33.92-.273.036-.627-.099-.796.268h-6.5c-.176-.346-.523-.237-.801-.279C12.75 57.6 2.683 47.78.442 34.665c-.072-.422.076-.97-.442-1.254v-6.991ZM30.167 2.31C14.93 2.275 2.415 
14.59 2.37 29.66c-.041 15.245 12.254 27.78 27.301 27.832 15.469.052 28.001-12.16 28.048-27.34.05-15.364-12.26-27.8-27.553-27.84ZM127.371 
59.824c-.015-.198-.138-.297-.315-.354-5.531-1.75-5.866-9.76-3.196-13.176 1.64-2.1 3.842-2.873 6.432-2.558 2.415.294 4.219 1.499 5.127 3.815.159.404.726 1.099.008 
1.273-.619.151-1.536.529-2.074-.414-1.25-2.18-2.692-2.943-4.829-2.595-1.944.318-3.362 1.84-3.813 4.082-.333 1.647-.257 3.274.352 4.838.705 1.814 2.064 2.892 4.026 2.975 
1.872.08 3.41-.617 4.18-2.452.474-1.13 1.468-.736 2.101-.554.799.231.161.955-.021 1.387-.63 1.494-1.744 2.535-3.243 3.189-.37.161-.888.062-1.098.544h-3.637ZM139.341 
59.055c-1.111-.932-1.444-2.114-1.228-3.478.198-1.241 1.046-1.933 2.184-2.326 1.239-.427 2.548-.461 3.826-.654.64-.096 1.377-.101 
1.45-.973.07-.838-.258-1.515-1.052-1.91-1.054-.526-2.67-.337-3.459.634-.609.753-1.19.623-1.931.44-.63-.156-.622-.414-.365-.908.867-1.663 3.446-2.585 5.851-2.062 2.145.469 3.23 
1.69 3.21 3.905-.026 2.699.26 5.408-.18 8.1h-1.819l-.305-1.572c-.721.674-1.489 1.153-2.293 1.572-1.041 0-2.97 
0-3.889-.768Zm6.263-4.035c-.258-.205.401-1.121-.575-.952-1.226.21-2.499.224-3.676.692-.705.284-1.108.778-1.095 1.578.013.728.359 1.21 1.009 1.51 2.023.93 4.295-.482 
4.337-2.828ZM169.058 59.146c-1.226-.89-1.572-2.121-1.364-3.537.2-1.372 1.158-2.106 2.405-2.41 1.169-.287 2.379-.41 3.574-.597.637-.099 1.395-.075 
1.486-.934.096-.89-.268-1.632-1.133-1.983-1.231-.5-2.485-.362-3.342.63-.666.773-1.264.632-2.04.455-.575-.133-.56-.37-.349-.812.802-1.671 3.399-2.652 5.81-2.168 2.178.437 3.222 
1.608 3.274 3.839.052 2.238.008 4.48.023 6.718.006.684.05 1.267-.965 1.246-.883-.018-1.518-.083-1.114-1.374-.953.62-1.708 1.112-2.465 1.603-1.036 
0-2.874-.06-3.8-.676Zm6.14-4.035c-.177-.33.369-1.202-.573-1.043-1.27.213-2.592.2-3.797.744-.656.294-1.028.81-1.021 1.515.008.698.378 1.208 1.028 1.512a3.049 3.049 0 0 0 
4.363-2.728ZM87.01 59.34a6.729 6.729 0 0 1-2.333-9.011c1.143-2.049 3.444-3.04 5.875-2.528 2.405.505 3.98 2.343 4.255 4.912.349 3.248-.804 5.677-3.829 
7.113-1.132.222-2.248.383-3.969-.486Zm-.852-5.591c0 2.605 1.205 4.211 3.18 4.24 1.963.028 3.246-1.614 3.265-4.175.02-2.64-1.27-4.334-3.27-4.303-1.98.032-3.175 1.627-3.175 
4.238ZM162.983 59.823c-1.944-1.062-2.264-2.798-2.113-4.807.109-1.458 0-2.93.031-4.396.015-.664-.099-1.117-.903-1.01-.68.09-.896-.208-.898-.88-.003-.666.195-.94.879-.903 
1.409.073.906-1.028.875-1.653-.047-.984.268-1.244 1.236-1.226.898.018 1.153.352 1.041 1.15-.171 1.23.12 2.018 1.612 1.747.619-.112.817.216.806.823-.01.596-.078 
1.075-.827.934-1.505-.281-1.677.52-1.611 1.744.085 1.634.02 3.277.026 4.914.002.833.317 1.395 1.244 1.51 1.202.148 1.546.931.942 2.056-.781-.003-1.559-.003-2.34-.003ZM165.744 
11.133c.344-.453.164-.851.224-1.216.107-.65-.364-1.637.297-1.887.794-.302 1.791-.114 2.694-.07.507.023.393.49.393.81.005 8.794 0 17.59.015 26.384 0 
.692-.273.854-.921.88-2.634.099-2.634.122-2.634-2.502v-7.61c-.409-.143-.461.203-.601.377-3.725 4.636-10.823 3.99-13.543-1.28-2.277-4.415-2.256-8.884.086-13.262 2.746-5.132 
9.854-5.741 13.522-1.21.135.161.268.33.468.586Zm-12.116 7.46c.044 1.569.409 3.151 1.132 4.65.911 1.885 2.421 2.88 4.506 2.98 2.215.108 4.021-.686 5.127-2.641 1.861-3.287 
1.908-6.653.138-10.003-1.101-2.085-2.907-3.019-5.231-2.91-2.246.104-3.798 1.25-4.685 3.314a11.947 11.947 0 0 0-.987 4.61ZM96.697 19.42c-2.207 
0-4.415.018-6.622-.011-.68-.01-1.064.104-.984.908.232 2.33 1.101 4.303 3.316 5.344 2.179 1.023 4.376.89 6.432-.424.356-.227.794-.526.916-.888.646-1.884 2.192-1.374 
3.543-1.418.681-.024.661.351.51.843-.484 1.585-1.411 2.845-2.764 3.8-5.672 4.013-13.21 1.465-15.125-5.177-.846-2.928-.766-5.898.354-8.764 1.704-4.362 5.538-6.64 10.041-6.046 
4.308.57 7.384 3.907 7.871 8.537.346 3.293.346 3.293-2.946 3.293-1.513.002-3.027.002-4.542.002Zm-1.856-2.788c1.554 0 3.108-.055 4.659.02.973.047 1.166-.301 
1.015-1.189-.502-2.962-2.787-4.896-5.78-4.828-2.804.062-5.222 2.348-5.555 5.187-.076.648.117.83.742.82 1.64-.031 3.28-.01 4.919-.01ZM140.359 19.42c-2.165 
0-4.331.049-6.494-.021-.966-.032-1.156.32-1.088 1.192.195 2.613 1.957 4.93 4.388 5.505 2.606.617 5.766-.154 6.828-2.634.434-1.018 1.379-.469 2.074-.5 1.775-.08 1.804.018 1.091 
1.65a6.897 6.897 0 0 1-1.101 1.747c-3.907 4.547-12.254 3.906-15.281-1.247-2.254-3.837-2.291-7.913-.612-11.947 1.676-4.03 5.224-6.007 9.565-5.601 3.959.372 6.95 3.139 7.895 
7.303.328 1.442.804 3.155.075 4.274-.554.851-2.34.214-3.576.266-1.25.054-2.509.013-3.764.013Zm-1.903-2.788c1.599 0 3.199-.05 4.795.018.934.042 
1.075-.315.932-1.127-.526-3.024-2.744-4.937-5.732-4.888-2.813.044-5.234 2.288-5.609 5.138-.094.721.154.885.823.87 1.593-.037 3.193-.011 4.791-.011ZM111.382 10.954c1.489-2.288 
3.571-3.275 6.093-3.402 4.63-.232 7.775 2.756 7.788 7.392.013 4.27-.024 8.545.015 12.816.008.903-.138 1.301-1.181 1.28-2.403-.052-2.405.008-2.405-2.381 0-3.537 
0-7.077.002-10.614.003-3.314-1.548-5.235-4.339-5.37-3.282-.159-5.747 1.817-5.921 5.013-.164 3.011-.06 6.039-.073 9.058-.003.648 0 1.293 0 1.942 0 2.373 0 2.326-2.434 
2.347-.944.008-1.14-.325-1.134-1.192.033-5.393.018-10.788.018-16.181V9.85c0-1.908.005-1.83 1.783-1.934 1.213-.07 1.785.216 1.582 1.525-.065.432-.14.927.206 1.512ZM187.933 
25.596c-1.522 2.018-3.17 3.189-5.307 3.61-4.734.938-8.735-2.235-8.782-7.035-.041-4.401.019-8.803-.028-13.204-.011-.893.296-1.064 1.111-1.06 2.447.014 2.447-.02 2.447 2.434 0 
3.496-.018 6.992.005 10.487.026 3.727 1.559 5.38 4.937 5.385 3.184.006 5.373-2.196 5.404-5.528a437.02 437.02 0 0 0-.024-11.392c-.018-1.029.183-1.437 1.32-1.396 2.283.084 
2.285.016 2.285 2.348 0 5.825-.023 11.653.021 17.478.008.99-.203 1.348-1.27 1.31-2.108-.079-2.111-.014-2.121-2.122 0-.3.002-.594.002-1.315ZM224.74 
25.528l4.484-13.39c.341-1.02.768-2.022 1.002-3.065.214-.958.703-1.177 1.622-1.156 2.493.055 2.491.013 1.642 2.324-2.553 6.952-5.099 13.907-7.652 20.86-.403 1.098-.966 
2.11-1.619 3.084-1.27 1.892-3.079 2.694-5.304 2.701-1.835.008-1.783.005-1.843-1.837-.032-.937.166-1.234 1.194-1.195 2.9.11 5.209-3.227 
4.201-5.947-2.319-6.26-4.627-12.52-6.978-18.767-.343-.913-.356-1.257.807-1.228 2.553.065 2.548.005 3.376 2.478 1.65 4.94 3.306 9.877 5.068 15.138ZM79.047 10.846c-3.602 
0-6.92.013-10.236-.015-.461-.006-1.198.325-1.343-.347-.16-.736-.076-1.543.02-2.306.045-.346.505-.234.792-.234 4.633-.005 9.266.013 13.899-.018.767-.005.931.273.945.979.018 
1.025-.183 1.881-.839 2.738-3.383 4.424-6.702 8.901-10.03 13.368-.196.263-.615.51-.464.856.19.437.687.221 1.041.221 3.03.018 6.065.078 9.092-.023 1.249-.042 1.28.53 1.26 
1.46-.022.875.075 1.533-1.227 1.504-4.242-.085-8.485-.018-12.73-.049-.599-.005-1.52.385-1.746-.32-.258-.807-.354-1.788.218-2.613.172-.248.36-.487.542-.73 3.344-4.455 
6.692-8.909 10.034-13.365.203-.268.387-.554.772-1.106ZM205.487 
18.064c.002-2.07-.029-4.139.015-6.205.016-.763-.172-1.159-1.002-1.026-.127.02-.26.003-.388.003-1.762-.005-2.222-.661-1.793-2.434.161-.666.635-.458 1.02-.471 2.158-.073 
2.158-.065 2.163-2.212V4.685c0-1.837 0-1.837 1.83-1.837 1.7 0 1.676 0 1.707 1.702.019 1.07-.437 2.394.177 3.139.602.729 1.955.258 2.968.229 1.554-.044.825 1.057.95 
1.702.145.768.018 1.304-1.031 1.244-.973-.057-2.241-.447-2.866.185-.622.63-.187 1.885-.195 2.866-.026 3.06-.013 6.119-.008 9.18.003 2.262.812 3.12 3.022 3.014.867-.042 
1.109.167 1.096 1.025-.029 1.976.016 1.976-1.913 2.018-4.133.086-5.841-1.645-5.755-5.786.031-1.768.003-3.535.003-5.302ZM99.896 49.526c.809-1.257 1.874-1.89 3.274-1.848 
1.099.034 2.043.414 2.679 1.338.351.515.546.606 1.033.073 1.28-1.398 3.004-1.799 4.586-1.138 1.692.706 2.127 2.132 2.121 3.798-.007 2.285-.034 4.573.016 
6.858.018.862-.37.982-1.093.982-.721 0-1.117-.112-1.096-.977.052-2.113.031-4.227.01-6.34-.015-1.77-.793-2.65-2.298-2.668-1.549-.018-2.436.924-2.46 2.684-.026 2.069-.044 
4.14.008 6.21.024.87-.296 1.085-1.124 1.098-.864.01-1.112-.27-1.091-1.111.052-2.07.024-4.141.013-6.21-.008-1.76-.705-2.621-2.121-2.668-1.452-.047-2.543 1.017-2.582 2.642-.05 
2.069-.044 4.14-.003 6.21.016.799-.156 1.155-1.067 1.14-.877-.016-1.205-.221-1.19-1.156.055-3.105.019-6.21.024-9.315 0-.622-.195-1.31.893-1.291.867.015 1.457.099 1.299 
1.158-.013.107.07.23.169.531ZM196.033 18.519c0-3.144.029-6.291-.018-9.435-.013-.862.169-1.2 1.122-1.187 2.462.028 2.462-.018 2.462 2.413 0 5.773-.023 11.546.024 17.316.008 
1.015-.169 1.442-1.317 1.4-2.267-.083-2.27-.015-2.27-2.238-.003-2.754-.003-5.51-.003-8.27ZM69.188 
54.516c0-1.252-.031-2.504.01-3.753.026-.765-.101-1.286-1.038-1.158-.64.088-.804-.245-.81-.838-.005-.622.12-1.023.849-.935.843.104.981-.356 1.005-1.082.09-2.65 1.647-3.938 
4.24-3.54.39.06 1.132-.102.955.669-.123.53.002 1.426-1.09 1.202-.568-.117-1.146.008-1.531.495-.438.551-.48 1.233-.292 1.853.206.684.88.36 1.351.39.612.042 1.192-.09 
1.182.885-.01.93-.52.984-1.169.896-1.124-.157-1.504.236-1.46 1.408.094 2.498-.005 5.005.047 7.506.018.88-.297 1.093-1.125 
1.083-.799-.008-1.19-.161-1.142-1.067.07-1.338.018-2.676.018-4.014ZM78.784 49.43c.948-1.26 1.937-1.819 3.264-1.78.838.026.706.568.708 1.068.003.51.107.97-.744 
1.004-2.606.102-3.321.909-3.324 3.506-.002 1.806-.044 3.615.018 
5.42.031.908-.447.939-1.124.934-.666-.003-1.148-.008-1.135-.927.047-3.31.034-6.624.005-9.935-.005-.671.216-.984.885-.882.51.078 1.322-.357 
1.307.71-.003.206.062.412.14.883ZM153.282 49.294c.924-1.051 1.895-1.658 3.225-1.647.82.005.765.507.742 1.033-.021.481.166.994-.708 1.044-2.634.143-3.314.913-3.316 3.51-.003 
1.766-.042 3.53.015 5.292.032.898-.361 1.05-1.15 1.07-.864.024-1.098-.268-1.085-1.103.044-3.054-.008-6.11.044-9.162.008-.505-.51-1.362.643-1.474 1.007-.101 1.705.073 1.59 
1.437ZM197.803.016c1.211-.013 2.262 1.004 2.254 2.189-.005 1.174-1.083 2.217-2.267 2.194a2.196 2.196 0 0 1-2.15-2.142 2.16 2.16 0 0 1 2.163-2.241Z"
		/>
		<path
			fill="#888"
			d="M19.766 40.162c.375.34.69.221.97.221 6.838.008 13.676-.013 20.513.031.982.006 1.3-.255 1.218-1.226-.083-1.028-.033-2.069-.01-3.102.016-.685.323-1.187 
1.08-1.19.776-.002 1.039.518 1.041 1.206.008 1.725.008 3.448 0 5.174-.002.856-.453 1.22-1.311 1.22-8.785-.01-17.572-.007-26.357-.002-.546 
0-1.062-.052-1.283-.645-.229-.62.068-1.062.536-1.46 1.78-1.51 3.548-3.03 5.315-4.553.58-.5 1.224-.815 1.807-.12.58.69.161 1.265-.425 1.762-1.033.88-2.05 1.778-3.094 
2.684ZM40.13 19.432H20.161c-2.514 0-2.511 0-2.514 2.478 0 .645.023 1.293-.016 1.939-.039.643-.377 1.057-1.07 
1.044-.7-.01-1.007-.443-1.01-1.091-.012-1.767-.005-3.535-.007-5.302 0-.992.606-1.197 1.457-1.195 3.85.016 7.702.006 11.552.006 4.758 0 9.518-.003 14.276.002.63 0 1.356-.159 
1.653.659.281.77-.247 1.194-.75 1.624a874.885 874.885 0 0 0-5.018 4.3c-.609.528-1.275.921-1.902.159-.565-.688-.123-1.268.458-1.76 1.012-.851 2.006-1.723 3.008-2.587a10.035 
10.035 0 0 0-.148-.276Z"
		/>
		<path
			fill="#888"
			d="M38.696 29.719c.01.481-.27.799-.607 1.082-2.201 1.866-4.406 3.73-6.608 5.596-.568.482-1.192.633-1.702.006-.508-.625-.211-1.198.351-1.671 2.207-1.861 4.407-3.73 
6.611-5.596.383-.326.802-.518 1.307-.313.388.164.588.482.648.896ZM27.43 33.253c-.473-.018-.8-.182-1.004-.565-.203-.38-.226-.804.089-1.078a243.452 243.452 0 0 1 
5.796-4.932c.388-.317.927-.156 1.263.276.333.424.372.942-.024 1.291-1.871 1.65-3.777 3.261-5.68 4.878-.122.099-.327.096-.44.13ZM28.509 25.864c-.016.08-.003.237-.076.328-1.012 
1.293-2.262 2.327-3.68 3.142-.344.197-.763-.014-1.023-.354-.29-.378-.385-.836-.058-1.187a18.313 18.313 0 0 1 3.33-2.835c.642-.427 1.462.117 1.507.906Z"
		/>
	</svg>
);
export default ZenEquityImg;
