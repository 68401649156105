import { DEFAULT_PERMISSION } from '../../services/constants';

export function getLabel(item, perm) {
	if (item && perm) {
		for (let x = 0; x < DEFAULT_PERMISSION.length; x++) {
			if (DEFAULT_PERMISSION[x].obj === item.obj) {
				for (let y = 0; y < DEFAULT_PERMISSION[x].permList.length; y++) {
					if (DEFAULT_PERMISSION[x].permList[y].obj === perm.obj) {
						return DEFAULT_PERMISSION[x].permList[y].label;
					}
				}
			}
		}

		return item.obj + '.' + perm.obj;
	} else {
		return '';
	}
}

export function getLabel2(item, perm) {
	if (item && perm) {
		for (let x = 0; x < DEFAULT_PERMISSION.length; x++) {
			if (DEFAULT_PERMISSION[x].obj === item) {
				for (let y = 0; y < DEFAULT_PERMISSION[x].permList.length; y++) {
					if (DEFAULT_PERMISSION[x].permList[y].obj === perm) {
						return DEFAULT_PERMISSION[x].permList[y].label;
					}
				}
			}
		}

		return item + '.' + perm;
	} else {
		return '';
	}
}
