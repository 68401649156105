import React from 'react';
import './index.css';
import ShopImage1 from '../../images/ShopImage1.png';
import ShopImage2 from '../../images/ShopImage2.png';
import StarRatings from 'react-star-ratings';
import ReactSlider from 'react-slider';
import Slider from 'react-slick';
import { useNavigate } from 'react-router-dom';

const ShopProduct = function () {
	const memory = ['16GB', '32GB', '64GB'];
	const storage = ['512BG', '2TB', '8TB'];
	const tips = [ShopImage1, ShopImage2, ShopImage1];
	const settings = {
		speed: 500,
		slidesToShow: 1,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 4000,
	};
	const navigate = useNavigate();

	return (
		<div className="shop-container">
			<button className="prod-title">IT Products </button>
			<div className="shop-body">
				<div className="body-left">
					<div className="customize">Customize</div>
					<div className="sec-proc">
						<div className="sec-head">Processor</div>
						<div className="item-1">Apple M1 Pro with 10-core CPU, 16-core GPU, 16-core Neural Engine</div>
						<div className="item-2">Apple M1 Max with 10-core CPU, 24-core GPU, 16-core Neural Engine</div>
						<div className="item-3">Apple M1 Max with 10-core CPU, 32-core GPU, 16-core Neural Engine</div>
					</div>
					<div className="note">64GB available only when M1 max is selected</div>
					<div className="sec-mem">
						<div className="sec-head">Memory</div>
						<ReactSlider
							className="horizontal-slider"
							thumbClassName="example-thumb"
							trackClassName="example-track"
							marks
							min={0}
							max={2}
							markClassName="example-mark"
							ariaLabelledby={['mem1', 'mem2', 'mem3']}
						/>
						<label id="mem1">16GB</label>
						<label id="mem2">32GB</label>
						<label id="mem3">64GB</label>
					</div>
					<div className="sec-stor">
						<div className="sec-head">Storage</div>
						<ReactSlider
							className="horizontal-slider"
							thumbClassName="example-thumb"
							trackClassName="example-track"
							marks
							min={0}
							max={2}
							markClassName="example-mark"
							ariaLabelledby={['mem1', 'mem2', 'mem3']}
						/>
						<label id="str1">512GB</label>
						<label id="str2">2TB</label>
						<label id="str3">8TB</label>
					</div>
				</div>
				<div className="body-right">
					<div className="body-image">
						<Slider {...settings} className="prod-slider">
							{tips.map((el, index) => (
								<div className="carousel">
									<img key={index} className="carousel-img" src={el} />
								</div>
							))}
						</Slider>
					</div>
					<div className="body-details">
						<div className="product-buy">
							<div className="prod-name">16‑inch MacBook Pro - Space Grey</div>
							<div className="cost-buy">
								<div className="cost">₹ 225,500.00</div>
								<button
									className="buy-now"
									onClick={() => {
										navigate('/products/shopping-cart');
									}}
								>
									Buy Now
								</button>
							</div>
						</div>
						<div className="prod-config">
							<ul>
								<li>Apple M1 Pro with 10-core CPU, 16-core GPU, 16-core Neural Engine</li>
								<li>16GB unified memory</li>
								<li>512GB SSD storage</li>
								<li>41.05 cm (16.2-inch) Liquid Retina XDR display</li>
								<li>Three Thunderbolt 4 ports, HDMI port, SDXC card slot, MagSafe 3 port</li>
								<li>140W USB-C Power Adapter</li>
								<li>Backlit Magic Keyboard with Touch ID - US English</li>
							</ul>
						</div>
						<div className="best-suit">Best Suited for</div>
						<div className="tech-team">
							<div className="team">Tech Team</div>
							<div className="team-name">AI/ML</div>
							<div className="team-name">Design</div>
							<div className="team-name">UI/UX</div>
							<div className="team-name">Management</div>
						</div>
						<div className="best-suit">Reviews</div>
						<div className="ratings">
							<StarRatings
								rating={4}
								starEmptyColor="rgb(204,204,204)"
								starRatedColor="rgb(253,211,5)"
								starDimension="30px"
								starSpacing="10px"
							/>
							<div className="rating-count">1200</div>
						</div>
						<div className="lease-status">
							<div className="lease-rent">Lease/rent :</div>
							<div className="status">&nbsp; Available</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ShopProduct;
