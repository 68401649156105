import React from 'react';
import HeaderComponent from '../../components/Header';
import './index.css';
import AssetNotFound from '../../images/AssestNotFound.png';
import { useNavigate } from 'react-router-dom';

const PageNotFoundScreen = function () {
	const navigate = useNavigate();
	return (
		<div className="error-page-container">
			<HeaderComponent />
			<div className="error-page-body">
				<img src={AssetNotFound} />
				<div className="p">Did you lose track of where you wanted to be?</div>
				<div className="h4">Seems like the page maybe moved or might have been a typo error</div>
				<button
					onClick={() => {
						navigate('/dashboard');
					}}
				>
					Back to home page
				</button>
			</div>
		</div>
	);
};

export default PageNotFoundScreen;
