import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './index.css';
import CartImage from '../../images/Cart-Image.png';
import { IoMdRadioButtonOff } from 'react-icons/io';
import { IoMdRadioButtonOn } from 'react-icons/io';
import AddressIcon from '../../images/AddressIcon.png';

const ShopCart = function () {
	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm();
	const user = useSelector((state) => state.appreducer.user);
	const navigate = useNavigate();
	return (
		<div className="cart-container">
			<div>
				<div className="heading">Shopping Cart</div>
				<div className="item-order">
					<table className="items-table">
						<thead>
							<tr>
								<th>Product Details</th>
								<th>Quantity</th>
								<th>Price</th>
								<th>Total</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<div className="product-image">
										<img src={CartImage} alt="product" />
										<div className="device-details">
											<div className="device-name">13-inch MacBook Pro</div>
											<div className="device-color">Color: Space Grey</div>
											<div className="device-memory">8GB unified memory</div>
											<div className="device-storage">256GB SSD storage</div>
										</div>
									</div>
								</td>
								<td>Quantity</td>
								<td className="price">₹ 114,500.00</td>
								<td className="price">₹ 229,000.00</td>
							</tr>
						</tbody>
					</table>
					<div className="order-summary">
						<div className="heading">ORDER SUMMARY</div>
						<hr />
						<div className="item-summary">
							<div>PRODUCTS 2</div>
							<div className="prod-cost">₹ 229,000.00</div>
						</div>
						<div className="ship-summary">
							<div>SHIPPING</div>
							<div className="ship-cost">FREE</div>
						</div>
						<hr />
						<div className="total-cost">
							<div>TOTAL COST</div>
							<div className="bill">₹ 229,000.00</div>
						</div>
						<button className="checkout">CHECKOUT</button>
						<br />
						<input placeholder="PROMO CODE" />
					</div>
				</div>
				<form className="shopping-cart">
					<div className="heading">Shipping Details</div>
					<div className="name-container">
						<div className="first-name">
							<div className="container-heading">First Name*</div>
							<input type="text" required placeholder={user.firstName} />
						</div>
						<div className="last-name">
							<div className="container-heading">Last Name</div>
							<input type="text" placeholder={user.lastName} />
						</div>
					</div>
					<div className="address">Address*</div>
					<input />
					<div className="landmark">Landmark</div>
					<input />
					<div className="name-container">
						<div className="first-name">
							<div className="container-heading" {...register('city')} required>
								City*
							</div>
							<input required />
						</div>
						<div className="last-name">
							<div className="container-heading" {...register('state')} required>
								State*
							</div>
							<input />
						</div>
					</div>
					<div className="name-container">
						<div className="first-name">
							<div className="container-heading" {...register('pincode')} required>
								Pincode*
							</div>
							<input />
						</div>
						<div className="last-name">
							<div className="container-heading">Phone Number*</div>
							<input />
						</div>
					</div>
					<div className="address-type">Address type</div>
					<div className="address-type-container">
						<div className="addr-type-item">
							<IoMdRadioButtonOn className="radio-icon" />
							<div className="addr-item">Home (All day delivery)</div>
						</div>
						<div className="addr-type-item">
							<IoMdRadioButtonOff className="radio-icon" />
							<div className="addr-item">Office (Delivery between 10AM and 6PM)</div>
						</div>
					</div>
					<div className="save-changes">
						<button
							className="yes"
							onClick={(e) => {
								e.preventDefault();
								navigate('/confirm-shipping');
							}}
							type="submit"
						>
							Checkout
						</button>
						<button className="no">Cancel</button>
					</div>
				</form>
			</div>
		</div>
	);
};

export default ShopCart;
