import { useState } from 'react';

const useVendor = (sections) => {
	console.log('sections', sections);
	const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	console.log('sections', sections, currentSectionIndex, currentQuestionIndex);

	// console.log("questions", sections[currentSectionIndex]?.questions);
	// console.log("answers", sections[currentSectionIndex]?.questions[currentQuestionIndex]);
	// console.log(question)

	const next = (sectionIdx, questionIdx) => {
		const questionCount = sections[sectionIdx]?.questions?.length;

		if (sections?.length - 1 === sectionIdx && questionCount - 1 === questionIdx) return;

		if (questionIdx >= questionCount - 1) {
			setCurrentSectionIndex((i) => {
				if (i >= sections?.length - 1) return i;
				return i + 1;
			});
			setCurrentQuestionIndex(0);
		} else {
			setCurrentQuestionIndex((i) => {
				if (i >= questionCount - 1) return i;
				return i + 1;
			});
		}

		// console.log("next data", currentQuestionIndex, currentSectionIndex);
	};
	const back = (sectionIdx, questionIdx) => {
		// console.log("back", sectionIdx, questionIdx);
		// const questionCount = sections[sectionIdx]?.questions?.length;

		if (sectionIdx === 0 && questionIdx === 0) return sectionIdx, questionIdx;

		if (questionIdx === 0) {
			setCurrentSectionIndex((i) => {
				if (i <= 0) return i;
				return i - 1;
			});
			const prevQuestionCount = sections[currentSectionIndex - 1]?.questions?.length - 1;
			console.log(prevQuestionCount);
			setCurrentQuestionIndex(prevQuestionCount);
		} else {
			setCurrentQuestionIndex((i) => {
				if (i <= 0) return i;
				return i - 1;
			});
		}
	};
	return {
		question: sections[currentSectionIndex]?.questions[currentQuestionIndex],
		mcqClicked: sections[currentQuestionIndex]?.questions[currentQuestionIndex]?.mcqAnswer,
		next,
		back,
		sections,
		questions: sections[currentSectionIndex]?.questions,
		section: sections[currentSectionIndex],
		currentSectionIndex,
		currentQuestionIndex,
		isSectionLastQuestion: currentQuestionIndex === sections[currentSectionIndex]?.questions.length - 1,
		isSectionLastSection: currentSectionIndex === sections.length - 1,
		isFirstStep: currentQuestionIndex === 0 && currentSectionIndex === 0,
		isLastStep:
			currentQuestionIndex === sections[currentSectionIndex]?.questions?.length - 1 &&
			currentSectionIndex === sections?.length - 1,
	};
};

export default useVendor;
