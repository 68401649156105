import React, { useEffect } from 'react';
import format from 'date-fns/format';
import HeaderComponent from '../../components/Header';
import Paper from '@mui/material/Paper';
// import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import './index.css';
import { getApi } from '../../services/api';
import { Table, Select, Tag } from 'antd';

const columns = [
	{ id: 'action', label: 'Action', minWidth: 170 },
	{ id: 'loggedTime', label: 'Action Time', minWidth: 170 },
	{
		id: 'email',
		label: 'User Email/ Name',
		minWidth: 170,
	},
	{
		id: 'actionInfo',
		label: 'Action Info',
		minWidth: 170,
		maxWidth: 500,
		width: 500,
	},
];

const assetUpdateMapper = {
	name: 'Name',
	custom: 'Configuration',
	department: 'Department',
	imeiNumber: 'IMEI Number',
	purchaseOrder: 'Purchase Order',
	vendor: 'Vendor',
	type: 'Asset Type',
	id: 'Asset Id',
	health: 'Asset Health',
};

function createData(eachAudit) {
	try {
		const resp = {
			action: eachAudit.actionType,
			loggedTime: format(new Date(eachAudit.createdAt), 'dd MMM yyyy H:mm:ss'),
			code: eachAudit._id,
		};
		if (eachAudit.actionType === 'ASSET_UPDATED') {
			resp['email'] = eachAudit.payload.email + '/ ' + eachAudit.payload.name;
			if (eachAudit.payload.updatedInfo) {
				const keys = Object.keys(eachAudit.payload.updatedInfo).map((el) => {
					if (assetUpdateMapper[el]) {
						return assetUpdateMapper[el];
					}
					return el;
				});
				resp['actionInfo'] = `Updated Fields are ${keys.join(', ')} for ${eachAudit.payload.serialNumber}.`;
			} else {
				resp['actionInfo'] = `Asset Info is updated for ${eachAudit.payload.serialNumber}.`;
			}
		} else if (eachAudit.actionType === 'ADMIN_LOGIN') {
			resp['email'] = eachAudit.payload.email + '/ ' + eachAudit.payload.name;
			resp['actionInfo'] = 'User Loggedin.';
		} else if (eachAudit.actionType === 'EMPLOYEE_LOGIN') {
			resp['email'] = eachAudit.payload.email + '/ ' + eachAudit.payload.name;
			resp['actionInfo'] = 'Employee Loggedin.';
		} else if (eachAudit.actionType === 'ASSET_UNASSIGNED') {
			resp['email'] = eachAudit.payload.email + '/ ' + eachAudit.payload.name;
			resp[
				'actionInfo'
			] = `Asset (${eachAudit.payload.assetName}) Un Assigned from ${eachAudit.payload.unassignedFrom.name}`;
		} else if (eachAudit.actionType === 'ASSET_ASSIGNED') {
			resp['email'] = eachAudit.payload.email + ' / ' + eachAudit.payload.name;
			resp[
				'actionInfo'
			] = `Asset (${eachAudit.payload.assetName}) Assigned to ${eachAudit.payload.assignedTo.name}`;
		} else if (eachAudit.actionType === 'EMPLOYEE_DELETED') {
			resp['email'] = eachAudit.adminName;
			resp['actionInfo'] = `Employee(${eachAudit.payload.employeeName}) deleted by admin ${eachAudit.adminName}.`;
		} else if (eachAudit.actionType === 'ROLE_ASSIGNED') {
			resp['email'] = `${eachAudit.payload.email}/${eachAudit.payload.name}`;
			resp['actionInfo'] = `Role ${eachAudit.payload.roleName} is assigned to ${eachAudit.payload.assignToUser}.`;
		}
		return resp;
	} catch (e) {
		console.error(e);
	}
}

const actionsList = [
	{
		value: 'ASSET_ASSIGNED',
		label: 'Asset Assigned',
	},
	{
		value: 'ASSET_UNASSIGNED',
		label: 'Asset Unassigned',
	},
	{
		value: 'ASSET_UPDATED',
		label: 'Asset Updated',
	},
	{
		value: 'ADMIN_LOGIN',
		label: 'Admin Login',
	},
	{
		value: 'EMPLOYEE_LOGIN',
		label: 'Employee Login',
	},
	{
		value: 'EMPLOYEE_DELETED',
		label: 'Employee Deleted',
	},
	{
		value: 'ROLE_ASSIGNED',
		label: 'Role Assigned',
	},
];

const ActivityInfoColumns = [
	{
		title: 'Action',
		dataIndex: 'action',
		key: '1',
	},
	{
		title: 'Action Time',
		dataIndex: 'actionTime',
		key: '2',
	},
	{
		title: 'User Email/ Name',
		dataIndex: 'email',
		key: '3',
	},
	{
		title: 'Action Info',
		dataIndex: 'actionInfo',
		key: '4',
	},
];

const tagRender = (props) => {
	const { label, value, closable, onClose } = props;
	const onPreventMouseDown = (event) => {
		event.preventDefault();
		event.stopPropagation();
	};
	return (
		<Tag
			color={'#32d7db'}
			onMouseDown={onPreventMouseDown}
			closable={closable}
			onClose={onClose}
			style={{
				marginInlineEnd: 4,
			}}
		>
			{label}
		</Tag>
	);
};

export default function ActivityInfo() {
	const [page, setPage] = React.useState(1);
	const [pageSize, setPageSize] = React.useState(10);
	const [filterSelected, setSelectedFilter] = React.useState([]);
	const [actionType, setActionType] = React.useState(null);
	const [totalRows, setTotalRows] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [rows, setRows] = React.useState([]);

	useEffect(() => {
		const getAudits = async function () {
			try {
				const resp = await getApi(
					`/assetze/audit?page=${page}&limit=${pageSize}&filterText=${filterSelected.join(',')}`
				);
				if (resp.type === 'success') {
					const auditList = [];
					for (let x = 0; x < resp.auditList.length; x++) {
						auditList.push(createData(resp.auditList[x]));
					}
					setRows(auditList);
					setTotalRows(resp.totalAuditCount);
				}
			} catch (e) {}
		};
		getAudits();
	}, [page, pageSize, filterSelected]);

	const ActivityInfoData = rows.map((data) => ({
		action: data.action,
		actionTime: data.loggedTime,
		email: data.email,
		actionInfo: data.actionInfo,
	}));
	const handleChange = (value) => {
		console.log(`selected ${value}`, value);
		setSelectedFilter(value);
	};

	console.log(rows, totalRows, page, 'rows to render');

	return (
		<div className="account-settings">
			<HeaderComponent />
			<div className="header-activity">
				<div className="header-full">
					<h4>Activity Log</h4>
					<Link className="jobs-info" to={`/jobs-info`}>
						<h5>Jobs Info</h5>
					</Link>
				</div>
			</div>

			<div className="activity-table-container">
				<div className="filter-container">
					Filter By:{'  '}
					<Select
						mode="multiple"
						tagRender={tagRender}
						// defaultValue={['gold', 'cyan']}
						style={{
							width: 300,
						}}
						options={actionsList}
						onChange={handleChange}
						value={filterSelected}
					/>
				</div>
				<Table
					pagination={{
						pageSize: pageSize,
						total: totalRows,
						onChange: (page, pageSize) => {
							setPage(page);
							setPageSize(pageSize);
						},
					}}
					dataSource={ActivityInfoData}
					columns={ActivityInfoColumns}
				/>
				{/* <Paper sx={{ width: '100%', overflow: 'hidden' }}>
					<TableContainer sx={{ maxHeight: 440 }}>
						<Table stickyHeader aria-label="sticky table">
							<TableHead className="activity-table-head">
								<TableRow>
									{columns.map((column) => (
										<TableCell
											key={column.id}
											align={column.align}
											style={{ minWidth: column.minWidth }}
										>
											{column.label}
										</TableCell>
									))}
								</TableRow>
							</TableHead>
							<TableBody>
								{rows.map((row) => {
									return (
										<TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
											{columns.map((column) => {
												const value = row[column.id];
												return (
													<TableCell key={column.id} align={column.align}>
														{column.format && typeof value === 'number'
															? column.format(value)
															: value}
													</TableCell>
												);
											})}
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						rowsPerPageOptions={[10]}
						component="div"
						count={totalRows}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper> */}
			</div>
		</div>
	);
}
