import React from 'react';
import Slider from 'react-slick';
import EachTip from './EachTip';
import './index.css';

const settings = {
	infinite: true,
	speed: 500,
	slidesToShow: 1,
	slidesToScroll: 1,
	autoplay: true,
	autoplaySpeed: 4000,
};

const allTips = [
	{
		info: `Dashboard allows you to track all the important metrics, with each section leading to independant pages with
details. Keeping a track of all the assets is now easier than ever.`,
		id: 1,
		heading: 'Tip 1 : Easy access of Dashboard',
	},
	{
		info: `The tedious and unreliable methods of tracking are gone. Now you can move all the asset details to
our platform by just a click, upload the excel or csv file and your asset data is ready at your fingertips`,
		id: 2,
		heading: 'Tip 2 : You can move your asset details online by one click',
	},
	{
		info: `Keeping track of earlier device users were a pain! Now with our easy access dashboard you can now
track the earlier device users, the device history, and generate reports accordingly`,
		id: 3,
		heading: 'Tip 3 : You can track the device history',
	},
];

const TipsCarousel = function () {
	return (
		<div>
			<Slider {...settings}>
				{allTips.map((el, index) => (
					<EachTip key={index} heading={el.heading} info={el.info} />
				))}
			</Slider>
		</div>
	);
};

export default TipsCarousel;
