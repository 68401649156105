import { useEffect, useState } from 'react';
import { AiFillMail } from 'react-icons/ai';
import { BsBoxArrowUpRight, BsFillTelephoneFill } from 'react-icons/bs';
import { CgWebsite } from 'react-icons/cg';
import { IoMdContact } from 'react-icons/io';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { getApi, postApi } from '../../services/api';
import Modal from 'react-modal';
import { showSnack } from 'react-redux-snackbar';
import { Strings } from '../vendors/config';
// import ImgsViewer from 'react-images-viewer';
import { Document, Page } from 'react-pdf';
// import LightBox from 'react-awesome-lightbox';
// import 'react-awesome-lightbox/build/style.css';
import HeaderComponent from '../../components/Header';
import './index.css';
import { Table } from 'antd';

const VendorContent = () => {
	const [responseModal, setResponseModal] = useState(false);
	const [vendorList, setVendorList] = useState([]);
	const [responseList, setResponseList] = useState([]);
	const [fileModal, setFileModal] = useState(false);
	const [numPages, setNumPages] = useState(null);
	const [fileUrl, setFileUrl] = useState(null);
	const [view, setView] = useState(null);
	useEffect(() => {
		const fetchVendorList = async () => {
			try {
				const resp = await getApi('/vendorForm/list');
				if (resp.type === 'success') {
					setVendorList(resp.vendorsList);
					console.log('response', resp);
				}
				throw new Error(resp.error);
			} catch (e) {
				// dispatch(
				//   showSnack("ERROR_MESSAGE", {
				//     label: e.message,
				//     timeout: 7000,
				//   })
				// );
			}
		};
		fetchVendorList();
	}, []);

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const customStyles = {
		content: {
			top: '50%',
			height: '100vh',
			left: '50%',
			transform: 'translate(10%, -50%)',
			backgroundColor: '#ffffff',
			boxShadow: '12px 12px 8px rgba(85, 85, 85, 0.12)',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	const customStylesFile = {
		content: {
			top: '50%',
			height: '100vh',
			width: '75vw',
			left: '50%',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#ffffff',
			boxShadow: '12px 12px 8px rgba(85, 85, 85, 0.12)',
			zIndex: '3000',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	const vendorResponseShowForm = async (vendorId) => {
		try {
			const resp = await getApi(`/vendorForm/${vendorId}`);
			if (resp.type === 'success') {
				console.log(resp.vendorData);

				setResponseList(resp.vendorData);
				setResponseModal(true);
			}
			// else {
			//   return dispatch(
			//     showSnack("VENDOR_RESPONSE_FORM_ADDED", {
			//       label: resp.error,
			//       timeout: 7000,
			//     })
			//   );
			// }
		} catch (error) {
			return dispatch(
				showSnack('VENDOR_RESPONSE_FORM_ADDED', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};

	const viewFileHandler = async (vendorId, sectionId, questionId) => {
		setFileModal(true);

		const data = {
			sectionId,
			questionId,
			vendorId,
		};

		try {
			const resp = await postApi(data, '/vendorForm/vendor-files-view');
			if (resp.type === 'success') {
				console.log(resp);
				setFileUrl(resp.url);
			} else {
				return dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (error) {
			return dispatch(
				showSnack('VENDOR_RESPONSE_FORM_ADDED', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};
	// const onDocumentLoadSuccess = ({ numPages }) => {
	//   setNumPages(numPages);
	// };

	const fileTypes = fileUrl?.substring(fileUrl.lastIndexOf('.') + 1);
	const url = fileTypes?.split('?');
	// const urltype = Array.isArray(url);
	// if (urltype) return url[0];
	console.log('url', fileTypes, url);

	const tableColumns = [
		{
			title: 'Vendor Company Name',
			dataIndex: 'companyName',
			key: '1',
		},
		{
			title: 'Vendor Type',
			dataIndex: 'vendorType',
			key: '2',
		},
		{
			title: 'POC',
			dataIndex: 'POC',
			key: '3',
		},
		{
			title: 'Contact',
			dataIndex: 'Contact',
			key: '4',
		},
		{
			title: 'Email Id',
			dataIndex: 'emailId',
			key: '5',
		},
		{
			title: 'Website',
			dataIndex: 'Website',
			key: '6',
		},
		{
			title: 'Actions',
			dataIndex: 'actions',
			key: '7',
		},
	]

	const tableData = vendorList?.map((el, index) => (
		{
			key: index,
			companyName: el.vendorName,
			vendorType: el.vendorType,
			POC: el.pointOfContact,
			Contact: el.contactNumber,
			emailId: el.email,
			Website: el.website,
			actions: <BsBoxArrowUpRight
				onClick={() => vendorResponseShowForm(el._id)}
				style={{ fontSize: '12px', cursor: 'pointer' }}
			/>,
		}
	))

	return (
		<>
			<HeaderComponent />

			<h3 className="vendorRegistrationList">Vendor Registration</h3>

			<div className="vendor-content-container">
				<button className="vendorList">Vendors</button>
				<div className="add-vendor-btn">
					<button onClick={() => navigate('/vendor-registration')}>Create Form</button>
				</div>

				<div className='vendor-table'>
					<Table bordered scroll={{ x: 900 }} dataSource={tableData} columns={tableColumns} />
				</div>

				{/* <table>
					<thead>
						<tr className="table-heading-container">
							<th className="table-heading">Vendor Company Name</th>
							<th className="table-heading">Vendor Type</th>
							<th className="table-heading">POC</th>
							<th className="table-heading">Contact</th>
							<th className="table-heading">Email Id</th>
							<th className="table-heading">Website</th>
							<th className="table-heading"></th>
						</tr>
					</thead>

					{vendorList?.map((vendor) => {
						return (
							<tbody>
								<tr className="table-data-container">
									<td className="table-data">{vendor.vendorName}</td>
									<td className="table-data">{vendor.vendorType}</td>
									<td className="table-data">{vendor.pointOfContact}</td>
									<td className="table-data">{vendor.contactNumber}</td>
									<td className="table-data">{vendor.email}</td>
									<td className="table-data">{vendor.website}</td>
									<td className="table-data">
										<BsBoxArrowUpRight
											onClick={() => vendorResponseShowForm(vendor._id)}
											style={{ fontSize: '12px', cursor: 'pointer' }}
										/>
									</td>
								</tr>
							</tbody>
						);
					})}
				</table> */}
				<Modal
					contentLabel="Respone Modal"
					onRequestClose={() => setResponseModal(false)}
					isOpen={responseModal}
					ariaHideApp={false}
					style={customStyles}
				>
					<div className="responseModalContainer">
						<div className="vendorBasicInfo">
							<div className="vendorCompanyDetails">
								<h3>{responseList.vendorName}</h3>
								<h5>{responseList.vendorType}</h5>
							</div>
							<div className="vendorContantInfo">
								<h3>
									<IoMdContact />
									{responseList.pointOfContact}
								</h3>
								<h3>
									<BsFillTelephoneFill />
									{responseList.contactNumber}
								</h3>
							</div>
							<div className="vendorEmailInfo">
								<h3>
									<AiFillMail />
									{responseList.email}
								</h3>
								<h3>
									<CgWebsite />
									{responseList.website}
								</h3>
							</div>
						</div>

						{responseList?.sections?.map((section) => {
							console.log(responseList, section);
							return (
								<div className="vendorCustomInfo">
									<h3>{section.name}</h3>
									{section?.questions.map((question) => {
										return (
											<>
												{question.qtype === Strings.QUESTION_ANSWER && (
													<div className="vendorLongAns">
														<h3 className="question">{question.question}</h3>
														<h3 className="answer">{question.answer}</h3>
													</div>
												)}
												{question.qtype === Strings.MULTIPLE_CHOICE && (
													<div className="vendorMCQ">
														<h3 className="question">{question.question}</h3>
														<div className="mcq">
															{question.choices.map((eachChoice, index) => {
																console.log(
																	question?.mcqAnswer?.includes(eachChoice.choice)
																);
																if (question?.mcqAnswer?.includes(eachChoice.choice)) {
																	return (
																		<div className="checkedAnswer">
																			<span>{eachChoice.choice}</span>
																		</div>
																	);
																} else {
																	return (
																		<div className="uncheckedAnswer">
																			<span>{eachChoice.choice}</span>
																		</div>
																	);
																}
															})}
														</div>
														{/* <p>{question.answer}</p> */}
													</div>
												)}
												{question.qtype === Strings.UPLOAD_FILE && (
													<div className="vendorUpload">
														<h3 className="question">{question.question}</h3>
														<button
															onClick={() => {
																viewFileHandler(
																	responseList._id,
																	section._id,
																	question._id
																);
															}}
														>
															View File
														</button>

														<Modal
															contentLabel="File Modal"
															onRequestClose={() => setFileModal(false)}
															isOpen={fileModal}
															ariaHideApp={false}
															style={customStylesFile}
														>
															{url && url[0] == 'pdf' ? (
																<Document file={fileUrl}>
																	{/* {Array.from(new Array(numPages), (el, index) => (
                                    <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                                  ))} */}
																</Document>
															) : url?.includes('jpeg') ||
																url?.includes('jpg') ||
																url?.includes('png') ? (
																<img src={fileUrl} />
															) : null}
															{/* hi{(fileModal.fileType, question.answer)} */}
														</Modal>
													</div>
												)}
											</>
										);
									})}
								</div>
							);
						})}
					</div>
				</Modal>
			</div>
		</>
	);
};

export default VendorContent;
