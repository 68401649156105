import React from 'react';

import AsyncSelect from 'react-select/async';
import { searchEmployeeApi } from '../../services/employee';
import './index.css';
import { useNavigate } from 'react-router-dom';

export const SearchEmployeeSelct = ({ setEmployeeId }) => {
	const navigate = useNavigate();
	const promiseOptions = async (inputValue) => {
		const emplList = await searchEmployeeApi(inputValue);
		const resp = emplList.map((el) => ({ value: el.empId, label: `${el.name}-${el.email ? el.email : ''}` }));
		console.log(resp, 'resp');
		return resp;
	};

	return (
		<div className="new-email-add">
			<h4>Employee Section</h4>
			<AsyncSelect
				placeholder={'Search Employee By Name/Email'}
				cacheOptions
				defaultOptions
				onChange={(data) => setEmployeeId(data.value)}
				loadOptions={promiseOptions}
				className="select-emp"
			/>
			<div className="create-new-container">
				Employee Not Registered?{' '}
				<span onClick={() => navigate('/account-settings?view=employee-setup')} className="highlighter">
					Create Employee
				</span>{' '}
			</div>
		</div>
	);
};
