import * as React from 'react';
const SkillSlaskImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={172} height={61} fill="none" {...props}>
		<path
			fill="#888"
			d="M48.946 26.619c-2.269 0-4.027-.044-5.78.015-1.103.034-1.54-.773-2.084-1.438-2.006-2.434-4.017-4.857-5.955-7.34-.733-.942-1.146-.81-2.06-.111-2.302 1.758-2.71 
4.07-2.35 6.659.417 3.04-1.768 2.07-3.255 2.22-1.646.17-2.593-.04-2.54-2.22.17-6.95.122-13.906.02-20.857-.025-1.724.476-2.355 2.156-2.103a6.19 6.19 0 0 0 1.695 0c1.453-.19 
2.001.316 1.943 1.865-.117 3.06-.034 6.13-.034 9.404 1.443-.418 1.996-1.56 2.793-2.356 2.001-1.996 4.197-3.886 5.814-6.169 2.312-3.273 5.328-2.914 8.845-2.525-3.109 3.341-5.97 
6.518-8.952 9.578-1.054 1.083-1.146 1.778-.131 2.997 3.245 3.9 6.343 7.932 9.875 12.38ZM10.997.934c2.55.121 4.988.529 7.324 1.588 2.414 1.098.355 2.4.2 3.58-.19 1.408-.987 
1.258-2.07.752-2.394-1.112-4.935-1.588-7.562-1.03-1.39.297-2.691.86-2.784 2.575-.087 1.67 1.186 2.186 2.448 2.56 2.322.69 4.697 1.2 7.004 1.933 3.493 1.112 5.13 3.152 5.193 
6.198.068 3.244-1.642 5.809-4.979 6.99-4.988 1.762-9.884 1.184-14.605-1.05-2.536-1.2-.165-2.516.053-3.745.272-1.544 1.161-.99 2.089-.5 2.632 1.404 5.46 2.016 8.437 1.627 
1.481-.194 2.982-.602 3.24-2.385.281-1.957-1.327-2.409-2.696-2.846-1.919-.612-3.9-1.04-5.834-1.608C2.176 14.33.325 12.246.36 8.812.393 5.28 2.671 2.59 6.693 1.546c1.394-.36 
2.866-.418 4.304-.612ZM63.663 13.964c0-3.477.102-6.96-.039-10.428-.073-1.768.554-2.331 2.2-2.088.394.058.817.063 1.21 0 1.85-.287 2.477.359 2.419 2.326-.15 5.333.005 
10.671-.083 16.01-.029 1.656.408 2.297 2.157 2.224 2.987-.131 5.989.078 8.971-.073 2.113-.107 1.593 1.263 1.608 2.366.014 1.136.437 2.418-1.632 
2.365-5.013-.126-10.025-.083-15.038-.015-1.355.02-1.831-.359-1.797-1.768.082-3.638.024-7.28.024-10.918ZM85.603 13.839c0-3.473.058-6.95-.03-10.419-.033-1.413.297-2.151 
1.856-1.947a7.36 7.36 0 0 0 1.933 0c1.52-.21 1.904.422 1.88 1.894-.082 5.493.03 10.987-.073 16.475-.029 1.671.394 2.264 2.133 2.176 2.982-.14 5.984.093 8.961-.082 2.089-.127 
1.671 1.175 1.676 2.322 0 1.15.398 2.418-1.652 2.37-5.007-.117-10.015-.088-15.023-.005-1.496.024-1.714-.622-1.695-1.88.073-3.633.034-7.27.034-10.904ZM57.557 14.024c0 3.55-.102 
7.1.044 10.647.068 1.68-.53 2.137-2.045 1.972a9.876 9.876 0 0 0-2.176 0c-1.336.15-1.583-.457-1.574-1.676.054-7.257.064-14.518-.004-21.775-.015-1.394.417-1.89 1.773-1.739a9.058 
9.058 0 0 0 2.175-.004c1.565-.204 1.885.505 1.846 1.938-.102 3.536-.039 7.086-.039 10.637ZM171.444 51.226c-3.997.724-7.596 2.623-11.399 3.915-4.819 1.637-9.579 3.443-14.324 
5.29-1.559.606-1.899.28-1.924-1.341-.068-5.373-.121-5.353 4.984-7.14 4.162-1.458 8.306-2.973 
13.163-4.712-5.489-2.118-10.22-4.114-15.072-5.736-2.39-.802-3.492-1.91-3.065-4.464.185-1.093.029-2.244.029-3.725 4.61 1.738 8.967 3.375 13.314 5.031 4.153 1.584 8.296 3.187 
12.444 4.78.592.228 1.17.583 1.85.335v7.767ZM9.715 47.237c5.547 1.967 10.394 3.657 15.222 5.416 1.088.393 2.779.306 2.973 1.69.282 1.977.253 4.06-.049 6.032-.165 
1.084-1.238.195-1.797-.004-7.14-2.584-14.27-5.202-21.386-7.85-4.935-1.84-4.891-1.865-4.541-7.212.068-1.06.466-1.598 1.418-1.963a6391.973 6391.973 0 0 0 24.893-9.534c1.069-.413 
1.734-.37 1.584 1.025a1.34 1.34 0 0 0 0 .242c.208 5.013.228 5.062-4.391 6.888-4.391 1.734-8.826 3.346-13.926 5.27ZM140.329 47.039c0 3.56-.097 7.12.044 10.676.063 1.647-.481 
2.186-2.03 1.987a6.192 6.192 0 0 0-1.695 
0c-1.686.247-2.269-.384-2.147-2.108.15-2.172-.044-4.367.063-6.548.068-1.428-.486-1.792-1.822-1.748-2.666.082-5.343.111-8.004-.01-1.545-.068-1.943.51-1.875 1.952.102 2.259-.039 
4.532.053 6.79.049 1.249-.296 1.832-1.607 1.667-.476-.059-.982-.073-1.453.005-2.054.33-2.754-.33-2.7-2.575.175-6.868.121-13.745.024-20.623-.024-1.647.495-2.04 2.055-2.02 
3.652.043 3.652-.035 3.652 3.734 0 1.535.063 3.075-.019 4.61-.063 1.229.311 1.743 1.627 1.71 2.827-.078 5.663-.093 8.49.004 1.38.049 1.603-.553 
1.564-1.724-.068-2.18.058-4.371-.048-6.547-.068-1.38.383-1.768 1.753-1.783 4.07-.044 4.066-.107 4.066 4.06.009 2.832.009 5.66.009 8.491ZM91.267 59.687c-.86 
0-1.267-.053-1.656.01-3.08.505-5.314-.296-5.94-3.716-.282-1.535-1.336-1.743-2.691-1.7-2.42.083-4.857.19-7.262-.029-2.088-.184-3.011.666-3.662 2.516-.947 2.686-3.517 
3.794-6.164 2.895-1.404-.476-.238-1.277-.063-1.68 2.36-5.48 4.814-10.92 7.247-16.364.82-1.841 1.788-3.629 2.458-5.518.893-2.516 2.929-1.307 4.507-1.574 1.365-.233 2.113.272 
2.715 1.657 3.366 7.747 6.878 15.426 10.511 23.503ZM77.09 40.147c-.748 1.763-1.253 2.939-1.748 4.124-2.531 6.013-2.526 6.018 4.065 5.61.16-.01.32-.015.48-.01.958.024 
1.08-.495.773-1.234-1.102-2.652-2.23-5.304-3.57-8.49ZM42.977 34.124c1.7-.024 4.027.5 6.339 1.35 2.288.84.903 2.128.558 3.264-.335 1.108-.665 2.06-2.302 
1.195-2.171-1.151-4.61-1.52-7.077-1.141-1.578.242-3.113.782-3.215 2.71-.097 1.773 1.38 2.23 2.705 2.618 2.25.656 4.547 1.146 6.78 1.846 3.328 1.044 5.043 3.103 5.174 5.984.145 
3.118-1.467 5.775-4.595 7.018-5.207 2.07-10.375 1.477-15.242-1.015-2.268-1.16.185-2.56.389-3.847.218-1.355 1.078-.719 1.797-.33 2.71 1.457 5.595 2.132 8.66 1.705 1.448-.204 
2.997-.598 3.187-2.424.199-1.923-1.36-2.39-2.755-2.817-2.006-.612-4.055-1.074-6.061-1.676-3.91-1.175-5.664-3.113-5.76-6.256-.108-3.536 1.728-6.086 5.405-7.46 1.69-.642 
3.473-.72 6.013-.724ZM102.924 34.127c2.264-.01 4.182.195 6.038.836 3.196 1.107 3.225 1.277 1.753 4.386-.451.952-.825 
1.127-1.792.67-2.225-1.05-4.59-1.583-7.062-1.268-1.662.214-3.313.656-3.483 2.7-.16 1.93 1.462 2.323 2.846 2.73 2.244.661 4.547 1.128 6.776 1.837 3.24 1.034 4.94 3.171 5.052 
6.066.111 2.949-1.521 5.64-4.493 6.84-5.183 2.083-10.327 1.539-15.237-.89-2.36-1.165-.04-2.584.165-3.856.233-1.467 1.112-.836 1.928-.394 2.778 1.506 5.751 2.152 8.889 1.657 
1.379-.219 2.788-.72 2.953-2.405.175-1.758-1.248-2.331-2.56-2.749-1.996-.631-4.051-1.069-6.062-1.656-4.284-1.248-6.071-3.288-6.032-6.786.039-3.55 2.312-6.134 6.319-7.261 
1.418-.408 2.866-.394 4.002-.457ZM55.78 60.896c-.592 0-1-.04-1.398.004-1.812.2-1.744-.529-1.122-1.904 3.652-8.101 7.227-16.232 10.836-24.349.66-1.481 5.012-2.35 
6.071-1.21.423.458.04.895-.145 1.312-3.697 8.345-7.422 16.675-11.085 25.034-.757 1.73-2.18.904-3.157 1.113Z"
		/>
	</svg>
);
export default SkillSlaskImg;
