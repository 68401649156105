import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/IT-tracking/Header';
import Logo from '../../../images/assetze-logo.png';
import Intro from '../../../images/assetze-intro.png';

const GetStarted = function () {
	const navigate = useNavigate();

	// gs: get started
	return (
		<div className="getstarted-container">
			<Header />
			<img className="logo" src={Logo} />
			<div className="tagline">Fixing something that’s broken is now easier than ever!</div>
			<img className="intro" src={Intro} />
			<button className="login">LOG IN</button>
			<hr />
			<div className="ps">
				P.S: You’ll be able to login or access Assetze if your organisation has already registered on our portal
			</div>
			<div className="note">If you are an Admin and want to register your company on Assetze</div>
			<button
				className="register"
				onClick={() => {
					navigate('/register');
				}}
			>
				Get Started
			</button>
		</div>
	);
};

export default GetStarted;
