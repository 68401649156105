import React from 'react'
import { IoChevronBack } from "react-icons/io5";
import './index.css'
import { useNavigate } from 'react-router-dom';

const MobileHeader = () => {
    const navigate = useNavigate();
    return (
        <div className='header-div'>
            <div className='icon-div'>
                <IoChevronBack style={{ fontSize: "30px" }} onClick={() => { navigate(-1) }} />
            </div>
            Assetze
        </div>
    )
}

export default MobileHeader