import React from 'react';

function DownloadIcon(props) {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14" {...props}>
			<g fillRule="evenodd" clipPath="url(#clip0_6713_1875)" clipRule="evenodd">
				<path d="M.486 10.916a.5.5 0 01.627.329 2.507 2.507 0 001.724 1.666.5.5 0 01-.264.965 3.506 3.506 0 01-2.415-2.334.5.5 0 01.328-.626zM13.514 10.916a.5.5 0 01.328.626 3.506 3.506 0 01-2.415 2.334.5.5 0 11-.264-.965 2.507 2.507 0 001.725-1.666.5.5 0 01.626-.33zM5.5.5A.5.5 0 016 0h2a.5.5 0 010 1H6a.5.5 0 01-.5-.5zm0 13A.5.5 0 016 13h2a.5.5 0 010 1H6a.5.5 0 01-.5-.5zM.5 5.5A.5.5 0 011 6v2a.5.5 0 01-1 0V6a.5.5 0 01.5-.5zM13.5 5.5a.5.5 0 01.5.5v2a.5.5 0 11-1 0V6a.5.5 0 01.5-.5zM3.187.474a.5.5 0 01-.35.615 2.506 2.506 0 00-1.724 1.666.5.5 0 01-.955-.297A3.506 3.506 0 012.573.124a.5.5 0 01.614.35zM10.813.474a.5.5 0 01.614-.35 3.506 3.506 0 012.415 2.334.5.5 0 11-.954.297 2.506 2.506 0 00-1.725-1.666.5.5 0 01-.35-.615zM7 3.5a.5.5 0 01.5.5v6a.5.5 0 01-1 0V4a.5.5 0 01.5-.5z"></path>
				<path d="M4.646 7.646a.5.5 0 01.708 0L7 9.293l1.646-1.647a.5.5 0 11.708.708l-2 2a.5.5 0 01-.708 0l-2-2a.5.5 0 010-.708z"></path>
			</g>
			<defs>
				<clipPath id="clip0_6713_1875">
					<path fill="#fff" d="M0 0H14V14H0z"></path>
				</clipPath>
			</defs>
		</svg>
	);
}

export default DownloadIcon;
