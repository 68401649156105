import React, { useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/IT-tracking/Header';
import Mac from '../../../images/macbook.png';
import Dell from '../../../images/dell.png';
import { BiDotsVertical } from 'react-icons/bi';
import RequestPending from '../../../images/Frame.png';
// import { Timeline, Container, YearContent, BodyContent, Section, Description } from 'vertical-timeline-component-react';
// import HorizontalTimeline from 'react-horizontal-timeline';
import { BsCircle, BsCircleFill } from 'react-icons/bs';

const EXAMPLE = [
	{
		data: '2019-12-05',
		status: 'status',
		statusB: 'Admission Start',
		statusE: 'Admission Open',
	},
	{
		data: '2020-01-21',
		status: 'status',
		statusB: 'Start 1st round',
		statusE: 'Open for Fillup',
	},
	{
		data: '2020-02-25',
		status: 'status',
		statusB: 'Start 2nd round',
		statusE: 'process',
	},
	{
		data: '2020-03-16',
		status: 'status',
		statusB: 'Start 3rd round',
		statusE: 'Done',
	},
	{
		data: '2020-04-19',
		status: 'status',
		statusB: 'Start 4th round',
		statusE: 'Done',
	},
	{
		data: '2020-05-23',
		status: 'status',
		statusB: 'Complete',
		statusE: 'Done',
	},
];

const RequestStatus = function () {
	const [status, setStatus] = useState('req-raised');

	const [curIndex, setCurIndx] = useState(0);
	const [prevIdx, setPrevIdx] = useState(-1);
	const curStatus = EXAMPLE[curIndex].statusB;
	const prevStatus = prevIdx >= 0 ? EXAMPLE[prevIdx].statusB : '';

	const customTheme = {
		yearColor: '#405b73',
		lineColor: '#A9F4F5',
		dotColor: '#A9F4F5',
		borderDotColor: '#A9F4F4',
		titleColor: '#405b73',
		subtitleColor: '#bf9765',
		textColor: '#262626',
	};
	// gs: get started
	return (
		<div className="mydevices-container">
			<Header />
			<div className="heading">Your Devices</div>
			<div className="devices">
				<div className="dev1">
					<div className="dev-img">
						<img src={Mac} />
					</div>
					<div className="dev-details">
						<div>Asset type: Macbook Pro A1398</div>
						<div>Serial No. : C02STQ8VASG31</div>
						<div>Processor : Intel i7 2.2Ghz</div>
						<div>Ram : Apple 16GB 3200Mhz</div>
						<div>HDD : Samsung 512GB</div>
					</div>
					<div>
						<BiDotsVertical className="dots" />
					</div>
				</div>
			</div>
			{/* 
                
                 <div className="timeline">
                    <Timeline theme={customTheme} dateFormat='full' >
            <Container>
            <YearContent startDate='2020/07/01' />
            <BodyContent>
            <Section title='Request raised'>
            <Description text='Description' />
            </Section>

            <Section title='Admin assignment'>
            <Description text='Unassigned' />
            </Section>

            <Section title='Query status'>
                <Description text='Unsolved' />
            </Section>
            </BodyContent>
            </Container>
        </Timeline>
            </div>
                
                */}

			{/* 
                     <div
          style={{
            width: "80%",
            height: "100px",
            margin: "0 auto",
            marginTop: "20px",
            fontSize: "15px"
          }}
        >
          <HorizontalTimeline
            styles={{
              background: "#f8f8f8",
              foreground: "#A9F4F5",
              outline: "#A9F4F5"
            }}
            index={curIndex}
            indexClick={(index) => {
              const curIdx = curIndex;
              setCurIndx (index);
                setPrevIdx(curIdx);
            }}
            values={EXAMPLE.map((x) => x.data)}
          />
        </div>
        <div className="text-center">
          {// Prevoius:-{prevStatus} - Current Select:-{curStatus} }
          {curStatus}
        </div>
                */}

			{status === 'req-raised' ? (
				<div>
					<div className="status">STATUS: Request Raised</div>
					<div className="timeline">
						<div className="left">
							<BsCircle className="active" />
							<hr />
							<BsCircleFill className="fill" />
							<hr />
							<BsCircleFill className="fill" />
						</div>
						<div className="right">
							<div className="st1">
								<div className="status1">Request raised</div>
								<div className="timestamp">At 02:46 PM, Friday 15th January 2022</div>
							</div>
							<div className="st2">
								<div className="status1">Admin assignment</div>
								<div className="timestamp">Unassigned</div>
							</div>
							<div className="st2">
								<div className="status1">Query status</div>
								<div className="timestamp">Unassigned</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
			{status === 'admin-assigned' ? (
				<div>
					<div className="status">STATUS:Admin Assigned</div>
					<div className="timeline">
						<div className="left">
							<BsCircleFill className="fill" />
							<hr />
							<BsCircle className="active" />
							<hr />
							<BsCircleFill className="fill" />
						</div>
						<div className="right">
							<div className="st1">
								<div className="status1">Request raised</div>
								<div className="timestamp">At 02:46 PM, Friday 15th January 2022</div>
							</div>
							<div className="st1">
								<div className="status1">Admin assignment</div>
								<div className="timestamp">Assigned to Ravi</div>
							</div>
							<div className="st2">
								<div className="status1">Query status</div>
								<div className="timestamp">Unassigned</div>
							</div>
						</div>
					</div>
				</div>
			) : null}

			{status === 'in-progress' ? (
				<div>
					<div className="status">STATUS: In Progress</div>
					<div className="timeline">
						<div className="left">
							<BsCircleFill className="fill" />
							<hr />
							<BsCircleFill className="fill" />
							<hr />
							<BsCircle className="active" />
							<hr />
							<BsCircleFill className="fill" />
						</div>
						<div className="right">
							<div className="st1">
								<div className="status1">Request raised</div>
								<div className="timestamp">At 02:46 PM, Friday 15th January 2022</div>
							</div>
							<div className="st1">
								<div className="status1">Admin assignment</div>
								<div className="timestamp">Assigned to Ravi</div>
							</div>
							<div className="st1">
								<div className="status1">Query status</div>
								<div className="timestamp">In Progress</div>
							</div>
							<div className="st1">
								<div className="status1">Resolved : Monday, 17th January 2022</div>
							</div>
						</div>
					</div>
				</div>
			) : null}

			{status === 'resolved' ? (
				<div>
					<div className="status">STATUS</div>
					<div className="timeline">
						<div className="left">
							<BsCircleFill className="fill" />
							<hr />
							<BsCircleFill className="fill" />
							<hr />
							<BsCircle className="active" />
						</div>
						<div className="right">
							<div className="st1">
								<div className="status1">Request raised</div>
								<div className="timestamp">At 02:46 PM, Friday 15th January 2022</div>
							</div>
							<div className="st1">
								<div className="status1">Admin assignment</div>
								<div className="timestamp">Assigned to Ravi</div>
							</div>
							<div className="st1">
								<div className="status1">Query status</div>
								<div className="timestamp">Resolved: Monday, 17th January 2022</div>
							</div>
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default RequestStatus;
