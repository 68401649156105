import React, { useState } from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/IT-tracking/Header';
import Mac from '../../../images/macbook.png';
import Dell from '../../../images/dell.png';
import { BiDotsVertical } from 'react-icons/bi';
import RequestPending from '../../../images/Frame.png';

const ServiceRequest = function () {
	const navigate = useNavigate();
	const [view, setView] = useState('submit-req');
	// gs: get started
	return (
		<div>
			{view === 'submit-req' ? (
				<div className="mydevices-container">
					<Header />
					<div className="heading">Your Devices</div>
					<div className="devices">
						<div className="dev1">
							<div className="dev-img">
								<img src={Mac} />
							</div>
							<div className="dev-details">
								<div>Asset type: Macbook Pro A1398</div>
								<div>Serial No. : C02STQ8VASG31</div>
								<div>Processor : Intel i7 2.2Ghz</div>
								<div>Ram : Apple 16GB 3200Mhz</div>
								<div>HDD : Samsung 512GB</div>
							</div>
							<div>
								<BiDotsVertical className="dots" />
							</div>
						</div>
					</div>
					<hr />
					<div className="issue">
						<div className="problem">Problem</div>
						<input placeholder="Software Issue" />
						<div>Describe the issue</div>
						<input className="description" placeholder="Enter the issue that you faced here" />
						<div>Issue start date</div>
						<input />
						<div>Priority</div>
						<input />
					</div>
					<button
						className="button"
						onClick={() => {
							setView('serve-req');
						}}
					>
						Submit
					</button>
				</div>
			) : null}

			{view == 'serve-req' ? (
				<div className="mydevices-container">
					<Header />
					<div className="heading">Your Devices</div>
					<div className="devices">
						<div className="dev1">
							<div className="dev-img">
								<img src={Mac} />
							</div>
							<div className="dev-details">
								<div>Asset type: Macbook Pro A1398</div>
								<div>Serial No. : C02STQ8VASG31</div>
								<div>Processor : Intel i7 2.2Ghz</div>
								<div>Ram : Apple 16GB 3200Mhz</div>
								<div>HDD : Samsung 512GB</div>
							</div>
							<div>
								<BiDotsVertical className="dots" />
							</div>
						</div>
					</div>
					<hr />
					<img src={RequestPending} />
					<div className="assurance">Don't Worry! We've got you</div>
					<div className="support"> Your query is delivered to your organization IT admin</div>
					<div className="support">You can track the status below</div>
					<button className="button">Check Status</button>
				</div>
			) : null}
		</div>
	);
};

export default ServiceRequest;
