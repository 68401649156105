import React from 'react';
import './index.css';
import Submit from './Submit.png';

const ResponseForm = () => {
	return (
		<div className="responseFormContainer">
			<h1>Thank you for submitting the form</h1>
			<img src={Submit} alt="submit" className="submit-img" />
		</div>
	);
};

export default ResponseForm;
