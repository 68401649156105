import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducer';
import { snackbarReducer } from 'react-redux-snackbar'; // Import it

const composeEnhancers =
	typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
				// Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		  })
		: compose;
//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const enhancer = composeEnhancers(applyMiddleware(thunkMiddleware));

const reducers = {
	appreducer: rootReducer,
	snackbar: snackbarReducer,
};

const reducer = combineReducers(reducers);

const store = createStore(reducer, enhancer);

export default store;
