import React from 'react';
import './index.css';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import { MapContainer, TileLayer, useMap, Marker, Popup } from 'react-leaflet';

const CustomMarker = ({ position, children }) => {
	const map = useMap();

	const customIcon = L.icon({
		iconUrl: 'https://simpleicon.com/wp-content/uploads/map-marker-1.svg',
		iconSize: [50, 50],
		iconAnchor: [25, 50],
	});

	return (
		<Marker position={position} icon={customIcon}>
			{children}
		</Marker>
	);
};

export default function MapViewUI({ devlocationInfo }) {
	const position = [devlocationInfo.Latitude, devlocationInfo.Longitude];
	return (
		<div className="llc-asset-location">
			<MapContainer center={position} zoom={17} scrollWheelZoom={false}>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>
				<CustomMarker position={position}>
					<Popup>This is a custom marker popup</Popup>
				</CustomMarker>
			</MapContainer>
		</div>
	);
}
