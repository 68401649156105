import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { HANDLE_INPUT_CHANGES } from '../../redux/actions';

const VendorDetails = () => {
	const vendorFormData = useSelector((state) => state.appreducer.vendorFormData);

	const dispatch = useDispatch();

	// const handleInputValue = (e) => {
	//   dispatch({ type: HANDLE_INPUT_CHANGES, payload: { name: e.target.name, value: e.target.value } });
	// };

	return (
		<div className="vendor-form">
			<h3>Vendor Details</h3>
			<div className="vendor-form-section">
				<div className="branch vendor-input">
					<span>Vendor Name</span>
					<input
					// value={vendorFormData?.vendorName}
					//  onChange={handleInputValue}
					// type="text"
					// name="vendorName"
					// id="vendorName"
					/>
				</div>
				<div className="branch vendor-input">
					<span>Vendor Type</span>
					<input
					// value={vendorFormData?.vendorType}
					// // onChange={handleInputValue}
					// type="text"
					// name="vendorType"
					// id="vendorType"
					/>
				</div>
			</div>
			<div className="vendor-form-section">
				<div className="branch vendor-input">
					<span>Point of Contact</span>
					<input
					// value={vendorFormData?.pointOfContact}
					// onChange={handleInputValue}
					// type="text"
					// name="pointOfContact"
					// id="pointOfContact"
					/>
				</div>
				<div className="branch vendor-input">
					<span>Email ID</span>
					<input
					// value={vendorFormData?.email}
					//  onChange={handleInputValue}
					// type="text"
					// name="email"
					// id="email"
					/>
				</div>
			</div>
			<div className="vendor-form-section">
				<div className="branch vendor-input">
					<span>Contact</span>
					<input
					// value={vendorFormData?.contactNumber}
					//  onChange={handleInputValue}
					// type="text"
					// name="contactNumber"
					// id="contactNumber"
					/>
				</div>
				<div className="branch vendor-input">
					<span>Website</span>
					<input
					// value={vendorFormData?.website}
					//  onChange={handleInputValue}
					// type="text"
					// name="website"
					// id="website"
					/>
				</div>
			</div>
			{/* <div className="vendor-modal-container">
        <button onClick={() => navigate("/vendor-form")}>Check Response</button>
      </div> */}
		</div>
	);
};

export default VendorDetails;
