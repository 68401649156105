import React, { useRef, useState, useCallback, useEffect, useReducer } from 'react';
import { useDropzone } from 'react-dropzone';
import Select from 'react-select';
import FilterIcon from '../../images/FilterIcon';
import './index.css';
import { BsSearch, BsFillCloudUploadFill } from 'react-icons/bs';
import { IoMdAdd } from 'react-icons/io';
import { BiArchive } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { download, fileUploadApi, getApi, postApi, uploadToS3 } from '../../services/api';
import { showSnack, dismissSnack } from 'react-redux-snackbar';
import _, { debounce } from 'lodash';
import AssetSettings from '../../images/asset-settings.png';
import { BiSearch, BiBarcodeReader } from 'react-icons/bi';
import { MdFilterAlt } from 'react-icons/md';
// import Drawer from '@mui/material/Drawer';
import { SiTask } from 'react-icons/si';
import { PiEyeThin } from 'react-icons/pi';
import { AiOutlineCloudDownload } from 'react-icons/ai';
import {
	getAssetsPagination,
	ADD_FILTER_TABLE,
	SET_ASSETS_RESULTS,
	SET_FILTER_TEXT,
	SET_SEARCH_RESULTS,
	setAssetColumns,
} from '../../redux/actions';
import { IoMdRadioButtonOff } from 'react-icons/io';
import { IoMdRadioButtonOn } from 'react-icons/io';
// import Modal from 'react-modal';
import { IoAddOutline } from 'react-icons/io5';
import { AiOutlineDelete } from 'react-icons/ai';
import uploadManually from '../../images/upload-manually.png';
import uploadExcelSheet from '../../images/upload-excel-sheet.png';
import { IoCheckbox } from 'react-icons/io5';
import { MdCheckBoxOutlineBlank } from 'react-icons/md';
import searchReducer from '../../redux/searchReducer';
import useDebounce from '../../hooks/useDebounce';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import SelectMUI, { SelectChangeEvent } from '@mui/material/Select';
import { CiViewColumn } from 'react-icons/ci';
// import Checkbox from '@mui/material/Checkbox';
import ReplaceAssetsData from './replaceAssets';
import { GrFormClose } from 'react-icons/gr';
import { DatePicker } from '@mui/x-date-pickers';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { fetchFilterData, generateAssetTag } from './fetchFilterData';
import { ShowFilterView } from './ShowFilterView';
import { addExtraColums } from '../../redux/addExtraColums';
import { assetTagUrl } from '../../services/constants';
import { IoFilter } from 'react-icons/io5';
import { IoMdSettings } from 'react-icons/io';
import {
	ARCHIEVE_VIEW,
	ASSET_CREATE,
	CREATE_REPORT,
	CheckPermission,
	FILTER_VIEW,
	GENERATE_TAG,
} from '../../utils/Permissions';
import { Button, Input, Drawer, Card, Collapse, Modal, Dropdown, Checkbox, Space, Divider } from 'antd';
const { Search } = Input;

function MyDropzone({ toggleModal }) {
	const [loading, setLoading] = useState(false);
	const [repeatedAssets, setRepeatedAssets] = useState(null);
	const hiddenFileInput = useRef(null);
	const dispatch = useDispatch();
	const uploadFile = useCallback(
		async (file) => {
			setLoading(true);
			try {
				const resp = await postApi(
					{ filename: file.name, mimetype: file.type },
					'/assets/upload-assets/signedurl'
				);
				if (resp.type === 'success') {
					await uploadToS3(resp.url, file.type, file);
					const serverResp = await postApi(
						{ filename: file.name },
						'/assets/upload-assets/readFile',
						assetTagUrl
					);

					if (serverResp.type === 'success') {
						dispatch(
							showSnack('FILE_UPLOAD_SUCCESS', {
								label: 'Successfully Added Assets',
								timeout: 7000,
							})
						);
						toggleModal();
						dispatch({
							type: 'RESET_ASSETS_TABLE',
							payload: Date.now() + '12312',
						});
					} else if (serverResp.type === 'error') {
						if (serverResp.payload && serverResp.payload.errorCode) {
							serverResp.payload.justCheck = true;
							const resp2 = await postApi(serverResp.payload, '/assets/reupload-assets');
							if (resp2.type === 'success') {
							} else if (resp2.errorCode === 'REQUIRES_REUPLOAD') {
								dispatch(
									showSnack('REQUIRES_REUPLOAD', {
										label: 'Requires Confirmation to Re Upload Assets',
										timeout: 7000,
									})
								);
								return setRepeatedAssets(serverResp.payload);
							} else if (resp2.errorCode === 'NO_NEED_OF_REUPLOAD') {
								return dispatch(
									showSnack('REQUIRES_REUPLOAD', {
										label: 'Assets are already upto date',
										timeout: 7000,
									})
								);
							}
						}
						dispatch(
							showSnack('FILE_UPLOAD_ERROR', {
								label: serverResp.error ? serverResp.error : serverResp.message,
								timeout: 7000,
							})
						);
					}
				}
				setLoading(false);
			} catch (e) {
				console.error(e);
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			} finally {
				setLoading(false);
			}
		},
		[dispatch, toggleModal]
	);
	const onDrop = useCallback(
		(acceptedFiles) => {
			if (acceptedFiles.length === 0)
				dispatch(
					showSnack('FILE_UPLOAD_FAILED', {
						label: 'Only Supported XLSX file types',
						timeout: 7000,
					})
				);
			uploadFile(acceptedFiles[0]);
		},
		[dispatch, uploadFile]
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
	});

	const downloadFile = (e) => {
		e.preventDefault();
		download(
			'https://assetze.s3.ap-south-1.amazonaws.com/download/Sample-Assetze+template.xlsx',
			'sample-assetze-file.xlsx'
		);
	};

	async function onFileChange(event) {
		const file = event.target.files[0];
		await uploadFile(file);
	}

	const handleButtonClick = () => {
		hiddenFileInput.current.click();
	};

	return (
		<div className="upload-excel">
			<RepeatedAssets repeatedAssets={repeatedAssets} />
			<span {...getRootProps()}>
				<img src={require('../../images/xlsx-icon.png')} />
				<div className="upload-caption">Upload Excel Sheet</div>

				<input {...getInputProps()} />
				<div className="upload-note">Note: Serial Numbers are mandatory</div>
				{isDragActive ? <div>Drop the files here ...</div> : null}
				<Button type="primary" size="large" className="upload-button" onClick={handleButtonClick}>
					<BsFillCloudUploadFill color={'#000'} />
					<input
						accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
						style={{ display: 'none' }}
						ref={hiddenFileInput}
						type="file"
						onChange={onFileChange}
					/>
					Upload
				</Button>
			</span>
			<p style={{ cursor: 'pointer' }} onClick={downloadFile}>
				Download Template
			</p>
			{loading ? (
				<p
					style={{
						color: '#fff',
						backgroundColor: 'black',
						padding: '10px 0px',
					}}
				>
					Uploading Assets...
				</p>
			) : null}
		</div>
	);
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const filterParameters = [
	{ key: 'Asset Type', value: 'type', _id: 1 },
	{ key: 'Warranty', value: 'warranty', _id: 2, options: ['Warranty Expired', 'Warranty Valid'] },
	{ key: 'IT/NON-IT', value: 'assetType', _id: 3, options: ['IT', 'NON-IT'] },
	{ key: 'Vendor', value: 'vendor', _id: 4 },
];

const RepeatedAssets = function ({ repeatedAssets }) {
	const [page, setPage] = useState('2');
	const [assetInfo, setAssetInfo] = useState(null);
	const dispatch = useDispatch();

	useEffect(() => {
		setAssetInfo(repeatedAssets);
		setPage('2');
	}, [repeatedAssets]);

	const closeModal = function () {
		setAssetInfo(null);
		setPage(null);
	};

	const gotToNext = function () {
		setPage('1');
	};
	const customStyles = {
		content: {
			top: '48%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			backgroundColor: '#ffffff',
			overflow: 'hidden',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	const submitForm = async function () {
		try {
			const resp = await postApi({ ...repeatedAssets, justCheck: false }, '/assets/reupload-assets');
			if (resp.type === 'success') {
				closeModal();
				dispatch(
					showSnack('REUPLOAD_ASSETS_SUCCESS', {
						label: 'Successfully reuploaded assets',
						timeout: 7000,
					})
				);
			} else {
				dispatch(
					showSnack('REUPLOAD_ASSETS_ERROR', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	return (
		<div>
			<Modal
				open={assetInfo}
				onCancel={() => {
					closeModal();
				}}
				footer={null}
				// contentLabel="Repeated Assets"
				// onRequestClose={() => {
				// 	closeModal();
				// }}
				// isOpen={!!assetInfo}
				// ariaHideApp={false}
				// style={customStyles}
			>
				{page === '1' ? (
					<div className="alert-container-cmp">
						<h3>Replace Assets</h3>
						<div className="description">
							Are you sure to replace the assets which were already exist in the system?
						</div>
						<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '15px' }}>
							<Button
								onClick={() => {
									closeModal();
								}}
								className={'no'}
							>
								Cancel
							</Button>
							<Button
								style={{ marginLeft: '10px' }}
								type={'primary'}
								onClick={() => submitForm()}
								className={'yes'}
							>
								Yes
							</Button>
						</div>
					</div>
				) : null}
				{page === '2' ? (
					<ReplaceAssetsData
						submitForm={() => setPage('1')}
						closeModal={closeModal}
						gotToNext={gotToNext}
						repeatedAssets={repeatedAssets}
					/>
				) : null}
			</Modal>
		</div>
	);
};

function MultipleSelectCheckmarks({ updateSelectedFilter }) {
	const [selectedFilters, setSelectedFilters] = React.useState([]);
	const [filterByParams, setFilterByParams] = React.useState(filterParameters);

	useEffect(() => {
		updateSelectedFilter(
			selectedFilters.map((el) => {
				for (let x = 0; x < filterByParams.length; x++) {
					if (filterByParams[x].key === el) {
						return filterByParams[x];
					}
				}
				throw new Error('Invalid Key');
			})
		);
	}, [selectedFilters]);

	useEffect(() => {
		async function getCustomParams() {
			const resp = await getApi('/assetze/asset-update/custom-keys');
			if (resp.type === 'success') {
				setFilterByParams(filterByParams.concat(resp.customKeys));
			}
		}
		getCustomParams();
	}, []);

	const handleChange = (event) => {
		const {
			target: { value },
		} = event;

		setSelectedFilters(typeof value === 'string' ? value.split(',') : value);
	};

	return (
		<div style={{ marginTop: '30px' }}>
			<FormControl sx={{ width: '100%' }}>
				<InputLabel id="demo-multiple-checkbox-label">Filter By</InputLabel>
				<SelectMUI
					labelId="demo-multiple-checkbox-label"
					id="demo-multiple-checkbox"
					multiple
					value={selectedFilters}
					onChange={handleChange}
					input={<OutlinedInput label="Filter By" />}
					renderValue={(selected) => selected.join(', ')}
					MenuProps={MenuProps}
				>
					{filterByParams.map((eachFilter) => (
						<MenuItem key={eachFilter._id} value={eachFilter.key}>
							<Checkbox checked={selectedFilters.indexOf(eachFilter.key) > -1} />
							<ListItemText primary={eachFilter.key} />
						</MenuItem>
					))}
				</SelectMUI>
			</FormControl>
		</div>
	);
}

export const allowedMethods = ['equalTo', 'containsTo'];

const filterValues = [
	{
		name: 'serialNumber',
		label: 'Serial Number',
		type: 'text',
		allowedMethods: [
			{ name: 'equalTo', label: 'is Equal To' },
			{ name: 'containsTo', label: 'is Contains' },
		],
		filterType: 'radio',
		multipleInputs: true,
		maximumInputs: 20,
	},
	{
		name: 'assetId',
		label: 'Asset Tag',
		type: 'text',
		allowedMethods: [
			{ name: 'equalTo', label: 'is Equal To' },
			{ name: 'containsTo', label: 'is Contains' },
		],
		filterType: 'radio',
		multipleInputs: true,
		maximumInputs: 20,
	},
	{
		name: 'employeeName',
		label: 'Employee Name',
		type: 'text',
		allowedMethods: [
			{ name: 'equalTo', label: 'is Equal To' },
			{ name: 'containsTo', label: 'is Contains' },
		],
		filterType: 'radio',
		multipleInputs: true,
		maximumInputs: 20,
	},
	{
		name: 'employeeId',
		label: 'Employee Id',
		type: 'text',
		allowedMethods: [
			{ name: 'equalTo', label: 'is Equal To' },
			{ name: 'containsTo', label: 'is Contains' },
		],
		filterType: 'radio',
		multipleInputs: true,
		maximumInputs: 20,
	},
	{
		name: 'type',
		label: 'Asset Type',
		type: 'text',
		allowedMethods: [
			{ name: 'equalTo', label: 'is Equal To' },
			{ name: 'containsTo', label: 'is Contains' },
		],
		filterType: 'radio',
		multipleInputs: true,
		maximumInputs: 20,
	},
	{
		name: 'vendor',
		label: 'Vendor',
		type: 'text',
		allowedMethods: [
			{ name: 'equalTo', label: 'is Equal To' },
			{ name: 'containsTo', label: 'is Contains' },
		],
		filterType: 'radio',
		multipleInputs: true,
		maximumInputs: 20,
	},
	{
		name: 'warranty',
		label: 'Warranty Expiry Date',
		type: 'date',
		allowedMethods: [
			{ value: 'startDate', label: 'Filter From Date', multiple: false },
			{ value: 'endDate', label: 'Filter To Date', multiple: false },
		],
		filterType: 'dropdown',
		multipleInputs: false,
	},
	{
		name: 'name',
		label: 'Asset Name',
		type: 'text',
		allowedMethods: [
			{ name: 'equalTo', label: 'is Equal To' },
			{ name: 'containsTo', label: 'is Contains' },
		],
		filterType: 'radio',
		multipleInputs: true,
	},
	{
		name: 'createdAt',
		label: 'Asset Added Date to Assetze',
		type: 'date',
		allowedMethods: [
			{ value: 'startDate', label: 'Filter From Date', multiple: false },
			{ value: 'endDate', label: 'Filter To Date', multiple: false },
		],
		filterType: 'dropdown',
		multipleInputs: false,
	},
	{
		name: 'assetAssignedDate',
		label: 'Asset Assigned Date',
		type: 'date',
		allowedMethods: [
			{ value: 'startDate', label: 'Filter From Date', multiple: false },
			{ value: 'endDate', label: 'Filter To Date', multiple: false },
		],
		filterType: 'dropdown',
		multipleInputs: false,
	},
];

const AddDateSelection = function ({ filterSelected, onCancel, addDateFilter }) {
	const [selectedField, setSelectedField] = useState(null);
	const [response, setResponse] = useState({});
	return (
		<>
			<LocalizationProvider dateAdapter={AdapterDateFns}>
				<DemoContainer components={['DateRangePicker']}>
					{filterSelected.allowedMethods.map((el, index) => (
						<DatePicker
							label={el.label}
							key={index}
							onChange={(value) => setResponse({ ...response, [el.value]: new Date(value) })}
						/>
					))}
				</DemoContainer>
			</LocalizationProvider>
			<div className="actions">
				<button onClick={onCancel} className="cancel">
					Cancel
				</button>
				<button
					onClick={() => {
						addDateFilter(response, filterSelected.type);
					}}
					className="apply"
				>
					Apply
				</button>
			</div>
		</>
	);
};

const FilterInfoAdd = ({ filterSelected, onCancel, onAdd }) => {
	const [filterValue, setFilterValue] = useState('');
	const [allFilterValues, setAllFilterValues] = useState([]);
	const [filterMethod, setFilterMethod] = useState('equalTo');
	const dispatch = useDispatch();
	const onChangeUpdate = (e) => setFilterValue(e.target.value);
	const onUpDatateSelectedRadio = (value) => () => setFilterMethod(value);
	const addDateFilter = (data, method) => {
		onAdd({ value: data, method, ...filterSelected });
	};
	const applyFilter = () => {
		if (allFilterValues.length === 0) {
			return dispatch(
				showSnack('FILTER_VALUE', {
					label: 'Please provide atleast one filter value',
					timeout: 7000,
				})
			);
		}
		onAdd({ value: allFilterValues, method: filterMethod, ...filterSelected });
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		e.stopPropagation();
		if (filterValue === '') {
			return dispatch(
				showSnack('FILTER_VALUE', {
					label: 'Please provide valid filter value',
					timeout: 7000,
				})
			);
		}

		if (allFilterValues.indexOf(filterValue) > -1) {
			return dispatch(
				showSnack('FILTER_VALUE', {
					label: 'Provided values is already exist in filter',
					timeout: 7000,
				})
			);
		}

		setAllFilterValues(allFilterValues.concat(filterValue));
		setFilterValue('');
	};
	const deleteFilterValue = (selectedValue) => () => {
		console.log(selectedValue, 'SASA');
		setAllFilterValues([...allFilterValues.filter((el) => selectedValue !== el)]);
	};

	return (
		<div className={'adding-filter'}>
			<div className={'head-1'}>{filterSelected.label}</div>
			<div className={'filter-body'}>
				{filterSelected.filterType === 'radio' ? (
					<>
						<div className="options-container">
							<div onClick={onUpDatateSelectedRadio('equalTo')} className="each-option">
								{filterMethod === 'equalTo' ? (
									<IoMdRadioButtonOn className="radio-icon" />
								) : (
									<IoMdRadioButtonOff className="radio-icon" />
								)}

								<div className="">is Equal To</div>
							</div>
							<div onClick={onUpDatateSelectedRadio('containsTo')} className="each-option">
								{filterMethod === 'containsTo' ? (
									<IoMdRadioButtonOn className="radio-icon" />
								) : (
									<IoMdRadioButtonOff className="radio-icon" />
								)}
								<div className="">is Contains</div>
							</div>
						</div>
						<form
							onSubmit={handleSubmit}
							// className="input-container"
						>
							<Input
								style={{ margin: '15px 0' }}
								type={'text'}
								onChange={onChangeUpdate}
								value={filterValue}
								placeholder={`Provide ${filterSelected.label}`}
							/>
						</form>
						<p>
							<i>Note: Press enter to add filter</i>
						</p>
						{allFilterValues.map((el) => (
							<div onClick={deleteFilterValue(el)} key={el} className={'each-filter-value'}>
								<div className="each-value">{el}</div>
								<GrFormClose style={{ fontSize: '20px' }} className="close-button" />
							</div>
						))}
						<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
							<Button onClick={onCancel}>Cancel</Button>
							<Button onClick={applyFilter} style={{ marginLeft: '10px' }} type="primary">
								Apply
							</Button>
						</div>
						{/* <div className="actions">
							<button onClick={onCancel} className="cancel">
								Cancel
							</button>
							<button onClick={applyFilter} className="apply">
								Apply
							</button>
						</div> */}
					</>
				) : null}
				{filterSelected.filterType === 'dropdown' ? (
					<AddDateSelection
						filterSelected={filterSelected}
						onCancel={onCancel}
						addDateFilter={addDateFilter}
					/>
				) : null}
			</div>
		</div>
	);
};

const FilterModel = function ({ saveFilterInfo, openModal, setModalState }) {
	const [filterBy, setFilterBy] = useState([]);
	const [selectedFilter, setFilterInfo] = useState({ assetType: null, warranty: null });
	const [categoryList, setCategoryList] = useState([]);
	const [customList, setCustomList] = useState([]);
	const [filterSelected, selectedNewFilter] = useState(null);
	const [allFilterInfo, updateAllFilterInfo] = useState([]);
	const [showCustom, toggleCustomView] = useState(false);
	const dispatch = useDispatch();
	useEffect(() => {
		async function getAssetTypes() {
			try {
				const resp = await getApi('/assetze/admin-setup/category');
				if (resp.type === 'success') {
					setCategoryList(resp.categories);
				} else {
					dispatch(
						showSnack('FILE_UPLOAD_ERROR', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			}
		}

		getAssetTypes();
	}, [dispatch]);
	useEffect(() => {
		async function getCustomKeys() {
			try {
				const resp = await getApi('/assetze/asset-update/custom-keys');
				if (resp.type === 'success') {
					setCustomList(resp.customKeys);
				} else {
					dispatch(
						showSnack('FILE_UPLOAD_ERROR', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			}
		}
		getCustomKeys();
	}, [dispatch]);
	const customStyles = {
		content: {
			top: '48%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			backgroundColor: '#f8f8f8',
			overflow: 'hidden',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	const updateSelectedFilter = (info) => {
		setFilterBy(info);
	};

	const setFilterText = (key, type, value) => {
		if (type === 'string') {
			setFilterInfo({ [key]: value });
		}
	};

	const customKeyOptions = customList.map((el) => {
		return {
			...el,
			value: el._id,
			label: el.key,
		};
	});

	const addNewFilter = (selectedItem) => {
		selectedNewFilter(selectedItem);
	};
	const onCanel = () => {
		selectedNewFilter(null);
		updateAllFilterInfo([]);
	};
	const onAdd = (filterData) => {
		updateAllFilterInfo(allFilterInfo.concat({ ...filterData, id: Date.now() }));
		selectedNewFilter(null);
	};
	const deleteFilter = (selectedFilter) => () => {
		console.log('fill filter delete', selectedFilter);
		updateAllFilterInfo(allFilterInfo.filter((el) => el.id !== selectedFilter));
	};

	const collapseItems = [
		{
			key: '1',
			label: 'Custom Configuration',
			children: (
				<div>
					{customKeyOptions.map((el) => {
						return (
							<div
								onClick={() => {
									addNewFilter({
										name: el.label,
										label: el.label,
										type: 'text',
										allowedMethods: [
											{ name: 'equalTo', label: 'is Equal To' },
											{ name: 'containsTo', label: 'is Contains' },
										],
										filterType: 'radio',
										multipleInputs: true,
										maximumInputs: 20,
										custom: true,
									});
								}}
								className="each-item custome-key filter-select-items"
								key={el._id}
							>
								{el.label}
							</div>
						);
					})}
				</div>
			),
		},
	];

	return (
		<>
			<div>
				<Drawer
					width={400}
					className="filter-modal-drawer"
					anchor={'right'}
					open={openModal}
					onClose={() => setModalState(false)}
				>
					<div className="filter-modal-container">
						<div className="filter-heading">
							<h3>Filter</h3>
							<Button
								size="small"
								onClick={() => {
									onCanel();
								}}
								// className="clear-heading"
							>
								Clear
							</Button>
						</div>
						<div className="label-text">Filter By Asset Type/Warranty/ IT or NON-IT and Custom Fields</div>
						{/* <div>
							<input type={'text'} placeholder="Filter " disabled className="filter-input" />
						</div> */}
						<ShowFilterView allFilterInfo={allFilterInfo} deleteFilter={deleteFilter} />

						{filterSelected ? (
							<FilterInfoAdd filterSelected={filterSelected} onCancel={onCanel} onAdd={onAdd} />
						) : (
							<>
								<Card>
									<div className="filter-list">
										<div className="head-1">Filters</div>
										<div className="list-container">
											{filterValues.map((el, index) => {
												return (
													<div
														onClick={() => {
															addNewFilter(el);
														}}
														key={index}
														// className="each-item"
														className="filter-select-items"
													>
														{el.label}
													</div>
												);
											})}

											{/* <div
												onClick={() => {
													toggleCustomView(!showCustom);
												}}
												className="each-item custom-el"
											>
												<div>Custom Configuration</div>
												<div>{!showCustom ? <FaAngleDown /> : <FaAngleUp />}</div>
											</div> */}

											<Collapse
												items={collapseItems}
												size={'small'}
												style={{ marginTop: '15px' }}
											/>

											{showCustom &&
												customKeyOptions.map((el) => {
													return (
														<div
															onClick={() => {
																addNewFilter({
																	name: el.label,
																	label: el.label,
																	type: 'text',
																	allowedMethods: [
																		{ name: 'equalTo', label: 'is Equal To' },
																		{ name: 'containsTo', label: 'is Contains' },
																	],
																	filterType: 'radio',
																	multipleInputs: true,
																	maximumInputs: 20,
																	custom: true,
																});
															}}
															className="each-item custome-key"
															key={el._id}
														>
															{el.label}
														</div>
													);
												})}
										</div>
									</div>
								</Card>
								<div className="filter-action-container">
									<CheckPermission requiredPermission={CREATE_REPORT}>
										<Button
											type="primary"
											size="large"
											// className="download"
											onClick={async () => {
												try {
													await fetchFilterData(allFilterInfo, dispatch, 'download');
												} catch (e) {
													console.error(e);
												}
											}}
										>
											<AiOutlineCloudDownload />
											<span>Download</span>
										</Button>
									</CheckPermission>
									<Button
										onClick={() => saveFilterInfo(allFilterInfo)}
										type="primary"
										size="large"
										// className="show"
									>
										<PiEyeThin />
										<span>View Assets</span>
									</Button>
									<CheckPermission requiredPermission={GENERATE_TAG}>
										<Button
											type="primary"
											size="large"
											// className="show assets-tag"
											onClick={async () => {
												try {
													await generateAssetTag(allFilterInfo, dispatch, 'barcode-create');
												} catch (e) {
													console.error(e);
												}
											}}
										>
											<BiBarcodeReader />
											<span>Create Asset Tag</span>
										</Button>
									</CheckPermission>
								</div>
							</>
						)}
					</div>
				</Drawer>
			</div>

			<Button
				icon={<IoFilter />}
				onClick={debounce(() => {
					setModalState(!openModal);
				}, 50)}
			>
				Filters
			</Button>

			{/* <div
				className="filter-icon-container"
				onClick={() => {
					setModalState(!openModal);
				}}
			>
				<MdFilterAlt color={'#3f3f3f'} />
			</div> */}
		</>
	);
};

export const FilterAssets = function ({
	onFilterTextChange,
	searchText,
	saveFilterInfo,
	filterView,
	openModal,
	setModalState,
}) {
	const hiddenFileInput = useRef(null);
	const navigate = useNavigate();

	const dispatch = useDispatch();
	const [openModalAdd, setOpenModal] = useState(false);

	const handleClose = () => {
		setOpenModal(false);
	};

	const handleOpen = debounce(() => {
		setOpenModal(true);
	}, 100);

	// const handleOpen = () => {
	// 	setOpenModal(true);
	// };

	const toggleModal = () => {
		setOpenModal(!openModalAdd);
	};

	const onFileChange = async (event) => {
		const file = event.target.files[0];
		const resp = await postApi({ filename: file.name, mimetype: file.type }, '/assets/upload-assets/signedurl');
		if (resp.type === 'success') {
			await uploadToS3(resp.url, file.type, file);
			const serverResp = await postApi({ filename: file.name }, '/assets/upload-assets/readFile');
			if (serverResp.type === 'success') {
				dispatch(
					showSnack('FILE_UPLOAD_SUCCESS', {
						label: 'Successfully Added Assets',
						timeout: 7000,
					})
				);
			} else if (serverResp.type === 'error') {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: serverResp.message,
						timeout: 7000,
					})
				);
			}
		}
	};

	const customStyles = {
		content: {
			top: '48%',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			marginRight: '-50%',
			transform: 'translate(-50%, -50%)',
			padding: '0px',
			backgroundColor: '#f8f8f8',
			overflow: 'hidden',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	return (
		<>
			<div className="filter-assets-container">
				<div className="filter-search">
					<div className="filter-search-input">
						{/* <BsSearch />
						<input
							onChange={onFilterTextChange}
							value={searchText}
							placeholder={
								'Search By Employee Id/ Employee Name/ Employee Email/ Department/ Serial Number/ Purchase Order'
							}
							type="text"
						/> */}
						<Search
							onChange={onFilterTextChange}
							placeholder={
								'Search By Employee Id/ Employee Name/ Employee Email/ Department/ Serial Number/ Purchase Order'
							}
							enterButton
						/>
					</div>
				</div>
				<div className="filter-buttons-container">
					<CheckPermission requiredPermission={ARCHIEVE_VIEW}>
						<Button
							icon={<BiArchive />}
							// type={"primary"}
							// size='large'
							// danger
							// style={{
							// 	backgroundColor: '#fa5c5c',
							// 	border: '0px',
							// 	color: 'white',
							// 	fontWeight: '400',
							// }}
							onClick={() => {
								navigate('/archieve-list');
							}}
						>
							Archive Assets
						</Button>
					</CheckPermission>
					<CheckPermission requiredPermission={ASSET_CREATE}>
						<Button icon={<BsFillCloudUploadFill />} onClick={handleOpen}>
							<input
								accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
								style={{ display: 'none' }}
								ref={hiddenFileInput}
								type="file"
								onChange={onFileChange}
							/>
							Upload
						</Button>
					</CheckPermission>
					{/* <DownloadAllAssets /> */}

					<Modal
						open={openModalAdd}
						onCancel={handleClose}
						footer={null}
						className="upload-asset-modal"
						title="Choose how you'll add the asset"
						// contentLabel="Filter Assets"
						// onRequestClose={handleClose}
						// isOpen={openModalAdd}
						// ariaHideApp={false}
						// style={customStyles}
					>
						{/* <div className="upload-modal-heading">Choose how you'll add the assets</div> */}
						<div className="upload-options-containers">
							<MyDropzone
								toggleModal={() => {
									toggleModal();
								}}
							/>

							<div className="upload-manually">
								<img src={uploadManually} />
								<div className="upload-caption">Add Manually</div>
								<div className="upload-note">Add individual assets here</div>
								<Button
									type="primary"
									size="large"
									onClick={() => {
										navigate('/assets/new');
									}}
								>
									Add
								</Button>
							</div>
						</div>
					</Modal>
					<CheckPermission requiredPermission={FILTER_VIEW}>
						<FilterModel
							openModal={openModal}
							setModalState={setModalState}
							saveFilterInfo={saveFilterInfo}
						/>
					</CheckPermission>
					<FilterAssetsColumn />
				</div>
			</div>
			{filterView}
		</>
	);
};
export const FilterAssetsColumn = function () {
	const assetsTableColumns = useSelector((state) => state.appreducer.assetsTableColumns);
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [openDropdown, setOpenDropdown] = useState(false);
	const [arrayOfChosenOptions, setArrayOfChosenOptions] = useState(assetsTableColumns);
	const [dropdownItems, setDropdownItems] = useState([]);
	useEffect(() => {
		const fetchCustomKeys = async function () {
			try {
				const resp = await getApi('/assetze/custom-keys');
				if (resp.type === 'success') {
					dispatch({
						payload: resp.customKeys.map((el) => ({
							name: el.key,
							checked: false,
							mapId: el.key,
							type: '',
							custom: true,
						})),
						type: 'SET_ASSET_TABLS_COLUMNS',
					});
				}
			} catch (e) {
				console.error(e);
			}
		};
		fetchCustomKeys();

		// setArrayOfChosenOptions(assetsTableColumns);
	}, [dispatch]);

	useEffect(() => {
		console.log('regresh things');
		setArrayOfChosenOptions(assetsTableColumns);
		setDropdownItems(assetsTableColumns);
	}, [assetsTableColumns]);

	const handleClose = () => {
		setOpenModal(false);
	};

	// const handleOpen = () => {
	// 	setOpenModal(true);
	// };
	const handleOpen = debounce(() => {
		setOpenModal(true);
	}, 100);

	const toggleCheckBox = (eachOption, val) => {
		setOpenDropdown(true);
		setArrayOfChosenOptions(
			arrayOfChosenOptions.map((el) => {
				if (el.mapId !== eachOption.mapId) {
					return el;
				} else {
					return {
						...el,
						checked: val,
					};
				}
			})
		);
	};

	const handleApplyClick = debounce(() => {
		dispatch({
			type: ADD_FILTER_TABLE,
			payload: arrayOfChosenOptions,
		});
		handleClose();
		setOpenDropdown(false);
	}, 100);

	const handleOpenChange = (flag) => {
		setOpenDropdown(flag);
	};

	const filterOption = debounce((val) => {
		const filterItems = arrayOfChosenOptions.filter((el) => el.name.toLowerCase().includes(val.toLowerCase()));
		setDropdownItems(filterItems);
	}, 300);

	return (
		<div>
			{/* <Button icon={<IoMdSettings />} style={{ border: "1px solid #33D7DB" }} onClick={handleOpen} size='medium'
			 className="asset-setting-button"
			>
				<img src={AssetSettings} />
			</Button> */}
			<div>
				<Dropdown
					menu={{
						items: dropdownItems.map((el) => {
							return {
								key: el.mapId,
								label: (
									<Checkbox
										defaultChecked={el.checked}
										onChange={(e) => {
											toggleCheckBox(el, e.target.checked);
										}}
									>
										{el.name}
									</Checkbox>
								),
							};
						}),
						// onClick: () => { setOpenDropdown(true); }
					}}
					trigger={['click']}
					onOpenChange={handleOpenChange}
					open={openDropdown}
					placement="bottom"
					dropdownRender={(menu) => (
						<div
							style={{
								backgroundColor: 'white',
								display: 'flex',
								flexDirection: 'column',
								padding: '10px 4px',
								borderRadius: '5px',
							}}
						>
							<Input
								onChange={(e) => {
									filterOption(e.target.value);
								}}
								placeholder="Search Columns"
							></Input>
							<Divider
								style={{
									margin: '8px 0',
								}}
							/>
							<div style={{ maxHeight: '300px', overflowY: 'scroll', width: '300px' }}>{menu}</div>
							<Divider
								style={{
									margin: '8px 0',
								}}
							/>
							<Button
								type={'primary'}
								onClick={() => {
									handleApplyClick();
								}}
							>
								Apply
							</Button>
						</div>
					)}
				>
					<Button
						icon={<CiViewColumn />}
						onClick={() => {
							setOpenDropdown(true);
						}}
					>
						Columns
					</Button>
				</Dropdown>
			</div>
			<Modal
				open={openModal}
				onCancel={handleClose}
				className="filter-add-column"
				title="Add or delete columns"
				footer={null}
				// contentLabel="Filter Assets"
				// onRequestClose={handleClose}
				// isOpen={openModal}
				// ariaHideApp={false}
				// style={customStyles}
			>
				<div className="assets-modal">
					{/* <div className="modal-header">
						<div className="modal-title">Add or delete columns</div>
					</div> */}
					<div className="checklist">
						{arrayOfChosenOptions.map((eachOption, index) => {
							return (
								<div key={index} className="each-option">
									{eachOption.checked ? (
										<div
											className="checked"
											onClick={() => {
												toggleCheckBox(eachOption);
											}}
										>
											{eachOption.name}
										</div>
									) : (
										<div
											className="unchecked"
											onClick={() => {
												toggleCheckBox(eachOption);
											}}
										>
											{eachOption.name}
										</div>
									)}
								</div>
							);
						})}
					</div>
					<Button size="large" type="primary" onClick={handleApplyClick}>
						Apply
					</Button>
				</div>
			</Modal>
		</div>
	);
};

const DownloadAllAssets = function () {
	const handleOpen = async function () {
		const resp = await postApi({}, '/assets/download-assets');
		if (resp.type === 'success') {
			const link = document.createElement('a');
			link.href = resp.url;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		}
	};

	return <button onClick={handleOpen}>Download</button>;
};
