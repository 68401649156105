import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Hamburger from 'hamburger-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { showLoginModal, verifyToken } from '../../redux/actions';
import EarlyAccessModel from '../EarlyAccess';
import LoginModal from '../Login';
import { HiOutlineChevronDown } from 'react-icons/hi';
import './index.css';
import { IoIosClose } from 'react-icons/io';

const customStyles = {
	content: {
		top: '60px',
		left: 'auto',
		position: 'fixed',
		right: '100px',
		bottom: 'auto',
		marginRight: 'auto',
		// transform: 'translate(-50%, -50%)',
		padding: '0px',
		border: '0px',
		boxShadow: '0 0 10px #f1f1f1',
		borderRadius: '4px',
	},
	overlay: {
		backgroundColor: 'rgba(255, 255, 255, 0.4)',
	},
};

const LandingPageHeader = function () {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const location = useLocation();
	const [showMenu, setShowMenu] = useState(false);
	function onLoginClick() {
		return async () => {
			const isLoggedIn = await dispatch(verifyToken());
			console.log(isLoggedIn, 'isLoggedIn');
			if (isLoggedIn) {
				navigate('/dashboard');
			} else {
				dispatch(showLoginModal());
			}
		};
	}

	return (
		<div className="sticky-header">
			<div className={'highlighter-page'}>
				<div className="text-head">Upto 30% Reduction in IT Expenses with Assetze</div>
				<EarlyAccessModel className="early-access-new" content="Try it Now" />
			</div>

			<header className="app-header">
				<div
					onClick={() => {
						navigate('/');
					}}
					className="app-header-image-container"
				>
					<img src={require('../../images/logo-5.png')} className="logo-header" />
				</div>

				<div className="app-header-action">
					<FeaturesModal />
					<button
						onClick={() => {
							navigate('/partner');
						}}
						className="price"
					>
						Partner
					</button>
					{/* <button
					onClick={() => {
						navigate('/survey');
					}}
					className="price"
				>
					Survey
				</button> */}
					{/* <button
						onClick={() => {
							navigate('/pricing');
						}}
						className="price"
					>
						How to pay
					</button> */}

					<EarlyAccessModel className="early-access-new" content="Get Started" />
					<button onClick={onLoginClick()} className="login">
						Login
					</button>
					<LoginModal />
				</div>
				<ShowMobileMenu showMenu={showMenu} setShowMenu={setShowMenu} />
			</header>
			<MenuContents showMenu={showMenu} setShowMenu={setShowMenu} />
		</div>
	);
};

export default LandingPageHeader;

function ShowMobileMenu({ showMenu, setShowMenu }) {
	const navigate = useNavigate();
	let location = useLocation();
	let dispatch = useDispatch();
	return (
		<>
			<div className="menu-container" onClick={() => setShowMenu(!showMenu)}>
				<Hamburger toggled={showMenu} toggle={setShowMenu} color={'#33D7DB'} />
			</div>
		</>
	);
}

function MenuContents({ showMenu, setShowMenu }) {
	let location = useLocation();
	let navigate = useNavigate();
	let dispatch = useDispatch();
	return (
		<div className="menu-contents" style={{ display: showMenu ? 'block' : 'none' }}>
			<IoIosClose
				onClick={() => {
					setShowMenu(!showMenu);
				}}
				size={40}
				style={{ float: 'right' }}
			/>

			<div
				onClick={() => {
					setShowMenu(!showMenu);
					dispatch(showLoginModal());
				}}
				style={{ color: '#33D7DB', cursor: 'pointer', paddingTop: '5vh' }}
			>
				Login
			</div>
			<EarlyAccessModel content="Get Early Access" className="early-access-menu" />
			<div
				onClick={() => {
					if (!location.pathname.includes('/assetManagment')) {
						navigate('/assetManagment');
					}
				}}
				style={{ cursor: 'pointer' }}
			>
				IT Asset Management
			</div>
			<div
				onClick={() => {
					if (!location.pathname.includes('/incideneManagment')) {
						navigate('/incideneManagment');
					}
				}}
				style={{ cursor: 'pointer' }}
			>
				Incident Management
			</div>

			<div
				onClick={() => {
					if (!location.pathname.includes('/reporting')) {
						navigate('/reporting');
					}
				}}
				style={{ cursor: 'pointer' }}
			>
				Reporting
			</div>
			<div
				onClick={() => {
					if (!location.pathname.includes('/diagnosticTool')) {
						navigate('/diagnosticTool');
					}
				}}
				style={{ cursor: 'pointer' }}
			>
				Assetze Diagnostic tool
			</div>

			<div
				onClick={() => {
					if (!location.pathname.includes('/partner')) {
						navigate('/partner');
					}
				}}
				style={{ cursor: 'pointer' }}
			>
				Partner
			</div>
			{/* <div
					onClick={() => {
						if (!location.pathname.includes('/pricing')) {
							navigate('/pricing');
						}
					}}
				>
					How to pay
				</div> */}
		</div>
	);
}

function FeaturesModal() {
	const [modalIsOpen, setModalState] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	const goToPath = (path) => {
		console.log(location, 'navigate', path);
		if (!location.pathname.includes(path)) {
			navigate(path);
		}
	};

	return (
		<div className="feature-container">
			<button onClick={() => setModalState(!modalIsOpen)}>
				Features <HiOutlineChevronDown />
			</button>
			<div className="feature-contianer-list">
				<div onClick={() => goToPath('/assetManagment')} className={'each-feature-head'}>
					IT Asset Management
				</div>
				<div onClick={() => goToPath('/incideneManagment')} className={'each-feature-head'}>
					Incident Management
				</div>
				<div onClick={() => goToPath('/reporting')} className={'each-feature-head'}>
					Reporting
				</div>
				<div onClick={() => goToPath('/diagnosticTool')} className={'each-feature-head'}>
					Assetze Diagnostic tool
				</div>
				{/* <div className={'each-feature-head'}>Contract Management</div> */}
			</div>
		</div>
	);
}
