import * as React from 'react';
const XoXoImg = (props) => (
	<svg width="226" height="60" viewBox="0 0 226 60" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path
			d="M205.407 59.3245C204.548 58.9478 203.603 58.783 202.871 58.159C202.148 57.5444 201.88 56.8003 202.228 55.8608C202.577 54.9236 203.165 54.3773 204.213 54.3326C205.07 54.2973 205.81 54.674 206.599 54.8789C211.226 56.0845 215.417 55.4958 218.683 51.7071C221.07 48.9403 221.367 45.5872 221.021 42.0481C220.444 42.1117 220.124 42.545 219.712 42.7993C213.778 46.4632 207.672 46.9176 201.628 43.2961C196.81 40.4093 194.672 35.8529 194.627 30.3029C194.594 26.0857 194.58 21.8707 194.587 17.6535C194.59 16.0264 195.247 15.1834 196.511 15.0162C198.176 14.7972 199.174 15.7132 199.193 17.5852C199.231 21.4116 199.231 25.2403 199.231 29.0667C199.231 31.1129 199.457 33.1144 200.323 35.0005C202.645 40.0419 207.99 42.3142 213.62 40.6565C218.332 39.2696 221.089 35.5845 221.127 30.6867C221.16 26.2387 221.198 21.7907 221.306 17.3427C221.344 15.8098 222.333 14.9597 223.677 15.0374C225.036 15.1151 225.799 16.0217 225.806 17.5805C225.825 21.9532 225.841 26.3258 225.858 30.6985C225.876 32.0289 225.818 44.9256 225.775 45.4719C225.325 51.4387 222.719 55.9762 217.131 58.5757C215.615 59.2822 213.966 59.527 212.384 59.9956C210.748 60.0003 206.959 60.0003 205.407 59.3245Z"
			fill="#888888"
		/>
		<path
			d="M155.52 17.3052C155.52 21.6002 155.549 25.8952 155.513 30.1901C155.45 37.8947 150.783 44.0475 143.584 45.757C134.258 47.9728 124.382 41.6669 124.208 31.0166C124.156 27.9084 124.285 24.8167 125.767 22.0075C128.088 17.6066 132.072 15.372 136.732 14.3289C141.366 13.2928 145.659 14.3454 149.509 17.1357C149.846 17.3806 150.168 17.828 150.625 17.5525C151.009 17.3217 150.804 16.8343 150.804 16.4669C150.816 12.0542 150.806 7.6439 150.813 3.2312C150.818 1.1214 151.588 0.0311718 153.062 0.000560734C154.529 -0.0300503 155.49 1.19439 155.502 3.24769C155.528 7.93353 155.509 12.617 155.509 17.3029C155.513 17.3052 155.516 17.3052 155.52 17.3052ZM150.799 30.1124C150.658 25.8292 149.401 22.0805 145.419 19.966C141.536 17.9033 137.488 17.9292 133.669 20.218C130.379 22.1889 129.007 25.3842 128.771 29.0646C128.519 33.0346 129.515 36.6443 132.809 39.1662C136.532 42.0177 140.716 42.2391 144.882 40.3271C149.182 38.3515 150.637 34.5439 150.799 30.1124Z"
			fill="#888888"
		/>
		<path
			d="M184.766 17.7838C184.938 17.3035 185.065 16.8938 185.232 16.5005C185.722 15.3538 186.746 14.7863 187.853 15.0312C188.908 15.262 189.595 16.145 189.598 17.3529C189.605 21.921 189.581 26.4868 189.603 31.0549C189.614 33.3743 189.93 35.6466 191.046 37.7328C191.719 38.9902 192.673 40.0075 193.796 40.8881C194.952 41.7923 195.105 42.965 194.267 44.0505C193.429 45.1383 192.275 45.289 191.065 44.4649C189.887 43.6619 188.91 42.6706 188.159 41.4509C187.271 40.0051 186.749 39.925 185.562 41.173C183.313 43.5348 180.634 45.0936 177.438 45.7929C170.219 47.3729 159.465 43.3464 158.542 32.0392C158.219 28.1021 158.502 24.2922 160.753 20.9038C164.949 14.5862 174.309 11.1624 182.404 16.2556C183.165 16.7289 183.909 17.2281 184.766 17.7838ZM184.921 30.04C184.919 29.3972 184.884 28.3446 184.646 27.3109C183.659 23.0066 181.281 19.8889 176.819 18.9141C172.411 17.951 168.342 18.7257 165.361 22.4273C163.148 25.1752 162.82 28.4412 163.216 31.8579C163.927 38.0013 168.865 41.9454 175.347 41.465C180.989 41.0435 185.096 36.3271 184.921 30.04Z"
			fill="#888888"
		/>
		<path
			d="M121.396 30.2424C121.391 37.9163 116.995 43.8478 109.992 45.6186C100.105 48.1216 91.2137 41.8228 90.293 31.6788C89.9021 27.365 90.7051 23.3455 93.3588 19.8511C96.5612 15.6339 101.005 13.9173 106.185 14.0115C115.298 14.1763 121.401 20.7294 121.396 30.2424ZM116.67 30.3013C116.67 29.7173 116.727 29.1263 116.661 28.547C116.169 24.1791 114.184 20.7624 109.882 19.2836C105.478 17.7696 101.275 18.3041 97.8304 21.7302C95.2708 24.2756 94.6492 27.5368 94.8187 31.0053C95.1366 37.4925 99.9496 41.7498 106.623 41.4649C112.462 41.2176 116.906 36.3104 116.67 30.3013Z"
			fill="#888888"
		/>
		<path
			d="M60.0378 30.1174C60.0284 37.8667 55.8229 43.6781 48.8577 45.5572C38.6878 48.3028 29.3161 41.3894 28.9441 30.8968C28.8028 26.8844 29.4998 23.1099 31.9816 19.8463C35.1958 15.6219 39.6368 13.9195 44.8147 14.0113C53.9227 14.1738 60.0473 20.6963 60.0378 30.1174ZM44.4097 41.4671C51.0123 41.4859 55.4933 36.9154 55.4909 30.1598C55.4886 23.2064 51.05 18.5323 44.4427 18.5323C37.932 18.5323 33.5169 23.0934 33.4887 29.8467C33.4627 36.7388 37.8872 41.4482 44.4097 41.4671Z"
			fill="#888888"
		/>
		<path
			d="M10.5208 29.9781C10.4714 29.1869 9.991 28.6712 9.50594 28.1556C6.71798 25.1981 3.92531 22.2453 1.15148 19.2737C-0.353174 17.6654 -0.27076 16.3703 1.30453 15.3766C2.21344 14.8044 3.01169 15.0046 3.79345 15.5886C4.90015 16.415 5.73136 17.5147 6.67559 18.5013C8.64883 20.5617 10.5538 22.688 12.5152 24.7601C13.9163 26.2388 14.8346 26.1965 16.2239 24.6942C18.7175 21.998 21.2111 19.2996 23.7212 16.6176C24.1192 16.1937 24.5666 15.7981 25.0469 15.4708C26.1018 14.7526 27.0013 14.8633 27.8961 15.7534C28.6826 16.5375 28.7933 17.5194 28.1222 18.5178C27.1544 19.9518 25.8193 21.0632 24.6513 22.323C22.8453 24.2727 20.971 26.1564 19.1343 28.0802C17.7332 29.5472 17.7686 30.3596 19.1908 31.8619C22.0659 34.8994 24.9221 37.9511 27.7854 41.0004C28.5295 41.794 28.7273 42.7099 28.2611 43.6942C27.8231 44.6196 26.9778 44.9069 26.0124 44.8668C25.2565 44.8339 24.7314 44.3724 24.2346 43.8449C21.5549 41.0004 18.8564 38.1724 16.1721 35.3327C14.5615 33.6279 14.0764 33.5973 12.4917 35.2667C9.96275 37.9299 7.46206 40.6213 4.94489 43.2963C4.57049 43.6942 4.19374 44.0992 3.76283 44.4289C2.70087 45.2436 1.70012 45.1423 0.729986 44.1746C-0.157735 43.2915 -0.275469 42.4132 0.602832 41.3725C1.98504 39.7312 3.4685 38.1701 4.93547 36.5995C6.42835 34.9983 7.95655 33.4301 9.4612 31.843C9.97217 31.3014 10.4761 30.7504 10.5208 29.9781Z"
			fill="#888888"
		/>
		<path
			d="M87.02 44.8262C86.3442 44.8098 85.8426 44.4825 85.3929 44.0045C82.6355 41.0729 79.8711 38.1483 77.0997 35.2285C75.7622 33.818 74.785 33.8369 73.4475 35.252C70.7632 38.0918 68.0741 40.9269 65.3827 43.7619C65.0059 44.1575 64.641 44.5719 64.1017 44.7627C63.1175 45.1064 61.9142 44.7415 61.4056 43.8891C60.8052 42.8836 61.0053 41.9535 61.7847 41.1129C64.6268 38.0471 67.4642 34.9765 70.3111 31.9131C71.3754 30.7687 72.4586 29.6408 73.537 28.5106C74.7002 27.2932 75.7881 27.2743 76.9584 28.5058C80.9119 32.6737 84.849 36.8556 88.7884 41.0352C89.4595 41.7487 89.7444 42.5634 89.297 43.4935C88.8425 44.4283 88.0584 44.8592 87.02 44.8262Z"
			fill="#888888"
		/>
		<path
			d="M75.1715 7.24815C78.4398 7.22931 80.9782 9.74177 80.9947 13.0101C81.0088 16.2195 78.6141 18.6143 75.3622 18.6449C72.0751 18.6755 69.4237 16.1583 69.4048 12.9865C69.3907 9.8807 72.0186 7.26463 75.1715 7.24815Z"
			fill="#888888"
		/>
		<path
			d="M89.4103 16.9276C89.415 17.9778 89.0971 18.6324 88.5744 19.1928C86.175 21.757 83.7496 24.2978 81.3784 26.8856C80.6202 27.7121 80.0151 27.7026 79.2239 26.9491C77.1141 24.9453 77.0623 24.7051 79.0638 22.5647C81.1971 20.2854 83.3752 18.0508 85.5533 15.8161C86.2691 15.0815 87.1569 14.8696 88.1341 15.2699C88.9888 15.6207 89.3561 16.3083 89.4103 16.9276Z"
			fill="#888888"
		/>
		<path
			d="M60.9585 17.3539C60.9703 15.6962 62.8658 14.5424 64.2103 15.2065C64.7072 15.4514 65.1051 15.8257 65.4795 16.2284C67.8248 18.7456 70.1677 21.2651 72.5389 23.7587C73.1134 24.3639 73.0899 24.8395 72.5813 25.48C70.5751 28.0066 70.2148 28.0301 68.0909 25.7272C66.1035 23.5703 64.095 21.4346 62.0864 19.2989C61.5142 18.6891 61.0268 18.0486 60.9585 17.3539Z"
			fill="#888888"
		/>
	</svg>
);
export default XoXoImg;
