import React, { useState } from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { Wrap } from '.';
import { putApi } from '../../services/api';
import ArrowButton from './ArrowButton';
import useDefaultQuestion from './useDefaultQuestions';

const DefaultQuestions = ({ vendorFormDetails, setVendorFormDetails, vendorId, setWrap }) => {
	const [answer, setAnswer] = useState();
	const [error, setError] = useState(false);
	const [validation, setValidation] = useState({
		isValid: false,
		errorMessage: '',
	});

	const dispatch = useDispatch();

	const { next, back, step, specification, currentVendorDetailIndex, vendorSpecifications, isFirstStep, isLastStep } =
		useDefaultQuestion(vendorFormDetails);
	const questions = {
		vendorName: 'Vendor Name',
		vendorType: 'Vendor Type',
		pointOfContact: 'Point Of Contact',
		email: 'Email',
		contactNumber: 'Contact Number',
		website: 'Website',
	};

	const handleVendorDetailsInput = (e) => {
		// console.log(e.target.value);
		const { name, value } = e.target;
		setVendorFormDetails((prevProps) => ({
			...prevProps,
			[name]: value,
		}));
		setAnswer(e.target.value);
		setError(false);
	};
	console.log('first, v', vendorFormDetails);

	const defaultQuestionsHandler = () => {
		if (!answer && vendorFormDetails[specification] === '') {
			console.log('in', answer);
			setError(true);
			return;
		}

		if (
			specification === 'email' &&
			!answer?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i) &&
			!vendorFormDetails[specification]?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
		) {
			console.log('error');
			setError(false);
			setValidation({ isValid: true, errorMessage: 'Provide proper email' });
			return;
		}
		if (
			specification === 'contactNumber' &&
			!answer?.match(/^[6789]\d{9}$/i) &&
			!vendorFormDetails[specification]?.match(/^[6789]\d{9}$/i)
		) {
			console.log('error');
			setError(false);
			setValidation({
				isValid: true,
				errorMessage: 'Provide proper contact number',
			});
			return;
		}
		if (
			specification === 'website' &&
			!answer?.match(
				/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i
			) &&
			!vendorFormDetails[specification]?.match(
				/[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/i
			)
		) {
			console.log('error');
			setError(false);
			setValidation({ isValid: true, errorMessage: 'Provide proper website' });
			return;
		}
		setValidation((prev) => ({ ...prev, isValid: false }));
		setAnswer('');
		if (isLastStep) {
			const vendorDefaultSubmitForm = async () => {
				const data = {
					...vendorFormDetails,
					isVendorDetailsSubmitted: true,
				};

				try {
					const resp = await putApi(data, `/vendorForm/vendor-details/${vendorId}`);
					if (resp.type === 'success') {
						console.log(resp.data?.vendorId);

						// dispatch(
						//   showSnack("VENDOR_RESPONSE_FORM_ADDED", {
						//     label: "Vendor Form Submitted Successfully",
						//     timeout: 7000,
						//   })
						// );
						setWrap(true);
					} else {
						return dispatch(
							showSnack('VENDOR_RESPONSE_FORM_ADDED', {
								label: resp.error,
								timeout: 7000,
							})
						);
					}
				} catch (err) {
					dispatch(
						showSnack('VENDOR_RESPONSE_FORM_ADDED', {
							label: err && err.message,
							timeout: 7000,
						})
					);
				}
			};
			vendorDefaultSubmitForm();
		}

		console.log('called');
		next();
	};

	const defaultBackQuestionHandler = () => {
		setError(false);
		setValidation((prev) => ({ ...prev, isValid: false }));
		back();
	};
	//   console.log("abd", vendorFormDetails);
	console.log(currentVendorDetailIndex, vendorSpecifications.length);

	return (
		<>
			<div className="descriptiveContainer">
				<div className="descriptiveForm">
					<h4 className="defaultQuestion">{questions[specification]}</h4>
					<div className="question-arrow">
						<input
							name={specification}
							value={vendorFormDetails[specification]}
							onChange={handleVendorDetailsInput}
							type="type"
							placeholder="Enter the answer here"
							className={error ? 'responseError' : ''}
							// pattern="/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i"
							required
						/>

						<div className="arrows">
							<button
								onClick={defaultBackQuestionHandler}
								disabled={isFirstStep}
								style={{ background: '#fff', border: 'none', outline: 'none' }}
								className="moveUpIcon"
							>
								<AiOutlineUp className="arrow_icon_container" />
							</button>
							<button
								onClick={defaultQuestionsHandler}
								disabled={isLastStep}
								style={{ background: '#fff', border: 'none', outline: 'none' }}
								className="moveDownIcon"
							>
								<AiOutlineDown className="arrow_icon_container" />
							</button>
						</div>
					</div>
					<button className="next-btn" onClick={defaultQuestionsHandler}>
						{isLastStep ? 'Submit' : 'Next'}
					</button>
				</div>

				<h6 className="responseErrorMessage">
					{error && answer === ''
						? 'Please answer this question before heading to next'
						: validation.isValid
						? validation.errorMessage
						: null}
				</h6>
				<div className="progressiveContainer">
					<p>
						Questions {currentVendorDetailIndex + 1} of {vendorSpecifications.length}
					</p>
					<div className="progressExtension">
						<div
							className="progress"
							style={{
								backgroundColor: '#33D7DB',
								width: ((currentVendorDetailIndex + 1) / vendorSpecifications.length) * 100 + '%',
							}}
						></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default DefaultQuestions;
