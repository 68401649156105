import React from 'react';
import Slider from 'react-slick';
import AlmaBaseImg from '../images/svgs/companies/alma-base';
import CytivaImg from '../images/svgs/companies/cytiva';
import DataFoundryImg from '../images/svgs/companies/data-foundary';
import ErithImg from '../images/svgs/companies/eriths';
import HcoImg from '../images/svgs/companies/hco';
import HoovuImg from '../images/svgs/companies/hoovu';
import IncrefImg from '../images/svgs/companies/incref';
import LearnBay from '../images/svgs/companies/learnbay';
import LegaEImag from '../images/svgs/companies/lega-e';
import LkqImg from '../images/svgs/companies/lkq';
import LoadShareImg from '../images/svgs/companies/load-share';
import MeddoImg from '../images/svgs/companies/meddo';
import MobilyImg from '../images/svgs/companies/mobily';
import MomoImg from '../images/svgs/companies/momo';
import MPLImg from '../images/svgs/companies/mpl';
import OneCodeImg from '../images/svgs/companies/one-code';
import PostmanImg from '../images/svgs/companies/postman';
import RentoMojo from '../images/svgs/companies/rento-mojo';
import SkillSlaskImg from '../images/svgs/companies/skill-slash';
import StoreKingImg from '../images/svgs/companies/store-king';
import TinyStepImg from '../images/svgs/companies/tinystep';
import VahanImg from '../images/svgs/companies/vaha';
import XoXoImg from '../images/svgs/companies/xoxo-day';
import ZenEquityImg from '../images/svgs/companies/zen-equity';

export const ReactCompanyCarousel = () => {
	const settings = {
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		dots: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
		],
	};
	return (
		<div style={{ padding: '0 5%', backgroundColor: '#F9F9F9', paddingBottom: '30px' }}>
			<div
				style={{
					color: '#555555',
					paddingTop: '23px',
					paddingBottom: '30px',
					textAlign: 'center',
					fontSize: '24px',
					lineHeight: '22px',
					textShadow: '0px 0px 0px rgba(255, 255, 255, 0.01)',
				}}
			>
				Trusted by companies
			</div>
			<div>
				<Slider className="slick-2" {...settings}>
					<div className="each-slick-item">
						<DataFoundryImg />
					</div>
					<div className="each-slick-item">
						<CytivaImg />
					</div>
					<div className="each-slick-item">
						<ErithImg />
					</div>
					<div className="each-slick-item">
						<IncrefImg />
					</div>
					<div className="each-slick-item">
						<LearnBay />
					</div>
					<div className="each-slick-item">
						<LegaEImag />
					</div>
					<div className="each-slick-item">
						<LkqImg />
					</div>
					<div className="each-slick-item">
						<LoadShareImg />
					</div>
					<div className="each-slick-item">
						<MobilyImg />
					</div>
					<div className="each-slick-item">
						<MomoImg />
					</div>
					<div className="each-slick-item">
						<MPLImg />
					</div>
					<div className="each-slick-item">
						<OneCodeImg />
					</div>
					<div className="each-slick-item">
						<SkillSlaskImg />
					</div>
					<div className="each-slick-item">
						<StoreKingImg />
					</div>
					<div className="each-slick-item">
						<TinyStepImg />
					</div>
					<div className="each-slick-item">
						<VahanImg />
					</div>
					<div className="each-slick-item">
						<XoXoImg />
					</div>
					<div className="each-slick-item">
						<ZenEquityImg />
					</div>
					<div className="each-slick-item">
						<RentoMojo />
					</div>
					<div className="each-slick-item">
						<AlmaBaseImg />
					</div>
					<div className="each-slick-item">
						<MeddoImg />
					</div>
					<div className="each-slick-item">
						<HcoImg />
					</div>
					<div className="each-slick-item">
						<PostmanImg />
					</div>
					<div className="each-slick-item">
						<HoovuImg />
					</div>
				</Slider>
			</div>
		</div>
	);
};
