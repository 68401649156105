import React, { useEffect, useState } from 'react'
import { Button, Select, Form, Col, Input, DatePicker } from 'antd';
import { ApiPost, DownloadDeprReport } from '../../services/agentConfig';
import { Line } from 'react-chartjs-2';
import { differenceInMonths, format } from 'date-fns';
import { useDispatch } from 'react-redux';
import "chart.js/auto";
import { showSnack } from 'react-redux-snackbar';
import { postApi } from '../../services/api';
import _, { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { CheckPermission, DEPRECIATION_CREATE } from '../../utils/Permissions';
const dateFormat = 'YYYY/MM/DD';
dayjs.extend(customParseFormat);


const Depreciation = ({ selectedAsset }) => {
    const [deprDetails, setDeprDetails] = useState({});
    const [deprData, setdeprData] = useState([]);
    const [salvagePrice, setSalvagePrice] = useState(null);
    const [assetDepreciation, setAssetDepreciation] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();


    useEffect(() => {
        getDepreciatonDetails();
    }, [])

    const getDepreciatonDetails = async function () {
        try {
            const resp = await ApiPost(
                { companyId: selectedAsset.companyId, assetId: selectedAsset._id },
                '/assetze/depreciation/get-for-asset'
            );
            if (resp.type === 'success') {
                setDeprDetails(resp);
                setdeprData(resp.data);
                const price = resp.price;
                const slavagePer = resp.depreciation.slavagePer;
                const salvagePrice = (price * slavagePer) / 100;
                setSalvagePrice(salvagePrice);
            }
            return null;
        } catch (e) {
            console.error(e);
        }
    };

    const downloadReport = async (value) => {
        await DownloadDeprReport(
            { fyear: value, assetId: selectedAsset._id },
            '/assetze/depreciation/depreciation-report'
        );
    };

    console.log(selectedAsset);

    const fyearItems = [...new Set(deprData.map((data) => data.financialYear))].map((financialYear) => ({
        label: financialYear,
        value: financialYear,
    }));

    console.log(deprData);
    const sortedArray = deprData.sort((a, b) => {
        const dateA = new Date(a.date.replace(/-/g, '/'));
        const dateB = new Date(b.date.replace(/-/g, '/'));
        return dateA - dateB;
    });
    const amount = sortedArray[0]?.amount;
    const startDate = new Date(sortedArray[0]?.date);
    const endDate =
        new Date(sortedArray[sortedArray.length - 1]?.date) < new Date()
            ? new Date(sortedArray[sortedArray.length - 1]?.date)
            : new Date();
    const numberOfMonths = differenceInMonths(endDate, startDate) + 1;
    console.log(numberOfMonths);

    const handleFYearClick = (value) => {
        console.log(`Clicked on ${value}`);
        // setFyear(value);
        downloadReport(value);
    };
    // console.log(sortedArray);
    const Bardata = {
        labels: sortedArray.map((data) => format(new Date(data.date), 'MM yyyy')),
        datasets: [
            {
                label: 'Depreciation',
                data: deprData.map((data) => data.remainingValue + salvagePrice),
                backgroundColor: '#33D7DB',
                borderColor: '#33D7DB',
                borderWidth: 3,
                pointRadius: 0,
                tension: 0.5,
            },
        ],
    };

    const Baroptions = {
        scales: {
            x: {
                type: 'category',
                labels: Bardata.labels,
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: 'Month',
                    font: {
                        size: 15,
                    },
                },
            },
            y: {
                beginAtZero: true,
                grid: {
                    drawOnChartArea: false,
                },
                title: {
                    display: true,
                    text: 'Price',
                    font: {
                        size: 15,
                    },
                },
                // min: 6000
            },
        },
    };

    const createDepreciation = async (data) => {
        const resp = await postApi(
            {
                ...data,
                companyId: selectedAsset.companyId,
                assetId: selectedAsset._id,
            },
            '/assetze/depreciation/run-for-asset'
        );
        if (resp.type === 'success') {
            dispatch(
                showSnack('COMPANY_INFO_UPDATED', {
                    label: 'Successfully Submitted',
                    timeout: 3000,
                })
            );
            getDepreciatonDetails();
        } else {
            dispatch(
                showSnack('COMPANY_INFO_UPDATE_FAILED', {
                    label: resp.error,
                    timeout: 7000,
                })
            );
        }
    };
    return (
        <div>
            <>
                <div className="tab-depreciation">
                    <div className="innerdiv">
                        <div className="flexdiv">
                            <div className="textdiv">
                                <div className="heading">Asset Depreciation</div>
                                <div className="subheading">Overall Asset depreciation</div>
                            </div>
                        </div>
                        {!isEmpty(deprDetails.depreciation) ? (
                            <div>
                                <div className="depr-text-div">
                                    <div>
                                        <div className="flexdiv">
                                            <div className="label">Date of Purchase</div>{' '}
                                            {deprDetails.dateOfPurchase &&
                                                format(new Date(deprDetails.dateOfPurchase), 'dd/MM/yyyy')}
                                        </div>
                                        <div className="flexdiv">
                                            <div className="label">Purchase price</div>{' '}
                                            {deprDetails.price && deprDetails.price}
                                        </div>
                                        <div className="flexdiv">
                                            <div className="label">Asset depreciation % in a year</div>
                                            {deprDetails.depreciation && deprDetails.depreciation.depreciationInPer}
                                            %
                                        </div>
                                        <div className="flexdiv">
                                            <div className="label">Salvage Percentage</div>
                                            {deprDetails.depreciation && deprDetails.depreciation.slavagePer}%
                                        </div>
                                        <div className="flexdiv">
                                            <div className="label">Overall depreciated amount</div>
                                            {amount * numberOfMonths}
                                        </div>
                                        <div className="flexdiv">
                                            <div className="label">Current Value</div>
                                            {deprDetails.price && deprDetails.price - amount * numberOfMonths}
                                        </div>
                                    </div>

                                    <div className="download-div">
                                        <div>Download Asset Depreciation Report:</div>
                                        <Select
                                            placeholder="Select Financial Year"
                                            onChange={handleFYearClick}
                                            options={fyearItems}
                                        />
                                    </div>
                                </div>
                                <div className="line-chart">
                                    <Line data={Bardata} options={Baroptions} />
                                </div>
                            </div>
                        ) : (
                            <>
                                {!assetDepreciation ? (
                                    <CheckPermission requiredPermission={DEPRECIATION_CREATE}>
                                        <Button
                                            onClick={() => {
                                                setAssetDepreciation(true);

                                                const updateFields = {
                                                    price: selectedAsset?.price,
                                                };

                                                if (selectedAsset?.dateOfPurchase) {
                                                    console.log('selectedAsset?.dateOfPurchase');
                                                    updateFields.dateOfPurchase = dayjs(
                                                        selectedAsset?.dateOfPurchase,
                                                        dateFormat
                                                    );
                                                }

                                                form.setFieldsValue(updateFields);
                                            }}
                                            className="depreciation-btn"
                                        >
                                            Set Asset Depreciation
                                        </Button>
                                    </CheckPermission>
                                ) : (
                                    <div>
                                        <Form
                                            form={form}
                                            onFinish={createDepreciation}
                                            layout="vertical"
                                            hideRequiredMark
                                        >
                                            <div className='depr-form'>
                                                <Form.Item
                                                    name="depreciationInPer"
                                                    label="Depreciation% per year"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Provide Asset Depreciation %',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="Depreciation% per year"
                                                        style={{ backgroundColor: 'white' }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="slavagePer"
                                                    label="Salvage Percentage"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Provide Salvage Percentage',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="Salvage Percentage"
                                                        style={{ backgroundColor: 'white' }}
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    name="price"
                                                    label="Price"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Provide Price',
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder="Price"
                                                        style={{ backgroundColor: 'white' }}
                                                    />
                                                </Form.Item>

                                                <Form.Item
                                                    name="dateOfPurchase"
                                                    label="Date of Purchase"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Provide Date of Purchase',
                                                        },
                                                    ]}
                                                >
                                                    <DatePicker
                                                        // defaultValue={dayjs('2015/01/01', dateFormat)}
                                                        format={dateFormat}
                                                        style={{ backgroundColor: 'white', width: '100%' }}
                                                        placeholder="Date of Purchase"
                                                    />
                                                </Form.Item>
                                            </div>
                                            <div style={{ display: 'flex', gap: '10px' }}>
                                                <Button
                                                    style={{ backgroundColor: 'white' }}
                                                    onClick={() => {
                                                        setAssetDepreciation(false);
                                                        form.resetFields();
                                                    }}
                                                >
                                                    Cancel
                                                </Button>
                                                <Button
                                                    className="ant-design-button"
                                                    onClick={() => {
                                                        form.submit();
                                                    }}
                                                >
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form>
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </>
        </div>
    )
}

export default Depreciation