import React from 'react';
import './index.css';
import HeaderComponent from '../../components/Header';
import FooterDashboard from '../../components/FooterDashboard';
import ShopProduct from '../../components/ShopNow';

const ShopNow = function () {
	return (
		<div className="shop-now-container">
			<HeaderComponent />
			<ShopProduct />
			<FooterDashboard />
		</div>
	);
};

export default ShopNow;
