import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';

const Header = function () {
	//const navigate = useNavigate();
	return (
		<div className="getstarted-header">
			<AiOutlineArrowLeft className="icon" />
			<div className="heading">Assetze</div>
			<BsThreeDotsVertical className="dot-icon" />
			<AiOutlineClose className="icon" />
		</div>
	);
};

export default Header;
