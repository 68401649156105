import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { BsCloudUpload, BsGrid1X2, BsQuestionCircle, BsQuestionDiamondFill } from 'react-icons/bs';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { RiDeleteBinLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import {
	ADD_CHOICES,
	ADD_NEW_QUESTION,
	ADD_NEW_SECTION,
	ADD_QUESTIONS,
	CHANGE_QUESTION_TYPE,
	DELETE_CHOICE,
	DELETE_QUESTION,
	MOVE_QUESTION_DOWN,
	MOVE_QUESTION_UP,
	SAVE_SECTION_ID,
	UPDATE_CHOICE,
	UPDATE_QUESTION,
	UPDATE_SECTION,
	UPDATE_UPLOAD,
} from '../../redux/actions';
import { v4 as uuidv4 } from 'uuid';
import { FiAlignLeft } from 'react-icons/fi';
import { BiRadioCircleMarked } from 'react-icons/bi';
import { HiOutlineChevronDown } from 'react-icons/hi';
const qId = uuidv4();
const template = [
	{
		sectionId: qId,
		qtype: 'section',
		name: 'Company details',
		description: 'Details of the company such as GST, PAN, MSME, etc..',
		subQuestions: [
			{ qId, qtype: 'question-answer', question: 'Company registered address' },
			{ qId, qtype: 'question-answer', question: 'Company GSTIN' },
			{
				qId,
				qtype: 'question-answer',
				question: 'Registered under MSME ? If yes,provide number',
			},
			{ qId, qtype: 'question-answer', question: 'Company telephone number' },
			{
				qId,
				qtype: 'question-answer',
				question: 'Title of the point of contact',
			},
			{ qId, qtype: 'question-answer', question: 'Nature of business' },
			{
				qId,
				qtype: 'multiple-choice',
				question: 'Whether any of your relative working with our company',
				choices: [
					{ choiceId: qId, choice: 'yes' },
					{ choiceId: qId, choice: 'No' },
				],
			},
			{
				qId,
				qtype: 'question-answer',
				question: 'Fax/Telephone number of company',
			},
		],
	},
	{
		sectionId: qId,
		qtype: 'section',
		name: 'Banking Information',
		description: 'Details of the company such as bank information, etc..',
		subQuestions: [
			{ qId, qtype: 'question-answer', question: 'Bank name' },
			{ qId, qtype: 'question-answer', question: 'Bank address' },
			{ qId, qtype: 'question-answer', question: 'Beneficiary Name' },
			{
				qId,
				qtype: 'question-answer',
				question: 'International bank account number (IBAN)',
			},
			{
				qId,
				qtype: 'question-answer',
				question: 'Swift / Bank identifier code (BIC)',
			},
			{
				qId,
				qtype: 'question-answer',
				question: 'Account currency (Eg:INR,USD,EUR)',
			},
			{ qId, qtype: 'question-answer', question: 'Bank account number' },
			{ qId, qtype: 'question-answer', question: 'Bank IFSC Code' },
		],
	},
	{
		sectionId: qId,
		qtype: 'section',
		name: 'Supporting documentation',
		description:
			'In order to be considered for registration by the <Company Name> procurement section please provide the following information',
		subQuestions: [
			{
				qId,
				qtype: 'file-question',
				question:
					'Current and valid copy of certificate of incorporation/business certificate or national equipment',
			},
			{
				qId,
				qtype: 'file-question',
				question: 'Current and valid copy of Company PAN (Permanent Account Number) card',
			},
			{
				qId,
				qtype: 'file-question',
				question: 'Current and valid copy of Company GST Registration Certificate',
			},
			{
				qId,
				qtype: 'file-question',
				question: 'MSME Certificate',
			},
			{
				qId,
				qtype: 'file-question',
				question: 'Cancel Cheque as per provided above bank details',
			},
		],
	},
	{
		sectionId: qId,
		qtype: 'section',
		name: 'ANNEXURE-1',
		description: 'Please read the below all questions and provide the correct answer by clicking the right option',
		subQuestions: [
			{
				qId,
				qtype: 'multiple-choice',
				question:
					'Has it proven impossible to determine the ownership structure/parent company or the Director structure of the company',
				choices: [
					{ choiceId: qId, choice: 'yes' },
					{ choiceId: qId, choice: 'No' },
				],
			},
			{
				qId,
				qtype: 'multiple-choice',
				question: 'Is the Third Party (or its parent company) registered to a High-Risk Country',
				choices: [
					{ choiceId: qId, choice: 'yes' },
					{ choiceId: qId, choice: 'No' },
				],
			},
			{
				qId,
				qtype: 'multiple-choice',
				question: 'Is it owned partly or wholly by the Government of a high-risk country?',
				choices: [
					{ choiceId: qId, choice: 'yes' },
					{ choiceId: qId, choice: 'No' },
				],
			},
		],
	},
];

const CustomField = () => {
	const questions = useSelector((state) => state.appreducer.vendorFormData.questions);

	const dispatch = useDispatch();
	console.log('questions-questions', questions);
	return (
		<div className="vendor-modal-container">
			<h3>Custom Field</h3>
			{questions?.map((q) => {
				console.log('q', q);
				return (
					<div key={q?.qId}>
						{q?.qtype === 'section' ? (
							<SectionForm sectionId={q.sectionId} />
						) : q?.qtype === 'question-answer' || q?.qtype === 'multiple-choice' ? (
							<QuestionForm qId={q.qId} choices={q.choices} sectionId={q.sectionId} />
						) : (
							q?.qtype === 'file-question' && <UploadForm qId={q.qId} sectionId={q.sectionId} />
						)}
					</div>
				);
			})}
		</div>
	);
};

export default CustomField;

const VendorFormWrapper = ({ children }) => {
	return (
		<div className="customFieldContainer">
			<div className="questionFieldContainer">{children}</div>
		</div>
	);
};

const SectionForm = ({ sectionId }) => {
	// const sectionInitialState = {
	//   name: "",
	//   description: "",
	// };
	const dispatch = useDispatch();

	const [sectionInputs, setSectionInputs] = useState();
	const [name, setName] = useState('');
	const [desc, setDesc] = useState('');

	const handleSectionInputValues = (e) => {
		// setSectionInputs((prev) => {
		//   return {
		//     ...prev,
		//     [e.target.name]: e.target.value,
		//   };
		// });
		dispatch({
			type: UPDATE_SECTION,
			payload: { name: e.target.value, desc: e.target.value, sectionId },
		});
	};
	return (
		<VendorFormWrapper>
			<div className="question-container">
				<input
					onChange={(e) => {
						setName(e.target.value);
						dispatch({
							type: UPDATE_SECTION,
							payload: { updateFeild: 'name', name: e.target.value, sectionId },
						});
						//dispatch({ type: UPDATE_SECTION, payload: { name, desc, qId } });
					}}
					// value={sectionInputs.name}
					type="text"
					placeholder="Section Name"
					name="name"
				/>
				<input
					onChange={(e) => {
						setDesc(e.target.value);
						dispatch({
							type: UPDATE_SECTION,
							payload: {
								updateFeild: 'description',
								desc: e.target.value,
								sectionId,
							},
						});
					}}
					type="text"
					placeholder="Add Description"
					name="description"
				/>
			</div>
			<VendorFormFooter sectionId={sectionId} data={sectionInputs} />
		</VendorFormWrapper>
	);
};
const QuestionForm = ({ qId, choices }) => {
	const [questionType, setQuestionType] = useState('question-answer');
	console.log(questionType);
	const sectionId = useSelector((state) => state.appreducer.sectionId);
	return (
		<VendorFormWrapper>
			{questionType === 'question-answer' ? (
				<DefaultQuestion qId={qId} setQuestionType={setQuestionType} questionType={questionType} />
			) : (
				<MCQFOrm qId={qId} setQuestionType={setQuestionType} questionType={questionType} choices={choices} />
			)}

			{/* <VendorFormFooter qId={qId} /> */}
		</VendorFormWrapper>
	);
};
const DefaultQuestion = ({ qId, setQuestionType, questionType }) => {
	const dispatch = useDispatch();
	const sectionId = useSelector((state) => state.appreducer.sectionId);
	const [isQuestionTypeModal, setIsQuestionTypeModal] = useState(false);
	// const questionAnswerInitialState = {
	//   question: "",
	//   qtype: "question",
	// };

	const [questionAnswerInputs, setQuestionAnswerInputs] = useState();
	const handleQuestionAnswerInputValues = (e) => {
		// setQuestionAnswerInputs((prev) => {
		//   return {
		//     ...prev,
		//     [e.target.name]: e.target.value,
		//   };
		// });

		dispatch({
			type: UPDATE_QUESTION,
			payload: {
				qId,
				qtype: 'question-answer',
				question: e.target.value,
				sectionId,
			},
		});
	};
	console.log(questionType);
	return (
		<>
			<div className="vendor_question_container">
				<div className="question-container">
					<input
						// value={questionAnswerInputs.question}
						type="text"
						placeholder="Question"
						name="question"
						onChange={handleQuestionAnswerInputValues}
					/>
					<h6>Answer</h6>
					<h6></h6>
				</div>
				<div className="left_vendor_question">
					<div className="question_selector_container">
						<div className="question_type" onClick={() => setIsQuestionTypeModal(!isQuestionTypeModal)}>
							{questionType ? (
								<>{questionType === 'question-answer' ? 'Answer' : 'MCQ'}</>
							) : (
								<>Type Of Input</>
							)}
							<HiOutlineChevronDown
								style={{
									fontSize: '16px',
									color: '#888888',
									marginLeft: '10px',
								}}
							/>
						</div>
						{isQuestionTypeModal ? (
							<>
								<div className="question_type_options">
									<div
										onClick={() => {
											setQuestionType('question-answer');
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: { qId, qtype: 'question-answer' },
											});
										}}
									>
										<FiAlignLeft
											style={{
												fontSize: '16px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Answer
									</div>
									<div
										onClick={() => {
											setQuestionType('multiple-choice');
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: { qId, qtype: 'multiple-choice' },
											});
										}}
									>
										<BiRadioCircleMarked
											style={{
												fontSize: '18px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Multiple Choice
									</div>
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
			<VendorFormFooter qId={qId} data={questionAnswerInputs} sectionId={sectionId} />
		</>
	);
};
const MCQFOrm = ({ qId, setQuestionType, questionType, choices }) => {
	const initialState = {
		ansId: uuidv4(),
		answer: '',
	};
	const mcqFormInitialState = {
		qtype: 'multiple-choice',
		question: '',
		multipleChoices: [],
	};
	const dispatch = useDispatch();

	const [multipleChoiceInputs, setmultipleChoiceInputs] = useState(mcqFormInitialState);
	const [isQuestionTypeModal, setIsQuestionTypeModal] = useState(false);
	const [mcqAnswerss, setMcqAnswers] = useState([initialState]);
	const questions = useSelector((state) => state.appreducer.vendorFormData.questions);
	let mcqAnswers;
	questions.forEach((el) => {
		if (el.qtype === 'multiple-choice') {
			mcqAnswers = el?.choices;
		}
	});
	console.log(mcqAnswers, 'choices');
	const addMCQAnswers = (e, id) => {
		console.log('e', e, 'id', id);
		const newArray = mcqAnswers.map((item) => {
			console.log(item);
			if (item?.ansId === id) {
				return { ...item, [e.target.name]: e.target.value };
			} else {
				return item;
			}
		});
		setMcqAnswers(newArray);
		const newArray2 = newArray?.map((q) => q.answer);
		setmultipleChoiceInputs((prev) => {
			return {
				qtype: prev.qtype,
				question: prev.question,
				multipleChoices: newArray2,
			};
		});
	};
	const handleEnter = (e) => {
		if (e.keyCode == 13) {
			if (!e.currentTarget.value) return;

			dispatch({
				type: ADD_CHOICES,
				payload: { qId, qtype: 'multiple-choice' },
			});
		}
	};

	const deleteHandler = (id) => {
		if (mcqAnswers?.length === 1) {
			alert('Edit current input ');
			return;
		}
		dispatch({ type: DELETE_CHOICE, payload: { qId, ansId: id } });
	};

	const handleMultipleChoice = (e) => {
		setmultipleChoiceInputs((prev) => {
			return {
				...prev,
				[e.target.name]: e.target.value,
			};
		});
		dispatch({
			type: UPDATE_QUESTION,
			payload: { qId, qtype: 'multiple-choice', question: e.target.value },
		});
	};
	console.log('mcaAnswersssssss======== ', mcqAnswers);
	return (
		<>
			<div className="vendor_question_container">
				<div className="left_vendor_question">
					<input
						type="text"
						placeholder="Question"
						value={multipleChoiceInputs.question}
						name="question"
						onChange={handleMultipleChoice}
					/>
					<div className="mcq_container">
						{mcqAnswers?.map((mcq, index) => {
							console.log('mcq', mcq);
							return (
								<div key={mcq.ansId} className="mcq_answer_container">
									<input
										onKeyDown={handleEnter}
										value={mcq?.answer}
										onChange={(e) => {
											// addMCQAnswers(e, mcq.ansId);
											dispatch({
												type: UPDATE_CHOICE,
												payload: {
													qId,
													choiceId: mcq.ansId,
													choice: e.target.value,
												},
											});
										}}
										type="text"
										name="answer"
									/>
									<AiOutlineCloseCircle
										onClick={() => deleteHandler(mcq.ansId)}
										style={{ fontSize: '25px', cursor: 'pointer' }}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className="left_vendor_question">
					<div className="question_selector_container">
						<div className="question_type" onClick={() => setIsQuestionTypeModal(!isQuestionTypeModal)}>
							{questionType ? (
								<>{questionType === 'question-answer' ? 'Answer' : 'MCQ'}</>
							) : (
								<>Type Of Input</>
							)}
						</div>
						{isQuestionTypeModal ? (
							<>
								<div className="question_type_options">
									<div
										onClick={() => {
											setQuestionType('question-answer');
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: { qId, qtype: 'question-answer' },
											});
										}}
									>
										<FiAlignLeft
											style={{
												fontSize: '16px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Answer
									</div>
									<div
										onClick={() => {
											setQuestionType('multiple-choice');
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: { qId, qtype: 'multiple-choice' },
											});
										}}
									>
										<BiRadioCircleMarked
											style={{
												fontSize: '18px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Multiple Choice
									</div>
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
			<VendorFormFooter qId={qId} data={multipleChoiceInputs} />
		</>
	);
};
const UploadForm = ({ qId }) => {
	// const uploadInitiaState = {
	//   question: "",
	// };

	const [uploadInputs, setuploadInputs] = useState();
	const dispatch = useDispatch();

	const handleUploadInputValues = (e) => {
		// setuploadInputs((prev) => {
		//   return {
		//     ...prev,
		//     [e.target.name]: e.target.value,
		//   };
		// });
		dispatch({
			type: UPDATE_UPLOAD,
			payload: { qId, qtype: 'file-question', question: e.target.value },
		});
	};
	return (
		<VendorFormWrapper>
			<div className="upload-container">
				<input
					onChange={handleUploadInputValues}
					// value={uploadInputs.question}
					type="text"
					placeholder="Question to Upload Images"
					name="question"
				/>
				<p>Allowed files for upload documents, images, PDF, jpeg, png, with max file size of 1MB</p>
			</div>
			<VendorFormFooter qId={qId} data={uploadInputs} />
		</VendorFormWrapper>
	);
};
const VendorFormFooter = ({ qId, data, sectionId }) => {
	const [questionModal, setQuestionModal] = useState(false);

	const questions = useSelector((state) => state.appreducer.vendorFormData.questions);
	console.log('questions', questions);
	const selectAddQuestionView = () => {
		// dispatch({ type: UPDATE_QUESTIONS, payload: { id: qId, data: data } });
		dispatch({
			type: ADD_NEW_QUESTION,
			payload: { qId: uuidv4(), qtype: 'question-answer', sectionId },
		});
		dispatch({ type: SAVE_SECTION_ID, payload: { sectionId } });
		setQuestionModal(false);
	};
	const selectSectionView = () => {
		// dispatch({ type: UPDATE_QUESTIONS, payload: { id: qId, data: data } });
		dispatch({
			type: ADD_NEW_SECTION,
			payload: { qId: uuidv4(), qtype: 'section' },
		});
		setQuestionModal(false);
	};
	const selectUploadView = () => {
		// dispatch({ type: UPDATE_QUESTIONS, payload: { id: qId, data: data } });
		dispatch({
			type: ADD_NEW_QUESTION,
			payload: { qId: uuidv4(), qtype: 'file-question', sectionId },
		});
		setQuestionModal(false);
	};

	const dispatch = useDispatch();

	const deleteQuestionHandler = () => {
		if (questions?.length === 1) return;
		dispatch({ type: DELETE_QUESTION, payload: { id: qId } });
	};
	const moveUpHandler = (id) => {
		if (questions?.length === 1) return;
		const index = questions.findIndex((object) => {
			return object.qId === id;
		});
		console.log('index-----------', index);
		dispatch({ type: MOVE_QUESTION_UP, payload: { qIndex: index } });
	};
	const moveDownHandler = (id) => {
		const moveDownIndex = questions.findIndex((object) => {
			return (object.qId = id);
		});
		dispatch({
			type: MOVE_QUESTION_DOWN,
			payload: { moveIndex: moveDownIndex },
		});
	};
	return (
		<div className="vendor_footer_container">
			<div className="vendor_sub_footer">
				<div style={{ color: '#333333' }}>
					<IoMdAddCircleOutline onClick={() => setQuestionModal(!questionModal)} />
				</div>

				{questionModal ? (
					<div className="dropdown-questions">
						<div onClick={selectAddQuestionView}>
							<h6>
								<BsQuestionCircle />
								&nbsp; Add Question
							</h6>
						</div>
						<div onClick={selectSectionView}>
							<h6>
								<BsGrid1X2 /> &nbsp; Add Section
							</h6>
						</div>
						<div onClick={selectUploadView}>
							<h6>
								<BsCloudUpload /> &nbsp; Upload File
							</h6>
						</div>
					</div>
				) : null}
				<div style={{ color: '#333333' }}>
					<AiOutlineUp onClick={() => moveUpHandler(qId)} />
				</div>
				<div style={{ color: '#333333' }}>
					<AiOutlineDown onClick={() => moveDownHandler(qId)} />
				</div>
				<div style={{ color: '#CF1414' }}>
					<RiDeleteBinLine onClick={deleteQuestionHandler} />
				</div>
			</div>
		</div>
	);
};

// const ChoiceCreator = ({ choice, choiceId, qId }) => {
//   const dispatch = useDispatch();
//   return (
//     <label>
//       {/* <input type="radio" className="radio-btn" /> */}
//       <input
//         type="text"
//         placeholder="create-option"
//         value={choice}
//         onChange={(e) => {
//           e.preventDefault();
//           e.stopPropagation();
//           dispatch({ type: UPDATE_CHOICE, payload: { choiceIndex: choiceId, choice: e.target.value, qId } });
//         }}
//       />
//       <input
//         type="submit"
//         hidden
//         onClick={(e) => {
//           e.preventDefault();
//           e.stopPropagation();
//         }}
//       />
//       <span onClick={() => {}}>X</span>
//     </label>
//   );
// };

// const QuestionSelector = () => {
//   const questionType = useSelector((state) => state.appreducer.question_type);
//   const [isQuestionTypeModal, setIsQuestionTypeModal] = useState(false);
//   const dispatch = useDispatch();
//   return (
//     <div className="question_selector_container">
//       <div className="question_type" onClick={() => setIsQuestionTypeModal(!isQuestionTypeModal)}>
//         {questionType ? <>{questionType === "answer" ? "Answer" : "MCQ"}</> : <>Type Of Input</>}
//       </div>
//       {isQuestionTypeModal ? (
//         <>
//           <div className="question_type_options">
//             <div
//               onClick={() => {
//                 dispatch({ type: QUESTION_TYPE_SELECTOR, payload: "answer" });
//                 setIsQuestionTypeModal(false);
//               }}
//             >
//               <FiAlignLeft style={{ fontSize: "16px", color: "#329C9E", marginRight: "5px" }} />
//               Answer
//             </div>
//             <div
//               onClick={() => {
//                 dispatch({ type: QUESTION_TYPE_SELECTOR, payload: "mcq" });
//                 setIsQuestionTypeModal(false);
//               }}
//             >
//               <BiRadioCircleMarked style={{ fontSize: "18px", color: "#329C9E", marginRight: "5px" }} />
//               Multiple Choice
//             </div>
//           </div>
//         </>
//       ) : null}
//     </div>
//   );
// };
