import React from 'react';
import { downloadTag } from '../../services/barcode';
import DownloadIcon from '../../images/svgs/DownloadSvg';
import { useDispatch } from 'react-redux';

export const DownloadAssetTag = ({ assetId, deviceId }) => {
	const dispatch = useDispatch();

	return (
		<div onClick={() => downloadTag(assetId, dispatch)} className="id">
			<div className="downlaod-asset-tag-click">{deviceId}</div>
			<DownloadIcon fill="#33D7DB" />
		</div>
	);
};
