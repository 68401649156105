import React from 'react';

export const IntegrationsComponent = () => {
	return (
		<div className="landingpage-content-7">
			<h4>Easy Integrations to</h4>
			<div>
				<img src={require('../../images/quickbooks.png')} alt="quick-books" />
				<img src={require('../../images/tally.png')} alt="tally" />
			</div>
		</div>
	);
};

export const IntegrationsComponentNew = () => {
	return (
		<div className="landingpage-content-7">
			<h4>
				Easy Integrations to <br /> your chosen HRMS Portals
			</h4>
		</div>
	);
};
