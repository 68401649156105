import React from 'react';
import LandingPageHeader from '../../components/LandingpageHeader';
import LandingScreenFooter from '../../components/LandingFooter';
import EarlyAccessModel from '../EarlyAccess';
import SignupToAssetze from '../SignupToAssetze';

import './index.css';
import { IntegrationsComponent } from '../../screens/LandingScreen/IntegrationsComponent';
import FeatureList from '../../screens/NewLandingDesign/featureList';

const AssetManagment = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />
			<div className="feature-incident-container">
				<div className="content-container">
					<div className="content-text-container">
						<h1>IT Asset Management</h1>
						<div className="description">
							Never lose sight of your company assets, at office or at work from home.
						</div>
						<EarlyAccessModel className="signup-button" content="Sign Up" />
					</div>
					<div className="image-container">
						<img src={require('../../images/features/assetsInfo.png')} />
					</div>
				</div>
				<div style={{ backgroundColor: '#F9F9F9' }} className="content-container">
					<div className="content-text-container">
						<h1>Managing your IT Assets is now simple</h1>
						<div className="description">
							Assetze simplifies the process of ITAM, adding assets, assigning/unassigning it to users,
							and all the right metrics are at your fingertips.
						</div>
					</div>
					<div className="image-container">
						<img src={require('../../images/features/assetsDashboard.png')} />
					</div>
				</div>
				<FeatureList />
				<div className="content-container">
					<div className="image-container">
						<img src={require('../../images/features/assetsDetailedView.png')} />
					</div>
					<div style={{ paddingLeft: '50px' }} className="content-text-container">
						<h1>Detail Card on Asset</h1>
						<div className="description">
							Find the details of each asset by just a click of a button. Get to view the existing user
							along with realtime health report of the asset and its warranty status.
						</div>
					</div>
				</div>
				<div style={{ backgroundColor: '#fafafa' }} className="features-highlights">
					<div className="each-highlight">
						<img src={require('../../images/features/alerts.png')} />
						<h2>Alerts</h2>
						<div className="description">
							Get alerts on warranty expiration, renewal, contract expiration, idle laptops, low asset
							health, etc.,
						</div>
					</div>
					<div className="each-highlight">
						<img src={require('../../images/features/reports.png')} />
						<h2>Reports</h2>
						<div className="description">
							Find the number of assets present, working, repair, or in dead condition. Monthly, quarterly
							and yearly asset spend.
						</div>
					</div>
				</div>
				<SignupToAssetze />
				<IntegrationsComponent />
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default AssetManagment;
