import React from 'react';
import './index.css';
import HeaderComponent from '../../components/Header';
import FooterDashboard from '../../components/FooterDashboard';
import { useState } from 'react';
import ShopCart from '../../components/ShoppingCart';
import SaaSProductLanding from '../ProductsScreen/saasProduct';

const ShoppingCart = function () {
	return (
		<div className="shopping-cart-container">
			<HeaderComponent />
			<ShopCart />
			<FooterDashboard />
		</div>
	);
};

export default ShoppingCart;
