import React, { useRef, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { GrFormAdd } from 'react-icons/gr';
import DataTable from '../../components/Datatable';
// import ReactPaginate from 'react-paginate';
import { AiFillDelete } from 'react-icons/ai';
import { FiEdit } from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import './index.css';
import { useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import { getApi, postApi, putApi } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import EditModal from '../../components/EditModal';
import HeaderComponent from '../../components/Header';
import { Table, Space, Row, Col, Button, Drawer, Form, Input, Select, Divider } from 'antd';
import { CiSearch } from 'react-icons/ci';
import {
	COMPANY_BRANCHES_CREATE,
	COMPANY_BRANCHES_DELETE,
	COMPANY_BRANCHES_EDIT,
	CheckPermission,
} from '../../utils/Permissions';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
	},
	overlay: {
		backgroundColor: 'rgba(0, 0, 0, 0.3)',
	},
};

const CompanyBranch = () => {
	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [editModalOpen, setEditModalOpen] = useState(false);
	const [filteredData, setFilteredData] = useState({});
	const [locationId, setLocationId] = useState('');
	const [updateId, setUpdateId] = useState();
	const [branchDetails, setBranchDetails] = useState([]);
	const inputRef = useRef(null);
	const [titleItems, setTitleItems] = useState(['Mr.', 'Mrs.']);
	const [codeItems, setCodeItems] = useState(['+91']);
	const [code, setCode] = useState('');
	const [title, setTitle] = useState('');
	const [form] = Form.useForm();
	const initialState = {
		branchId: '',
		address1: '',
		address2: '',
		address3: '',
		pincode: '',
		city: '',
		state: '',
		contact: {
			firstName: '',
			lastName: '',
			phone: '',
			title: '',
			countryCode: '',
		},
	};
	// const [initialValue, setInitialValue] = useState(initialState);
	const dispatch = useDispatch();

	const [itemsPerPage, setItemsPerPage] = useState(10);
	const [curItem, setCurItem] = useState([]);
	const [count, setCount] = useState(1);
	const [off, setOff] = useState(0);
	useEffect(() => {
		const endOff = off + itemsPerPage;
		setCount(Math.ceil(branchDetails.length / itemsPerPage));
		setCurItem(branchDetails.slice(off, endOff));
	}, [off, itemsPerPage, branchDetails]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
		getValues,
	} = useForm();

	useEffect(() => {
		getAllBranches();
	}, []);

	const getAllBranches = async () => {
		try {
			const resp = await getApi(`/company/locations?page=${count}&limit=${itemsPerPage}`);
			if (resp.type === 'success') {
				console.log(resp);
				setBranchDetails(resp.locations);
			}
		} catch (error) {
			dispatch(
				showSnack('Company_BRANCH_LIST_ERROR', {
					label: 'Something went wrong try again',
					timeout: 7000,
				})
			);
		}
	};
	const handlePageClick = (event) => {
		const newOff = (event.selected * itemsPerPage) % branchDetails.length;
		setOff(newOff);
	};

	function openModal() {
		setModalIsOpen(true);
	}
	function closeModal() {
		setModalIsOpen(false);
	}
	function openEditModal(id) {
		setUpdateId(id);
		setEditModalOpen(true);

		console.log('before', id);
		setFilteredData(branchDetails?.find((b) => b._id === id));
		// delete filter[0];
		// console.log("filter", filter);
		// for (const key in filter) {
		//   console.log(`${key}:${filter[key]} `);
		// }
		//const entries = Object.entries(filter);
		// const updatedEntries = delete entries[0];
		// console.log("entries", entries);
		// console.log("after", initialValue);
	}

	function closeEditModal() {
		setEditModalOpen(false);
		// setInitialValue(initialState);
		reset(initialState);
		getAllBranches();
	}
	const deleteHandler = (id) => {
		const deleteData = branchDetails?.filter((b) => b._id === id);
		console.log('deleteData', deleteData);
	};

	console.log('id', locationId);
	const submitHandler = async (data) => {
		console.log(data);
		try {
			const resp = await postApi(data, '/company/locations');
			if (resp.type === 'success') {
				console.log(resp);
				setLocationId(resp.locations);

				dispatch(
					showSnack('COMPANY_BRANCH_ADDED', {
						label: 'Company Branch Added Successfully',
						timeout: 7000,
					})
				);
				reset(initialState);
				form.resetFields();
				setModalIsOpen(false);
				getAllBranches();
			} else {
				return dispatch(
					showSnack('COMPANY_BRANCH_ADDED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (error) {
			return dispatch(
				showSnack('COMPANY_BRANCH_ERROR', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};

	const CompanyBranchColumns = [
		{
			title: 'Branch ID',
			dataIndex: 'BranchId',
			key: '1',
		},
		{
			title: 'Building No.',
			dataIndex: 'BuildingNo',
			key: '2',
		},
		{
			title: 'Street',
			dataIndex: 'Street',
			key: '3',
		},
		{
			title: 'Locality/Area',
			dataIndex: 'Locality',
			key: '4',
		},
		{
			title: 'Pincode',
			dataIndex: 'Pincode',
			key: '5',
		},
		{
			title: 'City',
			dataIndex: 'City',
			key: '6',
		},
		{
			title: 'State',
			dataIndex: 'State',
			key: '7',
		},
		{
			title: 'Poc',
			dataIndex: 'Poc',
			key: '8',
		},
		{
			title: 'Contact',
			dataIndex: 'Contact',
			key: '9',
		},
		{
			title: 'Action',
			dataIndex: 'Action',
			key: '10',
		},
	];

	const CompanyBranchesData = curItem.map((data, index) => {
		return {
			key: index,
			BranchId: data?.branchId,
			BuildingNo: data?.address1,
			Street: data?.address2,
			Locality: data?.address3,
			Pincode: data?.pincode,
			City: data?.city,
			State: data?.state,
			Poc: data?.contact?.firstName,
			Contact: data?.contact?.phone,
			Action: (
				<>
					<CheckPermission requiredPermission={COMPANY_BRANCHES_EDIT}>
						<FiEdit style={{ cursor: 'pointer' }} onClick={() => openEditModal(data?._id)} />
					</CheckPermission>
					{/* <CheckPermission requiredPermission={COMPANY_BRANCHES_DELETE}>
						<AiFillDelete
							style={{ cursor: 'pointer' }}
							className="edit-icon"
							onClick={() => deleteHandler(data?._id)}
						/>
					</CheckPermission> */}
				</>
			),
		};
	});

	console.log(curItem);
	console.log('branchDetails', branchDetails);

	const onNameChange = (event) => {
		setTitle(event.target.value);
	};
	const addItem = (e) => {
		e.preventDefault();
		setTitleItems(title ? [...titleItems, title] : [...titleItems]);
		setTitle('');
		setTimeout(() => {
			inputRef.current?.focus();
		}, 0);
	};
	const onCodeChange = (event) => {
		setCode(event.target.value);
	};
	const addItemCode = (e) => {
		e.preventDefault();
		setCodeItems(code ? [...codeItems, code] : [...codeItems]);
		setCode('');
		setTimeout(() => {
			inputRef.current?.focus();
		}, 0);
	};
	// console.log("initialDetails", initialValue[0]);
	return (
		<>
			{/* <HeaderComponent /> */}
			<div className="company-branch">
				{/* <div className="account-page-title">
						<div className="account-page-title-container">Account Settings</div>
					</div> */}
				<div className="size-div">
					<div className="company-branch-container">
						<div className="company-heading">
							<p>Company Branches</p>
						</div>
						<div className="search">
							<div className="search-input">
								<Input prefix={<CiSearch />} />
							</div>
							{/* <div className="search-bar">
							<AiOutlineSearch className="font" />
							<input type="search" placeholder="Search" />
						</div> */}
							<div>
								{/* <GrFormAdd className="fontAdd" /> */}
								<CheckPermission requiredPermission={COMPANY_BRANCHES_CREATE}>
									<Button className="ant-design-button-small" onClick={openModal}>
										+ Add New
									</Button>
								</CheckPermission>
								<Drawer
									title="Add Company Branch"
									width={800}
									destroyOnClose={true}
									onClose={() => {
										setModalIsOpen(false);
									}}
									open={modalIsOpen}
									styles={{
										body: {
											paddingBottom: 80,
										},
									}}
									extra={
										<Space className="mobile-hide-btns">
											<Button
												onClick={() => {
													setModalIsOpen(false);
												}}
											>
												Cancel
											</Button>
											<Button
												htmlType="submit"
												type="primary"
												onClick={() => form.submit()}
												style={{ backgroundColor: '#33D7DB', border: 'none', color: 'black' }}
											>
												Submit
											</Button>
										</Space>
									}
								>
									<div className="company-branch-div">
										<Form form={form} onFinish={submitHandler} layout="vertical" hideRequiredMark>
											<div className="companyInfo-div" style={{ display: 'flex' }}>
												<div className="company-details" style={{ paddingRight: '30px' }}>
													<h3>Company Info</h3>
													<p>Assign unique ID to your company branches for easier tracking</p>
												</div>
												<div style={{ float: 'right' }}>
													{/* <Row gutter={12}> */}
													{/* <Col span={19}> */}

													<Form.Item
														name="branchId"
														label="Branch ID"
														rules={[
															{
																required: true,
																message: 'Provide Branch ID',
															},
														]}
													>
														<Input
															// style={{ width: "380px" }}
															placeholder="Provide Branch ID"
														/>
													</Form.Item>
													{/* </Col> */}
													{/* </Row> */}
												</div>
											</div>
											<div className="addressInfo-div" style={{ display: 'flex' }}>
												<div className="address-info-details">
													<h3>Address Info</h3>
													<p>Enter the branch Details </p>
												</div>
												<div>
													<Row gutter={12}>
														<Col span={12}>
															<Form.Item
																name="address1"
																label="Building No."
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Provide Building No.',
																// 	},
																// ]}
															>
																<Input placeholder="Provide Building No." />
															</Form.Item>
														</Col>
														<Col span={12}>
															<Form.Item
																name="address2"
																label="Street"
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Provide Street',
																// 	},
																// ]}
															>
																<Input placeholder="Provide Street" />
															</Form.Item>
														</Col>
													</Row>
													{/* <Col span={12}> */}
													<Form.Item
														name="address3"
														label="Locality/Area"
														// rules={[
														// 	{
														// 		required: true,
														// 		message: 'Provide Locality/Area',
														// 	},
														// ]}
													>
														<Input
															style={{ width: '100%' }}
															placeholder="Provide Locality/Area"
														/>
													</Form.Item>
													{/* </Col> */}
													<Row gutter={16}>
														<Col span={12}>
															<Form.Item
																name="pincode"
																label="Pincode"
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Provide Pincode',
																// 	},
																// ]}
															>
																<Input placeholder="Provide Pincode" />
															</Form.Item>
														</Col>
														<Col span={12}>
															<Form.Item
																name="city"
																label="City"
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Provide City',
																// 	},
																// ]}
															>
																<Input placeholder="Provide City" />
															</Form.Item>
														</Col>
													</Row>
													{/* <Col span={12}> */}
													<Form.Item
														name="state"
														label="State"
														// rules={[
														// 	{
														// 		required: true,
														// 		message: 'Provide State',
														// 	},
														// ]}
													>
														<Input style={{ width: '100%' }} placeholder="Provide State" />
													</Form.Item>
													{/* </Col> */}
												</div>
											</div>
											<div className="contactInfo-div" style={{ display: 'flex' }}>
												<div style={{ paddingRight: '20px' }} className="company-details">
													<h3>Contact Info</h3>
													<p>Enter the branch contact person details </p>
												</div>
												<div style={{ width: '380px' }}>
													{/* <Row gutter={12}> */}

													<div className="name-div">
														<Col span={6}>
															<Form.Item
																name={['contact', 'title']}
																label="Title"
																// rules={[
																// 	{
																// 		required: true,
																// 	},
																// ]}
															>
																<Select
																	// style={{
																	// 	width: 300,
																	// }}
																	placeholder="Title"
																	dropdownRender={(menu) => (
																		<>
																			{menu}
																			<Divider
																				style={{
																					margin: '8px 0',
																				}}
																			/>
																			<Space
																				style={{
																					// padding: '0 8px 4px',
																					display: 'flex',
																					flexDirection: 'column',
																				}}
																			>
																				<Input
																					placeholder="Title"
																					ref={inputRef}
																					value={title}
																					onChange={onNameChange}
																					onKeyDown={(e) =>
																						e.stopPropagation()
																					}
																				/>
																				<Button
																					type="primary"
																					onClick={addItem}
																				>
																					Add Title
																				</Button>
																			</Space>
																		</>
																	)}
																	options={titleItems.map((item) => ({
																		label: item,
																		value: item,
																	}))}
																/>
																{/* <Select
																	options={[
																		{
																			label: "Mr.",
																			value: "Mr."
																		},
																		{
																			label: "Mrs.",
																			value: "Mrs."
																		},
																		{
																			label: "Ms.",
																			value: "Ms."
																		}
																	]}
																	placeholder="Title"
																/> */}
															</Form.Item>
														</Col>
														{/* <Col span={7}> */}
														<Form.Item
															name={['contact', 'firstName']}
															label="First Name"
															// rules={[
															// 	{
															// 		required: true,
															// 		message: 'Provide First Name',
															// 	},
															// ]}
														>
															<Input placeholder="First Name" />
														</Form.Item>
														{/* </Col> */}
														{/* <Col span={7}> */}
														<Form.Item
															name={['contact', 'lastName']}
															label="Last Name"
															// rules={[
															// 	{
															// 		required: true,
															// 		message: 'Provide Last Name',
															// 	},
															// ]}
														>
															<Input placeholder="Last Name" />
														</Form.Item>
														{/* </Col> */}
													</div>
													{/* </Row> */}
													<div className="phone-no-div">
														{/* <Row gutter={12}> */}
														<Col span={7}>
															<Form.Item
																label="Country Code"
																name={['contact', 'countryCode']}
																// rules={[
																// 	{
																// 		required: true,
																// 	},
																// ]}
															>
																<Select
																	// style={{
																	// 	width: 300,
																	// }}
																	placeholder="Code"
																	dropdownRender={(menu) => (
																		<>
																			{menu}
																			<Divider
																				style={{
																					margin: '8px 0',
																				}}
																			/>
																			<Space
																				style={{
																					display: 'flex',
																					flexDirection: 'column',
																				}}
																			>
																				<Input
																					placeholder="Code"
																					ref={inputRef}
																					value={code}
																					onChange={onCodeChange}
																					onKeyDown={(e) =>
																						e.stopPropagation()
																					}
																				/>
																				<Button
																					type="primary"
																					onClick={addItemCode}
																				>
																					Add Code
																				</Button>
																			</Space>
																		</>
																	)}
																	options={codeItems.map((item) => ({
																		label: item,
																		value: item,
																	}))}
																/>
																{/* <Select
																	options={[
																		{
																			label: "+91",
																			value: "+91"
																		},
																	]}
																	placeholder='Code'
																>
																</Select> */}
															</Form.Item>
														</Col>
														<Col span={16}>
															<Form.Item
																name={['contact', 'phone']}
																label="Contact Number"
																// rules={[
																// 	{
																// 		required: true,
																// 		message: 'Provide Phone Number',
																// 	},
																// ]}
															>
																<Input placeholder="Provide Phone Number" />
															</Form.Item>
														</Col>
														{/* </Row> */}
													</div>
												</div>
											</div>
											<div className="drawer-mobile-btns">
												<Button
													style={{ height: '40px', width: '100px', borderRadius: '3px' }}
													size="large"
													onClick={() => {
														setModalIsOpen(false);
													}}
												>
													Cancel
												</Button>
												<Button
													type="primary"
													onClick={() => form.submit()}
													size="large"
													className="ant-design-button"
													style={{ height: '40px', width: '100px' }}
												>
													Submit
												</Button>
											</div>
										</Form>
									</div>
								</Drawer>
								<Modal
									// isOpen={modalIsOpen}
									onRequestClose={closeModal}
									style={customStyles}
									ariaHideApp={false}
									contentLabel="Add Company Branch Modal"
								>
									<div className="modal-form-container">
										<div className="company-info">
											<div className="company-details">
												<h3>Company Info</h3>
												<p>Assign unique ID to your company branches for easier tracking</p>
											</div>
											<div className="company-id">
												<div className="branch">
													<span>Branch ID</span>

													<input
														type="text"
														{...register('branchId', {
															required: true,
														})}
													/>
												</div>
											</div>
										</div>
										<div className="address-info">
											<div className="address-info-details">
												<h3>Address Info</h3>
												<p>Enter the branch Details </p>
											</div>
											<div className="address-details">
												<div className="street-container">
													<div className="branch">
														<span>Building No.</span>
														<input
															type="text"
															{...register('address1', {
																required: true,
															})}
														/>
													</div>

													<div className="branch">
														<span>Street</span>
														<input
															type="text"
															{...register('address2', {
																required: true,
															})}
														/>
													</div>
												</div>
												<div className="area-container">
													<div className="branch">
														<span>Locality/Area</span>
														<input
															type="text"
															{...register('address3', {
																required: true,
															})}
														/>
													</div>
												</div>
												<div className="street-container">
													<div className="branch">
														<span>Pincode</span>
														<input
															type="text"
															{...register('pincode', {
																required: true,
															})}
														/>
													</div>
													<div className="branch">
														<span>City</span>
														<input
															type="text"
															{...register('city', {
																required: true,
															})}
														/>
													</div>
												</div>
												<div className="area-container">
													<div className="branch">
														<span>State</span>
														<input
															type="text"
															{...register('state', {
																required: true,
															})}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="contact-info">
											<div className="company-details">
												<h3>Contact Info</h3>
												<p>Enter the branch contact person details </p>
											</div>
											<div className="info-container">
												<div className="name-container">
													<select
														className="select-one"
														{...register('contact.title', {
															required: true,
														})}
													>
														<option value="Mr">Mr</option>
														<option value="Mrs">Mrs</option>
													</select>
													<div className="branch">
														<span>First Name</span>
														<input
															className="contact"
															type="text"
															{...register('contact.firstName', {
																required: true,
															})}
														/>
													</div>
													<div className="branch">
														<span>Last Name</span>
														<input
															className="contact"
															type="text"
															{...register('contact.lastName', {
																required: true,
															})}
														/>
													</div>
												</div>
												<div className="mobile-container">
													<select
														className="select-one"
														{...register('contact.countryCode', {
															required: true,
														})}
													>
														<option value="+91">+91</option>
													</select>
													<div className="branch">
														<span>Contact Number</span>
														<input
															className="contact"
															type="text"
															{...register('contact.phone')}
														/>
													</div>
												</div>
											</div>
										</div>
										<div className="btn-container">
											<button onClick={closeModal} className="cancel-btn">
												Cancel
											</button>
											<button className="save-btn" onClick={handleSubmit(submitHandler)}>
												Save
											</button>
										</div>
									</div>
								</Modal>
								<EditModal
									branchDetails={branchDetails}
									closeEditModal={closeEditModal}
									openEditModal={openEditModal}
									editModalOpen={editModalOpen}
									updateId={updateId}
									filteredData={filteredData}
								/>
							</div>
						</div>
					</div>
					{/* <DataTable /> */}
					<div className="company-branches-table">
						<Table dataSource={CompanyBranchesData} columns={CompanyBranchColumns} scroll={{ x: 1250 }} />
					</div>
				</div>
				{/* <div className="table-container">
						<table>
							<thead>
								<tr>
									<th className="companyHeader">Branch ID</th>
									<th className="companyHeader">BUILDING NO.</th>
									<th className="companyHeader">STREET</th>
									<th className="companyHeader locality">LOCALITY/AREA</th>
									<th className="companyHeader">PINCODE</th>
									<th className="companyHeader">CITY</th>
									<th className="companyHeader">STATE</th>
									<th className="companyHeader">POC</th>
									<th className="companyHeader">CONTACT</th>
									<th className="companyHeader">Action</th>
								</tr>
							</thead>
							<tbody>
								{curItem &&
									curItem.map((branch) => {
										return (
											<tr key={branch._id}>
												<td className="companyData checkbox">
													{branch.branchId}
												</td>
												<td className="companyData">{branch.address1}</td>
												<td className="companyData">{branch.address2} </td>
												<td className="companyData">{branch.address3}</td>
												<td className="companyData">{branch.pincode}</td>
												<td className="companyData">{branch.city}</td>
												<td className="companyData">{branch.state}</td>
												<td className="companyData">
													{branch.contact && branch.contact.firstName}
												</td>
												<td className="companyData">{branch.contact && branch.contact.phone}</td>
												<td className="companyData">
													<FiEdit
														style={{ cursor: 'pointer' }}
														onClick={() => openEditModal(branch._id)}
													/>
													<AiFillDelete
														style={{ cursor: 'pointer' }}
														className="edit-icon"
														onClick={() => deleteHandler(branch._id)}
													/>
												</td>
											</tr>
										);
									})}
							</tbody>
						</table>
					</div> */}
				{/* <ReactPaginate
						pageCount={count}
						onPageChange={handlePageClick}
						className="pagination"
						pageClassName="page-item"
						pageLinkClassName="page-link"
						activeClassName="active"
						activeLinkClassName="active"
						nextLinkClassName="page-link"
						nextClassName="page-item"
						previousLinkClassName="page-link"
						previousClassName="page-item"
					/> */}
			</div>
		</>
	);
};

export default CompanyBranch;
