import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import EarlyAccessModel from '../EarlyAccess';
import './index.css';

const LandingScreenFooter = function () {
	const navigate = useNavigate();
	const { pathname } = useLocation();
	return (
		<div className="app-container-4">
			<h2 className="head-1">
				See how you can right-size <br />
				your IT asset management quickly and effectively
			</h2>
			<div className="actions">
				<EarlyAccessModel content={'Book A Demo'} className="footer-signup" />
				{/* <button className="request-demo">Request Demo</button> */}
			</div>
			<div className="footer-actions">
				<div
					onClick={() => {
						if (!pathname.includes('/terms')) {
							navigate('/terms');
						}
					}}
				>
					Terms & Conditions
				</div>
				<div
					onClick={() => {
						if (!pathname.includes('/privacy')) {
							navigate('/privacy');
						}
					}}
				>
					Privacy Policy
				</div>
				<div
					onClick={() => {
						if (!pathname.includes('/faqs')) {
							navigate('/faqs');
						}
					}}
				>
					FAQs
				</div>
				<div
					onClick={() => {
						if (!pathname.includes('/faqs')) {
							navigate('/contact-us');
						}
					}}
				>
					Contact Us
				</div>
			</div>
			<div className="copyright">© 2021 Copyright SP Ecorework, Inc All rights reserved </div>
		</div>
	);
};

export default LandingScreenFooter;
