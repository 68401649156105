import * as React from 'react';
const MomoImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={242} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M82.682.866h18.6c7.328 2.735 15.02 4.968 18.354 13.486.541-2.784.718-5.292.492-7.761-.285-3.187.571-4.643 4.21-4.475 6.01.285 12.059.236 18.079.01 2.813-.109 
4.062.835 4.8 3.462 1.367 4.869 3.069 9.64 5.075 15.787 2.036-6.178 3.718-10.948 5.164-15.787.689-2.292 1.653-3.482 4.358-3.404 6.511.197 13.032.158 19.554.02 2.616-.059 
3.403.944 3.285 3.384-.158 3.334-.039 6.678-.039 10.023 4.977-8.931 6.767-9.974 16.563-13.554 6.522-2.38 14.115-2.587 20.509-1.2 8.193 2.813 15.836 6.275 18.6 15.452.747 4.072 
1.672 8.016 0 12.56-3.817 7.447-9.63 12.276-18.227 13.348-5.35.66-10.711.826-16.101.285-9.335-.944-17.095-4.091-20.371-13.76-.039-.108-.531-.06-.973-.099 0 3.05-.148 6.099.049 
9.118.177 2.764-.423 4.151-3.679 3.935-4.22-.276-8.479-.187-12.708-.03-2.489.089-3.472-.639-3.364-3.236.187-4.75.049-9.521.049-14.282-2.449 4.456-4.387 9-5.597 13.722-.914 
3.57-3.019 4.062-6.069 3.767-3.137-.305-6.826 1.318-8.045-3.748-1.122-4.652-3.168-9.098-4.8-13.632-.925 5.586 1.032 12.167-1.446 15.855-2.725 4.053-9.876.836-15.04 
1.505-.324.04-.649-.02-.973.01-2.105.187-2.902-.777-2.833-2.823.108-2.95.029-5.901.029-8.852-.373-.256-.757-.512-1.131-.768-5.174 13.515-16.711 13.604-28.013 
13.545-11.872-.06-22.83-2.213-26.43-17.086-.747 6.03 1.968 12.404-1.229 15.138-3.305 2.823-9.659.62-14.675.846-.325.01-.66-.03-.974 
0-2.351.266-3.138-.777-3.079-3.059.128-4.76.04-9.521.04-14.282-2.341 4.18-4.122 8.44-5.292 12.856-.964 3.62-2.784 4.918-6.541 
4.554-3.463-.334-7.043.738-8.095-4.466-.866-4.308-2.931-8.38-4.466-12.55-.875.983-.836 1.622-.885 2.252-.345 4.75 2.134 11.134-1.023 13.84-3.295 
2.822-9.669.54-14.676.875-2.665.177-3.737-.62-3.698-3.463.118-10.76.098-21.54-.01-32.32-.02-2.578.57-3.807 3.502-3.729 6.354.177 12.718.177 19.072-.01 2.705-.078 3.669 1.132 
4.348 3.433 1.465 4.958 3.177 9.846 5.124 15.797 2.076-6.276 3.846-11.272 5.36-16.357.62-2.076 1.683-2.833 3.817-2.804 6.679.089 13.367.099 20.046 0 2.331-.03 3.167.817 3.098 
3.099-.127 3.993-.039 7.996-.039 12C66.394 7.713 79.053 1.575 82.682.866Zm16.555 22.26c.216-5.4-1.928-8.43-6.109-8.647-4.662-.236-7.377 2.39-7.564 7.319-.216 5.891 2.105 9.383 
6.384 9.62 4.377.255 7.072-2.804 7.289-8.293Zm106.603-.788c-.04 5.912 2.459 9.217 6.875 9.099 4.19-.108 6.758-3.413 6.836-8.843.079-5.183-2.38-8.164-6.708-8.115-4.623.06-6.974 
2.696-7.003 7.86ZM4.19 59.933H.462L5.38 46.438h5.341l-.059 9.698 6.866-9.699h5.134l-4.918 13.496h-3.718l3.089-8.48-6.325 8.48h-3l-.521-8.46-3.079 8.46ZM21.069 
59.933l4.918-13.495H36.58l-.747 2.045h-6.876l-1.337 3.68h5.026l-.748 2.045h-5.026l-1.338 3.679h6.876l-.748 2.046H21.07ZM47.095 56.706c-.324.895-1.17 1.653-2.547 
2.282-1.377.63-2.863.945-4.486.945H34.21l4.918-13.495h5.852c1.623 0 2.892.314 3.797.944.905.63 1.2 1.387.876 2.272l-2.558 7.052Zm-8.42 1.22h2.243a3.7 3.7 0 0 0 
1.623-.354c.492-.236.797-.512.915-.826l2.577-7.063c.118-.324.01-.6-.315-.826-.334-.226-.787-.344-1.357-.344h-2.243l-3.443 9.413ZM52.908 59.932H49.19l4.919-13.485h3.718l-4.919 
13.485ZM61.938 56.834l-2.036 3.098h-3.876l9.109-13.485h5.124l-.777 13.485h-3.915l.256-3.098h-3.885Zm4.613-7.298-3.315 5.321h2.754l.561-5.321ZM88.722 53.736l-4.151.728 2.823 
2.93-3.521 2.607-1.948-3.718-1.987 3.718-3.58-2.606 3.01-2.98-4.092-.68 1.406-4.091 3.718 1.82-.6-4.2h4.309l-.6 4.259 3.875-1.87 1.338 4.083Z"
		/>
	</svg>
);
export default MomoImg;
