import React from 'react';
import LandingPageHeader from '../../components/LandingpageHeader';
import LandingScreenFooter from '../../components/LandingFooter';
import EarlyAccessModel from '../../components/EarlyAccess';
import SignupToAssetze from '../../components/SignupToAssetze';

import { IntegrationsComponent } from '../../screens/LandingScreen/IntegrationsComponent';

const DiagnosticToolScren = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />
			<div className="feature-incident-container">
				<div className="content-container">
					<div className="content-text-container">
						<h1>Assetze Diagnostic tool</h1>
						<div className="description">
							ADT is an advance intelligent tool that allows you to monitor realtime incidents, predict &
							prevent system failures.
						</div>
						<EarlyAccessModel className="signup-button" content="Sign Up" />
					</div>
					<div className="image-container">
						<img src={require('../../images/features/adt.png')} />
					</div>
				</div>
				<div style={{ backgroundColor: '#F9F9F9' }} className="content-container">
					<div className="content-text-container">
						<h1>Features</h1>
						<ul>
							<li>Checks the organisations asset health in realtime</li>
							<li>
								Our predictive model analysis thousands of systems realtime and notify on the system
								failure in the future.
							</li>
							<li>
								ADT is like the blackbox for organisation systems, that capture the cause of failure and
								help prevent in future cases.
							</li>
						</ul>
					</div>
					<div className="image-container">
						<img src={require('../../images/features/adt-feature.png')} />
					</div>
				</div>
				<div className="content-container">
					<div className="image-container">
						<img src={require('../../images/features/adt-privacy.png')} />
					</div>
					<div style={{ paddingLeft: '50px' }} className="content-text-container">
						<h1>Privacy</h1>
						<ul>
							<li>
								All the organisation data are stored in the respective company profiles that are
								securely stored in cloud.
							</li>
							<li>
								The crash reports are unianimously collected to create a data map of report in order to
								predict the future system failures.
							</li>
						</ul>
					</div>
				</div>
				<div style={{ backgroundColor: '#fafafa' }} className="features-highlights">
					<div className="each-highlight">
						<img src={require('../../images/features/adt-health.png')} />
						<h2>Health Detection</h2>
						<div className="description">
							ADT detects the hardware health through our intelligent software, it provide data on
							productivity & efficiency of the machine along with the alert on health if the efficiency is
							decreasing.
						</div>
					</div>
					<div className="each-highlight">
						<img src={require('../../images/features/adt-consulting.png')} />
						<h2>Consulting</h2>
						<div className="description">
							Our data backed consulting helps you choose the right machine for your each employee. Which
							helps in
							<ul>
								<li>Saving cost to the company</li>
								<li>Increased employee productivity</li>
								<li>Increased lifespan of the devices</li>
							</ul>
						</div>
					</div>
				</div>
				<SignupToAssetze />
				<IntegrationsComponent />
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default DiagnosticToolScren;
