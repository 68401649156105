import React from 'react';
import { useNavigate } from 'react-router-dom';

const ConnectWitTeam = () => {
	const navigate = useNavigate();
	return (
		<div className="connect-with-team">
			<div className="container">
				<div className="image-container">
					<img src={require('../../../images/new-landing/expert-team.png')} />
				</div>
				<div className="text-content">
					<div className="head-1">Want to streamline your IT process and simplify IT management?</div>
					<div className="head-2">Get on call with us for a free consultation</div>
					<button onClick={() => navigate('/contact-us')} className="expert-btn">
						{' '}
						Talk to our expert
					</button>
				</div>
			</div>
		</div>
	);
};

export default ConnectWitTeam;
