import * as React from 'react';
const LkqImg = (props) => (
	<svg width="219" height="60" viewBox="0 0 219 60" fill="none" {...props} xmlns="http://www.w3.org/2000/svg">
		<g clip-path="url(#clip0_6219_6330)">
			<path
				d="M218.854 34.0604C217.225 36.4825 215.587 38.898 213.976 41.3318C213.724 41.7132 213.467 41.8351 213.022 41.7814C208.394 41.2296 204 40.0225 200.159 37.2505C199.924 37.0814 199.713 37.0565 199.433 37.1312C192.927 38.8914 186.309 39.1758 179.643 38.4733C174.773 37.9596 170.086 36.7525 165.815 34.2465C161.823 31.9044 158.736 28.7444 157.442 24.2057C155.744 18.2422 157.045 12.8974 161.263 8.33768C164.71 4.6102 169.148 2.60492 173.964 1.33228C176.542 0.650748 179.173 0.279835 181.835 0.127801C181.954 0.121247 182.105 0.174984 182.16 -0.00195312C185.13 -0.00195312 188.101 -0.00195312 191.071 -0.00195312C191.146 0.206439 191.325 0.118626 191.462 0.12649C194.234 0.285078 196.968 0.691378 199.636 1.46597C204.236 2.8002 208.379 4.93525 211.647 8.52903C214.189 11.3259 215.808 14.5737 215.935 18.3903C216.083 22.8544 214.614 26.7693 211.526 30.0459C211.115 30.4823 210.605 30.8323 210.319 31.3827C210.873 31.6344 211.424 31.8952 211.984 32.1364C214.179 33.0813 216.438 33.7668 218.855 33.797C218.854 33.8861 218.854 33.9726 218.854 34.0604ZM186.257 29.5229C186.987 29.5347 187.945 29.4915 188.897 29.346C191.484 28.9502 193.83 28.0367 195.64 26.0668C197.67 23.8596 198.335 21.1925 198.141 18.2632C197.873 14.2081 195.593 11.2014 191.725 9.97992C188.358 8.91698 184.931 8.87504 181.536 9.85409C178.816 10.6392 176.721 12.2578 175.476 14.8608C174.504 16.8909 174.272 19.0404 174.566 21.2436C175.033 24.7483 176.822 27.2686 180.186 28.5662C182.068 29.2936 184.028 29.5282 186.257 29.5229Z"
				fill="#888888"
			/>
			<path
				d="M2.56835e-05 2.35742C3.27664 2.36266 6.55194 2.37184 9.82855 2.37184C17.2114 2.37446 24.593 2.37184 31.9758 2.37184C32.1881 2.37184 32.4018 2.37184 32.6141 2.37184C32.622 2.40067 32.6298 2.4282 32.6377 2.45703C31.7373 2.80304 30.8421 3.16085 29.9365 3.49113C26.8748 4.6078 24.3544 6.58556 21.7974 8.524C19.6269 10.1702 17.564 11.9684 15.1983 13.3472C12.3922 14.9829 9.61098 16.6592 6.88222 18.4207C6.33044 18.7759 5.90055 19.1638 5.62138 19.8218C5.06829 21.1285 4.45491 22.409 4.19802 23.8114C3.47061 27.7734 5.53095 31.244 9.39866 32.5953C11.7133 33.404 14.0646 33.3647 16.429 32.8483C16.6177 32.8076 16.8444 32.6477 16.986 32.8037C17.2848 33.1314 17.6793 33.3332 17.9873 33.632C18.1341 33.7736 17.8694 33.8719 17.7737 33.9584C16.872 34.7854 15.9873 35.6281 15.2245 36.5888C15.0829 36.7671 14.9571 36.9571 14.8614 37.1904C21.0057 34.0305 27.2405 31.0946 33.6351 28.5074C40.0389 25.9163 46.5397 23.6148 53.2332 21.8965C53.3734 22.1416 53.3157 22.3618 53.3157 22.5715C53.3197 27.4183 53.3066 32.2663 53.3341 37.1131C53.338 37.7055 53.1716 37.8235 52.6067 37.8235C35.352 37.8077 18.0974 37.8104 0.84146 37.8117C0.559671 37.8117 0.277882 37.8405 -0.00390625 37.8562C2.56835e-05 26.025 2.56835e-05 14.1912 2.56835e-05 2.35742Z"
				fill="#888888"
			/>
			<path
				d="M111.393 59.9994C111.541 58.808 112.022 57.7057 112.333 56.5589C112.655 55.3636 113.089 54.1958 113.291 52.9704C113.355 52.5824 113.527 52.4736 113.894 52.5038C114.262 52.5339 114.637 52.5418 115.004 52.5025C115.488 52.4514 115.714 52.5903 115.527 53.1788C116.156 52.8734 116.702 52.5785 117.328 52.4789C117.938 52.3819 118.539 52.3714 119.143 52.513C120.638 52.8629 121.287 54.0857 120.68 55.4973C120.05 56.9613 118.79 57.5943 117.322 57.901C116.527 58.0675 115.72 58.1094 114.925 57.9089C114.535 57.8106 114.37 57.9285 114.301 58.3034C114.197 58.8774 113.891 59.4017 113.885 60.0007C113.052 59.9994 112.223 59.9994 111.393 59.9994ZM115.832 56.7844C116.616 56.7765 117.225 56.4973 117.679 55.9888C118.294 55.3007 118.45 54.3832 118.018 53.9822C117.532 53.53 116.532 53.6742 115.864 54.2666C115.321 54.7463 115.177 55.4042 114.974 56.0504C114.83 56.5091 114.979 56.7018 115.427 56.7372C115.6 56.7516 115.773 56.7778 115.832 56.7844Z"
				fill="#888888"
			/>
			<path
				d="M122.517 15.0027C124.197 13.6789 125.768 12.4417 127.338 11.2031C131.366 8.02876 135.394 4.85438 139.419 1.67607C139.664 1.48209 139.904 1.34709 140.24 1.3484C147.098 1.35758 153.955 1.35627 160.812 1.35627C160.853 1.35627 160.894 1.37069 160.977 1.3851C160.943 1.64199 160.706 1.7049 160.551 1.8163C154.58 6.10473 148.61 10.3945 142.622 14.6593C142.108 15.025 142.048 15.2019 142.496 15.6882C148.985 22.7329 155.452 29.7959 161.918 36.8603C162.101 37.0595 162.355 37.2194 162.436 37.5812C162.2 37.5812 161.992 37.5812 161.783 37.5812C155.69 37.5812 149.597 37.5759 143.505 37.5917C143.044 37.593 142.753 37.4527 142.459 37.1028C138.67 32.5902 134.863 28.0934 131.075 23.5822C130.8 23.2545 130.635 23.1785 130.255 23.455C127.805 25.2428 125.341 27.0082 122.865 28.7605C122.564 28.9729 122.514 29.2062 122.515 29.5273C122.523 31.9939 122.507 34.4605 122.53 36.9272C122.534 37.4134 122.447 37.5969 121.898 37.5956C116.482 37.572 111.067 37.5733 105.65 37.5943C105.109 37.5969 105.009 37.4226 105.009 36.9285C105.021 25.2926 105.022 13.6567 105.006 2.02077C105.005 1.46112 105.177 1.34054 105.706 1.34185C111.079 1.3602 116.451 1.36413 121.823 1.33792C122.42 1.3353 122.535 1.51224 122.532 2.07188C122.506 6.33148 122.517 10.5871 122.517 15.0027Z"
				fill="#888888"
			/>
			<path
				d="M82.0647 37.5825C75.8195 37.5825 69.5756 37.5759 63.3303 37.5956C62.789 37.5969 62.6436 37.4645 62.6449 36.9153C62.6619 25.2821 62.6619 13.6488 62.6436 2.01418C62.6423 1.44274 62.8284 1.34313 63.3474 1.34576C68.7184 1.3641 74.0894 1.36804 79.4604 1.34182C80.066 1.3392 80.1682 1.52925 80.1669 2.08234C80.1498 10.6159 80.1603 19.1509 80.1433 27.6845C80.142 28.2507 80.2442 28.4473 80.872 28.4446C87.5314 28.4184 94.1908 28.4355 100.85 28.4158C101.394 28.4145 101.537 28.5521 101.53 29.0973C101.5 31.6937 101.496 34.2927 101.533 36.8891C101.541 37.4881 101.355 37.5969 100.798 37.5956C94.5538 37.5746 88.3086 37.5825 82.0647 37.5825Z"
				fill="#888888"
			/>
			<path
				d="M108.634 45.1731C144.514 45.1731 180.396 45.1731 216.276 45.1823C216.552 45.1823 216.994 44.987 217.088 45.3435C217.201 45.7708 217.119 46.2518 217.096 46.7079C217.09 46.8298 216.938 46.835 216.83 46.8376C216.634 46.8429 216.437 46.8416 216.24 46.8416C144.458 46.8416 72.6751 46.8416 0.891087 46.8337C0.636822 46.8337 0.223969 47.0172 0.14664 46.6659C0.0470315 46.2125 0.0470315 45.717 0.175475 45.2504C0.188581 45.2019 0.365518 45.1823 0.469059 45.1784C0.708907 45.1679 0.948755 45.1744 1.18991 45.1744C37.0046 45.1731 72.8193 45.1731 108.634 45.1731Z"
				fill="#888888"
			/>
			<path
				d="M16.8993 13.616C16.7748 13.2189 16.9727 12.9843 17.307 12.73C19.9282 10.7418 22.5364 8.73516 25.1315 6.71284C25.4749 6.44547 25.7095 6.38387 26.0935 6.63813C28.0516 7.93698 29.5851 9.64213 30.9272 11.5439C31.1513 11.8611 30.9914 12.059 30.8315 12.2949C29.3963 14.4116 27.6846 16.3028 25.9991 18.2151C25.2639 19.0486 24.5037 19.8599 23.754 20.6791C23.6112 20.835 23.5037 21.0133 23.214 20.8953C20.4132 19.7485 18.0763 18.1272 17.1366 15.0498C16.9963 14.5898 16.8967 14.1232 16.8993 13.616Z"
				fill="#888888"
			/>
			<path
				d="M26.0073 21.5386C27.8278 19.2607 29.761 17.0628 31.4373 14.6826C33.1412 12.2632 35.7074 11.4519 38.2317 10.4715C38.4702 10.3785 38.6301 10.4362 38.8005 10.621C39.7534 11.6603 40.5109 12.8137 40.9565 14.1623C41.0588 14.4716 41.0706 14.6695 40.718 14.8386C35.8752 17.1571 31.014 19.4364 26.0073 21.5386Z"
				fill="#888888"
			/>
			<path
				d="M36.5986 2.23603C38.4191 2.03157 40.0705 2.17443 41.7193 2.18098C41.8281 2.18098 41.9408 2.20982 42.0444 2.18885C44.4193 1.71177 46.1716 2.86776 47.7391 4.41301C48.9292 5.58604 49.7313 7.01857 50.3014 8.69358C48.6159 8.47863 47.0222 8.27679 45.4284 8.07233C44.4782 7.95044 43.5306 7.81282 42.5791 7.70797C42.1387 7.65948 41.8504 7.49434 41.5948 7.09721C40.3458 5.14697 38.7442 3.53881 36.5986 2.23603Z"
				fill="#888888"
			/>
			<path
				d="M31.7649 11.8271C31.6286 11.6121 31.5185 11.3985 31.3691 11.2176C31.1738 10.9817 31.2354 10.8611 31.491 10.7392C32.0598 10.4653 32.6221 10.1796 33.1791 9.88206C33.4216 9.7523 33.5644 9.78245 33.7007 10.0315C33.8999 10.3932 34.128 10.7405 34.3574 11.0839C34.495 11.2897 34.5055 11.443 34.2446 11.5374C32.5565 12.1442 31.6561 13.6017 30.6128 14.9031C28.7989 17.1666 26.985 19.4301 25.1815 21.7014C24.8775 22.0842 24.5367 22.3856 24.0976 22.615C23.0753 23.1484 22.0687 23.7146 21.07 24.29C20.7791 24.4577 20.5523 24.5102 20.2391 24.3201C19.3256 23.7657 18.4291 23.189 17.5706 22.5507C17.2181 22.2886 17.2836 22.1746 17.623 21.9832C18.5143 21.4813 19.3937 20.957 20.2666 20.4223C20.5222 20.2663 20.7122 20.2624 20.9731 20.4236C21.6782 20.8587 22.437 21.1864 23.2051 21.4957C23.4803 21.6058 23.6717 21.6005 23.8892 21.362C26.428 18.5729 29.0217 15.8324 31.2013 12.7393C31.4005 12.4575 31.5657 12.1508 31.7649 11.8271Z"
				fill="#888888"
			/>
			<path
				d="M160.911 56.9442C159.764 57.9206 158.523 58.2351 157.137 57.9022C155.829 57.5877 155.276 56.6008 155.69 55.3255C156.053 54.2036 156.891 53.5194 157.925 53.0778C159.879 52.2429 161.913 52.2901 163.968 52.6033C164.359 52.6623 164.272 52.8523 164.206 53.0935C163.874 54.2875 163.418 55.4487 163.227 56.6807C163.168 57.0634 163.265 57.6008 163.046 57.7987C162.727 58.087 162.175 57.8891 161.723 57.8983C160.871 57.9153 160.871 57.9022 160.911 56.9442ZM160.949 53.6439C159.879 53.6741 159.102 54.0542 158.537 54.8288C158.088 55.4461 158.036 56.0949 158.381 56.4527C158.738 56.8223 159.483 56.8183 160.086 56.4291C160.44 56.201 160.713 55.893 160.915 55.5195C161.124 55.1302 161.191 54.6938 161.344 54.2888C161.554 53.737 161.382 53.5614 160.949 53.6439Z"
				fill="#888888"
			/>
			<path
				d="M76.0252 50.4379C76.7697 50.3894 77.5075 50.4405 78.2415 50.5729C78.6019 50.6384 78.6308 50.7551 78.4866 51.0919C78.2101 51.7407 77.8863 52.0605 77.0855 51.8717C75.9911 51.6136 74.8732 51.7315 73.7984 52.105C73.0776 52.3554 72.4721 52.7656 72.0605 53.4222C71.2427 54.7263 71.7302 56.0278 73.2086 56.4459C74.2362 56.7369 75.286 56.6831 76.3227 56.5062C76.7369 56.4354 76.9898 56.4393 77.0134 56.9387C77.0318 57.3214 77.2834 57.7382 76.5849 57.8352C74.8181 58.0803 73.0592 58.1851 71.3383 57.6202C70.1142 57.2179 69.126 56.5219 68.9936 55.1038C68.8861 53.9635 69.3409 53.0146 70.1758 52.2531C71.4969 51.0487 73.1261 50.6148 74.8483 50.4431C75.2362 50.4051 75.632 50.4379 76.0252 50.4379Z"
				fill="#888888"
			/>
			<path
				d="M132.926 52.4237C133.674 52.4001 134.404 52.5023 135.096 52.7972C136.513 53.3988 136.934 54.7042 136.118 56.0136C135.505 56.9978 134.552 57.5024 133.466 57.7764C132.086 58.1263 130.702 58.1827 129.357 57.6322C127.91 57.0385 127.494 55.6702 128.374 54.3726C129.302 53.0056 130.734 52.5967 132.273 52.4276C132.487 52.404 132.707 52.4237 132.926 52.4237ZM131.799 56.7803C132.897 56.7842 133.897 55.8038 133.909 54.7068C133.914 54.1433 133.607 53.8222 133.076 53.7029C132.007 53.4617 131.003 54.0568 130.63 55.1485C130.291 56.1328 130.754 56.7777 131.799 56.7803Z"
				fill="#888888"
			/>
			<path
				d="M89.2129 52.3984C90.1159 52.4011 90.804 52.4915 91.462 52.7471C93.0072 53.346 93.4515 54.7419 92.5301 56.1272C92.0124 56.9057 91.2549 57.3618 90.3859 57.6449C88.9835 58.1024 87.5628 58.2007 86.146 57.7472C84.2836 57.1521 83.8379 55.3906 85.1814 53.9581C86.3242 52.7379 87.8302 52.4561 89.2129 52.3984ZM88.3217 56.7786C89.3925 56.7642 90.361 55.8166 90.3977 54.7353C90.4161 54.177 90.129 53.8362 89.5943 53.7065C88.5628 53.4548 87.5615 54.0079 87.1408 55.0551C86.7305 56.0761 87.222 56.7943 88.3217 56.7786Z"
				fill="#888888"
			/>
			<path
				d="M197.066 58.0376C196.281 58.0114 195.569 57.9314 194.893 57.6549C193.439 57.0624 193.01 55.7361 193.841 54.4058C194.21 53.816 194.727 53.3782 195.345 53.086C196.9 52.3533 198.52 52.1777 200.181 52.6561C201.035 52.9012 201.771 53.3363 201.956 54.2878C202.147 55.2747 201.744 56.0847 201.018 56.7295C199.875 57.7427 198.467 57.9838 197.066 58.0376ZM197.336 56.7807C198.347 56.7702 199.181 56.0113 199.375 54.9235C199.493 54.2629 199.2 53.8304 198.54 53.6954C197.51 53.4857 196.557 54.0296 196.153 55.0572C195.748 56.0873 196.231 56.7911 197.336 56.7807Z"
				fill="#888888"
			/>
			<path
				d="M212.392 53.2623C213.527 52.506 214.766 52.2085 216.116 52.5165C217.083 52.738 217.467 53.3016 217.305 54.2833C217.115 55.4235 216.68 56.4996 216.381 57.6123C216.326 57.8155 216.239 57.9059 216.028 57.9033C215.396 57.8967 214.763 57.8941 214.13 57.9033C213.725 57.9085 213.866 57.6595 213.919 57.4668C214.174 56.5664 214.458 55.6739 214.69 54.7682C214.894 53.9753 214.524 53.5768 213.72 53.7276C212.769 53.9058 212.21 54.5533 211.913 55.4314C211.683 56.1103 211.485 56.8023 211.31 57.4983C211.231 57.8115 211.092 57.9203 210.775 57.9072C210.231 57.8849 209.683 57.8784 209.141 57.9085C208.745 57.9308 208.713 57.7827 208.81 57.4537C209.255 55.9294 209.683 54.3999 210.122 52.8743C210.156 52.7577 210.146 52.5624 210.299 52.5571C210.952 52.5362 211.615 52.4772 212.255 52.5676C212.56 52.6109 212.166 53.0224 212.392 53.2623Z"
				fill="#888888"
			/>
			<path
				d="M18.8013 27.6298C17.9533 28.5564 17.1341 29.4253 16.7449 30.6036C16.6898 30.7701 16.5561 30.833 16.408 30.8828C13.2035 31.985 10.1353 31.7268 7.26499 29.8815C5.09325 28.4856 4.3606 26.5262 4.92287 23.8105C5.16009 24.171 5.07228 24.5851 5.18762 24.9547C5.88488 27.1946 7.52974 28.5315 9.55993 29.4411C11.2952 30.2183 13.0554 30.2196 14.8051 29.3572C15.9375 28.7989 17.1119 28.3283 18.2705 27.8224C18.4422 27.749 18.6243 27.694 18.8013 27.6298Z"
				fill="#888888"
			/>
			<path
				d="M5.60187 23.7213C5.46688 23.2625 5.74867 22.9755 6.18511 22.7094C7.07766 22.1642 7.94137 21.5731 8.81033 20.9912C9.05411 20.8274 9.18387 20.7893 9.33066 21.1262C9.87851 22.3792 10.6859 23.4604 11.6557 24.4172C11.9152 24.6728 11.9782 24.8065 11.6151 25.0253C10.3844 25.7698 9.16814 26.5365 7.95841 27.3137C7.69497 27.4828 7.53376 27.492 7.28605 27.2744C6.23491 26.3478 5.59663 25.2364 5.60187 23.7213Z"
				fill="#888888"
			/>
			<path
				d="M175.507 51.082C175.364 51.6142 175.248 52.0493 175.117 52.5355C175.713 52.5355 176.254 52.5355 176.789 52.5355C176.853 52.8501 176.682 53.0781 176.642 53.3311C176.583 53.7112 176.367 53.7938 176.003 53.7938C174.708 53.7951 174.708 53.8095 174.321 55.048C174.282 55.1726 174.243 55.2971 174.205 55.4216C173.88 56.4819 173.932 56.5776 175.022 56.6418C175.334 56.6601 175.378 56.7571 175.342 57.031C175.235 57.862 175.127 57.9721 174.298 58.0114C173.709 58.0389 173.122 58.0455 172.543 57.9026C171.653 57.6824 171.334 57.2211 171.537 56.3299C171.692 55.6523 171.893 54.9838 172.111 54.3246C172.233 53.955 172.254 53.744 171.762 53.7741C171.592 53.7846 171.206 53.9052 171.309 53.5421C171.409 53.1935 171.277 52.5578 171.811 52.5644C172.478 52.5722 172.826 52.3966 172.893 51.7203C172.908 51.5552 173.07 51.5027 173.236 51.4752C173.962 51.356 174.687 51.2249 175.507 51.082Z"
				fill="#888888"
			/>
			<path
				d="M16.1825 22.1761C16.0449 22.2705 15.9466 22.3452 15.8404 22.4107C14.8404 23.0254 13.8338 23.6296 12.8416 24.2561C12.59 24.4147 12.434 24.3911 12.2283 24.1853C11.2335 23.1919 10.4078 22.0857 9.82323 20.7999C9.6961 20.5195 9.74197 20.3871 9.99754 20.2311C10.9268 19.6675 11.8469 19.0869 12.7604 18.4984C13.0081 18.3385 13.1431 18.3149 13.3056 18.619C13.9675 19.8602 14.8666 20.9192 15.8968 21.8694C15.9885 21.9559 16.0685 22.0542 16.1825 22.1761Z"
				fill="#888888"
			/>
			<path
				d="M20.0961 19.8723C19.02 20.5132 18.0121 21.1096 17.0121 21.7164C16.8077 21.8396 16.6687 21.8134 16.4996 21.6587C15.4315 20.6849 14.5009 19.6023 13.801 18.3284C13.6569 18.0649 13.6857 17.9234 13.9557 17.7661C14.8378 17.2523 15.7106 16.7202 16.5757 16.1776C16.8247 16.0216 16.9322 16.061 17.0541 16.3244C17.7159 17.7622 18.75 18.8906 20.0961 19.8723Z"
				fill="#888888"
			/>
			<path
				d="M21.0464 32.7576C20.2652 32.7484 19.7187 32.7209 19.412 32.2045C19.0896 31.6619 19.3058 31.1271 19.6296 30.6763C20.6964 29.19 22.1408 28.3381 23.9927 28.3224C25.2064 28.3119 25.6926 29.1743 25.0635 30.2189C24.1028 31.8087 22.6021 32.5492 21.0464 32.7576Z"
				fill="#888888"
			/>
			<path
				d="M48.1295 21.1234C47.596 21.1247 47.0456 21.1116 46.7507 20.5769C46.4492 20.0317 46.6602 19.5008 46.9905 19.05C48.1151 17.5152 49.604 16.6161 51.5476 16.7446C52.6302 16.8153 52.95 17.569 52.4415 18.5677C51.7442 19.936 49.7468 21.1195 48.1295 21.1234Z"
				fill="#888888"
			/>
			<path
				d="M146.647 53.5067C147.381 52.8344 148.162 52.4517 149.092 52.3979C149.551 52.3717 149.838 52.4228 149.575 52.9825C149.483 53.1765 149.44 53.3967 149.39 53.609C149.326 53.8829 149.179 53.9681 148.887 53.9838C147.344 54.0677 146.666 54.6273 146.223 56.1136C146.049 56.6969 146.029 57.5173 145.634 57.7926C145.201 58.0927 144.413 57.8568 143.787 57.9131C143.23 57.9629 143.193 57.7912 143.347 57.3011C143.801 55.8476 144.302 54.4019 144.509 52.8829C144.546 52.6155 144.64 52.512 144.918 52.5277C145.31 52.55 145.706 52.5578 146.096 52.5264C146.548 52.491 146.829 52.5618 146.652 53.1096C146.621 53.2027 146.647 53.3141 146.647 53.5067Z"
				fill="#888888"
			/>
			<path
				d="M103.131 53.5254C103.924 52.7809 104.751 52.4126 105.73 52.393C106.124 52.3851 106.251 52.4978 106.101 52.8753C106.028 53.0575 105.949 53.2423 105.921 53.4336C105.862 53.853 105.658 53.9749 105.226 53.9867C103.935 54.0234 103.135 54.684 102.751 55.9304C102.553 56.5726 102.543 57.5045 102.111 57.7849C101.6 58.1165 100.724 57.8885 100.006 57.9003C99.9209 57.9016 99.8344 57.8832 99.6758 57.8662C99.892 57.1388 100.089 56.4324 100.313 55.7338C100.606 54.819 100.851 53.8936 100.977 52.9421C101.019 52.6197 101.142 52.4926 101.473 52.5253C101.753 52.5529 102.039 52.5306 102.323 52.5306C103.167 52.5319 103.167 52.5319 103.131 53.5254Z"
				fill="#888888"
			/>
			<path
				d="M40.4507 9.90874C43.3066 9.43691 45.8833 9.85238 48.4679 11.0608C46.7051 11.9219 45.0471 12.7279 43.3918 13.5445C43.1965 13.6415 43.0772 13.6467 42.992 13.4186C42.4848 12.0647 41.6067 10.9704 40.4507 9.90874Z"
				fill="#888888"
			/>
			<path
				d="M42.4337 9.14947C41.4756 9.1049 40.3196 9.31592 39.1793 9.61212C38.8635 9.69469 38.7101 9.65275 38.562 9.36572C38.3156 8.88603 37.714 8.29624 37.8399 7.99086C38.0168 7.55965 38.8019 7.54524 39.3209 7.34209C39.4428 7.2949 39.5673 7.2569 39.6918 7.21889C40.7691 6.88336 40.7914 6.86894 41.393 7.8362C41.5975 8.16517 41.878 8.17566 42.1611 8.21367C44.6421 8.54395 47.1245 8.8585 49.6068 9.18223C49.8978 9.22024 50.3434 9.15078 50.4286 9.3998C50.5308 9.69994 50.1796 9.96862 49.9437 10.1914C49.4902 10.6187 49.0786 10.793 48.3997 10.4444C46.6159 9.52562 44.6762 9.14029 42.4337 9.14947Z"
				fill="#888888"
			/>
			<path
				d="M20.0437 24.8499C18.8326 25.5813 17.6989 26.2654 16.5665 26.9509C16.4027 27.0505 16.2533 27.0505 16.0829 26.9548C14.999 26.3506 13.9531 25.6927 12.9609 24.8276C14.1431 24.1055 15.2873 23.4108 16.425 22.707C16.6268 22.5825 16.767 22.6179 16.944 22.7607C17.8916 23.5314 18.9217 24.1828 20.0437 24.8499Z"
				fill="#888888"
			/>
			<path
				d="M20.3309 33.408C20.1618 33.4172 19.9928 33.4342 19.8224 33.4355C18.9914 33.4447 18.1657 33.3634 17.6965 32.5665C17.2103 31.7422 17.5274 30.9282 18.0045 30.2349C19.517 28.037 21.6376 26.8246 24.2956 26.6372C25.1724 26.5756 26.0794 26.7224 26.5303 27.6608C26.8854 28.3987 26.4765 29.6949 25.7255 30.3778C25.7137 30.3883 25.6836 30.3765 25.6181 30.3712C25.6862 30.1759 25.7701 29.9938 25.8133 29.8024C26.061 28.7159 25.4306 27.8862 24.2746 27.767C22.4135 27.5743 19.9521 28.8823 19.0465 30.5442C18.302 31.9099 18.7555 32.8968 20.3309 33.408Z"
				fill="#888888"
			/>
			<path
				d="M53.0089 18.79C53.5385 16.8909 52.5528 15.8345 50.6406 16.1674C49.1019 16.4361 47.8149 17.1556 46.7965 18.3457C46.5383 18.6471 46.3456 18.9892 46.2041 19.3575C45.7978 20.4152 46.2185 21.2697 47.3011 21.6105C47.4138 21.6459 47.5685 21.5895 47.655 21.815C46.8646 21.9251 46.132 21.853 45.4701 21.4218C44.9157 21.06 44.7073 20.4034 44.8895 19.6812C45.1215 18.7599 45.6772 18.0377 46.3522 17.4007C47.7572 16.0757 49.3903 15.2211 51.3392 15.0481C51.6629 15.0193 51.9958 15.035 52.3195 15.0717C53.7167 15.2264 54.3366 16.2251 53.8425 17.5462C53.6748 17.9971 53.4808 18.4532 53.0089 18.79Z"
				fill="#888888"
			/>
			<path
				d="M30.8252 5.54802C31.744 5.1758 32.5723 4.84814 33.3927 4.50213C33.6955 4.37499 33.9118 4.42349 34.1477 4.65809C34.9839 5.48904 35.765 6.36717 36.5081 7.28069C36.7585 7.58869 36.7205 7.71583 36.3522 7.86131C35.5645 8.17324 34.7912 8.52187 34.0205 8.87444C33.782 8.98322 33.6405 8.96356 33.4845 8.73551C32.7204 7.62277 31.8632 6.58081 30.8252 5.54802Z"
				fill="#888888"
			/>
			<path
				d="M52.8334 6.73476C53.0366 6.69413 53.093 6.86058 53.169 6.98247C53.7352 7.88812 54.2345 8.82917 54.7077 9.78594C54.8204 10.014 54.7889 10.1293 54.5569 10.2434C53.6002 10.7139 52.6513 11.1988 51.6984 11.6772C51.6224 11.7152 51.5385 11.7545 51.456 11.7571C51.3813 11.7598 50.6106 10.3141 50.6578 10.2617C51.3459 9.50808 50.9618 8.74135 50.6486 8.01001C50.469 7.5893 50.587 7.45037 50.9697 7.33765C51.5975 7.15416 52.2135 6.93791 52.8334 6.73476Z"
				fill="#888888"
			/>
			<path
				d="M182.676 57.9019C183.187 56.1731 183.684 54.506 184.173 52.8362C184.237 52.6186 184.339 52.5203 184.583 52.5256C185.281 52.5413 185.978 52.5308 186.748 52.5308C186.241 54.2674 185.747 55.9516 185.257 57.6371C185.199 57.8337 185.088 57.9084 184.878 57.9058C184.163 57.8953 183.446 57.9019 182.676 57.9019Z"
				fill="#888888"
			/>
			<path
				d="M34.4043 4.12322C35.0046 3.93973 35.5236 3.84012 35.9902 3.62386C36.9194 3.19397 37.6246 3.45348 38.3126 4.14026C39.0204 4.84801 39.757 5.5243 40.3953 6.38408C39.4267 6.72223 38.4909 7.04071 37.5616 7.38017C37.3362 7.46274 37.2418 7.3474 37.1344 7.20192C36.348 6.12851 35.4502 5.15339 34.4043 4.12322Z"
				fill="#888888"
			/>
			<path
				d="M33.1605 9.24358C32.4751 9.604 31.8014 9.94608 31.1408 10.3118C30.8984 10.4454 30.7725 10.3917 30.6113 10.1807C29.8184 9.14004 28.9389 8.1754 27.9324 7.33659C27.627 7.08232 27.7397 6.98271 28.0162 6.85689C28.648 6.56724 29.2823 6.28152 29.901 5.96565C30.188 5.81886 30.3623 5.88439 30.5772 6.09934C31.534 7.05742 32.3768 8.10594 33.1605 9.24358Z"
				fill="#888888"
			/>
			<path
				d="M47.228 3.10092C47.9214 2.89384 48.5426 2.71297 49.1612 2.52162C49.3827 2.45346 49.5348 2.54128 49.6894 2.68021C50.7314 3.62125 51.6187 4.69467 52.4287 5.83755C52.6187 6.10623 52.6056 6.23336 52.2648 6.33297C51.6383 6.51646 51.0027 6.94504 50.4077 6.88869C49.7825 6.82971 49.73 5.93454 49.329 5.4745C48.9856 5.08 48.6907 4.64355 48.3473 4.25167C48.017 3.87551 47.6514 3.53344 47.228 3.10092Z"
				fill="#888888"
			/>
			<path
				d="M30.1396 25.0668C30.213 25.4167 30.0387 25.5649 29.7294 25.6907C29.0452 25.9685 28.3807 26.2949 27.7031 26.5924C26.9901 26.9056 26.4646 26.6107 26.3034 25.8165C26.0098 24.3669 26.0111 24.3695 27.3715 23.7719C28.0282 23.4835 28.6953 23.2122 29.3323 22.8846C29.6717 22.7103 29.7989 22.781 29.85 23.1284C29.9457 23.7745 30.0426 24.4207 30.1396 25.0668Z"
				fill="#888888"
			/>
			<path
				d="M34.2418 23.1398C34.3034 23.4792 34.1737 23.6562 33.8539 23.7964C32.9364 24.1948 32.0321 24.6208 31.129 25.0533C30.8355 25.1935 30.6887 25.2512 30.6533 24.8135C30.5995 24.1424 30.4842 23.474 30.3453 22.8147C30.2863 22.5369 30.3584 22.4176 30.5904 22.3127C31.5615 21.875 32.5327 21.4359 33.4961 20.9811C33.7779 20.8488 33.884 20.9339 33.9273 21.2092C34.0308 21.8527 34.137 22.4962 34.2418 23.1398Z"
				fill="#888888"
			/>
			<path
				d="M38.3863 21.3019C38.4243 21.5417 38.3024 21.661 38.1005 21.7567C37.1176 22.2246 36.1398 22.7004 35.1621 23.1787C34.9078 23.3033 34.7781 23.2888 34.7558 22.9546C34.7256 22.4998 34.6588 22.045 34.5854 21.5942C34.4176 20.5706 34.4124 20.5719 35.3744 20.1249C36.0848 19.7947 36.8017 19.4775 37.5016 19.1262C37.8253 18.9637 38.0022 18.9454 38.0625 19.37C38.1517 20.0175 38.2762 20.6584 38.3863 21.3019Z"
				fill="#888888"
			/>
			<path
				d="M50.1162 2.29466C52.1647 1.5856 52.1713 1.57774 53.6353 3.026C54.2395 3.62366 54.8031 4.26325 55.3889 4.87925C55.6458 5.14925 55.638 5.31963 55.2343 5.37599C55.2133 5.37861 55.1923 5.38647 55.1714 5.39303C54.5488 5.58831 53.8843 6.01558 53.3155 5.91466C52.7598 5.81636 52.5422 4.98542 52.1438 4.50179C51.5225 3.74423 50.8764 3.01158 50.1162 2.29466Z"
				fill="#888888"
			/>
			<path
				d="M38.9891 21.3272C38.8318 20.5015 38.7086 19.7741 38.5513 19.0545C38.4884 18.7649 38.571 18.6181 38.8305 18.4988C39.7965 18.0584 40.7598 17.6102 41.7139 17.1462C42.0206 16.9968 42.1779 16.9994 42.2303 17.39C42.3129 18.0125 42.4374 18.6299 42.5383 19.2498C42.5737 19.4687 42.4872 19.605 42.2762 19.7072C41.1857 20.2341 40.1031 20.7767 38.9891 21.3272Z"
				fill="#888888"
			/>
			<path
				d="M57.6563 8.35048C57.7389 8.63227 57.5581 8.68339 57.4362 8.74892C56.8437 9.06741 56.2422 9.36885 55.6484 9.68734C55.4427 9.79743 55.3129 9.80399 55.2028 9.55759C54.7769 8.60475 54.2631 7.69778 53.7192 6.80916C53.5422 6.51951 53.651 6.46053 53.9197 6.38452C54.5239 6.21282 55.1189 6.00574 55.7179 5.81307C55.8411 5.77376 55.9931 5.70167 56.0862 5.81439C56.7323 6.61126 57.2435 7.49332 57.6563 8.35048Z"
				fill="#888888"
			/>
			<path
				d="M9.55615 26.9589C10.5051 26.3626 11.3163 25.8541 12.1263 25.3429C12.2993 25.2341 12.454 25.1489 12.6493 25.3193C13.589 26.1385 14.6585 26.7741 15.7254 27.4164C15.6887 27.4583 15.6782 27.4793 15.6611 27.4898C15.1107 27.841 14.5982 28.3417 13.9966 28.5016C13.3478 28.6746 12.8773 27.9786 12.3033 27.7126C11.4631 27.3259 10.6073 27.0507 9.55615 26.9589Z"
				fill="#888888"
			/>
			<path
				d="M15.7397 28.1091C18.6376 26.3109 21.5551 24.5966 24.5407 22.9963C24.7308 22.8941 24.9759 22.729 25.027 23.0946C25.0807 23.4721 25.6862 23.9112 24.9693 24.2388C23.8028 24.7709 22.6285 25.286 21.4568 25.8063C19.808 26.5377 18.1605 27.2703 16.5078 27.9938C16.281 28.0921 16.0543 28.2612 15.7397 28.1091Z"
				fill="#888888"
			/>
			<path
				d="M53.334 1.93867C53.777 1.66868 54.0535 1.76304 54.372 2.06187C56.7941 4.34633 58.7116 6.98596 60.1192 10.0044C60.3472 10.4932 60.0104 10.5299 59.7509 10.7003C59.3603 10.9546 59.3184 10.6164 59.2266 10.4015C58.203 8.00827 57.0208 5.71202 55.2003 3.80765C54.6001 3.18116 53.9762 2.57958 53.334 1.93867Z"
				fill="#888888"
			/>
			<path
				d="M55.5595 12.6333C55.5883 12.8875 55.4179 12.9871 55.2384 13.0802C54.6407 13.3895 54.0483 13.7093 53.4441 14.0055C52.8307 14.3056 52.064 13.8666 51.832 13.2571C51.5358 12.4799 51.8307 12.1575 52.5149 11.8823C53.258 11.5834 53.9644 11.1916 54.6709 10.8102C54.9461 10.6621 55.0903 10.6621 55.1663 10.9871C55.2934 11.5376 55.4284 12.0854 55.5595 12.6333Z"
				fill="#888888"
			/>
			<path
				d="M9.49418 19.8188C9.4719 19.7624 9.44438 19.7061 9.42472 19.6458C8.88866 17.9393 8.95682 17.7519 10.4431 16.8371C10.9621 16.5173 11.4877 16.2067 12.0054 15.8829C12.2478 15.7309 12.4012 15.7178 12.4209 16.0651C12.43 16.2171 12.4667 16.3666 12.4877 16.5173C12.548 16.959 12.9766 17.4229 12.7498 17.7938C12.5349 18.1438 12.0159 18.3102 11.6279 18.5527C10.9634 18.9695 10.2976 19.3823 9.63049 19.7939C9.59641 19.8162 9.54661 19.8096 9.49418 19.8188Z"
				fill="#888888"
			/>
			<path
				d="M12.9623 15.7542C12.9164 15.4999 12.9518 15.3073 13.2152 15.1578C14.1406 14.6349 15.0541 14.0936 15.9637 13.5457C16.2481 13.3754 16.3477 13.4527 16.3542 13.7581C16.3582 13.9101 16.3765 14.0608 16.387 14.2129C16.4158 14.6559 16.8221 15.1329 16.5469 15.5065C16.2979 15.8459 15.7998 16.0045 15.4119 16.2404C14.8378 16.5891 14.2559 16.9272 13.6897 17.2903C13.459 17.4384 13.3489 17.3899 13.2768 17.1474C13.1405 16.69 12.9859 16.2365 12.9623 15.7542Z"
				fill="#888888"
			/>
			<path
				d="M43.1529 19.2351C43.0048 18.4815 42.8842 17.7515 42.7125 17.0346C42.6339 16.7017 42.8148 16.6204 43.0402 16.5143C43.9655 16.0778 44.883 15.6269 45.8135 15.2023C45.9787 15.1276 46.2225 14.8261 46.347 15.0974C46.461 15.3465 46.6235 15.7003 46.2814 15.9952C45.8869 16.336 45.5108 16.7043 45.1661 17.0949C44.8948 17.4029 44.6077 17.7345 44.4609 18.108C44.2211 18.7174 43.7781 19.0254 43.1529 19.2351Z"
				fill="#888888"
			/>
			<path
				d="M5.74854 22.3187C5.85994 20.0342 6.32784 19.3199 8.49696 18.084C8.63851 18.7629 8.78006 19.4274 8.91636 20.0932C8.94127 20.2138 8.87442 20.2964 8.77219 20.358C8.67914 20.4143 8.59263 20.4798 8.50089 20.5388C7.59654 21.1234 6.68958 21.7092 5.74854 22.3187Z"
				fill="#888888"
			/>
			<path
				d="M46.8542 14.0656C46.655 14.139 46.655 13.9595 46.5973 13.8586C46.0232 12.8638 46.0285 12.8743 47.0573 12.3749C47.9931 11.9214 48.9276 11.4627 49.8372 10.9607C50.1085 10.8113 50.2448 10.7943 50.389 11.0643C50.5109 11.2936 50.6328 11.523 50.7704 11.7432C50.9277 11.9948 50.8398 12.118 50.5908 12.2386C49.4008 12.814 48.2199 13.4051 47.035 13.9896C46.9761 14.0198 46.9145 14.0407 46.8542 14.0656Z"
				fill="#888888"
			/>
			<path
				d="M38.141 9.71361C38.1331 9.92332 38.0164 9.9338 37.9168 9.97443C37.0479 10.3204 36.1802 10.6691 35.3113 11.0177C35.2916 11.0256 35.268 11.0465 35.251 11.0413C34.5616 10.8486 34.5039 10.1356 34.1592 9.66774C33.9181 9.33877 34.3702 9.32828 34.5328 9.25358C35.2615 8.91412 36.0125 8.62185 36.7438 8.28763C37.0453 8.15001 37.2117 8.21817 37.3716 8.49602C37.6206 8.9246 37.9024 9.33877 38.141 9.71361Z"
				fill="#888888"
			/>
			<path
				d="M56.1469 12.5925C55.9779 11.9201 55.852 11.3002 55.6594 10.7025C55.5716 10.4286 55.62 10.3251 55.8612 10.2058C56.4654 9.90696 57.0631 9.59241 57.6502 9.26081C57.9058 9.11664 58.0487 9.1612 58.1483 9.42202C58.1561 9.44299 58.1653 9.46265 58.1745 9.48231C58.9622 11.0197 58.9635 11.021 57.4432 11.8755C57.0277 12.1088 56.6083 12.3369 56.1469 12.5925Z"
				fill="#888888"
			/>
			<path
				d="M8.14697 27.9388C10.261 27.0161 11.8967 28.0646 13.556 29.1262C12.3895 29.8628 9.47597 29.2232 8.14697 27.9388Z"
				fill="#888888"
			/>
			<path
				d="M60.2228 11.0781C60.2674 12.0218 59.8152 12.5487 59.1258 13.0022C58.1507 13.6431 57.2345 14.3731 56.1887 15.1424C56.1887 14.5042 56.1978 13.9917 56.1847 13.4792C56.1795 13.2394 56.3119 13.1424 56.5006 13.0441C57.7339 12.4006 58.962 11.7466 60.2228 11.0781Z"
				fill="#888888"
			/>
			<path
				d="M46.0981 14.1133C46.182 14.4082 46.0312 14.4724 45.8949 14.538C44.8595 15.0347 43.8189 15.5197 42.79 16.0295C42.5174 16.1645 42.4034 16.1029 42.3142 15.8342C42.2461 15.6297 42.1504 15.4305 42.0338 15.2484C41.883 15.0124 41.904 14.884 42.1766 14.7542C43.1937 14.2706 44.2016 13.7686 45.2082 13.264C45.4703 13.133 45.6446 13.1566 45.7691 13.4423C45.8805 13.6992 46.0155 13.9456 46.0981 14.1133Z"
				fill="#888888"
			/>
			<path
				d="M6.96631 30.5701C7.25989 30.4417 7.43159 30.6645 7.63736 30.7615C9.40149 31.5977 11.2049 32.2792 13.2102 32.0944C14.1866 32.004 15.1395 31.7707 16.0884 31.5269C16.2942 31.4745 16.5458 31.2753 16.61 31.7169C16.6572 32.042 16.6402 32.2412 16.2601 32.3159C12.9324 32.9739 9.76585 32.7956 6.96631 30.5701Z"
				fill="#888888"
			/>
			<path
				d="M38.4636 18.068C38.3116 18.1021 38.2801 18.0038 38.2395 17.8963C38.1491 17.6525 38.073 17.3996 37.9472 17.1741C37.803 16.9159 37.8725 16.8006 38.1255 16.6839C39.0888 16.237 40.0469 15.7809 40.9971 15.3091C41.2763 15.1702 41.4008 15.2291 41.5489 15.4952C42.0377 16.3799 42.0469 16.3786 41.1622 16.7954C40.3785 17.165 39.596 17.5398 38.8136 17.9107C38.6956 17.9671 38.575 18.0169 38.4636 18.068Z"
				fill="#888888"
			/>
			<path
				d="M29.0151 20.9297C29.3415 21.1237 29.3467 21.4998 29.483 21.7973C29.5682 21.9834 29.6154 22.1538 29.3507 22.2705C28.2773 22.7423 27.2104 23.2286 26.1435 23.7148C25.9325 23.8105 25.8368 23.7646 25.7883 23.53C25.7438 23.3203 25.6586 23.1158 25.5695 22.9179C25.4528 22.6597 25.4672 22.5051 25.7739 22.3753C26.7739 21.952 27.7595 21.4946 28.7517 21.0503C28.829 21.0149 28.9077 20.9782 29.0151 20.9297Z"
				fill="#888888"
			/>
			<path
				d="M34.3444 19.9745C34.1661 20.0204 34.1464 19.8579 34.1006 19.753C34.0062 19.5341 33.9446 19.3009 33.8437 19.0872C33.7205 18.8238 33.8188 18.7098 34.0626 18.5997C35.0115 18.1711 35.9578 17.7333 36.8909 17.2733C37.1898 17.1265 37.3326 17.1396 37.4781 17.466C37.87 18.3415 37.8844 18.3402 37.0154 18.7451C36.2487 19.1029 35.4794 19.4568 34.7113 19.812C34.5934 19.8657 34.4741 19.9169 34.3444 19.9745Z"
				fill="#888888"
			/>
			<path
				d="M33.6432 20.1462C33.6628 20.3258 33.5016 20.369 33.3666 20.4306C32.3994 20.8736 31.4269 21.3074 30.4675 21.7648C30.2027 21.8907 30.0743 21.8631 30.0035 21.5709C29.9629 21.4031 29.9131 21.2262 29.8174 21.0872C29.5631 20.7176 29.744 20.5761 30.0795 20.4306C30.9157 20.0676 31.7585 19.7124 32.5606 19.2812C33.1202 18.981 33.3719 19.1108 33.4885 19.694C33.5187 19.8408 33.5855 19.9797 33.6432 20.1462Z"
				fill="#888888"
			/>
			<path
				d="M34.2603 24.2168C34.2905 24.3518 34.3128 24.4121 34.3154 24.4737C34.3665 25.5379 34.3665 25.5379 33.3861 26.0019C32.622 26.3636 31.8566 26.7254 31.0938 27.0924C30.8343 27.2169 30.7386 27.1972 30.7019 26.8604C30.6207 26.1225 30.8409 25.6821 31.5906 25.416C32.5001 25.0923 33.3586 24.6283 34.2603 24.2168Z"
				fill="#888888"
			/>
			<path
				d="M42.5278 20.2051C42.5619 20.289 42.575 20.3086 42.5763 20.327C42.6549 21.6389 42.6536 21.6376 41.4675 22.1881C40.7191 22.5354 39.9747 22.8906 39.2263 23.2392C39.1896 23.2563 39.1437 23.2536 39.0022 23.2746C39.0022 22.9024 39.0245 22.542 38.9943 22.1855C38.9733 21.9495 39.111 21.8801 39.2669 21.8041C40.3377 21.2785 41.4085 20.7542 42.5278 20.2051Z"
				fill="#888888"
			/>
			<path
				d="M34.9482 25.2593C34.9089 25.1846 34.8932 25.1689 34.8919 25.1505C34.8093 23.9578 34.808 23.9552 35.8906 23.4323C36.7071 23.0377 37.5263 22.6524 38.3874 22.2422C38.4215 22.6406 38.4516 22.9814 38.4778 23.3222C38.4909 23.4873 38.4424 23.621 38.2825 23.697C37.1698 24.2173 36.0571 24.739 34.9482 25.2593Z"
				fill="#888888"
			/>
			<path
				d="M27.2664 28.8315C27.2586 28.4226 27.2599 28.0136 27.2389 27.606C27.2311 27.4461 27.2966 27.357 27.4237 27.3006C28.311 26.9088 29.1996 26.5195 30.1014 26.125C30.239 26.5523 30.1341 26.9206 30.142 27.2862C30.1459 27.4566 30.0909 27.5706 29.9218 27.6401C29.0227 28.0084 28.1983 28.5431 27.2573 28.8184L27.2664 28.8315Z"
				fill="#888888"
			/>
			<path
				d="M186.088 50.059C186.218 50.0669 186.353 50.0564 186.476 50.0879C186.906 50.1953 187.361 50.3199 187.387 50.8638C187.412 51.388 187.025 51.6148 186.586 51.7524C186.143 51.8913 185.695 51.9215 185.241 51.7812C184.888 51.6711 184.591 51.4916 184.548 51.0944C184.501 50.6606 184.788 50.4155 185.138 50.2478C185.435 50.1049 185.756 50.0433 186.088 50.059Z"
				fill="#888888"
			/>
			<path
				d="M39.2891 10.3253C39.5591 10.1431 39.7622 10.0776 40.0165 10.2951C41.0584 11.1864 41.8841 12.2297 42.4399 13.4918C42.7007 14.0842 42.1869 14.0646 41.9208 14.2284C41.4909 14.4918 41.5237 14.0633 41.4542 13.8653C40.9942 12.5298 40.2367 11.3751 39.2891 10.3253Z"
				fill="#888888"
			/>
			<path
				d="M51.3379 13.5943C51.3943 13.8485 51.2396 13.8695 51.0417 13.8918C49.7245 14.0386 48.5161 14.4921 47.3929 15.1893C47.2697 15.2654 47.0888 15.4895 46.9787 15.2103C46.9001 15.0124 46.7769 14.7319 47.081 14.5812C48.3431 13.9534 49.6105 13.3361 50.8753 12.7122C51.0784 12.6126 51.1545 12.7319 51.1938 12.8931C51.2501 13.1251 51.2921 13.361 51.3379 13.5943Z"
				fill="#888888"
			/>
			<path
				d="M33.407 3.86412C32.3584 4.30843 31.4829 4.74619 30.5615 5.03977C30.0832 5.19181 29.8538 4.60071 29.4121 4.34513C30.3649 3.88509 31.2719 3.53515 32.2025 3.25336C32.7425 3.09215 32.8709 3.70423 33.407 3.86412Z"
				fill="#888888"
			/>
			<path
				d="M44.9956 1.75346C47.4871 1.00508 47.7427 1.02212 48.7532 2.03001C47.4596 2.54902 46.1726 2.99202 44.9956 1.75346Z"
				fill="#888888"
			/>
			<path
				d="M29.7859 5.3874C28.9078 5.81467 28.1109 6.24981 27.2761 6.58795C26.8186 6.77406 26.6338 6.16068 26.0283 6.10301C26.9812 5.45425 27.8147 4.99814 28.6955 4.69931C29.0834 4.56694 29.4059 5.07416 29.7859 5.3874Z"
				fill="#888888"
			/>
			<path
				d="M55.5292 13.5508C55.866 14.9623 55.6275 15.6386 54.5724 16.2835C54.4401 15.6242 54.2553 14.9964 53.7231 14.501C54.3483 14.172 54.9171 13.8732 55.5292 13.5508Z"
				fill="#888888"
			/>
			<path
				d="M51.4692 1.26243C50.3473 1.70805 49.228 2.14449 48.3394 0.884964C49.937 0.154935 50.4049 0.207361 51.4692 1.26243Z"
				fill="#888888"
			/>
			<path
				d="M33.1763 2.97257C34.2484 2.72355 35.2903 1.86377 36.4594 2.87558C35.6468 3.14295 34.8853 3.44047 34.0977 3.63445C33.6599 3.74323 33.5 3.22028 33.1763 2.97257Z"
				fill="#888888"
			/>
			<path
				d="M23.5024 25.5162C24.0844 25.2554 24.6663 24.9972 25.2456 24.7338C25.4186 24.6552 25.5353 24.6591 25.5575 24.8858C25.5851 25.165 25.7279 25.4284 25.6585 25.7758C24.9704 25.6198 24.2665 25.5962 23.5024 25.5162Z"
				fill="#888888"
			/>
			<path
				d="M43.2029 21.3056C43.0679 19.8534 43.0679 19.8534 43.9539 19.5703C43.6105 20.1522 44.5436 21.1156 43.2029 21.3056Z"
				fill="#888888"
			/>
			<path
				d="M27.2573 28.8184C27.2429 28.8315 27.2297 28.8459 27.2153 28.859C27.2324 28.8498 27.2494 28.8406 27.2664 28.8328L27.2573 28.8184Z"
				fill="#888888"
			/>
			<path
				d="M211.091 4.99106C211.074 3.22693 212.37 1.88876 214.104 1.8809C215.826 1.87303 217.13 3.15877 217.15 4.88358C217.171 6.70538 215.893 8.05927 214.141 8.07369C212.413 8.0868 211.108 6.7696 211.091 4.99106ZM215.576 6.8194C216.381 6.37378 216.469 5.57691 216.438 4.81019C216.399 3.81147 215.935 3.00936 214.948 2.6201C213.728 2.13909 212.365 2.79573 211.942 4.05657C211.462 5.48517 212.132 6.95964 213.442 7.36463C214.298 7.62938 215.124 7.41575 215.589 6.8063L215.576 6.8194Z"
				fill="#888888"
			/>
			<path
				d="M215.589 6.80517C215.027 6.86415 214.716 6.55746 214.5 6.08432C214.402 5.86806 214.254 5.67539 214.135 5.467C214.048 5.31497 213.92 5.25468 213.753 5.27958C213.536 5.31235 213.57 5.48928 213.567 5.63476C213.56 5.91786 213.563 6.20096 213.563 6.48406C213.563 6.72391 213.412 6.76061 213.216 6.76454C213.01 6.76847 212.956 6.65969 212.956 6.48144C212.959 5.52205 212.963 4.56397 212.954 3.60458C212.951 3.33196 213.082 3.27691 213.315 3.28609C213.642 3.29789 213.971 3.27429 214.296 3.29264C214.832 3.3241 215.364 3.38832 215.495 4.05937C215.622 4.71338 215.314 5.0974 214.71 5.28089C214.95 5.83136 215.406 6.24421 215.578 6.81828C215.576 6.81828 215.589 6.80517 215.589 6.80517ZM214.022 4.76712C214.461 4.74615 214.904 4.76974 214.895 4.29398C214.886 3.77103 214.41 3.86015 214.049 3.82608C213.686 3.792 213.563 3.91913 213.553 4.2966C213.54 4.74877 213.756 4.80906 214.022 4.76712Z"
				fill="#888888"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6219_6330">
				<rect width="218.854" height="60" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default LkqImg;
