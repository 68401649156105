import React, { useRef } from 'react';
import './index.css';
import Slider from 'react-slick';
import LandingPageHeader from '../../components/LandingpageHeader';
import EarlyAccessModel from '../../components/EarlyAccess';
import ITDevices from '../../images/dashboard/it-devices.png';
import ITManagement from '../../images/dashboard/it-management.png';
import ITRepairs from '../../images/dashboard/it-repairs-2.png';

import './indexNew.css';
import FeatureList from './featureList';
import AssetzeProsList from './assetzePros';
import ConnectWitTeam from './connect';
import FooterNew from '../../components/FooterNew';
import { useLocation, useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';
import { ReactCompanyCarousel } from '../../components/ReactCompanyCarousel';

const settings = {
	infinite: true,
	speed: 500,
	autoplay: false,
	autoplaySpeed: 2000,
	slidesToShow: 1,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
};

const eachReview = [
	{
		name: 'Brahmanand Alapati',
		designation: 'Director, Datafoundry',
		image: require('../../images/dashboard/review-1.png'),
		review: 'Assetze has helped us in streamlining the process of  IT management, ticketing resolution, and majorly the productivity of the entire organization',
		companyLogo: require('../../images/dashboard/datafoundry.png'),
	},
	{
		name: 'Omprakash R',
		designation: 'IT Admin Lead, Loadshare',
		image: require('../../images/dashboard/review-2.png'),
		review: 'Assetze has simplified our complex IT management, as we are spread across PAN India. Managing all the local & central assets & IT management was a massive problem, which got streamlined thanks to Assetze.',
		companyLogo: require('../../images/dashboard/loadshare.png'),
	},
];

export default function NewLandingDesign() {
	const customeSlider = useRef();
	const previous = () => {
		customeSlider.current.slickNext();
	};

	const next = () => {
		customeSlider.current.slickPrev();
	};
	const location = useLocation();
	const navigate = useNavigate();

	const goToPath = function (toPath) {
		if (toPath === '/settings') {
			return;
		}

		if (isArray(toPath)) {
			for (let x = 0; x < toPath.length; x++) {
				if (!location.pathname.includes(toPath[x])) {
					navigate(toPath[x]);
					break;
				}
			}
		} else if (!location.pathname.includes(toPath)) {
			navigate(toPath);
		}
	};
	return (
		<div style={{ fontFamiliy: 'League-Spartan' }}>
			<LandingPageHeader />
			<div className="dashboard-content">
				<div className="content-container">
					<div className={'head-1'}>Experience the All-in-One IT Management Solution at Your Fingertips.</div>
					<div className="content-1">
						Assetze empowers your IT team to seamlessly manage entire IT process by just a click. Assetze
						helps you focus on the most important tasks and leave the rest to us.
					</div>
					<div className="desktop">
						<EarlyAccessModel className="early-access-new" content="Get Started" />
					</div>
				</div>
				<div className="right-image-container">
					<img src={require('../../images/new-landing/image-right.png')} />
				</div>
				<div className="mobile">
					<EarlyAccessModel className="early-access-new full-width" content="Get Started" />
				</div>
			</div>
			<ReactCompanyCarousel />

			<div className={'main-features'}>
				<div className="each-feature">
					<div className="image-container">
						<img style={{ height: '35px' }} src={require('../../images/new-landing/lost-tracking.png')} />
					</div>
					<div className="head-1">Lack of Tracking</div>
					<div className="content-1">With IT Asset management, you can reduce device losses by 60%.</div>
				</div>
				<div className="each-feature">
					<div className="image-container">
						<img style={{ height: '45px' }} src={require('../../images/new-landing/save-money.png')} />
					</div>
					<div className="head-1">Save using ITAM</div>
					<div className="content-1">
						Companies can save upto 30% per asset in the first year of implementing IT asset management by
						preventive maintenance
					</div>
				</div>
				<div className="each-feature">
					<div className="image-container">
						<img style={{ height: '45px' }} src={require('../../images/new-landing/real-time.png')} />
					</div>
					<div className="head-1">Realtime Meaningful Data</div>
					<div className="content-1">
						Save up to 30 mins of your daily productive time through realtime data retrieval and analysis.
					</div>
				</div>
			</div>
			<div className="assetze-smart">
				<div className="head-1">IT Management is now smarter</div>
				<div className="all-smart-thing">
					<div className="each-smart-thing">
						<div className="image-container">
							<img src={require('../../images/new-landing/asset-management.png')} />
						</div>
						<div className="content-container">
							<div className="head-1">Asset Management</div>
							<div className="content-1">
								Assetze: Organize assets previously managed using multiple conflicting excel sheets
								improves effectiveness and ROI.
							</div>
							<div onClick={() => goToPath('/assetManagment')} className="action">
								Learn More
							</div>
						</div>
					</div>
					<div className="each-smart-thing">
						<div className="image-container">
							<img src={require('../../images/new-landing/incident-management.png')} />
						</div>
						<div className="content-container">
							<div className="head-1">Incident Management</div>
							<div className="content-1">
								Raise tickets anytime and on any device. Our unique interaction solutions help find the
								most important tickets faster.
							</div>
							<div onClick={() => goToPath('/incideneManagment')} className="action">
								Learn More
							</div>
						</div>
					</div>
				</div>
				<div className="all-smart-thing">
					<div className="each-smart-thing">
						<div className="image-container">
							<img src={require('../../images/new-landing/asset-management.png')} />
						</div>
						<div className="content-container">
							<div className="head-1">Reporting</div>
							<div className="content-1">
								Get meaningful insights w.r.t to your IT spend, depreciation, and device details.
								Assetze reports help you analyse with clarity and take right decisions in your IT
								management.
							</div>
							<div onClick={() => goToPath('/reporting')} className="action">
								Learn More
							</div>
						</div>
					</div>
					<div className="each-smart-thing">
						<div className="image-container">
							<img src={require('../../images/new-landing/it-service.png')} />
						</div>
						<div className="content-container">
							<div className="head-1">IT Managed Services</div>
							<div className="content-1">
								Don't have a IT team? Don't worry, Assetze helps you solve it through IT managed
								services. Your IT Tickets, IT procurement, IT management, Shipment, and device
								disposables all managed under one umbrella.
							</div>
							<div onClick={() => goToPath('/assetManagment')} className="action">
								Learn More
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="support-container">
				<div className="head-1">Assetze Values Commitment and Trust</div>
				<div className="head-2">As our valued customer you can expect our support all the time</div>
				<div className="content-container">
					<div className="text-content">
						<div className="each-info">
							<div className="head-1">A Safe & Industry Grade Secure platform</div>
							<div className="content-1">
								Hosted on AWS data centres and with enterprise grade security{' '}
							</div>
							<div className="content-1">Multi-factor authentication for each user</div>
							<div className="content-1">Role-based access to users keep your data secure</div>
						</div>
						<div className="each-info">
							<div className="head-1">Implementation & Integration</div>
							<div className="content-1">
								Our ready to support team helps you in easy migration, implementation, and integration
							</div>
						</div>
						<div className="each-info">
							<div className="head-1">Accessibility</div>
							<div className="content-1">
								Access our applications anywhere with secured logins and easy to use interface enables
								you to access relevant data in a few steps.
							</div>
						</div>
					</div>
					<div className="image-container">
						<img src={require('../../images/new-landing/support-1.jpg')} />
					</div>
				</div>
			</div>
			<FeatureList />
			<div className={'circle-container'}>
				<div className={'head-1'}>IT Managed Services</div>
				<div className="heading-1">Just a click, and ready to ship</div>
				<div className="heading-2">
					You can buy, ship, raise tickets, get repairs <br /> directly from Assetze website
				</div>
				<div className="circle-image">
					<img src={require('../../images/new-landing/circle-image-2.png')} />
				</div>
				<div className="action">
					<EarlyAccessModel className="action-getstarted" content="Book A Demo" />
				</div>
			</div>
			<AssetzeProsList />
			<SoftwareInfo />
			<div className="shipment-container">
				<div className="heading-1">Shipment & Device Disposing now easy</div>
				<div className="heading-2">
					Shipment and retrieving of devices from remote employee can now be automated through Assetze .
					Disposing device? Worry not, we give you attractive prices for your old/dead devices{' '}
				</div>
				<div className="image-conatiner">
					<div>
						<img src={require('../../images/dashboard/ship-1.png')} />
					</div>
					<div>
						<img src={require('../../images/dashboard/ship-2.png')} />
					</div>
					<div>
						<img src={require('../../images/dashboard/ship-3.png')} />
					</div>
				</div>
			</div>
			<div className="new-task-container">
				<div className="heading-1">
					Manual tasks are now automated <br /> through Assetze
				</div>
				<div className="tasklist-container">
					<div className="each-task">
						<div className="image-container">
							<img src={require('../../images/dashboard/task-1.png')} />
						</div>
						<div className="head">Manage Assets</div>
						<div className="subtext">
							Keep track of IT/Non-IT assets without any hassle. Manage IT tickets and keep track of every
							device issue raised.
						</div>
					</div>
					<div className="each-task">
						<div className="image-container">
							<img src={require('../../images/dashboard/task-2.png')} />
						</div>
						<div className="head">Device History</div>
						<div className="subtext">
							Track the device change-overs between employees. Shift-based user assignment.
						</div>
					</div>
					<div className="each-task">
						<div className="image-container">
							<img src={require('../../images/dashboard/task-3.png')} />
						</div>
						<div className="head">Employee Sign-off</div>
						<div className="subtext">
							Employee exit sign-off is now much simpler with Assetze. You can track all the departments
							sign-off & then proceed with settlement.
						</div>
					</div>
				</div>
			</div>
			<ConnectWitTeam />
			<FooterNew />
		</div>
	);

	// 	<div className={'dashboard-bg-1'}>
	// 		<h1 className={'heading-1'}>ZERO Downtime</h1>
	// 		<div className="container">
	// 			<div className="text-container">
	// 				<div className="heading-2">Seamless IT management now possible with Assetze</div>
	// 				<div className="text-info">
	// 					Your Organisations’
	// 					<ul>
	// 						<li>Data Secured</li>
	// 						<li>Productivity Improved</li>
	// 						<li>Manual work Automated</li>
	// 						<li>Cost Saved</li>
	// 					</ul>
	// 				</div>
	// 				<div className="email-container">
	// 					<label for={'email'}>Email Address</label>
	// 					<input placeholder="someone@example.com" id={'email'} />
	// 				</div>
	// 				<EarlyAccessModel className="action-getstarted" content="Book A Demo" />
	// 				{/* <button className="action-getstarted">Get Started</button> */}
	// 			</div>
	// 			<div className={'image-container'}>
	// 				<img src={require('../../images/dashboard/laptop-1.png')} />
	// 			</div>
	// 		</div>
	// 		<div className="text-info">
	// 			Your Organisations’
	// 			<ul>
	// 				<li>Data Secured</li>
	// 				<li>Productivity Improved</li>
	// 				<li>Manual work Automated</li>
	// 				<li>Cost Saved</li>
	// 			</ul>
	// 		</div>
	// 		<div className="email-container">
	// 			<label for={'email'}>Email Address</label>
	// 			<input placeholder="someone@example.com" id={'email'} />
	// 		</div>
	// 		<EarlyAccessModel className="action-getstarted" content="Book A Demo" />
	// 		{/* <img src={require('../../images/dashboard/wallpaper-bg.png')} /> */}
	// 	</div>

	// 	<SoftwareInfo />

	// 	<div className="portfolio">
	// 		<div className="heading">Here’s what our clients had to say</div>
	// 		<div className="slider-container">
	// 			<div className="previous" onClick={previous}>
	// 				<img className="direction-icon" src={require('../../images/dashboard/direction.png')} />
	// 			</div>
	// 			<div className="all-slides-data">
	// 				<Slider {...settings} className={'prod-slider'} ref={customeSlider}>
	// 					{eachReview.map((el, index) => (
	// 						<div key={index} className="each-review">
	// 							<div className="image-container">
	// 								<img key={index} src={el.image} />
	// 							</div>
	// 							<div className="review-content">
	// 								<div className="head">{el.review}</div>
	// 								<div className="name">
	// 									{el.name}-<span className="designation">{el.designation}</span>
	// 								</div>
	// 								<div className="image-container">
	// 									<img className="logo" src={el.companyLogo} />
	// 								</div>
	// 							</div>
	// 						</div>
	// 					))}
	// 				</Slider>
	// 			</div>
	// 			<div onClick={next} className="next-container">
	// 				<img className="direction-icon right" src={require('../../images/dashboard/direction.png')} />
	// 			</div>
	// 		</div>
	// 	</div>
	// 	<IntegrationsComponentNew />
	// 	<LandingScreenFooter />
	// </div>
	// );
}

const imageList = [
	require('../../images/dashboard/it-devices.png'),
	require('../../images/dashboard/it-management.png'),
	require('../../images/dashboard/it-repairs-2.png'),
];

function SoftwareInfo() {
	const [selectedImage, setSelectedImage] = React.useState(0);
	const customeSlider = useRef();
	React.useEffect(() => {
		const timer = setInterval(() => {
			if (selectedImage === 2) {
				setSelectedImage(0);
			} else {
				setSelectedImage(selectedImage + 1);
			}
		}, 6000);

		return () => clearInterval(timer);
	}, [selectedImage]);

	const settings = {
		infinite: true,
		speed: 500,
		autoplay: true,
		autoplaySpeed: 2000,
		slidesToShow: 1,
		slidesToScroll: 1,
		arrows: false,
		dots: true,
	};

	const previous = () => {
		customeSlider.current.slickNext();
	};

	const next = () => {
		customeSlider.current.slickPrev();
	};

	const imageToShow =
		selectedImage === 0 ? ITDevices : selectedImage === 1 ? ITManagement : selectedImage === 2 ? ITRepairs : null;

	return (
		<div className="software-info-container">
			<div className="heading-1">One software is all you need for your IT </div>
			<div className="heading-2">Your entire IT managed seamlessly with our software and service</div>
			<div className="content">
				<div className="image-container">
					<img src={imageToShow} />
				</div>
				<div className="text-container">
					<div
						onClick={() => setSelectedImage(0)}
						className={selectedImage === 0 ? 'each-content active' : 'each-content'}
					>
						<div className="icon-container">
							<img src={require('../../images/dashboard/it-bag.png')} />
						</div>
						<div className="text-content">
							<div className="heading-1">Buy IT Devices</div>
							<div className="heading-2">
								You can purchase new or refurbished devices for your existing employees or new recruits.
							</div>
						</div>
					</div>
					<div
						onClick={() => setSelectedImage(1)}
						className={selectedImage === 1 ? 'each-content active' : 'each-content'}
					>
						<div className="icon-container">
							<img src={require('../../images/dashboard/it-device.png')} />
						</div>
						<div className="text-content">
							<div className="heading-1">IT Management</div>
							<div className="heading-2">
								You can have ZERO-downtime in your organisation. With few clicks of a button, the IT
								issues will be resolved by our dedicated expert team. In case of device issue,
								replacement would be shipped to keep the productivity moving of employee.
							</div>
						</div>
					</div>
					<div
						onClick={() => setSelectedImage(2)}
						className={selectedImage === 2 ? 'each-content active' : 'each-content'}
					>
						<div className="icon-container">
							<img src={require('../../images/dashboard/it-repairs.png')} />
						</div>
						<div className="text-content">
							<div className="heading-1">Repairs </div>
							<div className="heading-2">
								Life of devices can be extended up to 6-8 months through repair services. You can
								request for the service on the portal.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="content-2">
				<Slider className="custom-slider-1" {...settings}>
					<div className={'service-info'}>
						<div className="image-container">
							<img src={require('../../images/dashboard/it-devices.png')} />
						</div>
						<div className={'each-content'}>
							<div className="icon-container">
								<img src={require('../../images/dashboard/it-bag.png')} />
							</div>
							<div className="text-content">
								<div className="heading-1">Buy IT Devices</div>
								<div className="heading-2">
									You can purchase new or refurbished devices for your existing employees or new
									recruits.
								</div>
							</div>
						</div>
					</div>
					<div className={'service-info'}>
						<div className="image-container">
							<img src={require('../../images/dashboard/it-management.png')} />
						</div>
						<div className={'each-content'}>
							<div className="icon-container">
								<img src={require('../../images/dashboard/it-device.png')} />
							</div>
							<div className="text-content">
								<div className="heading-1">IT Management</div>
								<div className="heading-2">
									You can have ZERO-downtime in your organisation. With few clicks of a button, the IT
									issues will be resolved by our dedicated expert team. In case of device issue,
									replacement would be shipped to keep the productivity moving of employee.
								</div>
							</div>
						</div>
					</div>
					<div className={'service-info'}>
						<div className="image-container">
							<img src={require('../../images/dashboard/it-repairs-2.png')} />
						</div>
						<div className={'each-content'}>
							<div className="icon-container">
								<img src={require('../../images/dashboard/it-repairs.png')} />
							</div>
							<div className="text-content">
								<div className="heading-1">Repairs</div>
								<div className="heading-2">
									Life of devices can be extended up to 6-8 months through repair services. You can
									request for the service on the portal.
								</div>
							</div>
						</div>
					</div>
				</Slider>
			</div>
		</div>
	);
}
