import React from 'react';
import './index.css';
import { useNavigate } from 'react-router-dom';
import Header from '../../../components/IT-tracking/Header';
import Logo from '../../../images/assetze-logo.png';
import Intro from '../../../images/assetze-intro.png';

const ITRegister = function () {
	const navigate = useNavigate();

	// gs: get started
	return (
		<div className="register-container">
			<Header />
			<img className="logo" src={Logo} />
			<div className="tagline">Just one simple step to have </div>
			<div className="inputs">
				<input placeholder="Employee ID" />
				<input placeholder="Business Mail" />
			</div>
			<button
				onClick={() => {
					navigate('/login');
				}}
			>
				Get OTP
			</button>
			<hr />
		</div>
	);
};

export default ITRegister;
