import React from 'react';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';

const ArrowButton = ({ isFirstStep, isLastStep, nextHandler, backHandler, answer }) => {
	return (
		<>
			<div className="arrows">
				<button
					disabled={isFirstStep}
					style={{ background: '#fff', border: 'none', outline: 'none' }}
					className="moveUpIcon"
				>
					<AiOutlineUp className="arrow_icon_container" onClick={() => backHandler()} />
				</button>
				<button
					disabled={isLastStep}
					style={{ background: '#fff', border: 'none', outline: 'none' }}
					className="moveDownIcon"
				>
					<AiOutlineDown className="arrow_icon_container" onClick={() => nextHandler()} />
				</button>
			</div>
		</>
	);
};

export default ArrowButton;
