import { showSnack } from 'react-redux-snackbar';
import { downloadFromURL } from '../utils/downloadFromURL';
import { getApi, postApi } from './api';
import { assetTagUrl } from './constants';

export const downloadTag = async (assetId, dispatch) => {
	try {
		console.log('downloadTag');
		let token = localStorage.getItem('jwt-token');
		const rawResponse = await fetch(`${assetTagUrl}/assetze/barcode/download-single`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + token,
				'is-az': 'yes',
			},
			credentials: 'include',
			body: JSON.stringify({ assetId }),
		});
		const resp = await rawResponse.json();

		if (resp.type === 'success') {
			if (resp.responseType === 'barcode-found') {
				await downloadFromURL(resp);
			}
		} else {
			console.log(resp);
			dispatch(
				showSnack('download_tag_failed', {
					label: resp.error ? resp.error : resp.message,
					timeout: 7000,
				})
			);
		}
	} catch (e) {
		console.error(e);
	}
};

export const getCompanyJobs = async (page) => {
	try {
		let token = localStorage.getItem('jwt-token');
		const rawResponse = await fetch(`${assetTagUrl}/assetze/barcode/get-jobs`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
				authorization: 'Bearer ' + token,
				'is-az': 'yes',
			},
			credentials: 'include',
			body: JSON.stringify({ page }),
		});
		const resp = await rawResponse.json();

		return resp;
	} catch (e) {
		console.error(e);
	}
};

export const getUserTagInfo = async function () {
	try {
		const resp = await getApi('/assetze/barcode/tag-info', assetTagUrl);
		if (resp.type === 'success') {
			if (resp.barcodeInfo) {
				const barcodeInfo = resp.barcodeInfo;
				return {
					formData: {
						companyName: barcodeInfo.name,
						address: barcodeInfo.address,
						note: barcodeInfo.note,
						phone: barcodeInfo.phone,
						bgColor: barcodeInfo.bgColor,
						textColor: barcodeInfo.textColor,
					},
					logoImage: barcodeInfo.logoUrl,
				};
			}
		}
		return null;
	} catch (e) {
		console.error(e);
	}
};
