import React, { useState, useEffect, useRef } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import { useForm, Controller } from 'react-hook-form';
import { KeyboardDatePicker } from '@material-ui/pickers';
import Select from 'react-select';
import Modal from 'react-modal';
import { useNavigate, useParams } from 'react-router-dom';
import { getApi, postApi } from '../../services/api';
import { showSnack } from 'react-redux-snackbar';
import { useDispatch } from 'react-redux';
import { BsXCircleFill } from 'react-icons/bs';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import teal from '@material-ui/core/colors/teal';
import CreatableSelect from 'react-select/creatable';
import './index.css';
import { SearchInvoicePO } from '../../components/SearchPO';
import { SearchOfficeLoc } from '../../components/SearchOffice';
import { IoCloudUploadOutline } from 'react-icons/io5';
import { Button, Modal as AntModal, Upload, Image } from 'antd';
import {
	CheckPermission,
	CheckPermissionCondition,
	UPDATE_CUSTOM_CREATE,
	UPDATE_CUSTOM_DELETE,
	UPDATE_CUSTOM_EDIT,
} from '../../utils/Permissions';
import { getInvoice, getOfficeLoc } from '../../services/assets';
import { uploadAssetImgs } from '../../utils/ImageUpload';
const { Dragger } = Upload;

const defaultMaterialTheme = createMuiTheme({
	palette: {
		primary: teal,
	},
});

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0px',
		boxShadow: '0 0 5px #7f7f7f',
	},
	overlay: {
		backgroundColor: '#4e4e4e65',
	},
};

const options = [
	{ value: 'tablet', label: 'Tablet' },
	{ value: 'laptop', label: 'Laptop' },
	{ value: 'mobile', label: 'Mobile' },
];

const convertOptionInputs = function (el) {
	return { value: el, label: el.charAt(0).toUpperCase() + el.slice(1) };
};

const defaultHealth = [
	{
		key: 'Condition',
		value: '',
		comment: 'fine',
		alias: 'H1',
		type: 'dropdown',
		inputs: ['working', 'repaired', 'dead'].map(convertOptionInputs),
	},
	{
		key: 'Internal Parts',
		value: '',
		comment: 'fine checked on 26th september',
		alias: 'H2',
		type: 'dropdown',
		inputs: ['present', 'damaged', 'missing'].map(convertOptionInputs),
	},
	{
		key: 'Physical Damage',
		value: '',
		comment: '',
		alias: 'H3',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
	{
		key: 'Software Issue',
		value: '',
		comment: '',
		alias: 'H4',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
	{
		key: 'Is the product ready to be disposed?',
		value: '',
		comment: '',
		alias: 'H6',
		type: 'dropdown',
		inputs: ['yes', 'no', 'already disposed'].map(convertOptionInputs),
	},
	{
		key: 'is the product still in usable condition??',
		value: '',
		comment: '',
		alias: 'H5',
		type: 'dropdown',
		inputs: ['yes', 'no'].map(convertOptionInputs),
	},
];

const AddHealth = ({ setCustomHealth, healthInfoExist }) => {
	const [showModal, setShowModal] = useState(false);
	const [healthInfo, setHealthInfo] = useState(healthInfoExist.length > 0 ? healthInfoExist : defaultHealth);
	const dispatch = useDispatch();

	useEffect(() => {
		setHealthInfo(healthInfoExist);
	}, [setHealthInfo, healthInfoExist]);

	const updateHealthInfo = function (selectedHealth, type, value) {
		setHealthInfo(
			healthInfo.map((eachHealth) => {
				if (eachHealth.alias !== selectedHealth.alias) {
					return eachHealth;
				}
				if (type === 'dropdown') {
					return {
						...eachHealth,
						value,
					};
				}
				if (type === 'comment') {
					return {
						...eachHealth,
						comment: value,
					};
				}
			})
		);
	};

	const saveHealthInfo = function () {
		//setCustomHealth
		for (let x = 0; x < healthInfo.length; x++) {
			const eachHealth = healthInfo[x];
			if (!eachHealth.value) {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'Please fill all the health fields',
						timeout: 7000,
					})
				);
			}
		}
		setCustomHealth(healthInfo);
		setShowModal(false);
	};

	return (
		<>
			<button
				onClick={() => {
					setShowModal(true);
				}}
				type="button"
			>
				+ Update
			</button>
			<Modal
				isOpen={showModal}
				onRequestClose={() => {
					setShowModal(false);
				}}
				style={customStyles}
				contentLabel="Write Post"
				ariaHideApp={false}
			>
				<div className="modal-health-container">
					<div className="head">Health</div>
					<div className="all-health-container">
						{healthInfo.map((el, index) => {
							const healthConf = defaultHealth.find((elHealth) => elHealth.alias === el.alias);

							if (!healthConf) {
								return null;
							}
							return (
								<div className="each-health-container" key={index}>
									<div className="health-name">{healthConf.key}</div>
									{healthConf.type === 'dropdown' ? (
										<Select
											value={healthConf.inputs.filter((c) =>
												el.value ? el.value.toLowerCase().includes(c.value) : null
											)}
											onChange={(val) => {
												updateHealthInfo(el, 'dropdown', val.value);
											}}
											className={'health-condition-options'}
											// styles={customStylesSelect}
											options={healthConf.inputs}
										/>
									) : null}
									<input
										onChange={(e) => {
											updateHealthInfo(el, 'comment', e.target.value);
										}}
										className="health-comments"
										placeholder={'Comments'}
									/>
								</div>
							);
						})}
					</div>
					<p className="health-note-on-expiry">
						Note: Health score also depends on the number of years of usage and the warrarnty period
						remaining*
					</p>
					<div className="health-actions">
						<button onClick={() => setShowModal(false)} className="cancel">
							Cancel
						</button>
						<button onClick={() => saveHealthInfo()} className="save">
							Save
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

const configurationDefault = [
	{
		key: '',
		value: '',
		company: '',
		name: 'cpu',
		placeholder1: 'CPU',
		placeholder2: 'Clock Speed GHz',
		placeholder3: 'Company',
	},
	{
		key: '',
		value: '',
		company: '',
		name: 'ram',
		placeholder1: 'RAM',
		placeholder2: 'Clock Speed GHz',
		placeholder3: 'Company',
	},
	{
		key: '',
		name: 'hdd',
		value: '',
		company: '',
		placeholder1: 'HDD',
		placeholder2: 'Type',
		placeholder3: 'Company',
	},
	{
		key: '',
		value: '',
		company: '',
		name: 'graphics_Card',
		placeholder1: 'Graphic Card',
		placeholder2: 'Clock Speed GHz',
		placeholder3: 'Company',
	},
];

const EachConfInput = function ({
	customKeysList,
	updateInputConfiguration,
	index,
	keyName = '',
	value = '',
	setConf,
	conf,
	newItem,
}) {
	const [valueOptions, setValueOptions] = useState([]);
	const [keyOptions, setKeyOptions] = useState([]);
	const [keySelected, setKeySelected] = useState(keyName);
	const [valueSelected, setValueSelected] = useState(value);

	useEffect(() => {
		setKeyOptions(
			customKeysList.map((el) => {
				return {
					label: el.key,
					value: el.key,
				};
			})
		);
	}, [customKeysList]);

	useEffect(() => {
		const keyFound = customKeysList.find((el) => el.key === keySelected);
		if (keyFound) {
			setValueOptions(
				keyFound.values.map((el) => {
					return {
						label: el,
						value: el,
					};
				})
			);
		}
	}, [keySelected, customKeysList]);

	return (
		<div className="each-custom-input-container">
			<CreatableSelect
				value={keyOptions.filter((c) => keySelected === c.value)}
				isDisabled={newItem ? false : true}
				onChange={(val) => {
					if (val.__isNew__) {
						const alreadExist = keyOptions.find((el) => el.label === val.value);
						if (!alreadExist) {
							setKeyOptions(keyOptions.concat(val));
						}
					}
					updateInputConfiguration(val.value, 'key', index);
					setKeySelected(val.value);
				}}
				className={'each-custom-key-input'}
				// styles={customStylesSelect}
				options={keyOptions}
			/>
			<CreatableSelect
				value={valueOptions.filter((c) => valueSelected === c.value)}
				isDisabled={!newItem && !CheckPermissionCondition(UPDATE_CUSTOM_EDIT)}
				onChange={(val) => {
					if (val.__isNew__) {
						const alreadyExist = valueOptions.find((el) => el.label === val.value);
						if (!alreadyExist) {
							setValueOptions(valueOptions.concat(val));
						}
					}
					updateInputConfiguration(val.value, 'value', index);
					setValueSelected(val.value);
				}}
				className={'each-custom-key-input'}
				// styles={customStylesSelect}
				options={valueOptions}
			/>
			<CheckPermission requiredPermission={UPDATE_CUSTOM_DELETE}>
				<BsXCircleFill
					onClick={() => {
						setConf(
							conf.filter((eachConf, filterIndex) => {
								if (index === filterIndex) {
									return false;
								}
								return true;
							})
						);
					}}
					style={{ cursor: 'pointer' }}
					color="#ff0000"
				/>
			</CheckPermission>
		</div>
	);
};

export const AddCustomConfiguration = function ({ configurationInfo, setAllConfiguration }) {
	const [showModal, setShowModal] = useState(false);
	const [conf, setConf] = useState(configurationInfo);
	const [errorMessage, setErrorMessage] = useState(null);
	const [customKeysList, setCustomKeys] = useState([]);

	useEffect(() => {
		const fetchCustomKeys = async function () {
			try {
				const resp = await getApi('/assetze/custom-keys');
				if (resp.type === 'success') {
					for (let x = 0; x < configurationInfo.length; x++) {
						const conf = configurationInfo[x];
						const keyExist = resp.customKeys.find((el) => el.key === conf.key);
						if (!keyExist) {
							resp.customKeys.push({ key: conf.key, values: [conf.value] });
						} else {
							const valueExist = keyExist.values.find((el) => el === conf.value);
							if (!valueExist) {
								keyExist.values.push(conf.value);
							}
						}
					}
					setCustomKeys(resp.customKeys);
				}
			} catch (e) {
				console.error(e);
			}
		};
		fetchCustomKeys();
	}, [configurationInfo]);

	useEffect(() => {
		setConf(configurationInfo);
	}, [setConf, configurationInfo]);

	const updateInputConfiguration = (value, key, itemIndex) => {
		setConf(
			conf.map((el, index) => {
				if (index === itemIndex) {
					return { ...el, [key]: value };
				}
				return el;
			})
		);
	};
	const addNewConfig = () => {
		setConf(conf.concat([{ key: '', value: '', newItem: true }]));
	};

	const saveConfigurationList = () => {
		setErrorMessage(null);
		for (let x = 0; x < conf.length; x++) {
			if (!conf[x].key || !conf[x].value) {
				setErrorMessage('Fill All the fields or remove');
				return;
			}
		}
		setAllConfiguration(conf);
		setShowModal(false);
	};

	return (
		<>
			<button
				onClick={() => {
					setShowModal(true);
				}}
				type="button"
			>
				{configurationInfo && configurationInfo.length > 0 ? 'Update Custom Fields' : 'Add Custom Fields'}
			</button>
			<Modal
				isOpen={showModal}
				onRequestClose={() => {
					setShowModal(false);
				}}
				style={customStyles}
				contentLabel="Write Post"
				ariaHideApp={false}
			>
				<div className="configuration-modal-container">
					<div className="head">Custom Fields</div>
					<div className="configuration-list-container">
						{conf.map((el, index) => {
							return (
								<EachConfInput
									updateInputConfiguration={updateInputConfiguration}
									index={index}
									keyName={el.key}
									value={el.value}
									setConf={setConf}
									conf={conf}
									key={el.key}
									newItem={el?.newItem}
									customKeysList={customKeysList}
									setCustomKeys={setCustomKeys}
								/>
							);
						})}
					</div>
					<CheckPermission requiredPermission={UPDATE_CUSTOM_CREATE}>
						<button onClick={addNewConfig}>+Add Custom Input</button>
					</CheckPermission>
					{errorMessage ? (
						<div
							style={{
								fontSize: '14px',
								color: 'red',
								textAlign: 'center',
								marginBottom: '18px',
							}}
						>
							{errorMessage}
						</div>
					) : null}
					<div className="actions">
						<button
							onClick={() => {
								setShowModal(false);
								setConf(configurationInfo);
								setErrorMessage(null);
							}}
						>
							Cancel
						</button>
						<button
							onClick={() => {
								saveConfigurationList();
							}}
							className="save"
						>
							Save
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

const EditAssetScreen = function () {
	const {
		register,
		handleSubmit,
		watch,
		control,
		setValue,
		getValues,
		formState: { errors },
	} = useForm({
		defaultValues: {
			defaultInvoiceInfo: null,
			defaultOfficeInfo: null,
		},
	});
	const [configurationInfo, setAllConfiguration] = useState([]);
	const [healthInfo, setCustomHealth] = useState([]);
	const [confString, setConfString] = useState('');
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [selectedOffice, setSelectedOffice] = useState(null);
	const textareaRef = useRef(null);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	let { assetId } = useParams();
	const [confirmationModal, setConfirmationModal] = useState(false);
	const [imgFileList, setImgFileList] = useState([]);
	const [previewOpen, setPreviewOpen] = useState(false);
	const [previewImage, setPreviewImage] = useState('');

	useEffect(() => {
		async function getAssetInfo(assetId) {
			const resp = await postApi({ assetId }, '/assets/get-asset');
			if (resp.type == 'success') {
				const assetInfo = resp.data;
				if (resp.data.images) {
					setImgFileList(
						resp.data.images.map((el, index) => {
							return {
								uid: index,
								name: el.split('/').pop(),
								url: el,
								alreadyUploaded: true,
							};
						})
					);
				}
				if (assetInfo.employee) {
					setValue('employee.name', assetInfo.employee.name);
					setValue('employee.id', assetInfo.employee.id);
					setValue('employee.dateOfAssign', assetInfo.employee.dateOfAssign);
				}
				if (assetInfo.type) {
					setValue('type', assetInfo.type);
				}
				if (assetInfo.name) {
					setValue('name', assetInfo.name);
				}
				if (assetInfo.id) {
					setValue('id', assetInfo.id);
				}
				if (assetInfo.department) {
					setValue('department', assetInfo.department);
				}
				if (assetInfo.serialNumber) {
					setValue('serialNumber', assetInfo.serialNumber);
				}
				if (assetInfo.price) {
					setValue('price', assetInfo.price);
				}
				if (assetInfo.vendor) {
					setValue('vendor', assetInfo.vendor);
				}
				if (assetInfo.dateOfPurchase) {
					setValue('dateOfPurchase', assetInfo.dateOfPurchase);
				}
				if (assetInfo.warrantyExpiryDate) {
					setValue('warrantyExpiryDate', assetInfo.warrantyExpiryDate);
				}
				if (assetInfo.purchaseOrder) {
					setValue('purchaseOrder', assetInfo.purchaseOrder);
				}
				if (assetInfo.imeiNumber) {
					setValue('imeiNumber', assetInfo.imeiNumber);
				}
				if (assetInfo.custom && assetInfo.custom.length) {
					setAllConfiguration(assetInfo.custom);
				}
				if (assetInfo.invoiceId && assetInfo.invoiceId._id.length) {
					setValue('defaultInvoiceInfo', {
						value: assetInfo.invoiceId._id,
						label: assetInfo.invoiceId.invoiceId,
					});
					setSelectedInvoice(assetInfo.invoiceId);
				} else {
					setValue('defaultInvoiceInfo', {
						value: '',
						label: '',
					});
				}

				if (assetInfo.officeId && assetInfo.officeId._id.length) {
					setValue('defaultOfficeInfo', {
						value: assetInfo.officeId._id,
						label: `${assetInfo.officeId.fullAddres}`,
					});
					setSelectedOffice(assetInfo.officeId);
				} else {
					setValue('defaultOfficeInfo', {
						value: '',
						label: '',
					});
				}

				if (assetInfo.health && assetInfo.health.length) {
					setCustomHealth(assetInfo.health);
				}
				setValue('assetId', assetInfo._id);
			}
		}
		getAssetInfo(assetId);
	}, [assetId, setValue]);

	useEffect(() => {
		if (confString) {
			textareaRef.current.style.height = '0px';
			const scrollHeight = textareaRef.current.scrollHeight;
			textareaRef.current.style.height = scrollHeight + 'px';
		}
	}, [confString]);
	useEffect(() => {
		let confList = '';
		for (let x = 0; x < configurationInfo.length; x++) {
			let tempStr = '';
			const eachConf = configurationInfo[x];
			tempStr += eachConf.key;
			tempStr += ',' + eachConf.value;
			if (eachConf.company) {
				tempStr += ',' + eachConf.company;
			}

			tempStr += '\n';
			confList += tempStr;
		}
		setConfString(confList);
	}, [configurationInfo]);

	const defaultInvoiceInfo = getValues('defaultInvoiceInfo');
	const defaultOfficeInfo = getValues('defaultOfficeInfo');
	const officeId = getValues('officeId');
	const invoiceId = getValues('invoiceId');

	const setInvoiceId = async (id) => {
		try {
			const invoiceFound = await getInvoice(id);

			console.log(invoiceFound, 'invoiceFound12');
			if (invoiceFound) {
				setSelectedInvoice(invoiceFound);
			}

			setValue('invoiceId', id);
		} catch (e) {
			console.error(e);
		}
	};
	const setOfficeLocation = async (id) => {
		try {
			const officeFound = await getOfficeLoc(id);

			if (officeFound) {
				setSelectedOffice(officeFound);
			}

			setValue('officeId', id);
		} catch (e) {
			console.error(e);
		}
	};

	// const toUploadList = imgFileList.filter((el) => !el.hasOwnProperty('alreadyUploaded'));

	const onSubmit = async (data) => {
		try {
			const body = data;

			body.images = await uploadAssetImgs(imgFileList, assetId);
			// body.configuration = configurationInfo;
			body.custom = configurationInfo;
			body.health = healthInfo.map((el) => {
				if (!el.value) {
					throw new Error('Please Provide Health Information');
				}
				return {
					...el,
					value: el.value.toLowerCase(),
				};
			});

			const resp = await postApi(body, '/assets/update-asset');
			if (resp.type === 'error') {
				dispatch(
					showSnack('ASSETS_CREATE_FAILED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			} else {
				dispatch(
					showSnack('ASSETS_CREATE_SUCCESS', {
						label: 'Successfully Updated Asset',
						timeout: 7000,
					})
				);
				navigate('/assets', { replace: true });
			}
		} catch (e) {
			dispatch(
				showSnack('ASSETS_CREATE_FAILED', {
					label: e.message,
					timeout: 7000,
				})
			);
		}
	};

	const getBase64 = (file) =>
		new Promise((resolve, reject) => {
			const reader = new FileReader();
			reader.readAsDataURL(file);
			reader.onload = () => resolve(reader.result);
			reader.onerror = (error) => reject(error);
		});

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPreviewOpen(true);
	};

	console.log(imgFileList);

	const props = {
		name: 'file',
		headers: {
			authorization: 'authorization-text',
		},
		onChange: (info) => {
			if (info.file.status === 'removed' && info.file.alreadyUploaded) {
				console.log('remove file', info.file);
				// Delete Img Func
			}
			setImgFileList(info.fileList);
		},
		beforeUpload: (file, fileList) => {
			// console.log(fileList);

			for (let i = 0; i < fileList.length; i++) {
				if (fileList[i].size > 1e6) {
					dispatch(
						showSnack('INVALID_HEALTH', {
							label: 'File Size should be less than 1MB',
							timeout: 7000,
						})
					);
					return Upload.LIST_IGNORE;
				}
			}

			if (fileList.length + imgFileList.length > 4) {
				dispatch(
					showSnack('ASSETS_CREATE_FAILED', {
						label: 'Cannot Add more than 4 images',
						timeout: 3000,
					})
				);
				return Upload.LIST_IGNORE;
			}
			return false;
		},
	};

	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<div className="assets-header">
				<h3>Organization Assets</h3>
			</div>

			<AntModal title="Are you sure you want to update?" footer={null} centered open={confirmationModal}>
				<textarea
					className="remarks-class"
					{...register('remarks')}
					placeholder="Provide Remarks for the update"
					type="text"
				/>
				<div className="add-asset-confirmation-action">
					<Button
						onClick={() => {
							setConfirmationModal(false);
						}}
					>
						Cancel
					</Button>
					<button form="form1" type="submit" value="Submit" className="edit-asset-confirmation">
						OK
					</button>
				</div>
			</AntModal>

			<div className="add-assest-container">
				<div className="add-assest-header">
					<h4>Update Asset</h4>
					<div className="add-assest-action">
						<button
							onClick={() => {
								navigate('/assets');
							}}
							className="cancel-btn"
						>
							Cancel
						</button>
						<button onClick={() => setConfirmationModal(true)} className="save-btn">
							Update
						</button>
					</div>
				</div>
				<form className="add-newasset-form" id="form1" onSubmit={handleSubmit(onSubmit)}>
					<h4>Employee Section</h4>
					<div
						style={{ borderBottom: '1px solid #3f3f3f', paddingBottom: '20px' }}
						className="each-assest-row"
					>
						<div className="each-assest-field">
							<label>Employee Name*</label>
							<input disabled {...register('employee.name')} type="text" />
						</div>
						<div className="each-assest-field">
							<label>Employee Id*</label>
							<input disabled {...register('employee.id')} type="text" />
						</div>
					</div>
					<h4>Device Section</h4>
					<div className="each-assest-row">
						<div className="each-assest-field">
							<label>Asset Id*</label>
							<input {...register('id')} type="text" />
						</div>
						<div className="each-assest-field">
							<label>Serial Number *</label>
							<input disabled {...register('serialNumber')} type="text" />
						</div>
					</div>
					<div className="each-assest-row">
						<div className="each-assest-field">
							<label>Asset Type*</label>

							<AssetTypeFromBackend control={control} />
							{/* <Controller
								render={({ field: { onChange, value, ref } }) => {
									return (
										<Select
											inputRef={ref}
											value={options.filter((c) => (value ? value.includes(c.value) : null))}
											onChange={(val) => {
												onChange(val.value);
											}}
											options={options}
										/>
									);
								}}
								control={control}
								name="type"
								defaultValue={'laptop'}
							/> */}
							{/* <input {...register('assestType')} type="text" /> */}
						</div>
						<div className="each-assest-field">
							<label>Asset *</label>
							<input {...register('name')} type="text" />
						</div>
					</div>

					<div className="each-assest-row">
						<div className="each-assest-field">
							<label>Custom Fields*</label>
							{configurationInfo.length > 0 ? (
								<textarea
									ref={textareaRef}
									className="conf-textare"
									readOnly
									disabled
									value={confString}
								/>
							) : null}

							<AddCustomConfiguration
								configurationInfo={configurationInfo}
								setAllConfiguration={setAllConfiguration}
							/>
						</div>
						<div className="each-assest-field">
							<label>Department *</label>
							<input {...register('department')} type="text" />
						</div>
					</div>
					<div className="each-assest-row">
						<div className="each-assest-field">
							<label>Date Of Purchase*</label>
							<Controller
								control={control}
								name="dateOfPurchase"
								render={({ field }) => {
									return (
										<ThemeProvider theme={defaultMaterialTheme}>
											<KeyboardDatePicker
												clearable
												value={field.value}
												placeholder="10/10/2018"
												onChange={(date) => field.onChange(date)}
												maxDate={new Date()}
												format="dd/MM/yyyy"
												disabled={true}
											/>
										</ThemeProvider>
									);
								}}
							/>
						</div>
						<div className="each-assest-field">
							<label>Date Of Assigning *</label>
							<Controller
								control={control}
								name="employee.dateOfAssign"
								render={({ field }) => {
									return (
										<ThemeProvider theme={defaultMaterialTheme}>
											<KeyboardDatePicker
												clearable
												value={field.value}
												placeholder="10/10/2018"
												onChange={(date) => field.onChange(date)}
												maxDate={new Date()}
												format="dd/MM/yyyy"
											/>
										</ThemeProvider>
									);
								}}
							/>
						</div>
					</div>
					<div className="each-assest-row">
						<div className="each-assest-field">
							<label>Warranty Ending On*</label>
							<Controller
								control={control}
								name="warrantyExpiryDate"
								render={({ field }) => {
									return (
										<ThemeProvider theme={defaultMaterialTheme}>
											<KeyboardDatePicker
												clearable
												value={field.value}
												placeholder="10/10/2018"
												onChange={(date) => field.onChange(date)}
												format="dd/MM/yyyy"
											/>
										</ThemeProvider>
									);
								}}
							/>
						</div>
						<div className="each-assest-field">
							<label>Vendor *</label>
							<input {...register('vendor')} type="text" />
						</div>
					</div>
					<div className="each-assest-row">
						<div className="each-assest-field">
							<label>Price*</label>
							<input disabled={true} {...register('price')} type="text" />
						</div>
						<div className="each-assest-field">
							<label>IMEI Number</label>
							<input {...register('imeiNumber')} type="text" />
						</div>
					</div>

					<SearchInvoicePO
						setInvoiceId={setInvoiceId}
						defaultInvoiceInfo={defaultInvoiceInfo}
						selectedInvoice={selectedInvoice}
					/>

					<div className="add-asset-dragger">
						<Dragger
							onPreview={handlePreview}
							multiple
							fileList={imgFileList}
							listType="picture"
							accept="image/png,image/jpeg"
							{...props}
						>
							{/* <p className="ant-upload-drag-icon"> */}
							<IoCloudUploadOutline fontSize={20} />
							{/* </p> */}
							<p className="ant-upload-text">Click or drag to Upload Asset Images.</p>
						</Dragger>
						{/* </div> */}
						<div>
							{previewImage && (
								<Image
									wrapperStyle={{
										display: 'none',
									}}
									preview={{
										visible: previewOpen,
										onVisibleChange: (visible) => setPreviewOpen(visible),
										afterOpenChange: (visible) => !visible && setPreviewImage(''),
									}}
									src={previewImage}
								/>
							)}
						</div>
					</div>
					<h4 style={{ marginTop: '20px', paddingTop: '20px', borderTop: '2px solid #3f3f3f' }}>
						Additional Information
					</h4>
					<div className="each-assest-row">
						<div className="each-assest-field">
							<label>Health</label>
							<AddHealth healthInfoExist={healthInfo} setCustomHealth={setCustomHealth} />
						</div>
					</div>
					<SearchOfficeLoc
						setOfficeLocation={setOfficeLocation}
						defaultOfficeInfo={defaultOfficeInfo}
						selectedOffice={selectedOffice}
					/>
				</form>
			</div>
			<FooterDashboard />
		</div>
	);
};

export default EditAssetScreen;

const AssetTypeFromBackend = ({ control }) => {
	const [assetTypes, setAssetTypes] = useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		async function getAssetTypes() {
			try {
				const resp = await getApi('/assetze/admin-setup/category');
				if (resp.type === 'success') {
					setAssetTypes(resp.categories);
				} else {
					dispatch(
						showSnack('FILE_UPLOAD_ERROR', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				dispatch(
					showSnack('FILE_UPLOAD_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			}
		}

		getAssetTypes();
	}, []);

	const allOptions = options.concat(
		assetTypes.map((el) => {
			return {
				value: el.key,
				label: el.key,
			};
		})
	);
	return (
		<Controller
			render={({ field: { onChange, value, ref } }) => {
				return (
					<Select
						inputRef={ref}
						value={allOptions.filter((c) => (value ? value.includes(c.value) : null))}
						onChange={(val) => {
							onChange(val.value);
						}}
						options={allOptions}
					/>
				);
			}}
			control={control}
			name="type"
			defaultValue={'laptop'}
		/>
	);
};
