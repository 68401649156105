import React, { useState } from 'react';

const FeatureList = () => {
	const [showAllFeature, toggleFeature] = useState(false);
	return (
		<div className="featurelist-container">
			<div className={'head-1'}>What you can do with Assetze</div>
			<div className="feature-row">
				<div className="each-feature-container">
					<div className="image-container">
						<img src={require('../../../images/new-landing/features/asset-management.png')} />
					</div>
					<div className="head-1">Asset Management</div>
					<div className="content-1">Efficiently track the IT assets using Assetze platform</div>
				</div>
				<div className="each-feature-container">
					<div className="image-container">
						<img src={require('../../../images/new-landing/features/device-health.png')} />
					</div>
					<div className="head-1">Device Health</div>
					<div className="content-1">
						With pre-built parameters, you'd be able to track the health of the devices
					</div>
				</div>
				<div className="each-feature-container">
					<div className="image-container">
						<img src={require('../../../images/new-landing/features/assign-users.png')} />
					</div>
					<div className="head-1">Assign users</div>
					<div className="content-1">
						Just a click of a button can assign a new user to a particular device
					</div>
				</div>
			</div>
			{showAllFeature ? (
				<>
					<div className="feature-row">
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/warranty-check.png')} />
							</div>
							<div className="head-1">Warranty check </div>
							<div className="content-1">You can directly check if the device is in warranty or not.</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/product-images.png')} />
							</div>
							<div className="head-1">Product Images</div>
							<div className="content-1">Can monitor each and every device along with images.</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/unassign-users.png')} />
							</div>
							<div className="head-1">Unassign users </div>
							<div className="content-1">You can unassign a particular device</div>
						</div>
					</div>
					<div className="feature-row">
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/custom-inputs.png')} />
							</div>
							<div className="head-1">Custom Inputs </div>
							<div className="content-1">You can track any custom metric of your choice.</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/schedules-assign.png')} />
							</div>
							<div className="head-1">Schedule Assign/Unassign</div>
							<div className="content-1">
								Yet to join and on notice period? Devices can be scheduled for assign or unassign
							</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/device-history.png')} />
							</div>
							<div className="head-1">Device History</div>
							<div className="content-1">
								You can keep track of the previous users of a particular device
							</div>
						</div>
					</div>
					<div className="feature-row">
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/multi-admin.png')} />
							</div>
							<div className="head-1">Multi-Admin</div>
							<div className="content-1">Multi-admin portal for various departments to access</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/mult-location.png')} />
							</div>
							<div className="head-1">Multi-Location</div>
							<div className="content-1">
								Tracking Devices based out of various locations can be tracked easily
							</div>
						</div>
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/reports.png')} />
							</div>
							<div className="head-1">Reports</div>
							<div className="content-1">
								Generate custom reports that are required for various departments
							</div>
						</div>
					</div>
					<div className="feature-row">
						<div className="each-feature-container">
							<div className="image-container">
								<img src={require('../../../images/new-landing/features/shift-based.png')} />
							</div>
							<div className="head-1">Shift-based</div>
							<div className="content-1">Assign assets based on shift based users</div>
						</div>
					</div>
				</>
			) : null}

			<button onClick={() => toggleFeature(!showAllFeature)} className="view-feature-btn">
				View Feature
			</button>
		</div>
	);
};
export default FeatureList;
