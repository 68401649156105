import React, { useState } from 'react';
import './index.css';
import Logo from '../../../images/assetze-logo.png';
import { BsCircle } from 'react-icons/bs';
import { BsCircleFill } from 'react-icons/bs';

const Feedback = function () {
	const [op1, setOp1] = useState(false);
	const [op2, setOp2] = useState(false);
	const [op3, setOp3] = useState(false);
	const [op4, setOp4] = useState(false);
	const [op5, setOp5] = useState(false);
	const [ans1, setAns1] = useState(false);
	const [ans2, setAns2] = useState(false);
	return (
		<div className="feedback-container">
			<div className="header">
				<img src={Logo} />
			</div>
			<div className="body">
				<div className="title">Assetze + Gmail Addon</div>
				<div className="qn">How do you rate the experience of using Assetez Gmail Addon?</div>
				<div className="options">
					<div className="each-option">
						{op1 ? (
							<BsCircleFill
								className="fill"
								onClick={() => {
									setOp1(false);
								}}
							/>
						) : (
							<BsCircle
								className="hollow"
								onClick={() => {
									setOp1(true);
								}}
							/>
						)}
						<div className="opinion">I love it!</div>
					</div>
					<div className="each-option">
						{op2 ? (
							<BsCircleFill
								className="fill"
								onClick={() => {
									setOp2(false);
								}}
							/>
						) : (
							<BsCircle
								className="hollow"
								onClick={() => {
									setOp2(true);
								}}
							/>
						)}
						<div className="opinion">I like it</div>
					</div>
					<div className="each-option">
						{op3 ? (
							<BsCircleFill
								className="fill"
								onClick={() => {
									setOp3(false);
								}}
							/>
						) : (
							<BsCircle
								className="hollow"
								onClick={() => {
									setOp3(true);
								}}
							/>
						)}
						<div className="opinion">I'm neutral</div>
					</div>
					<div className="each-option">
						{op4 ? (
							<BsCircleFill
								className="fill"
								onClick={() => {
									setOp4(false);
								}}
							/>
						) : (
							<BsCircle
								className="hollow"
								onClick={() => {
									setOp4(true);
								}}
							/>
						)}
						<div className="opinion">I dislike it</div>
					</div>
					<div className="each-option">
						{op5 ? (
							<BsCircleFill
								className="fill"
								onClick={() => {
									setOp5(false);
								}}
							/>
						) : (
							<BsCircle
								className="hollow"
								onClick={() => {
									setOp5(true);
								}}
							/>
						)}
						<div className="opinion">I hate it</div>
					</div>
				</div>
				<div className="qn">What specific feedback do you have about this integration? </div>
				<input className="answer" />
				<div className="qn">Email ID*</div>
				<input className="email" required />
				<div className="qn">Name*</div>
				<div className="name">
					<input className="first-name" placeholder="First Name" required />
					<input className="last-name" placeholder="Last Name" required />
				</div>

				<div className="qn">If necessary can we follow up with you for more detail?*</div>
				<div className="follow-up">
					<div className="ans1">
						{ans1 ? (
							<BsCircleFill
								className="fill"
								onClick={() => {
									setAns1(false);
								}}
							/>
						) : (
							<BsCircle
								className="hollow"
								onClick={() => {
									setAns1(true);
								}}
							/>
						)}
						<div className="opinion">Yes</div>
					</div>
					<div className="ans2">
						{ans2 ? (
							<BsCircleFill
								className="fill"
								onClick={() => {
									setAns2(false);
								}}
							/>
						) : (
							<BsCircle
								className="hollow"
								onClick={() => {
									setAns2(true);
								}}
							/>
						)}
						<div className="opinion">No</div>
					</div>
				</div>
				<button>Submit</button>
			</div>
		</div>
	);
};

export default Feedback;
