import React from 'react';
import DashboardFooter from '../../../components/FooterDashboard';
import HeaderComponent from '../../../components/Header';
import './index.css';
import { useNavigate } from 'react-router-dom';
import TimeIcon from '../../../images/time-icon.png';
import InProgress from '../../../images/in-progress.png';
import RecentTicket from '../../../images/recent-tickets.png';
import ResolvedTicket from '../../../images/resolved-tickets.png';
import ThisWeek from '../../../images/tickets-weekly.png';
import Chart from 'react-google-charts';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from 'recharts';

const data = [
	{ name: 'Mon', x: 35, y: 10, z: 5 },
	{ name: 'Tue', x: 20, y: 15, z: 15 },
	{ name: 'Wed', x: 22, y: 25, z: 5 },
	{ name: 'Thu', x: 5, y: 10, z: 25 },
	{ name: 'Fri', x: 40, y: 5, z: 5 },
	{ name: 'Sat', x: 20, y: 5, z: 5 },
];

const donutData = [
	['Resolved', 'Unresolved'],
	['Resolved', 24],
	['In-Progress', 3],
	['Unresolved', 25],
];

const donutOptions = {
	pieHole: 0.8,
	colors: ['#33D7DB', '#e1e1e1', '#35EFA0'],
};

const DashboardIT = function () {
	const navigate = useNavigate();
	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<div className="page-heading">Incident Management</div>
			<div className="tickets">
				<div className="body-box">
					<img src={TimeIcon} />
					<div className="box-value">3</div>
					<div className="box-name">Pending Tickets</div>
				</div>
				<div className="body-box">
					<img src={RecentTicket} />
					<div className="box-value">8</div>
					<div className="box-name">Recent Tickets</div>
				</div>
				<div className="body-box">
					<img className="img" src={InProgress} />
					<div className="box-value">2</div>
					<div className="box-name">In-progress Tickets</div>
				</div>
				<div className="body-box">
					<img className="img" src={ThisWeek} />
					<div className="box-value">30</div>
					<div className="box-name">Tickets This Week</div>
				</div>
				<div className="body-box">
					<img src={ResolvedTicket} />
					<div className="box-value">24</div>
					<div className="box-name">Resolved Tickets</div>
				</div>
			</div>
			<div className="page-body">
				<div className="page-left">
					<div className="row1">
						{/*<div className='this-week'>
						<div className='item-heading'>This Week</div>
						<Chart 
							className='donut-chart'
							width={'300px'}
							chartType="PieChart"
							loader={<div>Loading Chart</div>}
							data={donutData}
							options={donutOptions}
							/>
						
					</div>
					<div className='ticket-board'>
						<div className='item-heading'>Ticket Board</div>
						<div className='ticket-table'>
                            <div className='table-name'>Puneeth</div>
                            <div className='table-issue'>Sofwtare Issue</div>
                            <div className='table-priority'>1hr 29mins</div>
                        </div>
					</div>
                    */}

						<div className="this-week">
							<div className="heading">This&nbsp;Week</div>
							<Chart
								className="donut-chart"
								width={'300px'}
								chartType="PieChart"
								loader={<div>Loading Chart</div>}
								data={donutData}
								options={donutOptions}
							/>
						</div>
						<div className="ticket-board">
							<div className="heading">Ticket&nbsp;Board</div>
							<div className="ticket-table">
								<div className="table-name">Puneeth</div>
								<div className="table-issue">Sofwtare Issue</div>
								<div className="table-priority">1hr 29mins</div>
							</div>
							<div className="ticket-table">
								<div className="table-name">Sooraj</div>
								<div className="table-issue">Hardware Issue</div>
								<div className="table-priority1">1day 10 hrs</div>
							</div>
							<div className="ticket-table">
								<div className="table-name">Sooraj</div>
								<div className="table-issue">Hardware Issue</div>
								<div className="table-priority1">1day 10hrs</div>
							</div>
						</div>
					</div>
					<div className="row2">
						<div className="leaderboard">
							<div className="heading">Leaderboard</div>
							<table>
								<thead>
									<tr>
										<th>Admin</th>
										<th>Tickets Resolved</th>
										<th>Resolved</th>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>Sumanth</td>
										<td>10</td>
										<td>12%</td>
									</tr>
									<tr>
										<td>Puneeth</td>
										<td>15</td>
										<td>30%</td>
									</tr>
								</tbody>
							</table>
						</div>
						<div className="weekly-chart">
							<div className="heading">Weekly&nbsp;chart</div>
							<BarChart width={400} height={300} data={data}>
								<XAxis dataKey="name" />
								<YAxis />
								<Bar dataKey="x" stackId="a" fill="#33D7DB" />
								<Bar dataKey="y" stackId="a" fill="#5EFAA0" />
								<Bar dataKey="z" stackId="a" fill="#FFAF00" />
							</BarChart>
						</div>
					</div>
				</div>

				<div className="page-right">
					<div className="right-title">Tickets on Priority</div>
					<div className="name-priority">
						<div className="name">Santhosh</div>
						<div className="priority1">High</div>
					</div>
					<div className="name-priority">
						<div className="name">Jagadish</div>
						<div className="priority1">High</div>
					</div>
					<div className="name-priority">
						<div className="name">Sharath</div>
						<div className="priority2">Medium</div>
					</div>
					<div className="name-priority">
						<div className="name">Pramatha</div>
						<div className="priority2">Medium</div>
					</div>
					<div className="name-priority">
						<div className="name">Sooraj</div>
						<div className="priority2">Medium</div>
					</div>
					<div className="name-priority">
						<div className="name">Puneeth</div>
						<div className="priority3">Low</div>
					</div>
				</div>
			</div>
			<button className="download-report">Download Report</button>
			<DashboardFooter />
		</div>
	);
};

export default DashboardIT;
