import React, { useEffect, useState } from 'react';
import { Button, Input, Tag } from 'antd';
import { IoMdCopy } from 'react-icons/io';
import { AiOutlineFieldTime } from 'react-icons/ai';
import { FaWindows } from 'react-icons/fa';
import HeaderComponent from '../../components/Header';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { postApi } from '../../services/api';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';

const DownloadAgent = () => {
	const [searchParams] = useSearchParams();
	const [releaseInfo, setReleaseInfo] = useState({});
	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		async function fetchReleaseInfo() {
			try {
				const resp = await postApi(
					{ token: searchParams.get('token') },
					'/agent/release/download-agent-by-link'
				);

				if (resp.type === 'success') {
					setReleaseInfo(resp);
				} else {
					dispatch(
						showSnack('INVALID_releaseve_version', {
							label: resp.error,
							timeout: 7000,
						})
					);
					navigate('/');
				}
			} catch (e) {
				console.error(e);
			}
		}
		fetchReleaseInfo();
	}, [searchParams]);

	console.log('releaseid');

	if (!(releaseInfo && releaseInfo.version)) {
		return <div>loading!!</div>;
	}

	return (
		<div>
			<HeaderComponent />
			<div className="agent-drawer">
				<div style={{ marginTop: '150px' }} className="div1">
					<div className="text">Platform</div>
					<div className="icon-div">
						<FaWindows />
					</div>
					<div>
						<Button href={releaseInfo.releaseUrl}>Download Agent</Button>
					</div>
				</div>
				<div className="div1">
					<div className="text">Version</div>
					<Tag color="#32D7DB">v{releaseInfo.version}</Tag>
				</div>
				{/* <div className='div2'>
                    <div className='text'>Copy Link</div>
                    <div>
                        <div><Input readOnly value={"https://assetze.com/downloadagent"} addonAfter={<IoMdCopy />} /></div>
                        <div className='innerdiv'>
                            <div className='icon-div'><AiOutlineFieldTime /></div>
                            <div className='download-text'>Download link expires in 14 days<span>Expire Now</span></div>
                        </div>

                    </div>
                </div> */}
				<div className="div3">
					<div>How to install?</div>
					<div className="innerdiv">
						<p>
							1. Download the file on the system or copy the link and paste it on the browser to download
						</p>
						<p>2. Right click on the agent file and run as administrator</p>
						<p>3. Follow the steps of installation shown on screen</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DownloadAgent;
