import React, { useCallback, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { Strings } from './config';
import { AiOutlineCloseCircle, AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { HiOutlineChevronDown } from 'react-icons/hi';
import { IoMdAddCircleOutline } from 'react-icons/io';
import { BsCloudUpload, BsGrid1X2, BsQuestionCircle } from 'react-icons/bs';
import { RiDeleteBinLine } from 'react-icons/ri';
import {
	ADD_CHOICES,
	ADD_NEW_QUESTION,
	ADD_NEW_SECTION,
	CHANGE_QUESTION_TYPE,
	DELETE_CHOICE,
	DELETE_QUESTION,
	DELETE_SECTION,
	MOVE_QUESTION_DOWN,
	MOVE_QUESTION_UP,
	MOVE_SECTION_DOWN,
	MOVE_SECTION_UP,
	SET_QUESTION_ERROR,
	SET_SECTION_ERROR,
	SET_UPlOAD_ERROR,
	SHOW_CUSTOM_FIELD,
	SHOW_TEMPLATE,
	UPDATE_CHOICE,
	UPDATE_MULTIPLE_CHOICE_QUESTION,
	UPDATE_QUESTION,
	UPDATE_SECTION,
	UPDATE_UPLOAD,
} from '../../redux/actions';
import { FiAlignLeft } from 'react-icons/fi';
import { BiRadioCircleMarked } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { showSnack, snackbarReducer } from 'react-redux-snackbar';

export default function CustomFieldComponent() {
	const sections = useSelector((state) => state.appreducer?.vendorFormData?.sections);
	const [useTemplate, setUseTemplate] = useState(true);
	const [removeTemplate, setRemoveTemplate] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	console.log('sections', sections);
	const showTemplate = (e) => {
		e.preventDefault();
		setUseTemplate(false);
		setRemoveTemplate(true);
		dispatch({
			type: SHOW_TEMPLATE,
			payload: [
				{
					_id: uuid(),
					qtype: 'section',
					name: 'Company details',
					validSection: true,
					description: 'Details of the company such as GST, PAN, MSME, etc..',
					questions: [
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Company registered address',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Company GSTIN',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Registered under MSME ? If yes,provide number',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Company telephone number',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Title of the point of contact',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Nature of business',
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question: 'Whether any of your relative working with our company',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Fax/Telephone number of company',
						},
					],
				},
				{
					_id: uuid(),
					qtype: 'section',
					name: 'Banking Information',
					description: 'Details of the company such as bank information, etc..',
					validSection: true,

					questions: [
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Bank name',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Bank address',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Beneficiary Name',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'International bank account number (IBAN)',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Swift / Bank identifier code (BIC)',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Account currency (Eg:INR,USD,EUR)',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Bank account number',
						},
						{
							_id: uuid(),
							validQuestion: true,
							qtype: Strings.QUESTION_ANSWER,
							question: 'Bank IFSC Code',
						},
					],
				},
				{
					_id: uuid(),
					qtype: 'section',
					validSection: true,
					name: 'Supporting documentation',
					description:
						'In order to be considered for registration by the <Company Name> procurement section please provide the following information',
					questions: [
						{
							_id: uuid(),
							qtype: 'file-question',
							validQuestion: true,
							question:
								'Current and valid copy of certificate of incorporation/business certificate or national equipment',
						},
						{
							_id: uuid(),
							qtype: 'file-question',
							validQuestion: true,
							question: 'Current and valid copy of Company PAN (Permanent Account Number) card',
						},
						{
							_id: uuid(),
							qtype: 'file-question',
							validQuestion: true,
							question: 'Current and valid copy of Company GST Registration Certificate',
						},
						{
							_id: uuid(),
							qtype: 'file-question',
							validQuestion: true,
							question: 'MSME Certificate',
						},
						{
							_id: uuid(),
							qtype: 'file-question',
							validQuestion: true,
							question: 'Cancel Cheque as per provided above bank details',
						},
					],
				},
				{
					_id: uuid(),
					qtype: 'section',
					validSection: true,
					name: 'ANNEXURE-1',
					description:
						'Please read the below all questions and provide the correct answer by clicking the right option',
					questions: [
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Has it proven impossible to determine the ownership structure/parent company or the Director structure of the company',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question: 'Is the Third Party (or its parent company) registered to a High-Risk Country',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question: 'Is it owned partly or wholly by the Government of a high-risk country?',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question: 'Are any of the Directors or shareholders of the company public officials?',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Have any of the Directors,Officers or Shareholders of the Third Party been investigated or prosecuted or are they currently under investigation for involvement in an act of bribery or corruption ',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Have any of the Directors,Officers or Shareholders of the Third Party been convicted for involvement in an act of bribery or corruption?',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Is the Third Party seeking to act as an agent or intermediary between <Company Name> and government authorities or service?',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Has the Third Party failed to provide assurance that it is commited to the principle of preventing bribery and corruption in its business dealings with <Company Name> ',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'If requested of the Third Party,has the Third Party expressed an unwillingness to accept contractual conditions relating to bribery and corruption including:',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'A commitment to observe laws regarding the prevention of bribery and corruption and to abide by the standards mutually agreed between <company name> and the third party',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Acceptance of the right to suspend payment on the basis of substantial allegations of bribery and corruption',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Acceptance of the right to terminate in the event of bribery and corruption being proved',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
						{
							_id: uuid(),
							qtype: Strings.MULTIPLE_CHOICE,
							validQuestion: true,
							question:
								'Acceptance of the right to audit on the grounds of a suspicion bribery and corruption',
							choices: [
								{ _id: uuid(), choice: 'Yes' },
								{ _id: uuid(), choice: 'No' },
							],
						},
					],
				},
				// {
				//   _id: uuid(),
				//   qtype: "section",
				//   validSection: true,
				//   name: "CERTIFICATION",
				//   description: "Signature",
				// },
			],
		});
	};
	const returnToCustomField = (e) => {
		e.preventDefault();
		dispatch({ type: SHOW_CUSTOM_FIELD });
		setRemoveTemplate(false);
		setUseTemplate(true);
	};
	return (
		<div className="vendor-modal-container">
			<div className="templateContainer">
				<h3>Custom Field</h3>
				{useTemplate ? <h5 onClick={showTemplate}>Use Template</h5> : null}
				{removeTemplate ? <h5 onClick={returnToCustomField}>Remove Template</h5> : null}
			</div>

			{sections?.map((section, index) => (
				<div key={section?._id}>
					<SectionWrapper
						questions={section?.questions}
						sectionId={section?._id}
						sectionName={section?.name}
						sectionDescription={section?.description}
						sectionType={section?.qtype}
						sectionIndex={index}
						validSection={section?.validSection}
					/>
				</div>
			))}
			{/* <div>
        I,the undersigned, hereby accept the "Company Name" General Terms & Conditions,a copy of which has been provided
        to me and warrant that the information provided in this form is correct and, in the event of changes, details
        will be provided as soon as possible
      </div> */}
		</div>
	);
}

const VendorFormWrapper = ({ children }) => {
	return (
		<div className="customFieldContainer">
			<div className="questionFieldContainer">{children}</div>
		</div>
	);
};

const SectionWrapper = ({
	questions,
	sectionDescription,
	sectionName,
	sectionType,
	sectionId,
	sectionIndex,
	validSection,
}) => {
	const sectionDetails = {
		sectionDescription,
		sectionName,
		sectionType,
		validSection,
	};

	return (
		<div>
			<SectionComponent
				sectionDetails={sectionDetails}
				sectionId={sectionId}
				questions={questions}
				sectionIndex={sectionIndex}
			/>

			{questions?.map((q, i) => (
				<div key={q?._id}>
					{q?.qtype === Strings.QUESTION_ANSWER || q?.qtype === Strings.MULTIPLE_CHOICE ? (
						<QuestionForm question={q} sectionId={sectionId} index={i} questions={questions} />
					) : (
						q.qtype === Strings.UPLOAD_FILE && (
							<UploadFileComponent question={q} sectionId={sectionId} index={i} questions={questions} />
						)
					)}
				</div>
			))}
		</div>
	);
};

const SectionComponent = ({ sectionDetails, sectionId, questions, sectionIndex, index }) => {
	const dispatch = useDispatch();

	const [questionModal, setQuestionModal] = useState(false);
	const sections = useSelector((state) => state.appreducer.vendorFormData.sections);
	const customFieldErrors = useSelector((state) => state.appreducer.customFieldErrors);

	const selectAddQuestionView = (section_id) => {
		console.log('selectAddQuestionView');
		sections?.forEach((section) => {
			if (section._id === section_id) {
				console.log('out');
				console.log('section1', section._id, section_id);
				console.log(
					!section.hasOwnProperty('name') ||
						section.name === '' ||
						!section.hasOwnProperty('description') ||
						section.description === ''
				);
				if (
					!section.hasOwnProperty('name') ||
					section.name === '' ||
					!section.hasOwnProperty('description') ||
					section.description === ''
				) {
					console.log('in');
					dispatch({
						type: SET_SECTION_ERROR,
						payload: { isSection: true, sectionId: section_id },
					});
					return;
				}
			}
		});
		dispatch({
			type: ADD_NEW_QUESTION,
			payload: {
				_id: uuid(),
				qtype: Strings.QUESTION_ANSWER,
				sectionId,
				answer: '',
			},
		});
		setQuestionModal(false);
	};

	const sectionViewHandler = (section_id) => {
		let flag = false;
		sections?.forEach((section) => {
			if (section._id === section_id) {
				if (section.questions.length === 0) {
					flag = true;
					dispatch(
						showSnack('NEW_SECTION_ERROR', {
							label: 'Please fill atleast one question to proceed to next section',
							timeout: 5000,
						})
					);
					return;
				}
				if (
					!section.hasOwnProperty('name') ||
					section.name === '' ||
					!section.hasOwnProperty('description') ||
					section.description === ''
				) {
					dispatch({
						type: SET_SECTION_ERROR,
						payload: { isSection: true, sectionId: section_id },
					});
					flag = true;
					return;
				}
			}
		});
		if (flag === false) {
			dispatch({
				type: ADD_NEW_SECTION,
				payload: { _id: uuid(), qtype: 'section' },
			});
			setQuestionModal(false);
		}
	};

	const selectUploadView = (section_id) => {
		sections?.forEach((section) => {
			if (section._id === section_id) {
				if (
					!section.hasOwnProperty('name') ||
					section.name === '' ||
					!section.hasOwnProperty('description') ||
					section.description === ''
				) {
					console.log('in');
					dispatch({
						type: SET_SECTION_ERROR,
						payload: { isSection: true, sectionId: section_id },
					});
					return;
				}
			}
		});
		dispatch({
			type: ADD_NEW_QUESTION,
			payload: { _id: uuid(), qtype: 'file-question', sectionId, answer: '' },
		});
		setQuestionModal(false);
	};
	const deleteSectionHandler = () => {
		dispatch({ type: DELETE_SECTION, payload: { sectionId } });
	};
	const moveSectionUpHandler = (id) => {
		const index = sections.findIndex((object) => {
			return object._id === id;
		});
		dispatch({ type: MOVE_SECTION_UP, payload: { sectionIndex: index } });
	};
	const moveSectionDownHandler = (id) => {
		const index = sections.findIndex((object) => {
			return object._id === id;
		});
		console.log('index-----------', index);
		dispatch({ type: MOVE_SECTION_DOWN, payload: { sectionDownIndex: index } });
	};

	// if (sectionError) {
	//   dispatch(
	//     showSnack("NEW_SECTION_ERROR", {
	//       label: "Please fill atleast one question to proceed to next section",
	//       timeout: 7000,
	//     })
	//   );
	// }
	console.log(customFieldErrors);
	console.log(sectionDetails[index], sectionDetails[sectionId]?.questions?.length);
	return (
		<VendorFormWrapper>
			<div className="question-container">
				<div className="topSection">
					<span>
						{sectionIndex + 1} of {sections.length}
					</span>
					<div className="moveIcons">
						<div style={{ color: '#333333' }}>
							{sectionIndex === 0 ? null : (
								<AiOutlineUp
									onClick={() => {
										moveSectionUpHandler(sectionId);
									}}
								/>
							)}
						</div>
						<div style={{ color: '#333333' }}>
							{sectionIndex === sections.length - 1 ? null : (
								<AiOutlineDown
									onClick={() => {
										moveSectionDownHandler(sectionId);
									}}
								/>
							)}
						</div>
					</div>
				</div>

				<input
					value={sectionDetails?.sectionName}
					type="text"
					placeholder="Section Name"
					name="name"
					onChange={(e) => {
						dispatch({
							type: UPDATE_SECTION,
							payload: { updateFeild: 'name', name: e.target.value, sectionId },
						});
						dispatch({
							type: SET_SECTION_ERROR,
							payload: { isSection: false },
						});
					}}
				/>
				{!sectionDetails.validSection ? (
					<div className="customErrorMessage">Please fill the section name</div>
				) : null}

				<input
					value={sectionDetails?.sectionDescription}
					type="text"
					placeholder="Add Description"
					name="description"
					onChange={(e) => {
						dispatch({
							type: UPDATE_SECTION,
							payload: {
								updateFeild: 'description',
								desc: e.target.value,
								sectionId,
							},
						});
						dispatch({
							type: SET_SECTION_ERROR,
							payload: { isSection: false },
						});
					}}
				/>
				{!sectionDetails.validSection ? (
					<div className="customErrorMessage">Please fill the section description</div>
				) : null}
			</div>
			{/* {sectionError
        ? dispatch(
            showSnack("NEW_SECTION_ERROR", {
              label: "Please fill atleast one question to proceed to next section",
              timeout: 7000,
            })
          )
        : // <div className="customErrorMessage">Please fill atleast one question to proceed to next section </div>
          null} */}
			<div className="vendor_footer_container">
				<div className="vendor_sub_footer">
					<div style={{ color: '#333333' }}>
						<IoMdAddCircleOutline onClick={() => setQuestionModal(!questionModal)} />
					</div>

					{questionModal ? (
						<div className="dropdown-questions">
							<div onClick={() => selectAddQuestionView(sectionId)}>
								<h6>
									<BsQuestionCircle />
									&nbsp; Add Question
								</h6>
							</div>

							<div onClick={() => sectionViewHandler(sectionId)}>
								<h6>
									<BsGrid1X2 /> &nbsp; Add Section
								</h6>
							</div>

							<div onClick={() => selectUploadView(sectionId)}>
								<h6>
									<BsCloudUpload /> &nbsp; Upload File
								</h6>
							</div>
						</div>
					) : null}

					<div style={{ color: '#CF1414' }}>
						<RiDeleteBinLine onClick={deleteSectionHandler} />
					</div>
				</div>
			</div>
		</VendorFormWrapper>
	);
};

const QuestionForm = ({ question, sectionId, index, questions }) => {
	console.log('question-answer-index', index);
	return (
		<>
			<VendorFormWrapper>
				{question?.qtype === Strings.QUESTION_ANSWER ? (
					<>
						<QuestionAnswerComponent question={question} sectionId={sectionId} index={index} />
					</>
				) : (
					<>
						<MultipleChoiceComponent
							question={question}
							sectionId={sectionId}
							index={index}
							questions={questions}
						/>
					</>
				)}
				<VendorFormFooter qId={question._id} sectionId={sectionId} index={index} questions={questions} />
			</VendorFormWrapper>
		</>
	);
};

const QuestionAnswerComponent = ({ question, sectionId }) => {
	const [isQuestionTypeModal, setIsQuestionTypeModal] = useState(false);
	const dispatch = useDispatch();
	const customFieldErrors = useSelector((state) => state.appreducer.customFieldErrors);

	const handleQuestionInput = (e) => {
		dispatch({
			type: SET_QUESTION_ERROR,
			payload: { isQuestion: false },
		});
		dispatch({
			type: UPDATE_QUESTION,
			payload: {
				qId: question._id,
				qtype: Strings.QUESTION_ANSWER,
				question: e.target.value,
				sectionId,
			},
		});
	};

	return (
		<>
			<div className="vendor_question_container">
				<div className="question-container">
					<textarea
						value={question?.question}
						type="text"
						placeholder="Question"
						name="question"
						onChange={handleQuestionInput}
						// error={!question.validQuestion}
						// helperText={!question.validQuestion ? "Please fill the Question" : ""}
					></textarea>
					{!question.validQuestion ? (
						<div className="customErrorMessage">Please fill the question</div>
					) : null}

					<h6>Answer</h6>
					<h6></h6>
				</div>
				<div className="left_vendor_question">
					<div className="question_selector_container">
						<div className="question_type" onClick={() => setIsQuestionTypeModal(!isQuestionTypeModal)}>
							<>
								{question?.qtype === Strings.QUESTION_ANSWER ? (
									<div className="optionsContainer">
										<FiAlignLeft
											style={{
												fontSize: '16px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Answer
									</div>
								) : (
									<div className="optionsContainer">
										<BiRadioCircleMarked
											style={{
												fontSize: '18px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Multiple Choice
									</div>
								)}
							</>
							{/* <>
                {question?.qtype === Strings.MULTIPLE_CHOICE ? (
                  <div>
                    <BiRadioCircleMarked style={{ fontSize: "18px", color: "#329C9E", marginRight: "5px" }} />
                    Multiple Choice
                  </div>
                ) : (
                  <>Type of Input</>
                )}
              </> */}

							<HiOutlineChevronDown
								style={{
									fontSize: '16px',
									color: '#888888',
									marginLeft: '10px',
								}}
							/>
						</div>
						{isQuestionTypeModal ? (
							<>
								<div className="question_type_options">
									<div
										onClick={() => {
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: {
													qId: question._id,
													qtype: Strings.QUESTION_ANSWER,
													sectionId,
												},
											});
										}}
										className="dropDownOptions"
									>
										<FiAlignLeft
											style={{
												fontSize: '16px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Answer
									</div>
									<div
										onClick={() => {
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: {
													qId: question._id,
													qtype: Strings.MULTIPLE_CHOICE,
													sectionId,
												},
											});
										}}
										className="dropDownOptions"
									>
										<BiRadioCircleMarked
											style={{
												fontSize: '18px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Multiple Choice
									</div>
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

const MultipleChoiceComponent = ({ question, sectionId, questions }) => {
	const [isQuestionTypeModal, setIsQuestionTypeModal] = useState(false);
	//   const questions = useSelector((state) => state.appreducer.sections.questions.choices);
	const customFieldErrors = useSelector((state) => state.appreducer.customFieldErrors);
	const choiceRef = useRef(null);

	let mcqAnswers;
	questions.forEach((el) => {
		console.log('questionmcq', el);
		if (el.qtype === Strings.MULTIPLE_CHOICE && el._id === question._id) {
			mcqAnswers = el.choices;
		}
	});
	console.log('question', questions, mcqAnswers);
	const dispatch = useDispatch();
	const handleMcqQuestion = (e) => {
		dispatch({
			type: UPDATE_MULTIPLE_CHOICE_QUESTION,
			payload: {
				qId: question._id,
				qtype: Strings.MULTIPLE_CHOICE,
				question: e.target.value,
				sectionId,
			},
		});
		dispatch({
			type: SET_QUESTION_ERROR,
			payload: {
				isQuestion: false,
				qtype: Strings.QUESTION_ANSWER,
				question: e.target.value,
				sectionId,
			},
		});
	};
	console.log('question99', question);

	const handleEnter = (e) => {
		if (e.keyCode == 13) {
			if (!e.currentTarget.value) return;

			dispatch({
				type: ADD_CHOICES,
				payload: { qId: question._id, qtype: 'multiple-choice', sectionId },
			});
			choiceRef.current.click();
		}
	};
	const deleteHandler = (id) => {
		if (mcqAnswers?.length === 1) {
			alert('Edit current input');
			return;
		}
		dispatch({
			type: DELETE_CHOICE,
			payload: { qId: question._id, choiceId: id, sectionId },
		});
	};
	return (
		<>
			<div className="vendor_question_container">
				<div className="left_vendor_question">
					<textarea
						value={question.question}
						type="text"
						placeholder="Question"
						name="question"
						onChange={handleMcqQuestion}
					></textarea>

					{!question.validQuestion ? (
						<div className="customErrorMessage">Please fill the question</div>
					) : null}
					<div className="mcq_container">
						{mcqAnswers?.map((choice) => {
							console.log('mcq', mcqAnswers, 'choice', choice);
							return (
								<div key={choice?._id} className="mcq_answer_container">
									<input type="radio" className="radioField" />
									<input
										onKeyDown={handleEnter}
										type="text"
										name="answer"
										value={choice.choice}
										ref={choiceRef}
										onChange={(e) => {
											dispatch({
												type: UPDATE_CHOICE,
												payload: {
													qId: question._id,
													qtype: Strings.MULTIPLE_CHOICE,
													choiceId: choice._id,
													choice: e.target.value,
													sectionId,
												},
											});
										}}
									/>
									<AiOutlineCloseCircle
										onClick={() => deleteHandler(choice._id)}
										style={{ fontSize: '25px', cursor: 'pointer' }}
									/>
								</div>
							);
						})}
					</div>
				</div>
				<div className="left_vendor_question">
					<div className="question_selector_container">
						<div className="question_type" onClick={() => setIsQuestionTypeModal(!isQuestionTypeModal)}>
							<>
								{question?.qtype === Strings.MULTIPLE_CHOICE ? (
									<div className="optionsContainer">
										<BiRadioCircleMarked
											style={{
												fontSize: '18px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Multiple Choice
									</div>
								) : (
									<div className="optionsContainer">
										<FiAlignLeft
											style={{
												fontSize: '16px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Answer
									</div>
								)}
							</>
							<HiOutlineChevronDown
								style={{
									fontSize: '16px',
									color: '#888888',
									marginLeft: '10px',
								}}
							/>
						</div>
						{isQuestionTypeModal ? (
							<>
								<div className="question_type_options">
									<div
										onClick={() => {
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: {
													qId: question._id,
													qtype: Strings.QUESTION_ANSWER,
													sectionId,
												},
											});
										}}
										className="dropDownOptions"
									>
										<FiAlignLeft
											style={{
												fontSize: '16px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Answer
									</div>
									<div
										onClick={() => {
											setIsQuestionTypeModal(false);
											dispatch({
												type: CHANGE_QUESTION_TYPE,
												payload: {
													qId: question._id,
													qtype: Strings.MULTIPLE_CHOICE,
													sectionId,
												},
											});
										}}
										className="dropDownOptions"
									>
										<BiRadioCircleMarked
											style={{
												fontSize: '18px',
												color: '#329C9E',
												marginRight: '5px',
											}}
										/>
										Multiple Choice
									</div>
								</div>
							</>
						) : null}
					</div>
				</div>
			</div>
		</>
	);
};

const UploadFileComponent = ({ question, sectionId, index, questions }) => {
	const dispatch = useDispatch();
	const customFieldErrors = useSelector((state) => state.appreducer.customFieldErrors);

	const handleUploadInput = (e) => {
		dispatch({
			type: UPDATE_UPLOAD,
			payload: {
				qId: question._id,
				qtype: 'file-question',
				question: e.target.value,
				sectionId,
			},
		});
		dispatch({
			type: SET_QUESTION_ERROR,
			payload: { isQuestion: false, question: e.target.value, sectionId },
		});
	};
	return (
		<VendorFormWrapper>
			<div className="upload-container">
				<textarea
					value={question.question}
					type="text"
					placeholder="Question to Upload Images"
					name="question"
					onChange={handleUploadInput}
				></textarea>
				{!question.validQuestion ? (
					<div className="customErrorMessage">Please fill the upload question</div>
				) : null}

				<p>Allowed files for upload documents, images, PDF, jpeg, png, with max file size of 1MB</p>
			</div>
			<VendorFormFooter qId={question._id} sectionId={sectionId} index={index} questions={questions} />
		</VendorFormWrapper>
	);
};

const VendorFormFooter = ({ qId, data, sectionId, index, questions }) => {
	const [questionModal, setQuestionModal] = useState(false);
	const dispatch = useDispatch();
	const customFieldErrors = useSelector((state) => state.appreducer.customFieldErrors);

	const sections = useSelector((state) => state.appreducer.vendorFormData.sections);

	const selectAddQuestionView = (section_id, qId) => {
		console.log('selectAddQuestionView');
		for (let i = 0; i < sections.length; i++) {
			const section = sections[i];
			if (section._id === section_id) {
				for (let j = 0; j < section.questions.length; j++) {
					const question = section.questions[j];
					if (question._id === qId) {
						console.log('hello', question);
						console.log(!question.hasOwnProperty('question'), question.question === '');
						if (!question.hasOwnProperty('question') || question.question === '') {
							console.log('true');
							dispatch({
								type: SET_QUESTION_ERROR,
								payload: { isQuestion: true, qId, sectionId: section_id },
							});
							setQuestionModal(false);
							return;
						}
					}
				}
			}
		}

		dispatch({
			type: ADD_NEW_QUESTION,
			payload: {
				_id: uuid(),
				qtype: Strings.QUESTION_ANSWER,
				sectionId,
				qIndex: index + 1 ?? 0,
				deleteCount: 0,
			},
		});

		setQuestionModal(false);
	};
	const selectSectionView = (sectionId, qId) => {
		for (let i = 0; i < sections.length; i++) {
			const section = sections[i];
			if (section._id === sectionId) {
				for (let j = 0; j < section.questions.length; j++) {
					const question = section.questions[j];
					if (question._id === qId) {
						console.log('hello', question);
						console.log(!question.hasOwnProperty('question'), question.question === '');
						if (!question.hasOwnProperty('question') || question.question === '') {
							console.log('true');
							dispatch({
								type: SET_QUESTION_ERROR,
								payload: { isQuestion: true, qId, sectionId },
							});
							setQuestionModal(false);
							return;
						}
					}
				}
			}
		}

		dispatch({
			type: ADD_NEW_SECTION,
			payload: { _id: uuid(), qtype: 'section' },
		});
		setQuestionModal(false);
	};
	const selectUploadView = (sectionId, qId) => {
		for (let i = 0; i < sections.length; i++) {
			const section = sections[i];
			if (section._id === sectionId) {
				for (let j = 0; j < section.questions.length; j++) {
					const question = section.questions[j];
					if (question._id === qId) {
						console.log('hello', question);
						console.log(!question.hasOwnProperty('question'), question.question === '');
						if (!question.hasOwnProperty('question') || question.question === '') {
							console.log('true');
							dispatch({
								type: SET_QUESTION_ERROR,
								payload: { isQuestion: true, qId, sectionId },
							});
							setQuestionModal(false);
							return;
						}
					}
				}
			}
		}
		dispatch({
			type: ADD_NEW_QUESTION,
			payload: {
				_id: uuid(),
				qtype: Strings.UPLOAD_FILE,
				sectionId,
				qIndex: index + 1 ?? 0,
				deleteCount: 0,
			},
		});

		setQuestionModal(false);
	};
	const deleteQuestionHandler = () => {
		dispatch({ type: DELETE_QUESTION, payload: { qId, sectionId } });
	};
	const moveUpHandler = (id) => {
		const index = questions.findIndex((object) => {
			return object._id === id;
		});
		dispatch({ type: MOVE_QUESTION_UP, payload: { qIndex: index, sectionId } });
	};
	const moveDownHandler = (id) => {
		if (questions?.length === 1) return;
		const index = questions.findIndex((object) => {
			return object._id === id;
		});
		dispatch({
			type: MOVE_QUESTION_DOWN,
			payload: { qIndex: index, sectionId },
		});
	};
	console.log(questions?.length, questions?.length - 1);

	return (
		<div className="vendor_footer_container">
			<div className="vendor_sub_footer">
				<div style={{ color: '#333333' }}>
					<IoMdAddCircleOutline onClick={() => setQuestionModal(!questionModal)} />
				</div>

				{questionModal ? (
					<div className="dropdown-questions">
						<div onClick={() => selectAddQuestionView(sectionId, qId)}>
							<h6>
								<BsQuestionCircle />
								&nbsp; Add Question
							</h6>
						</div>
						<div onClick={() => selectSectionView(sectionId, qId)}>
							<h6>
								<BsGrid1X2 /> &nbsp; Add Section
							</h6>
						</div>
						<div onClick={() => selectUploadView(sectionId, qId)}>
							<h6>
								<BsCloudUpload /> &nbsp; Upload File
							</h6>
						</div>
					</div>
				) : null}
				<div style={{ color: '#333333' }}>
					{index === 0 ? null : <AiOutlineUp onClick={() => moveUpHandler(qId)} />}
				</div>
				<div style={{ color: '#333333' }}>
					{index === questions?.length - 1 ? null : <AiOutlineDown onClick={() => moveDownHandler(qId)} />}
				</div>
				<div style={{ color: '#CF1414' }}>
					<RiDeleteBinLine onClick={deleteQuestionHandler} />
				</div>
			</div>
		</div>
	);
};

// export function getDefaultTemplate() {
//   return [
//     {
//       _id: uuid(),
//       qtype: "section",
//       name: "Company details",
//       description: "Details of the company such as GST, PAN, MSME, etc..",
//       questions: [
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Company registered address" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Company GSTIN" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Registered under MSME ? If Yes,provide number" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Company telephone number" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Title of the point of contact" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Nature of business" },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question: "Whether any of your relative working with our company",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Fax/Telephone number of company" },
//       ],
//     },
//     {
//       _id: uuid(),
//       qtype: "section",
//       name: "Banking Information",
//       description: "Details of the company such as bank information, etc..",
//       questions: [
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Bank name" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Bank address" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Beneficiary Name" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "International bank account number (IBAN)" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Swift / Bank identifier code (BIC)" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Account currency (Eg:INR,USD,EUR)" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Bank account number" },
//         { _id: uuid(), qtype: Strings.QUESTION_ANSWER, question: "Bank IFSC Code" },
//       ],
//     },
//     {
//       _id: uuid(),
//       qtype: "section",
//       name: "Supporting documentation",
//       description:
//         "In order to be considered for registration by the <Company Name> procurement section please provide the following information",
//       questions: [
//         {
//           _id: uuid(),
//           qtype: "file-question",
//           question: "Current and valid copy of certificate of incorporation/business certificate or national equipment",
//         },
//         {
//           _id: uuid(),
//           qtype: "file-question",
//           question: "Current and valid copy of Company PAN (Permanent Account Number) card",
//         },
//         {
//           _id: uuid(),
//           qtype: "file-question",
//           question: "Current and valid copy of Company GST Registration Certificate",
//         },
//         {
//           _id: uuid(),
//           qtype: "file-question",
//           question: "MSME Certificate",
//         },
//         {
//           _id: uuid(),
//           qtype: "file-question",
//           question: "Cancel Cheque as per provided above bank details",
//         },
//       ],
//     },
//     {
//       _id: uuid(),
//       qtype: "section",
//       name: "ANNEXURE-1",
//       description: "Please read the below all questions and provide the correct answer by clicking the right option",
//       questions: [
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "Has it proven impossible to determine the ownership structure/parent company or the Director structure of the company",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question: "Is the Third Party (or its parent company) registered to a High-Risk Country",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question: "Is it owned partly or wholly by the Government of a high-risk country?",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question: "Are any of the Directors or shareholders of the company public officials?",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "Have any of the Directors,Officers or Shareholders of the Third Party been investigated or prosecuted or are they currently under investigation for involvement in an act of bribery or corruption ",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "Have any of the Directors,Officers or Shareholders of the Third Party been convicted for involvement in an act of bribery or corruption?",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "Is the Third Party seeking to act as an agent or intermediary between <Company Name> and government authorities or service?",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "Has the Third Party failed to provide assurance that it is commited to the principle of preventing bribery and corruption in its business dealings with <Company Name> ",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "If requested of the Third Party,has the Third Party expressed an unwillingness to accept contractual conditions relating to bribery and corruption including:",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "A commitment to observe laws regarding the prevention of bribery and corruption and to abide by the standards mutually agreed between <company name> and the third party",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question:
//             "Acceptance of the right to suspend payment on the basis of substantial allegations of bribery and corruption",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question: "Acceptance of the right to terminate in the event of bribery and corruption being proved",
//           choices: [
//             { _id: uuid(), choice: "Yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//         {
//           _id: uuid(),
//           qtype: Strings.MULTIPLE_CHOICE,
//           question: "Acceptance of the right to audit on the grounds of a suspicion bribery and corruption",
//           choices: [
//             { _id: uuid(), choice: "yes" },
//             { _id: uuid(), choice: "No" },
//           ],
//         },
//       ],
//     },
//     {
//       _id: uuid(),
//       qtype: "section",
//       name: "CERTIFICATION",
//       description: "Signature",
//     },
//   ];
// }
