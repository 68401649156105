import React, { useState, useEffect, useRef } from 'react';
import format from 'date-fns/format';
import Drawer from '@mui/material/Drawer';
import './index.css';
import Select from 'react-select';
import { AiOutlineUser, AiOutlineCalendar } from 'react-icons/ai';
import { getApi, postApi } from '../../services/api';
import { MdOutlineOpenInNew } from 'react-icons/md';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { AiOutlineDown } from 'react-icons/ai';
import { AiOutlineUp } from 'react-icons/ai';
// import { Timeline, TimelineEvent } from 'react-event-timeline';
import { BsCircle } from 'react-icons/bs';
import { BsCircleFill } from 'react-icons/bs';
import Issue from '../../images/Issue.png';
import Time from '../../images/Time.png';
import { useDebounce } from 'usehooks-ts';

const incidentsList = [
	{
		employee: {
			name: 'santhosh h',
			employeeId: '121',
			email: 'santhosh@assetze.com',
		},
		createdAt: new Date(),
		errorMessage: 'Windows Suddenly Crashed',
		assetId: 'sGUEEkY4Z3',
		assetName: 'Lenovo ThinkPad P52',
		priority: 'high',
		assignedTo: 'Vikas',
		status: 'Pending',
	},
	{
		employee: {
			name: 'Sooraj',
			employeeId: '1213',
			email: 'sooraj@assetze.com',
		},
		createdAt: new Date(Date.now() - 10 * 24 * 60 * 60 * 1000),
		errorMessage: 'Wifi is not working',
		assetId: 'sGUEEkY4Z3',
		assetName: 'Lenovo ThinkPad P52',
		priority: 'medium',
		assignedTo: 'Dheeraj',
		status: 'Pending',
	},
];

const itAdmins = [
	{ value: 'adminid1', label: 'Vijay Rao' },
	{ value: 'adminid2', label: 'Krishna Kumar' },
	{ value: 'adminid3', label: 'Amith Ghosh' },
];

const markAsOptions = [
	{ value: 'open', label: 'Open' },
	{ value: 'closed', label: 'Closed' },
	{ value: 'waiting-for-review', label: 'Waiting For Review' },
	{ value: 'pending', label: 'Un Assigned' },
];

const IncidentsComponent = function () {
	const [incidents, setIncidents] = useState([]);
	const [itAdmins, setItAdmins] = useState([]);
	const [showIncidentInfo, toggleIncidentInfo] = useState(false);
	const [selectedIncidentId, setSelectedIncidentId] = useState(null);
	useEffect(() => {
		const getAdminList = async function () {
			const resp = await postApi({}, '/users/get-admins');
			if (resp.type === 'success') {
				setItAdmins(
					resp.admins.map((el) => {
						return {
							value: el._id,
							label: el.firstName,
						};
					})
				);
			}
		};
		getAdminList();
	}, []);

	useEffect(() => {
		const getTicketList = async function () {
			const resp = await postApi({}, '/assets/get-tickets');
			if (resp.type === 'success') {
				setIncidents(resp.data);
				//console.log(incidents);
			}
		};

		getTicketList();
	}, []);

	const openIncidentInfo = (incidentId) => {
		setSelectedIncidentId(incidentId);
		toggleIncidentInfo(true);
	};

	const updatedIncident = (incidentId, updatedData) => {
		setIncidents(
			incidents.map((el) => {
				if (el._id === incidentId) {
					return updatedData;
				}
				return el;
			})
		);
	};

	const selectedIncident = incidents.find((el) => el._id === selectedIncidentId);

	return (
		<>
			<div className="incidents-container">
				{/* <div className="add-filter-container">
          <button>+ Add Filter</button>
        </div> */}
				<div className="incidents-container-list">
					{incidents.map((el, index) => {
						return (
							<EachIncident
								openIncidentInfo={openIncidentInfo}
								itAdmins={itAdmins}
								key={index}
								incident={el}
								index={index}
								updatedIncident={updatedIncident}
							/>
						);
					})}
				</div>
				<Drawer
					anchor={'right'}
					open={showIncidentInfo}
					onClose={() => {
						toggleIncidentInfo(!showIncidentInfo);
					}}
				>
					<IncidentActionInfo updatedIncident={updatedIncident} incident={selectedIncident} />
				</Drawer>
			</div>
		</>
	);
};

const IncidentActionInfo = function ({ incident, updatedIncident }) {
	const [showConversation, toggleShowConversation] = useState(false);
	if (!incident) {
		return null;
	}
	return (
		<div className="incidentinfo-container">
			<h3>{incident.errorMessage}</h3>
			<div className="name-container">
				<div>
					<AiOutlineUser /> {incident.employee?.email}
				</div>
				<div>
					<AiOutlineCalendar />
					{format(new Date(incident.createdAt), 'dd MMM yyyy')}
				</div>
				{/* <button
					onClick={() => {
						toggleShowConversation(!showConversation);
					}}
				>
					View Past Conversation
				</button> */}
				<UserConversation updatedIncident={updatedIncident} incident={incident} />
			</div>
		</div>
	);
};

const AlwaysScrollToBottom = () => {
	const elementRef = useRef();
	useEffect(() => elementRef.current.scrollIntoView());
	return <div ref={elementRef} />;
};

const getIncidentInfo = async function (incidentId) {
	const resp = await postApi({ incidentId }, '/users/incident-info');
	if (resp.type === 'success') {
		return resp.incident;
	}
};

const UserConversation = function ({ incident, updatedIncident }) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
	} = useForm();
	const [incidentInfo, setIncidentInfo] = useState(incident);
	useEffect(() => {
		let timer;

		timer = setInterval(async () => {
			const data = await getIncidentInfo(incidentInfo._id);
			if (data.comments && data.comments.length > incidentInfo.comments.length) {
				setIncidentInfo(data);
			}
		}, 1000 * 10);

		return () => {
			if (timer) clearInterval(timer);
		};
	}, [incidentInfo, setIncidentInfo]); // empty bracket calls the useEffect callback on initial render

	if (!incidentInfo) {
		return null;
	}

	const onSubmit = async function (data) {
		console.log(data);
		const resp = await postApi({ incidentId: incident._id, ...data }, '/users/add-comment');
		if (resp.type === 'success') {
			updatedIncident(incident._id, resp.incident);
			reset({ replyMsg: '' });
		} else {
		}
	};

	const comments = incidentInfo.comments;
	return (
		<div className="conversation-container">
			<div className="content">
				{comments.map((eachComment, index) => {
					const dynamicClass = eachComment.msgType === 'employee' ? 'employee-message' : 'admin-message';
					return (
						<div className={dynamicClass} key={index}>
							<div>{eachComment.message}</div>
							<div className="date-message">
								{format(new Date(eachComment.createdAt), 'do MMM, EEE')} <br />
								{eachComment.fromUser} - {eachComment.msgType}
							</div>
						</div>
					);
				})}
				<AlwaysScrollToBottom />
			</div>
			<form onSubmit={handleSubmit(onSubmit)} className="reply-content">
				<input {...register('replyMsg', { required: true })} type={'text'} placeholder={'Reply'} />
				<button>Reply</button>
			</form>
		</div>
	);
};

const colors = ['#4285f4', '#ea4335', '#f7bd45', '#4ca954'];

const EachIncident = function ({ incident, index, itAdmins, openIncidentInfo, updatedIncident }) {
	const [adminComment, setAdminComment] = useState('');
	const [adminInsight, setAdminInsight] = useState('');
	const [view, setView] = useState(false);
	const [historyInfo, setHistoryInfo] = useState([]);
	const debounceVal = useDebounce(adminComment, 400);
	const insightDebounce = useDebounce(adminInsight, 400);
	const dispatch = useDispatch();
	useEffect(() => {
		const addComment = async function (adminComment) {
			//console.log(adminComment,"ue");
			const resp = await postApi(
				{ incidentId: incident._id, addComment: adminComment, type: 'comment' },
				'/users/ticket/admin-comment'
			);
			if (resp.type === 'success') {
				updatedIncident(incident._id, resp.incident);
			}
		};

		addComment(adminComment);
	}, [debounceVal]);

	useEffect(() => {
		const addInsight = async function (adminInsight) {
			//console.log(adminComment,"ue");
			const resp = await postApi(
				{ incidentId: incident._id, addComment: adminInsight, type: 'insight' },
				'/users/ticket/admin-comment'
			);
			if (resp.type === 'success') {
				updatedIncident(incident._id, resp.incident);
			}
		};

		addInsight(adminInsight);
	}, [insightDebounce]);

	useEffect(() => {
		setAdminComment(incident.adminComment);
		setAdminInsight(incident.adminInsights);
	}, [incident]);

	const logoChar = (incident.createdBy && incident.createdBy.name && incident.createdBy.name.charAt(0)) || 'A';
	const priorityClass = incident.priority === 'high' ? 'high' : 'medium';
	const bgColor = colors[2];

	const assignedToChanged = async function (value) {
		try {
			console.log(value, 'value');
			const resp = await postApi(
				{
					userId: value.value,
					incidentId: incident._id,
					assetId: incident.assetId,
				},
				'/assets/assign-incident'
			);
			if (resp.type === 'success') {
				updatedIncident(incident._id, resp.incident);
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: `successfully assigned to user ${value.label}`,
						timeout: 3000,
					})
				);
			} else {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};

	const getHistory = async function () {
		try {
			const resp = await postApi({ assetId: incident.assetId }, '/assets/incidents/get-history');
			if (resp.type === 'success') {
				console.log(resp.history, incident.assetId);
				setHistoryInfo(resp.history);
			}
		} catch (e) {
			console.log(e);
		}
	};

	const handleChange = (event) => {
		setAdminComment(event.target.value);
	};

	const handleInsightChange = (event) => {
		setAdminInsight(event.target.value);
	};

	const updateIncidentState = async function (value) {
		try {
			console.log(value, 'value');
			const resp = await postApi(
				{ updateState: value.value, incidentId: incident._id },
				'/assets/modify-incident-status'
			);
			if (resp.type === 'success') {
				updatedIncident(incident._id, resp.incident);
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: `successfully modified incident state to ${value.label}`,
						timeout: 3000,
					})
				);
			} else {
				dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (e) {
			console.error(e);
		}
	};
	return (
		<div className="each-incident-container">
			<div className="incident-short">
				<div className="each-incident-info">
					<div style={{ backgroundColor: bgColor, color: 'white' }} className="logo-container">
						{logoChar}
					</div>
					<div className="each-incident-data">
						<div className="error-message">
							{incident.errorMessage}
							<MdOutlineOpenInNew
								style={{
									color: colors[3],
									marginLeft: '20px',
									fontSize: '16px',
									cursor: 'pointer',
								}}
								onClick={() => openIncidentInfo(incident._id)}
							/>
						</div>
						<div className="name-container">
							<div>
								<AiOutlineUser /> {incident.employee?.email}
							</div>
							<div>
								<AiOutlineCalendar />
								{format(new Date(incident.createdAt), 'dd MMM yyyy')}
							</div>
						</div>
						<div className="priority-container">
							<div className={priorityClass}>{incident.priority}</div>
							<div className="asset-name">{incident.assetName}</div>
						</div>
					</div>
				</div>
				<div className="each-incident-action">
					<Select
						value={itAdmins.filter((option) => option.value === incident.assignedTo)}
						placeholder={'Assign To'}
						options={itAdmins}
						onChange={(value) => assignedToChanged(value)}
					/>
					<Select
						value={markAsOptions.filter((option) => option.value === incident.status)}
						placeholder={'Mark As'}
						options={markAsOptions}
						onChange={(value) => updateIncidentState(value)}
					/>
					<div className="downward-arrow">
						{view ? (
							<AiOutlineUp
								onClick={() => {
									setView(!view);
								}}
							/>
						) : (
							<AiOutlineDown
								onClick={() => {
									setView(!view);
									getHistory();
								}}
							/>
						)}
					</div>
				</div>
			</div>
			{view ? (
				<div className="toggle-show-container">
					<div className="comment-insight">
						<div className="comment">
							<div className="heading">Comments</div>
							<textarea
								type="text"
								placeholder="Your comments"
								value={adminComment}
								onChange={handleChange}
							></textarea>
						</div>
						<div className="admin-insight">
							<div className="heading">Admin Insights</div>
							<textarea
								placeholder="Admin insights"
								value={adminInsight}
								onChange={handleInsightChange}
							></textarea>
						</div>
					</div>
					<div className="history">
						<div className="heading">Other issues in the device</div>
						<div className="timeline">
							{historyInfo.map((el, index) => {
								if (el._id !== incident._id) {
									return (
										<div className="each-issue" key={index}>
											<div className="open-in-new">
												<div className="timestamp">
													<img src={Time} />
													<div>{format(new Date(el.createdAt), 'dd MMM yyyy')}</div>
												</div>
												<MdOutlineOpenInNew
													style={{
														color: colors[3],
														marginLeft: '20px',
														fontSize: '16px',
														cursor: 'pointer',
													}}
													onClick={() => openIncidentInfo(incident._id)}
												/>
											</div>
											<div className="issues">
												<img src={Issue} />
												<div>{el.errorMessage}</div>
											</div>
											<div className="name-status">
												<div className="user">
													<AiOutlineUser />
													<div>{el.assignedTo.firstName}</div>
												</div>
												<div className="status-each">
													<div className="resolved">{el.status}</div>
													<div>{format(new Date(incident.createdAt), 'dd MMM yyyy')}</div>
												</div>
											</div>
										</div>
									);
								}
							})}
						</div>
					</div>
				</div>
			) : null}
		</div>
	);
};

export default IncidentsComponent;
