import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { postApi } from '../../services/api';

const CertificationForm = () => {
	const [agree, setAgree] = useState(false);
	const { vendorId } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [otpId, setOtpId] = useState('');
	const [otp, setOpt] = useState('');
	const [email, setEmail] = useState(null);
	const [companyName, setCompanyName] = useState(null);

	useEffect(() => {
		const submitResponse = async () => {
			try {
				const resp = await postApi({ vendorId }, '/vendorForm/otp?requestType=sendOTP');
				if (resp.type === 'success') {
					console.log('resp', resp);
					setOtpId(resp.data.otpId);
					setEmail(resp.data.email);
					setCompanyName(resp.data.companyName);
					// dispatch(
					//   showSnack("VENDOR_RESPONSE_FORM_ADDED", {
					//     label: "Vendor Form Submitted Successfully",
					//     timeout: 7000,
					//   })
					// );
				} else {
					return dispatch(
						showSnack('VENDOR_RESPONSE_FORM_ADDED', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (error) {
				return dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: error && error.message,
						timeout: 7000,
					})
				);
			}
		};
		submitResponse();
	}, []);

	const resendOtpHandler = async () => {
		console.log('resendOtp');
		try {
			const resp = await postApi({ vendorId, otpId }, '/vendorForm/otp?requestType=resendOTP');
			if (resp.type === 'success') {
				console.log(resp);
				setOtpId(resp.data);
				// dispatch(
				//   showSnack("VENDOR_RESPONSE_FORM_ADDED", {
				//     label: "Vendor Form Submitted Successfully",
				//     timeout: 7000,
				//   })
				// );
			} else {
				return dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (error) {
			return dispatch(
				showSnack('VENDOR_RESPONSE_FORM_ADDED', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};

	const verifyOtpHandler = async () => {
		if (agree) {
			console.log('agree', agree);
			try {
				const resp = await postApi({ vendorId, otpId, otpData: otp }, '/vendorForm/otp?requestType=verifyOTP');
				if (resp.type === 'success') {
					console.log(resp);
					navigate('/responseSubmitted');
					dispatch(
						showSnack('VENDOR_RESPONSE_FORM_ADDED', {
							label: 'Vendor Form Submitted Successfully',
							timeout: 7000,
						})
					);
				} else {
					return dispatch(
						showSnack('VENDOR_RESPONSE_FORM_ADDED', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (error) {
				return dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: error && error.message,
						timeout: 7000,
					})
				);
			}
		}
	};

	const checkBoxHandler = (e) => {
		if (e.target.checked) {
			setAgree(true);
		} else {
			setAgree(false);
		}
	};
	return (
		<div className="certificationContainer">
			<div className="certificationDetails">
				<div className="certificationName">Certification</div>
				<div className="certificationDescription">Signature</div>
			</div>
			<div className="certificationInfo">
				<div className="certificationCheckbox">
					<input type="checkbox" name="agree" id="agree" value={agree} onChange={checkBoxHandler} />
				</div>
				<div className="certificationInfoText">
					I,the undersigned, hereby accept the {companyName} General Terms & Conditions,a copy of which has
					been provided to me and warrant that the information provided in this form is correct and, in the
					event of changes, details will be provided as soon as possible
				</div>
			</div>
			<div className="otpText">
				<input
					type="text"
					placeholder="Enter the OTP here"
					value={otp}
					onChange={(e) => setOpt(e.target.value)}
				/>
				<div className="resendOtp">
					<p>OTP is sent to {email}</p>
					<button onClick={resendOtpHandler}>Resend OTP</button>
				</div>
			</div>

			<button className={agree ? 'certificationSubmit' : 'disabledButton'} onClick={verifyOtpHandler}>
				Verify OTP
			</button>
		</div>
	);
};

export default CertificationForm;
