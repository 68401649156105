import React, { useEffect, useState, useMemo } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useForm, Controller } from 'react-hook-form';
import { getApi, postApi } from '../../services/api';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import SignOff from '../../images/sign-off.png';

const SignOffSuccess = function () {
	const { register, handleSubmit, reset, control } = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const countries = useMemo(() => countryList().getData(), []);

	useEffect(() => {
		console.log('fetch saved company info');
		const getCompany = async function () {
			const resp = await getApi('/users/company');
			if (resp.type === 'success') {
				const { currencyCode, assetTag, depreciationValue, depreciationDuration } = resp.company;
				reset({
					currencyCode,
					assetTag,
					depreciationValue,
					depreciationDuration,
				});
			}
		};
		getCompany();
	}, [reset]);
	const onSubmit = async (data) => {
		console.log(data);
		const resp = await postApi(data, '/users/update-companyInfo');
		console.log(resp);
		if (resp.type === 'success') {
			dispatch(
				showSnack('COMPANY_INFO_UPDATED', {
					label: 'Successfully Updated Company Info',
					timeout: 3000,
				})
			);
		} else {
			dispatch(
				showSnack('COMPANY_INFO_UPDATE_FAILED', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}
	};

	return (
		<div className="newsignoff-container">
			<HeaderComponent />
			<div className="account-page-title">
				<div className="account-page-title-container">Exit Sign-Off</div>
			</div>
			<div className="sign-off-note">New Sign - Off</div>
			<div className="success-note-1">Sign off form of Puneeth</div>
			<div className="success-note-2">Sent Successfully</div>
			<div className="success-image">
				<img src={SignOff} className="sign-off-img" />
			</div>
			<button
				className="save-next"
				onClick={() => {
					navigate('/exit-responses');
				}}
			>
				Check Responses
			</button>
			<FooterDashboard />
		</div>
	);
};

export default SignOffSuccess;
