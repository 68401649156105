import React from 'react';
import LandingScreenFooter from '../../components/LandingFooter';
import LandingPageHeader from '../../components/LandingpageHeader';
import './index.css';

const AboutUsScreen = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />

			<div class="terms-container">
				<h2 className="header-about-us">About US</h2>
				<div className="aboutus-content">
					<div className="head-1">Complex Software, Simple to Use</div>
					<div className="content">
						Assetze makes it fast and easy for businesses to manage their IT and assets. We do this by
						taking a fresh approach to building and delivering software-as-a-service that’s affordable,
						quick to implement, and designed for the end-user. Unlike legacy software, Assetze builds tech
						that works for everyone, making it easy for IT, Incident Management, Run Maintenance, Cyber
						Security and Sign-off to employee to do their job and delight their customers.
					</div>
				</div>
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default AboutUsScreen;
