const { assetTagUrl } = require('./constants');

export const ApiPost = async (body, path) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${assetTagUrl}${path}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
		},
		credentials: 'include',
		body: JSON.stringify(body),
	});
	return await rawResponse.json();
};

export const DownloadDeprReport = async (body, path) => {
	let token = localStorage.getItem('jwt-token');
	const rawResponse = await fetch(`${assetTagUrl}${path}`, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
			'Content-Type': 'application/json',
			authorization: 'Bearer ' + token,
			'is-az': 'yes',
		},
		credentials: 'include',
		body: JSON.stringify(body),
	});
	const blob = await rawResponse.blob();
	const link = document.createElement('a');
	link.href = URL.createObjectURL(blob);
	link.download = 'DepreciationReport.csv';
	link.click();
	// return await rawResponse.json();
};
