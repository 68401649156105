import React, { useEffect, useState, useMemo } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useForm, Controller } from 'react-hook-form';
import { getApi, postApi } from '../../services/api';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import Modal from 'react-modal';

const AuthoritySignScreen = function () {
	const { register, handleSubmit, reset, control } = useForm();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [openModal, setOpenModal] = useState(false);
	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		setOpenModal(true);
	}, []);

	const onSubmit = async (data) => {
		try {
		} catch (e) {
			console.error(e);
		}
	};
	const customStyles = {
		content: {
			top: '50%',
			/* 
background: #FFFFFF;
border: 0.5px solid #888888;
border-radius: 5px;
*/

			width: 'auto',
			height: 'auto',
			padding: '0',
			left: '50%',
			right: 'auto',
			bottom: 'auto',
			transform: 'translate(-50%, -50%)',
			backgroundColor: '#ffffff',
			overflow: 'hidden',
			boxShadow: '0px 4px 6px 6px rgba(136, 136, 136, 0.25',
			borderRadius: '5px',
			border: '0.5px solid #888888',
		},
		overlay: {
			backgroundColor: 'rgba(0, 0, 0, 0.3)',
		},
	};

	return (
		<div className="newsignoff-container">
			<Modal
				contentLabel="Confirm Signoff"
				onRequestClose={() => {
					setShowModal(false);
				}}
				isOpen={showModal}
				ariaHideApp={false}
				style={customStyles}
			>
				<form onSubmit={handleSubmit(onSubmit)} className="confirm-signoff">
					<div className="modal-message"> OTP is sent to it******@company.com</div>
					<div className="modal-message">
						By entering the OTP you'll be digitally signing the exit sign-off
					</div>
					<input {...register('email')} type="email" placeholder="Employee Email" />
					<div className={'actions'}>
						<button
							onClick={() => {
								setShowModal(false);
							}}
						>
							Sign-Off
						</button>
					</div>
				</form>
			</Modal>

			<Modal
				contentLabel="Admin procees"
				onRequestClose={() => {
					setOpenModal(false);
				}}
				isOpen={openModal}
				ariaHideApp={false}
				style={customStyles}
			>
				<form onSubmit={handleSubmit(onSubmit)} className="admin-proceed">
					<div className="modal-message">Hi IT Support,</div>
					<div className="modal-note">By entering the OTP you'll be digitally signing the exit sign-off</div>
					<input {...register('email')} type="email" placeholder="Employee Email" />
					<div className={'actions'}>
						<button
							onClick={() => {
								setShowModal(false);
							}}
						>
							Sign-Off
						</button>
					</div>
				</form>
			</Modal>

			<HeaderComponent />
			<div className="authority-note">Hi IT Support, </div>
			<div className="authority-sign">
				<table>
					<tbody>
						<tr>
							<td>Name:</td>
							<td className="sign-values">Puneeth R</td>
						</tr>
						<tr>
							<td>Emp ID</td>
							<td className="sign-values">ERW-003</td>
						</tr>
						<tr>
							<td>Reporting Manager</td>
							<td className="sign-values">Self</td>
						</tr>
						<tr>
							<td>Department</td>
							<td className="sign-values">All</td>
						</tr>
						<tr>
							<td>Date of Joining</td>
							<td className="sign-values">2-Jan-2020</td>
						</tr>
						<tr>
							<td>Date of Resignation</td>
							<td className="sign-values">2-Jan-2020</td>
						</tr>
					</tbody>
				</table>
				<div className="sign-section">
					<div className="select-actions">Select the actions done</div>

					<div className="exit-checklist">
						<input type="checkbox" className="round-checkbox" id="c1" />
						<label for="c1">Notice period served?</label>
						<br />
						<input type="checkbox" className="round-checkbox" id="c2" />
						<label for="c2">ID card submitted?</label>
						<br />
						<input type="checkbox" className="round-checkbox" id="c3" />
						<label for="c3">Insurance credentials?</label>
						<br />
						<input type="checkbox" className="round-checkbox" id="c4" />
						<label for="c4">Deactivate lattice access</label>
						<br />
						<input type="checkbox" className="round-checkbox" id="c5" />
						<label for="c5">Deactivate work email</label>
						<br />
						<input type="checkbox" className="round-checkbox" id="c6" />
						<label for="c6">Whatsapp groups</label>
						<br />
						<input type="checkbox" className="round-checkbox" id="c7" />
						<label for="c7">Remove from trackstar</label>
						<br />
					</div>

					<div className="authority-comment">Comments</div>
					<textarea></textarea>

					<div className="agree-sign">
						<input type="checkbox" id="s1" />
						<label for="s1">
							I agree by signing the document that I have received or completed the above mention task
						</label>
						<br />
					</div>

					<button
						className="auth-signature"
						onClick={() => {
							setShowModal(true);
						}}
					>
						Sign
					</button>
				</div>
			</div>
			<FooterDashboard />
		</div>
	);
};

export default AuthoritySignScreen;
