import * as React from 'react';
const MPLImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" width={186} height={60} fill="none" {...props}>
		<path
			fill="#888"
			d="M71.597 
59.92c-.435.022-.824.056-1.213.056-6.953.004-13.903-.016-20.854.023-.975.006-1.295-.219-1.287-1.249.045-7.248.023-14.497.023-21.745v-1.14c-1.468-.31-1.474-.311-1.895.991-2.386 
7.38-4.782 14.756-7.136 22.147-.245.77-.599 1.028-1.392.992a46.83 46.83 0 0 0-4.247 
0c-.801.038-1.147-.243-1.386-1.004-2.31-7.369-4.66-14.728-7-22.087-.415-1.305-.422-1.3-1.956-1.095v24.068H0V46.754h6.049V13.247H.036V.082h25.092c.527 1.766 1.079 3.578 1.613 
5.395 2.664 9.08 5.325 18.163 7.987 27.246.018.063.028.137.065.186.216.283.441.562.66.84.206-.263.5-.494.603-.793.845-2.455 1.65-4.921 2.47-7.384 2.72-8.168 5.441-16.336 
8.163-24.503.113-.34.251-.672.382-1.022H71.57v13.176h-4.59c-1.514 0-1.514 0-1.514 1.467v32.034h6.13l.001 13.196ZM158.496 
46.686h13.635V36.63h12.931v23.277H133.06V46.771h6.08c.026-.476.06-.824.06-1.174.002-10.411-.01-20.822.021-31.233.002-.926-.276-1.19-1.172-1.155-1.637.064-3.277.018-4.969.018V.072h31.336v13.173h-5.92v33.441ZM78.885 
59.912V46.755h6.084V14.51c0-1.283-.001-1.285-1.296-1.287-1.572-.002-3.143 0-4.772 0V.068h22.103c-.126.382-.231.738-.363 1.084-2.78 7.276-5.565 14.552-8.346 21.828-.552 
1.443.07 2.325 1.635 2.326 3.03.002 6.06 0 9.09 0h1.131c.024.487.061.876.061 1.264.004 10.705.002 21.411.002 32.117 0 1.287-.002 1.288-1.3 1.288-7.609 
0-15.217.002-22.825-.002-.39.002-.777-.037-1.204-.06ZM107.509 40.751c1.006-2.65 2.003-5.285 3.009-7.918 1.887-4.944 3.781-9.884 
5.671-14.827.595-1.556-.018-2.406-1.73-2.406-2.931-.002-5.863 0-8.794 0h-1.267V.024c1.581 0 3.182-.055 4.78.01 7.173.29 12.986 3.133 16.884 9.316 2.758 4.372 3.604 9.21 2.938 
14.301-.868 6.64-4.157 11.686-10.077 14.922-3.244 1.775-8.237 2.714-11.414 2.178Z"
		/>
	</svg>
);
export default MPLImg;
