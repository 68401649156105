import { postApi } from './api';

export const searchEmployeeApi = async (filterText) => {
	try {
		const resp = await postApi({ filterText }, '/users/get-employee');
		console.log(resp);
		if (resp.type === 'success') {
			return resp.employeeList;
		}
		return [];
	} catch (e) {
		console.error(e);
	}
};
