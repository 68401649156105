import React from 'react';
import { useNavigate } from 'react-router-dom';
import './index.css';

const DiagnosticPage = function () {
	const navigate = useNavigate();
	return (
		<div className="diagnostic-container-screen">
			<h2 className="head-1">Run Maintenance</h2>
			<div className="head-2">Just one click of a button you can</div>
			<div className="know-more">
				<div className="content-1">
					<div className="each-feature">
						<img src={require('../../images/diagnostic1.png')} />
						<div className="sub-head">Diagnose PC</div>
					</div>
					<div className="each-feature">
						<img src={require('../../images/diagnostic2.png')} />
						<div className="sub-head">Generate Reports</div>
					</div>
					<div className="each-feature">
						<img src={require('../../images/diagnostic3.png')} />
						<div className="sub-head">Calculate Depreciation</div>
					</div>
					<div className="each-feature">
						<img src={require('../../images/diagnostic4.png')} />
						<div className="sub-head">Get total IT Asset spend</div>
					</div>
				</div>
				{/* <div className="content-1">
					<div className="each-feature">
						<img src={require('../../images/diagnostic3.png')} />
						<div className="sub-head">Calculate Depreciation</div>
					</div>
					<div className="each-feature">
						<img src={require('../../images/diagnostic4.png')} />
						<div className="sub-head">Get total IT Asset spend</div>
					</div>
				</div> */}
			</div>
			<button
				onClick={() => {
					navigate('/diagnostics/running');
				}}
				className="diagnostic-button"
			>
				Run Diagnostics
			</button>
		</div>
	);
};

export default DiagnosticPage;
