import { postApi, uploadToS3 } from '../../services/api';
import { sanitizeFileName } from '../../utils/sanitizeFileName';

export const handleAuditFileUpload = async function handleAuditFileUpload(fileList, assetUid) {
	const payload = [];
	for (let x = 0; x < fileList.length; x += 1) {
		payload.push({
			filename: sanitizeFileName(fileList[x].name),
			mimetype: fileList[x].type,
			filesize: fileList[x].size,
		});
	}

	const resp = await postApi(
		{
			payload,
			assetUid,
			actionType: 'audit-files',
		},
		'/assetze/utils/generate-upload-url'
	);

	if (resp.type !== 'success') {
		throw new Error(resp.error);
	}
	for (let x = 0; x < fileList.length; x += 1) {
		const file = fileList[x];
		await uploadToS3(resp.urls[x].url, file.type, file);
	}

	return resp.urls.map((el) => el.actualUrl);
};
