import React, { useState } from 'react';
import './index.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { BsCheckCircleFill } from 'react-icons/bs';
import AssetHealth from '../../images/Asset-health.png';
import AssetDeprec from '../../images/Asset-deprec.png';
import ITAsset from '../../images/IT-Asset.png';
import TotalSpend from '../../images/Total-spend.png';
import TotalDisp from '../../images/Total-Disp.png';
import { LineChart, ResponsiveContainer, Legend, Tooltip, Line, XAxis, YAxis, CartesianGrid } from 'recharts';
import Chart from 'react-google-charts';

const data = [
	{
		month: 'Jan 01',
		deprec: 25,
	},
	{
		month: 'Jan 10',
		deprec: 20,
	},
	{
		month: 'Jan 20',
		deprec: 15,
	},
	{
		month: 'Jan 30',
		deprec: 5,
	},
];

const donutData = [
	['Health', 'Unhealthy'],
	['Healthy', 84],
	['Degraded', 16],
];

const donutOptions = {
	pieHole: 0.6,
	colors: ['#33D7DB', '#e1e1e1'],
};
//linearGradient(180deg, #33D7DB 0%, #5EFBA1 100%)

const DiagnosticResults = function () {
	const printDocument = () => {
		const input = document.getElementById('divToPrint');
		html2canvas(input).then((canvas) => {
			const imgData = canvas.toDataURL('image/jpeg');
			const pdf = new jsPDF('p', 'mm', 'a4');
			const imgProps = pdf.getImageProperties(imgData);
			var width = pdf.internal.pageSize.getWidth();
			const height = (imgProps.height * width) / imgProps.width;

			pdf.addImage(imgData, 'JPEG', 0, 0, width, height);
			pdf.save('download-report.pdf');
		});
	};

	return (
		<div id="divToPrint" className="diagnostic-results-container">
			<h2 className="head-1">Run Maintenance</h2>
			<div className="results-body">
				<div className="result-row1">
					<div className="dev-diagnose">
						<div className="item-heading">Diagnose Device</div>
						<div className="item-check">
							<ul>
								<li>CPU : OK</li>
								<li>RAM : OK</li>
								<li>Hard disk : OK</li>
								<li>Screen : Attention required</li>
							</ul>
						</div>
					</div>
					<div className="asset-health">
						<div className="item-heading">IT Asset Health</div>
						<div className="asset-health-items">
							<Chart
								className="donut-chart"
								width={'300px'}
								chartType="PieChart"
								loader={<div>Loading Chart</div>}
								data={donutData}
								options={donutOptions}
							/>
							<div className="asset-health-percent">84%</div>
						</div>
					</div>
				</div>
				<div className="result-row2">
					<div className="asset-deprec">
						<div className="details">
							<div className="title">
								<div className="item-heading">Asset Depreciation</div>
								<div className="tagline">Overall Asset Depreciation</div>
							</div>
							<div className="percent">35%</div>
							<div className="time-period">
								<button>This Month</button>
								<button>This Quarter</button>
								<button>This Year</button>
							</div>
						</div>
						<ResponsiveContainer width="100%" aspect={3}>
							<LineChart data={data} margin={{ right: 50 }}>
								<XAxis dataKey="month" interval={'preserveStart'} />
								<Tooltip />
								<Line dataKey="deprec" stroke="#33D7DB" />
							</LineChart>
						</ResponsiveContainer>
					</div>
					<div className="total-assets">
						<div className="item-heading">Total IT Assets</div>
						<div className="it-body">
							<img src={ITAsset} className="asset-image" />
							<div className="it-table">
								<table>
									<thead>
										<tr>
											<th>Laptops</th>
											<th>Desktops</th>
											<th>Monitor</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>102</td>
											<td>12</td>
											<td>21</td>
										</tr>
									</tbody>
								</table>
								<hr />
								<table>
									<thead>
										<tr>
											<th>Servers</th>
											<th>Printers</th>
											<th>Accessories</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>03</td>
											<td>03</td>
											<td>30</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
				<div className="result-row3">
					<div className="total-spend">
						<div className="item-heading">Total IT Spend</div>
						<div className="spend-body">
							<img src={TotalSpend} />
							<div className="spend-details">
								<div className="spend-amount">₹ 62,45,000.00</div>
								<div className="spend-percent">Up by 24%</div>
							</div>
						</div>
					</div>
					<div className="total-disposables">
						<div className="item-heading">Total Disposables</div>
						<div className="disp-body">
							<img src={TotalDisp} />
							<div className="total-disp-count">65</div>
						</div>
					</div>
				</div>
			</div>
			<button onClick={printDocument} className="download-report">
				Download Report
			</button>
		</div>
	);
};

export default DiagnosticResults;
