import * as React from 'react';
const LoadShareImg = (props) => (
	<svg width="223" height="51" viewBox="0 0 223 51" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<g clip-path="url(#clip0_6147_8245)">
			<path
				d="M222.744 9.0224C219.996 9.0224 217.248 9.11436 214.509 9.00197C212.691 8.93045 212.762 11.5563 212.762 12.343C212.762 14.039 212.394 15.1936 214.53 15.7249C216.093 16.1233 217.81 15.8373 219.495 15.827C220.313 15.827 221.753 15.5001 221.753 16.8794C221.753 18.2792 220.425 17.9726 219.618 18.0237C217.891 18.1259 216.144 18.1055 214.417 18.0339C213.447 17.9931 213.253 18.3507 213.202 19.26C212.905 24.624 212.875 24.5934 218.249 24.6547C219.659 24.6751 221.11 24.6649 222.499 24.6649C222.469 25.3086 222.448 26.2383 222.418 26.8922C218.964 26.9331 215.511 26.9638 212.057 27.0455C211.179 27.0659 210.739 26.8207 210.739 25.8603C210.76 19.8628 210.749 13.8551 210.749 7.85765C210.749 7.34679 210.739 6.78484 211.465 6.79506C215.061 6.83593 218.576 6.84614 222.244 6.9381C222.418 6.9381 222.744 6.97897 222.744 7.30592C222.744 7.87808 222.744 8.45024 222.744 9.0224Z"
				fill="#888888"
			/>
			<path
				d="M120.133 16.9618C120.133 14.0295 120.133 11.1074 120.133 8.17506C120.133 7.55181 119.98 6.76509 120.992 6.79574C124.077 6.88769 127.214 6.36662 130.248 7.14312C135.388 8.45092 137.983 12.7115 137.339 18.5251C136.798 23.3782 132.997 26.8112 127.817 27.0257C125.732 27.1075 123.628 26.9747 121.543 27.0666C120.328 27.1177 120.093 26.5966 120.113 25.5443C120.164 22.6732 120.133 19.8226 120.133 16.9618ZM124.006 16.8392C124.006 18.0551 123.985 19.2709 124.006 20.4765C124.067 23.6745 123.454 23.225 126.897 23.2352C130.984 23.2454 133.293 21.0589 133.416 17.156C133.538 13.2734 131.331 10.9133 127.326 10.6476C123.996 10.4228 123.996 10.4228 123.996 13.6208C124.006 14.6936 124.006 15.7664 124.006 16.8392Z"
				fill="#888888"
			/>
			<path
				d="M86.4988 6.77546C92.2408 6.75503 96.6035 11.036 96.6648 16.7576C96.7261 22.5508 92.3123 27.0361 86.5498 27.0361C80.8078 27.0361 76.2816 22.4997 76.3429 16.7985C76.4042 11.0871 80.7567 6.7959 86.4988 6.77546ZM80.0722 16.7576C80.0415 20.8036 82.606 23.6031 86.4068 23.6747C90.3813 23.7462 93.1501 20.9671 93.1604 16.9211C93.1706 12.9466 90.5448 10.1982 86.6827 10.1165C82.8308 10.0552 80.0926 12.8036 80.0722 16.7576Z"
				fill="#888888"
			/>
			<path
				d="M117.876 26.8208C113.911 27.3521 113.819 27.3828 112.563 24.0315C111.786 21.794 109.62 21.3955 108.292 21.4159C104.981 21.467 102.172 22.1618 102.172 25.8093C102.172 27.7097 98.6162 27.6484 97.9827 26.9128C97.6047 26.4837 97.9521 25.983 98.136 25.5232C100.486 19.6279 102.867 13.7428 105.135 7.80666C105.574 6.67256 106.371 6.37626 107.219 6.35582C108.384 6.32517 109.712 6.00844 110.366 7.68406C112.869 14.0596 115.362 20.4249 117.876 26.8208ZM108.067 11.2396C107.107 12.5883 106.882 14.0085 106.402 15.3061C105.216 18.4938 105.247 18.4427 108.649 18.4019C109.957 18.3814 110.284 18.0749 109.814 16.808C109.16 15.0506 108.68 13.2218 108.067 11.2396Z"
				fill="#888888"
			/>
			<path
				d="M156.405 16.848C156.405 13.9157 156.425 10.9833 156.395 8.05102C156.384 7.23364 156.446 6.71257 157.508 6.733C158.489 6.75344 158.939 6.97821 158.898 8.06123C158.816 10.1353 158.908 12.2094 158.857 14.2835C158.836 15.1826 159.102 15.5504 160.073 15.53C162.739 15.4789 165.416 15.4585 168.083 15.53C169.238 15.5606 169.34 14.9987 169.319 14.0996C169.278 12.097 169.36 10.0945 169.289 8.09189C169.248 7.07017 169.544 6.72278 170.607 6.733C171.69 6.74322 171.71 7.28473 171.71 8.08167C171.69 14.0178 171.679 19.954 171.72 25.8902C171.731 26.82 171.444 27.106 170.504 27.1163C169.554 27.1163 169.268 26.82 169.289 25.8902C169.35 23.7446 169.278 21.599 169.319 19.4534C169.34 18.4521 169.207 17.8493 167.94 17.8902C165.345 17.9719 162.739 17.9617 160.144 17.8902C159.031 17.8595 158.826 18.3499 158.857 19.3001C158.908 21.0881 158.969 22.8863 158.826 24.6641C158.755 25.5632 159.133 27.1673 157.559 27.106C156.303 27.2593 156.486 25.5939 156.446 24.797C156.333 22.1405 156.405 19.4942 156.405 16.848Z"
				fill="#888888"
			/>
			<path
				d="M202.719 18.677C204.66 21.5174 206.469 24.1636 208.328 26.8814C206.438 27.4025 205.222 27.0551 204.293 25.3488C203.24 23.4075 201.841 21.6604 200.625 19.8009C200.247 19.2287 199.879 18.8916 199.123 18.8405C197.447 18.7179 196.385 18.9018 196.691 21.0372C196.916 22.58 196.722 24.1738 196.742 25.7473C196.752 26.4829 196.844 27.1573 195.7 27.1062C194.791 27.0653 194.239 26.9938 194.249 25.8495C194.3 19.9133 194.3 13.9873 194.249 8.05113C194.239 6.96811 194.678 6.7229 195.649 6.74334C197.447 6.79442 199.256 6.74334 201.054 6.76377C204.374 6.80464 206.827 8.60286 207.47 11.433C208.124 14.2836 206.673 16.8583 203.608 18.299C203.363 18.4216 203.097 18.5237 202.719 18.677ZM200.288 8.60286C197.284 8.60286 197.284 8.60286 197.284 11.668C197.284 12.0971 197.284 12.5263 197.284 12.9452C197.284 16.9707 197.284 16.9707 201.452 16.797C204.15 16.6846 205.784 14.7843 205.488 12.1278C205.202 9.70632 203.577 8.60286 200.288 8.60286Z"
				fill="#888888"
			/>
			<path
				d="M191.919 26.8102C189.774 27.5356 189.018 26.6059 188.415 24.92C187.168 21.4564 187.076 21.4871 183.337 21.4871C183.194 21.4871 183.051 21.4871 182.908 21.4871C178.995 21.4871 178.903 21.4564 177.534 25.155C176.869 26.943 175.878 27.4539 173.958 26.7796C175.296 23.2546 176.624 19.7297 177.952 16.2048C178.964 13.5381 180.037 10.8919 180.956 8.19453C181.314 7.1626 181.733 6.72326 182.908 6.71304C184.083 6.70283 184.522 7.20347 184.89 8.1741C187.209 14.3861 189.559 20.5777 191.919 26.8102ZM183.041 10.238C182.489 11.6582 182.029 12.7718 181.62 13.9059C180.578 16.7565 179.587 19.3926 179.587 19.3926C179.587 19.3926 186.126 19.3312 186.351 19.2393C185.288 16.3478 184.226 13.4462 183.041 10.238Z"
				fill="#888888"
			/>
			<path
				d="M62.3657 16.7764C62.3657 14.0587 62.4985 11.3307 62.3146 8.62313C62.192 6.80448 63.0707 6.80448 64.4602 6.71252C66.1563 6.61035 66.2789 7.34599 66.2584 8.69465C66.1869 13.0574 66.2686 17.4201 66.2073 21.7829C66.1869 22.9578 66.473 23.4278 67.7399 23.3563C69.6097 23.2541 71.4998 23.3972 73.3696 23.3052C75.0656 23.2235 74.6978 24.3576 74.7591 25.3078C74.8204 26.3295 74.7693 27.0856 73.3287 27.0345C70.1512 26.9323 66.9736 26.963 63.7961 27.0243C62.6415 27.0447 62.3044 26.6564 62.335 25.553C62.4168 22.6513 62.3657 19.7088 62.3657 16.7764Z"
				fill="#888888"
			/>
			<path
				d="M146.116 6.76516C148.282 6.7243 150.06 7.52124 151.286 9.30924C151.562 9.70771 152.542 10.239 151.541 10.8214C150.897 11.1994 150.162 11.9657 149.416 10.7396C148.384 9.03338 146.708 8.67578 144.89 9.1662C143.643 9.49315 142.703 10.3105 142.56 11.6898C142.438 12.9261 143.204 13.7231 144.226 14.2033C145.585 14.8469 147.035 15.2965 148.394 15.93C150.315 16.8189 152.103 17.8917 152.491 20.2416C153.064 23.6644 151.306 26.1267 147.689 26.9032C144.369 27.6184 140.507 25.483 139.648 22.5813C139.352 21.5596 140.037 21.5698 140.558 21.2633C141.283 20.8444 141.63 21.0692 142.06 21.7844C143.715 24.5328 145.493 25.3298 147.832 24.5226C149.171 24.0628 150.039 23.1944 150.192 21.7538C150.336 20.3949 149.733 19.3425 148.537 18.7601C146.995 18.0041 145.36 17.4523 143.797 16.7371C140.834 15.3782 139.72 13.4881 140.292 10.8827C140.864 8.34883 143.092 6.77538 146.116 6.76516Z"
				fill="#888888"
			/>
			<path
				d="M68.4143 42.1675H67.6787L64.8792 37.8763V42.1675H64.1436V36.6094H64.8792L67.6889 40.9108V36.6094H68.4143V42.1675Z"
				fill="#888888"
			/>
			<path
				d="M89.768 39.6026H87.3567V41.5745H90.1562V42.1773H86.6211V36.6191H90.1154V37.222H87.3567V39.01H89.768V39.6026Z"
				fill="#888888"
			/>
			<path d="M111.888 37.2122H110.1V42.1777H109.375V37.2122H107.587V36.6094H111.888V37.2122Z" fill="#888888" />
			<path
				d="M130.862 40.4204L130.964 41.156L131.117 40.4919L132.221 36.6094H132.844L133.917 40.4919L134.07 41.1662L134.193 40.4204L135.051 36.6196H135.787L134.438 42.1777H133.774L132.629 38.1215L132.537 37.6924L132.446 38.1215L131.26 42.1777H130.596L129.248 36.6196H129.983L130.862 40.4204Z"
				fill="#888888"
			/>
			<path
				d="M157.784 39.5716C157.784 40.1131 157.692 40.5933 157.508 41.002C157.324 41.4107 157.069 41.7172 156.731 41.9318C156.394 42.1463 156.006 42.2485 155.556 42.2485C155.117 42.2485 154.729 42.1361 154.392 41.9216C154.054 41.707 153.789 41.4005 153.605 41.002C153.421 40.6035 153.319 40.1438 153.319 39.6227V39.2242C153.319 38.6929 153.411 38.2127 153.595 37.804C153.779 37.3954 154.044 37.0786 154.381 36.8641C154.719 36.6495 155.107 36.5371 155.546 36.5371C155.996 36.5371 156.384 36.6495 156.721 36.8641C157.058 37.0786 157.324 37.3954 157.508 37.804C157.692 38.2127 157.784 38.6929 157.784 39.2344V39.5716ZM157.048 39.214C157.048 38.5499 156.915 38.0493 156.65 37.6917C156.384 37.3443 156.016 37.1604 155.536 37.1604C155.076 37.1604 154.708 37.3341 154.443 37.6917C154.177 38.0493 154.044 38.5295 154.034 39.1629V39.5716C154.034 40.2051 154.167 40.7159 154.433 41.0735C154.698 41.4414 155.066 41.6253 155.546 41.6253C156.016 41.6253 156.384 41.4516 156.65 41.1042C156.905 40.7568 157.048 40.2664 157.048 39.6227V39.214Z"
				fill="#888888"
			/>
			<path
				d="M177.82 39.9197H176.512V42.1675H175.776V36.6094H177.615C178.239 36.6094 178.719 36.7524 179.066 37.0385C179.403 37.3246 179.577 37.7435 179.577 38.285C179.577 38.6324 179.485 38.9287 179.301 39.1943C179.117 39.4497 178.852 39.6439 178.525 39.7665L179.833 42.1266V42.1675H179.046L177.82 39.9197ZM176.512 39.3271H177.636C178.004 39.3271 178.29 39.2352 178.504 39.0411C178.719 38.8469 178.831 38.6017 178.831 38.285C178.831 37.9376 178.729 37.672 178.525 37.488C178.32 37.3041 178.024 37.2122 177.636 37.2122H176.512V39.3271Z"
				fill="#888888"
			/>
			<path
				d="M198.908 39.5826L198.223 40.2876V42.1675H197.488V36.6094H198.223V39.3578L200.696 36.6094H201.585L199.398 39.0615L201.759 42.1675H200.88L198.908 39.5826Z"
				fill="#888888"
			/>
			<path
				d="M220.936 39.6942C220.303 39.5103 219.853 39.2957 219.567 39.0301C219.281 38.7645 219.138 38.4375 219.138 38.0493C219.138 37.6099 219.312 37.2523 219.659 36.9662C220.006 36.6802 220.466 36.5371 221.028 36.5371C221.406 36.5371 221.754 36.6086 222.05 36.7619C222.346 36.9049 222.581 37.1093 222.745 37.3749C222.908 37.6304 222.99 37.9164 222.99 38.2229H222.254C222.254 37.8858 222.152 37.6201 221.937 37.426C221.723 37.2319 221.427 37.1399 221.028 37.1399C220.671 37.1399 220.384 37.2217 220.18 37.3749C219.976 37.5282 219.874 37.753 219.874 38.039C219.874 38.2638 219.966 38.4579 220.16 38.6112C220.354 38.7645 220.681 38.9075 221.141 39.0403C221.6 39.1731 221.968 39.3162 222.224 39.4694C222.489 39.6227 222.673 39.8066 222.806 40.0212C222.929 40.2255 223 40.4707 223 40.7568C223 41.2064 222.826 41.564 222.469 41.8398C222.111 42.1157 221.651 42.2485 221.059 42.2485C220.681 42.2485 220.323 42.177 219.986 42.0237C219.659 41.8807 219.404 41.6764 219.22 41.4209C219.036 41.1655 218.954 40.8794 218.954 40.5525H219.69C219.69 40.8896 219.812 41.1553 220.058 41.3494C220.303 41.5435 220.64 41.6457 221.049 41.6457C221.437 41.6457 221.733 41.564 221.937 41.4107C222.142 41.2574 222.244 41.0327 222.244 40.767C222.244 40.5014 222.152 40.2868 221.958 40.1336C221.784 39.9803 221.437 39.8373 220.936 39.6942Z"
				fill="#888888"
			/>
			<path
				d="M45.4256 0H4.86337C2.17626 0 0 2.17626 0 4.86337V45.4256C0 48.1127 2.17626 50.2889 4.86337 50.2889H45.4256C48.1127 50.2889 50.2889 48.1127 50.2889 45.4256V4.86337C50.2889 2.17626 48.1127 0 45.4256 0ZM37.4562 42.9939C35.1062 42.9939 33.1241 41.4817 32.3987 39.3872H17.8903C17.1649 41.4817 15.1725 42.9939 12.8328 42.9939C9.86979 42.9939 7.46875 40.5928 7.46875 37.6299C7.46875 35.2799 8.9809 33.2978 11.0754 32.5723V17.7166C8.9809 16.9912 7.46875 14.9988 7.46875 12.6591C7.46875 9.6961 9.86979 7.29506 12.8328 7.29506C15.7957 7.29506 18.1968 9.6961 18.1968 12.6591C18.1968 15.009 16.6846 16.9912 14.5901 17.7166V32.5621C16.1431 33.0934 17.359 34.3195 17.9005 35.8725H32.3987C33.1241 33.778 35.1164 32.2658 37.4562 32.2658C40.4191 32.2658 42.8202 34.6669 42.8202 37.6299C42.81 40.5928 40.4191 42.9939 37.4562 42.9939Z"
				fill="#888888"
			/>
		</g>
		<defs>
			<clipPath id="clip0_6147_8245">
				<rect width="223" height="50.2889" fill="white" />
			</clipPath>
		</defs>
	</svg>
);
export default LoadShareImg;
