import React from 'react';

import DashboardFooter from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import IncidentsComponent from '../../components/IncidentsComponent';
import AssetsTopHeader from './AssetsTopHeader';
import './index.css';

const IncidentsScreen = function () {
	return (
		<div className="dashboard-container">
			<HeaderComponent />
			<AssetsTopHeader />
			<IncidentsComponent />
			<DashboardFooter />
		</div>
	);
};

export default IncidentsScreen;
