import React, { useEffect, useState, useMemo } from 'react';
import FooterDashboard from '../../components/FooterDashboard';
import HeaderComponent from '../../components/Header';
import './index.css';
import { useForm, Controller } from 'react-hook-form';
import { getApi, postApi } from '../../services/api';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import SignOff from '../../images/sign-off.png';

const ExitResponses = function () {
	const { register, handleSubmit, reset, control } = useForm();
	const navigate = useNavigate();

	return (
		<div className="newsignoff-container">
			<HeaderComponent />
			<div className="account-page-title">
				<div className="account-page-title-container">Exit Sign-Off</div>
			</div>
			<div className="sign-off-note">Exit Responses</div>
			<table>
				<thead>
					<tr>
						<th>NAME</th>
						<th>DEPARTMENT</th>
						<th>EMAIL ID</th>
						<th>DOJ</th>
						<th>DOR</th>
						<th>STATUS</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td>Santhosh</td>
						<td>Tech</td>
						<td>santu@assetze.com</td>
						<td>14-Oct-2020</td>
						<td>14-Oct-2020</td>
						<td>
							<button className="checked-signoff">Check Signoff</button>
						</td>
					</tr>
				</tbody>
			</table>

			<FooterDashboard />
		</div>
	);
};

export default ExitResponses;
