import React from 'react';
import TipImage from '../../images/tip-image.png';

const EachTip = function ({ info, heading }) {
	return (
		<div className="eachtip-container">
			<div className="eachtip">
				<img src={TipImage} />
				<div>
					<h4>{heading}:</h4>
					<div>{info}</div>
				</div>
			</div>
		</div>
	);
};

export default EachTip;
