import React from 'react';

const SaaSProductLanding = function () {
	return (
		<div className="saas-product-container">
			<div className="saas-heading">
				<img src={require('../../images/saasProduct/idfc-logo.png')} />
				<div className="name">IDFC First Bank</div>
			</div>
			<div>
				<img src={require('../../images/saasProduct/idfc-1.png')} />
			</div>
			<div className="saas-actions">
				<button>Share</button>
				<button>Get Started</button>
			</div>
			<div className="head-1">Description</div>
			<div>
				Experience maker-checker facility, bulk uploads, chequebook management, one click FD & OD, approval
				management and much more with IDFC FIRST (new) Net & Mobile Banking. <br /> <br /> When it comes to your
				business, you want to do everything in your power to ensure that it grows and thrives. With IDFC FIRST
				Bank Business Loan, we help you take your business to new heights. Whether it is to expand to new
				markets, buy new equipment, upgrade existing facilities, or meet the requirements of your working
				capital, IDFC FIRST Bank provides you with unsecured business lending solutions that cater to your
				diverse needs. Choose to bank with us and plan finances better for your business.
			</div>
			<div className="head-1">Bank Recommendation</div>
			<div className="head-2">
				<span className="number">1203</span> {'    '}Finance and accountants
			</div>
		</div>
	);
};

export default SaaSProductLanding;
