import { postApi } from './api';

export const searchInvoice = async (filterText) => {
	try {
		const resp = await postApi({ filterText }, '/assetze/purchase-order/search');
		console.log(resp);
		if (resp.type === 'success') {
			return resp.purchaseOrderList;
		}
		return [];
	} catch (e) {
		console.error(e);
	}
};

export const getInvoice = async (invoice_id) => {
	try {
		const resp = await postApi({ invoice_id }, '/assetze/purchase-order/get-invoice');
		console.log(resp);
		if (resp.type === 'success') {
			return resp.purchaseOrder;
		}
		return null;
	} catch (e) {
		console.error(e);
	}
};

export const searchOfficeLocation = async (filterText) => {
	try {
		const resp = await postApi({ filterText }, '/company/locations/search');
		console.log(resp);
		if (resp.type === 'success') {
			return resp.offliceLocations;
		}
		return [];
	} catch (e) {
		console.error(e);
	}
};

export const getOfficeLoc = async (office_id) => {
	try {
		const resp = await postApi({ office_id }, '/company/locations/get-office_id');
		console.log(resp);
		if (resp.type === 'success') {
			return resp.companyLocation;
		}
		return null;
	} catch (e) {
		console.error(e);
	}
};
