import React, { useState } from 'react';
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Stack,
    Typography,
} from '@mui/material';
import './index.css';

const modalWidth = {
    '& .MuiDialog-container': {
        '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '500px',
            minHeight: '166px',
            borderRadius: '20px',
        },
    },
};

const UnassignDevicesModal = ({ open, setOpen, setIsSuccessfull, submitRef }) => {
    const handleClose = () => {
        setOpen(false);
    };
    return (
        <Dialog
            sx={modalWidth}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                <Typography sx={{ fontWeight: 700, fontSize: '21px', color: '#555555', marginBottom: 1 }}>
                    Unassign Devices
                </Typography>
                <Divider sx={{ paddingX: 2 }} />
            </DialogTitle>
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{
                        color: '#888888',
                        fontWeight: 500,
                        fontSize: '18px',
                    }}
                >
                    Do you want to unassign the devices associated with Employee Name?
                </DialogContentText>
                <DialogContentText sx={{ fontWeight: 400, fontSize: '10px' }}>
                    Note: All the devices associated with the user will be unassigned and moved to stock on working day
                    at 12:00 AM IST
                </DialogContentText>
            </DialogContent>
            <Box sx={{ padding: 1, display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                <button
                    style={{
                        color: '#333333',
                        backgroundColor: '#E9E9E9',
                        width: '105px',
                        height: '35px',
                        outline: 'none',
                        border: 'none',
                        borderRadius: '5px',
                    }}
                    type="submit"
                    onClick={() => {
                        submitRef.current.click();
                    }}
                >
                    Yes
                </button>
                <button
                    style={{
                        color: '#333333',
                        backgroundColor: '#33D7DB',
                        width: '105px',
                        height: '35px',
                        outline: 'none',
                        border: 'none',
                        borderRadius: '5px',
                    }}
                    onClick={() => {
                        setOpen(false);
                    }}
                >
                    No
                </button>
            </Box>
        </Dialog>
    );
};
export default UnassignDevicesModal;

export const UnassignSuccessfulModal = ({ isSuccessfull, setIsSuccessfull }) => {
    const handleClose = () => {
        setIsSuccessfull(false);
    };
    return (
        <Dialog
            sx={modalWidth}
            open={isSuccessfull}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent>
                <DialogContentText
                    id="alert-dialog-description"
                    sx={{ fontWeight: 700, fontSize: '21px', color: '#555555', marginBottom: 1, textAlign: 'center' }}
                >
                    Successfully Unassigned
                </DialogContentText>
            </DialogContent>

            <Box sx={{ padding: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 3 }}>
                <button
                    style={{
                        color: '#333333',
                        backgroundColor: '#33D7DB',
                        width: '105px',
                        height: '35px',
                        outline: 'none',
                        border: 'none',
                        borderRadius: '5px',
                    }}
                    onClick={handleClose}
                >
                    OK
                </button>
            </Box>
            <Box></Box>
        </Dialog>
    );
};
