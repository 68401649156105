import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { showSnack } from 'react-redux-snackbar';
import { getApi } from '../../services/api';

import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Controller } from 'react-hook-form';
import { Box, Chip, Dialog, DialogContent, DialogContentText, Grid, IconButton } from '@mui/material';
import TuneIcon from '@mui/icons-material/Tune';
import { Typography } from '@material-ui/core';

import { Select , Tag } from 'antd';

const { Option } = Select;

const AssetsAllocated = ({ control }) => {
	const [assetsList, setAssetsList] = useState([]);
	const [searchText, setSearchText] = useState('');

	const dispatch = useDispatch();
	useEffect(() => {
		async function getAssetTypes() {
			// setAssetsList([]);
			try {
				const resp = await getApi(`/assets/search-assets?assetName=${searchText}`);
				if (resp.type === 'success') {
					console.log(resp?.assets);
					setAssetsList(resp?.assets);
				} else {
					dispatch(
						showSnack('ASSET_SEARCH_ERROR', {
							label: resp.error,
							timeout: 7000,
						})
					);
				}
			} catch (e) {
				dispatch(
					showSnack('ASSET_SEARCH_ERROR', {
						label: 'Something went wrong',
						timeout: 7000,
					})
				);
			}
		}
		setTimeout(() => {
			if (searchText) {
				getAssetTypes();
			}
		}, 500);
	}, [searchText]);

	

	const onChange = (value)=>{
		console.log(`selected ${value}`);
	}

	const handleSearch = (value) => {
		setSearchText(value);
	};
	console.log('assets-list------', assetsList.length);
	return (
		<>
			<Controller
				render={({ field: { onChange, value, ref } }) => {
					return (
						<>
							<CheckboxesTags assetsList={assetsList} onChange={onChange} setSearchText={setSearchText} />
							{/* <Select
								mode="multiple"
								allowClear
								style={{
									width: '100%',
									height:'5rem'
								}}
								placeholder="Please select"
								onChange={onChange}
								onSearch={handleSearch}	
								
							>
								{assetsList.map(option => (
									<Option key={option.id} value={option.id}>
										<AssetComponent asset={option} />
									</Option>
								))}
							</Select> */}

						</>
					);
				}}
				control={control}
				name="devicesProvided"
			/>
		</>
	);
};

export default AssetsAllocated;

function CheckboxesTags({ assetsList, onChange, setSearchText }) {
	return (
		<>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Autocomplete
						multiple
						options={assetsList}
						disableCloseOnSelect
						getOptionLabel={(option) => option.name}
						onChange={(_, val) => {
							onChange(
								val?.map((asset) => {
									return {
										_id: asset._id,
										name: asset.name,
										assestId: asset.id,
									};
								})
							);
						}}
						clearOnBlur={true}
						renderOption={(props, option, { selected }) => (
							<li
								style={{
									backgroundColor: '#F8FFFF',
									display: 'flex',
									flexDirection: 'row',
									width: '100%',
								}}
								key={option._id}
								{...props}
							>
								<AssetComponent asset={option} selected={selected} />
							</li>
						)}
						renderTags={(value, getTagProps) =>
							value
								.filter((val, index, self) => index === self.findIndex((t) => t._id === val._id))
								.map((option, index) => (
									<Chip
										style={{ backgroundColor: '#33D7DB', borderRadius: '10px' }}
										key={option._id}
										variant="soft"
										label={`${option.name}`}
										{...getTagProps({ index })}
									/>
								))
						}
						renderInput={(params) => (
							<TextField
								{...params}
								label="Search Stock Devices"
								// endAdornment: (
								//     <InputAdornment position="end">
								//         <TuneIcon />
								//     </InputAdornment>
								// ),
								// placeholder="Select Devices"
								onChange={(e) => setSearchText(e.target.value)}
							/>
						)}
					/>
				</Grid>
				{/* <Grid item xs={1}>
                    <IconButton>
                        <TuneIcon />
                    </IconButton>
                </Grid> */}
			</Grid>
		</>
	);
}

const AssetComponent = ({ asset }) => {
	// console.log('props', props);
	const imageUrl =
		asset.images?.length > 0
			? asset.images[0]
			: 'https://assetze.s3.ap-south-1.amazonaws.com/asset-images/AssetImg1.png';
	return (
		<>
			<Box
				sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-around',
					alignItems: 'center',
					margin: 1,
					// backgroundColor: selected ? '#BEF8FA' : '#F8FFFF',
					padding: 1,
					height: 70,
					borderRadius: '10px',
				}}
			>
				<Box sx={{ fontSize: '16px', fontWeight: 300, color: '#333333', flex: 3 }}>
					<p>{asset.name}</p>
					<p>{asset.id}</p>
				</Box>
				<Box sx={{ fontSize: '10px', fontWeight: 300, color: '#555555', flex: 3 }}>
					<p>{asset.serialNumber}</p>
					<p>M1, 8GB, 256 SSD</p>
				</Box>
				<Box sx={{ flex: 1 }}>
					<img src={imageUrl} width="30" height="30" />
				</Box>
			</Box>
		</>
	);
};

export const UnassignedDevices = ({ control }) => {
	const [unAssignDevice, setUnAssignDevice] = useState([]);
	const [inputValue, setInputValue] = useState('');

	return (
		<>
			<Controller
				render={({ field: { onChange, value, ref } }) => {
					return (
						<>
							<Autocomplete
								multiple
								fullWidth
								freeSolo
								autoSelect
								limitTags={2}
								id="unavailableDevices"
								options={unAssignDevice}
								onChange={(event, newValue) => {
									setUnAssignDevice(newValue);
									onChange(
										newValue?.map((asset) => {
											return asset;
										})
									);
								}}
								renderTags={(value, getTagProps) =>
									value.map((option, index) => (
										<Chip
											style={{ backgroundColor: '#33D7DB', borderRadius: '10px' }}
											key={index}
											variant="soft"
											label={`${option}`}
											{...getTagProps({ index })}
										/>
									))
								}
								value={unAssignDevice}
								inputValue={inputValue}
								onInputChange={(event, newInputValue) => {
									setInputValue(newInputValue);
								}}
								className="autoCompleteInput"
								renderInput={(params) => (
									<TextField {...params} label="Device Name (if not in stock)" variant="outlined" />
								)}
								sx={{ width: 'auto', border: 'none', outline: 'none' }}
							/>
						</>
					);
				}}
				control={control}
				name="unavailableDevices"
			/>
		</>
	);
};
