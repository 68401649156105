import React from 'react';
import LandingScreenFooter from '../../components/LandingFooter';
import LandingPageHeader from '../../components/LandingpageHeader';
import EarlyAccessModel from '../../components/EarlyAccess';
import SignupToAssetze from '../../components/SignupToAssetze';

import { IntegrationsComponent } from '../../screens/LandingScreen/IntegrationsComponent';
import './index.css';

const PartnerScreen = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />
			<div className="partner-access-container">
				<h1>Assetze Partner</h1>
				<div className="head-description">
					Join Assetze partner program and enjoy the benefits that unlock new business opportunities
				</div>
				<EarlyAccessModel className="signup-button" content="Become a Partner" />
			</div>
			<div className="partner-content-1">
				<h2>Process</h2>
				<div className="partner-info-container">
					<div className="each-partner-info">
						<img src={require('../../images/features/partner-onboard.png')} />
						<div className="head">Partner onboarding</div>
						<div className="description">
							Share your basic profile details to register as referral or implementation partner
						</div>
					</div>
					<div className="each-partner-info">
						<img src={require('../../images/features/training.png')} />
						<div className="head">Training & Referral</div>
						<div className="description">
							Receive hands on implementation training & refer Assetze to your network of clients.
						</div>
					</div>
					<div className="each-partner-info">
						<img src={require('../../images/features/partner-earnings.png')} />
						<div className="head">Receive Partner Earnings</div>
						<div className="description">
							Receive your partner earnings on every successful referral and implementation
						</div>
					</div>
				</div>
			</div>
			<div className="white-bg partner-content-1">
				<h2>Types of Partners</h2>
				<div className="partner-info-container">
					<div className="each-partner-info">
						<img src={require('../../images/features/resell-partner.png')} />
						<div className="head">Reseller Partner</div>
						<div className="description">
							Share your basic profile details to register as referral or implementation partner
						</div>
					</div>
					<div className="each-partner-info">
						<img src={require('../../images/features/alliance-partner.png')} />
						<div className="head">Alliance Partner</div>
						<div className="description">
							Receive hands on implementation training & refer Assetze to your network of clients.
						</div>
					</div>
					<div className="each-partner-info">
						<img src={require('../../images/features/integration-partner.png')} />
						<div className="head">Integration Partner</div>
						<div className="description">
							Receive your partner earnings on every successful referral and implementation
						</div>
					</div>
				</div>
			</div>
			<div className="partner-content-2">
				<div className="head">WHY BECOME A PARTNER? </div>
				<div className="description">
					Partnering with Assetze provides you with a whole lot of benefits. Our partners have gained added
					revenue, along with increasing their brand value. As a partner you stand to gain
				</div>
				<ul>
					<li>Enter new markets and expand your business</li>
					<li>Acquire new customers and earn steady monthly* revenues</li>
					<li>Upsell/ cross-sell to your existing customers and maintain existing customer relationships</li>
					<li>Increase Lifetime value from your existing customers</li>
					<li>Customer acquisition cost is something which you don’t need to worry about just cross sell</li>
					<li>Be part of the global cloud apps phenomena</li>
					<li>Build better relationships with your existing clients Leverage</li>
					<li>Assetze’s trust, integrity and support to grow your network</li>
					<li>Achieve synergies of scale</li>
					<li>Growth driven agenda and move from being reactive to strategic advisors to your clients</li>
					<li>Recurring revenues is something which we can look at</li>
				</ul>
			</div>
			<SignupToAssetze />
			<IntegrationsComponent />
			<LandingScreenFooter />
		</div>
	);
};

export default PartnerScreen;
