const initialState = { isSearched: false, searchText: '', searchAssets: [], filterInfo: [] };
function searchReducer(state = initialState, action) {
	switch (action.type) {
		case 'inputSearched':
			return { ...state, isSearched: true };
		case 'inputCleared':
			return { ...state, isSearched: false };
		case 'updatedSearchText':
			return { ...state, searchText: action.payload };
		case 'saveFilterInfo':
			return { ...state, filterInfo: action.payload };
		case 'saveSearchResult':
			return { ...state, searchAssets: action.payload };
		case 'removeAsset':
			return {
				...state,
				searchAssets: state.searchAssets.filter((el) => {
					return el._id !== action.payload;
				}),
			};
		default:
			return { ...state };
	}
}

export default searchReducer;
