import React from 'react';
import LandingPageHeader from '../../components/LandingpageHeader';
import LandingScreenFooter from '../../components/LandingFooter';
import EarlyAccessModel from '../../components/EarlyAccess';
import SignupToAssetze from '../../components/SignupToAssetze';

import { IntegrationsComponent } from '../../screens/LandingScreen/IntegrationsComponent';

const ReportingScreen = function () {
	return (
		<div className="app-container">
			<LandingPageHeader />
			<div className="feature-incident-container">
				<div className="content-container">
					<div className="content-text-container">
						<h1>Reporting</h1>
						<div className="description">
							Advanced reporting for better management & productivity improvement
						</div>
						<EarlyAccessModel className="signup-button" content="Sign Up" />
					</div>
					<div className="image-container">
						<img src={require('../../images/features/reporingAnalysis.png')} />
					</div>
				</div>
				<div style={{ backgroundColor: '#F9F9F9' }} className="content-container">
					<div className="content-text-container">
						<h1>Run Maintenance</h1>
						<div className="description">
							Run maintenance on a monthly cycle helps you track all the key metrics such as device
							health, company asset health, depreciation, IT assets, IT spend, etc.,
						</div>
					</div>
					<div className="image-container">
						<img src={require('../../images/features/maintenance.png')} />
					</div>
				</div>
				<div className="content-container">
					<div className="image-container">
						<img src={require('../../images/features/visualRepresentation.png')} />
					</div>
					<div style={{ paddingLeft: '50px' }} className="content-text-container">
						<h1>Visual representation</h1>
						<div className="description">
							The metrics that you need are presented in data visualised format. It helps in making
							informed decision by just a glance.
						</div>
					</div>
				</div>
				<div style={{ backgroundColor: '#fafafa' }} className="features-highlights">
					<div className="each-highlight">
						<img src={require('../../images/features/financialReportIcon.png')} />
						<h2>Financial reports</h2>
						<div className="description">
							The reports contain all the necessary financial data w.r.t assets such as depreciation,
							monthly spend, repair cost, etc., that can be utilised by the financial team.
						</div>
					</div>
					<div className="each-highlight">
						<img src={require('../../images/features/complianceIcon.png')} />
						<h2>Compliance</h2>
						<div className="description">
							Compliance reports on various software products and disposable compliance are provided.
						</div>
					</div>
				</div>
				<SignupToAssetze />
				<IntegrationsComponent />
			</div>
			<LandingScreenFooter />
		</div>
	);
};

export default ReportingScreen;
