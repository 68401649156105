import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import './index.css';
import HeaderComponent from '../../components/Header';
import FooterDashboard from '../../components/FooterDashboard';
import { useNavigate } from 'react-router-dom';
import AddressIcon from '../../images/AddressIcon.png';

const ConfirmShip = function () {
	const navigate = useNavigate();
	const [address, setAddress] = useState('Jayanagar, Bengaluru - 560070');
	const [edit, setEdit] = useState(false);

	const handleInput = (event) => {
		setAddress(event.target.value);
	};

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		setValue,
	} = useForm();
	return (
		<div className="cart-container">
			<HeaderComponent />
			<div>
				<div className="heading">Shopping Cart</div>
				<div className="item-order">
					<div className="cart-addr">
						<div className="cart-login">
							<div className="cart-details">
								<div className="box-heading">
									<img src={AddressIcon} />
									<div className="addr-heading">LOGIN</div>
								</div>
								<div className="usr-details">
									<div className="usr-name">Puneeth Reddy</div>
									<div className="usr-phno">81473 81915</div>
									<div className="usr-email">puneeth@assetze.com</div>
								</div>
							</div>
							<div className="cart-edit">
								<button>CHANGE</button>
							</div>
						</div>
						<div className="usr-addr">
							<div className="cart-details">
								<div className="box-heading">
									<img src={AddressIcon} />
									<div className="addr-heading">SHIPPING ADDRESS</div>
								</div>
								{edit ? (
									<div className="addr-details">
										<input
											className="usr-address"
											placeholder="Address Line - Pincode"
											onChange={handleInput}
											defaultValue={address}
											{...register('Address')}
										/>
									</div>
								) : (
									<div className="addr-details">
										<div className="usr-address">{address}</div>
									</div>
								)}
							</div>
							{edit ? (
								<div className="cart-edit">
									<button
										onClick={() => {
											setEdit(false);
										}}
									>
										SAVE
									</button>
								</div>
							) : (
								<div className="cart-edit">
									<button
										onClick={() => {
											navigate('/products/shopping-cart');
										}}
									>
										CHANGE
									</button>
								</div>
							)}
						</div>
					</div>
					<div className="order-summary">
						<div className="heading">ORDER SUMMARY</div>
						<hr />
						<div className="item-summary">
							<div>PRODUCTS 2</div>
							<div className="prod-cost">₹ 229,000.00</div>
						</div>
						<div className="ship-summary">
							<div>SHIPPING</div>
							<div className="ship-cost">FREE</div>
						</div>
						<hr />
						<div className="total-cost">
							<div>TOTAL COST</div>
							<div className="bill">₹ 229,000.00</div>
						</div>
						<button className="checkout">CHECKOUT</button>
						<br />
						<input placeholder="PROMO CODE" />
					</div>
				</div>
				<div className="shopping-cart">
					<div className="save-changes">
						<button
							className="yes"
							onClick={() => {
								navigate('/confirm-order');
							}}
						>
							Make Payment
						</button>
						<button className="no">Continue</button>
					</div>
				</div>
			</div>
			<FooterDashboard />
		</div>
	);
};

export default ConfirmShip;
