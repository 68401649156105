import * as React from 'react';
const DataFoundryImg = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" fill="none" {...props}>
		<path
			fill="#888"
			d="M36.651 3.476s0 1.642.955 2.628c.829.86 2.831.891 3.8.188.97-.72 1.424-2.018 1.424-2.816S41.97-.106 39.373.003c-2.58.11-5.521 2.128-7.336 5.71-2.112 4.192-2.957 
9.792-2.925 15.596.031 5.803.453 30.894.453 30.894s-.047 4.38-2.675 5.115c0 0 .204-4.145-2.847-4.145-3.05 0-3.41 2.675-3.41 3.613 0 .94.767 3.082 3.285 3.207 2.519.125 
5.022-1.533 5.773-2.065 2.049-1.454 4.239-4.786 4.489-8.728s-.25-28.674-.25-28.674-.454-8.806.219-12.342c.469-2.55 2.502-4.708 2.502-4.708Z"
		/>
		<path
			fill="#888"
			d="m28.235 39.795-15.47 8.354c-2.973 1.61-6.586-.548-6.586-3.927v-28.72c0-3.379 3.613-5.537 6.585-3.926l15.237 8.228c.046-2.3.234-4.552.578-6.68L6.586 1.252C3.613-.36 0 
1.799 0 5.178v49.369c0 3.378 3.613 5.537 6.586 3.926l21.743-11.748c-.015-1.986-.063-4.38-.094-6.93ZM54.624 25.94 34.961 15.318c.032 2.754.141 5.084.141 5.084s.016.751.032 
2.018l6.523 3.52c3.128 1.69 3.128 6.179 0 7.868l-6.304 3.395c.03 2.518.046 4.943.046 6.976l19.21-10.37c3.144-1.69 3.144-6.18.015-7.87ZM78.98 35.962h-.046a4.089 4.089 0 0 
1-1.846 1.69 6.14 6.14 0 0 1-2.503.531c-.985 0-1.861-.172-2.628-.516a5.969 5.969 0 0 1-1.97-1.408c-.548-.594-.955-1.314-1.236-2.112a7.887 7.887 0 0 
1-.423-2.612c0-.939.157-1.799.454-2.612a6.46 6.46 0 0 1 1.251-2.112 5.49 5.49 0 0 1 1.94-1.408c.75-.344 1.58-.516 2.487-.516.595 0 1.126.062 1.58.188.454.125.86.281 
1.22.485a4.981 4.981 0 0 1 1.502 1.267h.078v-8.901h3.176v19.929h-3.02v-1.893h-.015Zm-7.508-4.443c0 .485.079.97.25 1.424.173.454.407.86.736 1.22.328.344.72.641 
1.173.845.454.203.986.313 1.564.313.58 0 1.095-.11 1.549-.313a3.519 3.519 0 0 0 1.173-.845 3.23 3.23 0 0 0 .735-1.22c.172-.454.25-.939.25-1.424s-.078-.97-.25-1.423a3.742 3.742 
0 0 0-.735-1.22 3.522 3.522 0 0 0-1.173-.845 3.75 3.75 0 0 0-1.549-.313 3.88 3.88 0 0 0-1.564.313c-.454.219-.845.485-1.173.845-.329.344-.563.75-.735 1.22-.157.453-.25.938-.25 
1.423ZM94.029 36.118h-.078c-.454.72-1.033 1.236-1.752 1.565-.704.328-1.47.484-2.315.484a6.232 6.232 0 0 1-1.705-.234 4.302 4.302 0 0 1-1.47-.72 3.833 3.833 0 0 
1-1.049-1.189c-.266-.469-.391-1.032-.391-1.658 0-.688.125-1.267.375-1.752.25-.485.58-.891.986-1.22.407-.328.892-.579 1.44-.782.547-.188 1.11-.344 1.704-.438a17.253 17.253 0 0 
1 1.783-.188c.595-.031 1.158-.031 1.69-.031h.782v-.344c0-.782-.266-1.392-.813-1.783-.548-.407-1.236-.595-2.08-.595-.673 
0-1.3.11-1.878.36-.579.25-1.08.563-1.502.97l-1.658-1.674a6.132 6.132 0 0 1 2.472-1.549 9.176 9.176 0 0 1 2.925-.485c.891 0 1.658.11 2.268.313.626.204 1.126.47 
1.533.783.407.312.72.688.939 1.095.219.406.375.829.469 1.25.094.423.156.83.172 1.221.016.407.031.75.031 1.048v7.29H94.06v-1.737h-.031Zm-.188-3.926h-.657c-.438 
0-.938.016-1.486.047a7.652 7.652 0 0 0-1.533.25 3.614 3.614 0 0 0-1.236.579c-.344.25-.5.61-.5 1.064 0 .297.063.547.188.75a2.025 2.025 0 0 0 1.235.83c.266.062.532.093.798.093 
1.095 0 1.893-.266 2.41-.782.515-.516.781-1.236.781-2.127v-.704ZM99.332 27.876v-2.69h2.613v-3.66h3.175v3.66h3.489v2.69h-3.489v5.538c0 .641.11 1.142.36 1.502.25.36.704.563 
1.408.563.282 0 .594-.032.923-.094.344-.063.594-.172.798-.313v2.66a4.296 4.296 0 0 1-1.236.328c-.485.062-.907.094-1.283.094-.845 
0-1.533-.094-2.065-.282-.531-.188-.954-.47-1.267-.845-.313-.375-.516-.844-.641-1.407-.11-.564-.172-1.22-.172-1.956v-5.803h-2.612v.015ZM119.543 36.118h-.079c-.453.72-1.032 
1.236-1.752 1.565-.703.328-1.47.484-2.315.484a6.238 6.238 0 0 1-1.705-.234 4.301 4.301 0 0 1-1.47-.72 3.826 3.826 0 0 1-1.048-1.189c-.266-.469-.391-1.032-.391-1.658 
0-.688.125-1.267.375-1.752.25-.485.579-.891.986-1.22.406-.328.891-.579 1.439-.782.547-.188 1.11-.344 1.705-.438a17.25 17.25 0 0 1 1.783-.188c.595-.031 1.158-.031 
1.69-.031h.782v-.344c0-.782-.266-1.392-.814-1.783-.547-.407-1.236-.595-2.08-.595-.673 0-1.299.11-1.877.36-.579.25-1.095.563-1.518.97l-1.658-1.674a6.131 6.131 0 0 1 2.472-1.549 
9.171 9.171 0 0 1 2.925-.485c.892 0 1.658.11 2.268.313.626.204 1.126.47 1.533.783.407.312.72.688.939 1.095.219.406.375.829.469 1.25.094.423.156.83.172 1.221.016.407.031.75.031 
1.048v7.29h-2.847v-1.737h-.015Zm-.188-3.926h-.657c-.438 0-.939.016-1.486.047a7.643 7.643 0 0 0-1.533.25 3.614 3.614 0 0 0-1.236.579c-.344.25-.5.61-.5 1.064 0 
.297.062.547.187.75a2.032 2.032 0 0 0 1.236.83c.266.062.532.093.798.093 1.095 0 1.893-.266 2.409-.782.516-.516.782-1.236.782-2.127v-.704ZM127.458 
27.875h-2.613v-2.69h2.613v-1.8c0-.876.062-1.673.187-2.393.125-.72.36-1.33.704-1.83a3.16 3.16 0 0 1 1.439-1.173c.61-.266 1.408-.407 2.409-.407.72 0 1.393.063 2.003.188l-.204 
2.69c-.234-.047-.453-.094-.657-.125a4.318 4.318 0 0 0-.657-.047 2.57 2.57 0 0 0-1.063.204c-.266.14-.47.328-.626.563-.157.234-.25.516-.282.829a6.873 6.873 0 0 0-.062 
1v2.3h2.925v2.691h-2.957v9.98h-3.175v-9.98h.016ZM135.514 31.52c0-1.002.188-1.91.548-2.738a6.25 6.25 0 0 1 1.47-2.096 6.363 6.363 0 0 1 2.19-1.346 7.738 7.738 0 0 1 
2.691-.485c.938 0 1.845.157 2.69.485.845.313 1.58.767 2.19 1.346.61.579 1.095 1.282 1.471 2.096.359.813.547 1.72.547 2.737 0 1.017-.188 1.909-.547 2.738a6.266 6.266 0 0 
1-1.471 2.096 6.515 6.515 0 0 1-2.19 1.345 7.661 7.661 0 0 1-2.69.47 7.663 7.663 0 0 1-2.691-.47 6.361 6.361 0 0 1-2.19-1.345 6.25 6.25 0 0 
1-1.47-2.096c-.36-.814-.548-1.737-.548-2.738Zm3.16 0c0 .484.078.97.25 1.423.172.453.407.86.735 1.22.329.344.72.641 1.174.845a3.82 3.82 0 0 0 1.564.313 3.75 3.75 0 0 0 
1.549-.313 3.53 3.53 0 0 0 1.173-.845 3.23 3.23 0 0 0 .735-1.22c.172-.454.25-.939.25-1.424s-.078-.97-.25-1.423a3.75 3.75 0 0 0-.735-1.22 3.53 3.53 0 0 0-1.173-.845 3.75 3.75 0 
0 0-1.549-.313c-.579 0-1.095.11-1.564.313-.454.219-.845.485-1.174.845-.328.344-.563.75-.735 1.22-.172.453-.25.938-.25 1.423ZM164.344 37.854h-3.004v-2.033h-.047a4.181 4.181 0 0 
1-1.376 1.642c-.626.47-1.455.704-2.519.704-.844 0-1.564-.14-2.143-.407-.579-.265-1.079-.64-1.439-1.11a4.462 4.462 0 0 1-.782-1.596 7.366 7.366 0 0 
1-.235-1.877v-7.993h3.176v6.413c0 .36.015.751.047 1.173.031.438.125.845.281 1.22.141.376.376.689.689.955.312.25.735.375 1.314.375.531 0 .969-.094 
1.329-.266.36-.172.657-.407.892-.72.234-.297.407-.64.516-1.032.11-.39.156-.782.156-1.22v-6.898h3.176v12.67h-.031ZM168.458 25.186h3.019v2.034h.047c.297-.642.766-1.174 
1.376-1.643.61-.47 1.455-.704 2.519-.704.844 0 1.564.14 2.143.422a4.136 4.136 0 0 1 1.439 1.111c.375.454.641.986.798 1.58.156.594.234 1.22.234 
1.877v7.994h-3.175v-6.414c0-.344-.016-.75-.047-1.173a4.081 4.081 0 0 0-.282-1.22 2.373 2.373 0 0 0-.704-.954c-.313-.25-.751-.392-1.298-.392-.532 0-.97.094-1.33.266a2.36 2.36 0 
0 0-.891.72 2.917 2.917 0 0 0-.517 1.032 4.442 4.442 0 0 0-.156 1.22v6.915h-3.175V25.186ZM194.174 35.962h-.047a4.084 4.084 0 0 1-1.846 1.69 6.138 6.138 0 0 1-2.502.531c-.986 
0-1.862-.172-2.628-.516a5.959 5.959 0 0 1-1.971-1.408c-.548-.594-.955-1.314-1.236-2.112a7.876 7.876 0 0 1-.423-2.612c0-.939.157-1.799.454-2.612a6.457 6.457 0 0 1 1.252-2.112 
5.484 5.484 0 0 1 1.939-1.408c.751-.344 1.58-.516 2.487-.516.595 0 1.127.062 1.58.188.454.125.861.281 1.221.485a4.968 4.968 0 0 1 1.501 
1.267h.078v-8.901h3.176v19.929h-3.019v-1.893h-.016Zm-7.493-4.443c0 .485.079.97.251 1.424.172.454.406.86.735 1.22.328.344.719.641 1.173.845.454.203.986.313 1.564.313a3.75 3.75 
0 0 0 1.549-.313 3.509 3.509 0 0 0 1.173-.845c.329-.344.579-.75.735-1.22.172-.454.251-.939.251-1.424s-.079-.97-.251-1.423a3.732 3.732 0 0 0-.735-1.22 3.512 3.512 0 0 
0-1.173-.845 3.752 3.752 0 0 0-1.549-.313 3.88 3.88 0 0 0-1.564.313c-.454.219-.845.485-1.173.845-.329.344-.563.75-.735 1.22a4.016 4.016 0 0 0-.251 1.423ZM201.307 
25.184h3.176v2.002h.047a4.034 4.034 0 0 1 1.501-1.72c.642-.407 1.393-.61 2.237-.61.188 0 .391.015.563.046.188.032.376.079.564.125v3.066c-.266-.078-.532-.125-.783-.171a4.04 
4.04 0 0 0-.75-.063c-.736 0-1.33.14-1.784.407a3.18 3.18 0 0 0-1.032.938 3.029 3.029 0 0 0-.469 1.064c-.079.344-.126.61-.126.782v6.789h-3.175V25.184h.031ZM210.396 
25.184h3.488l3.536 8.79h.047l3.175-8.79h3.301l-5.992 15.392a10.386 10.386 0 0 1-.75 1.564 4.59 4.59 0 0 1-.97 1.142 3.638 3.638 0 0 
1-1.314.689c-.501.14-1.111.219-1.815.219-.829 0-1.642-.11-2.424-.313l.391-2.847c.25.094.5.156.782.219.266.062.547.094.829.094.391 0 
.719-.032.985-.11.266-.063.485-.188.673-.328a1.9 1.9 0 0 0 .469-.58c.125-.234.251-.515.391-.86l.61-1.501-5.412-12.78Z"
		/>
	</svg>
);
export default DataFoundryImg;
