import React, { useEffect, useState } from 'react';
import EachAnalysis from './EachAnalysis';
import { MdDevices, MdDevicesOther, MdPermDeviceInformation, MdHealthAndSafety } from 'react-icons/md';
import { RiRecycleFill } from 'react-icons/ri';
import './index.css';
import { postApi } from '../../services/api';

const defaultAnalysis = [
	{
		title: 'Total Assets',
		key: 'totalAssets',
		value: '120',
		image: require('../../images/dashboard-1.png'),
	},
	{
		title: 'Working Assets',
		key: 'workingAssets',
		value: '80',
		image: require('../../images/dashboard-2.png'),
	},
	{
		title: 'Repair Assets',
		key: '',
		value: '',
		image: require('../../images/dashboard-3.png'),
	},
	{
		title: 'New Product Order',
		key: 'newProductOrder',
		value: '30',
		image: require('../../images/dashboard-4.png'),
	},
	{
		title: 'IT Assets Health',
		key: 'itAssetHealth',
		value: '84%',
		image: require('../../images/dashboard-5.png'),
	},
	{
		title: 'E-waste Disposable',
		key: '',
		value: '',
		image: require('../../images/dashboard-6.png'),
	},
];

const AnalysisComponenet = function () {
	const [analysisInfo, setAnalysisInfo] = useState(defaultAnalysis);
	useEffect(() => {
		async function getDashboardInfo() {
			const resp = await postApi({}, '/assets/dashboard');
			console.log(resp);
			if (resp.type === 'success') {
				setAnalysisInfo(
					analysisInfo.map((el) => {
						return {
							...el,
							value: resp.data[el.key] ? resp.data[el.key] : '',
						};
					})
				);
			}
		}
		getDashboardInfo();
	}, []);

	console.log(analysisInfo);
	return (
		<div className="analysis-container">
			{analysisInfo.map((el, index) => (
				<EachAnalysis key={index} title={el.title} value={el.value} Icon={el.image} />
			))}
		</div>
	);
};

export default AnalysisComponenet;
