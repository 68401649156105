import { useState } from 'react';

const useDefaultQuestion = (vendorDetails) => {
	const vendorSpecifications = ['vendorName', 'vendorType', 'pointOfContact', 'email', 'contactNumber', 'website'];
	const [currentVendorDetailIndex, setCurrentVendorDetailIndex] = useState(0);
	console.log(vendorDetails);

	const next = () => {
		setCurrentVendorDetailIndex((i) => {
			if (i >= Object.keys(vendorDetails).length - 2) return i;
			return i + 1;
		});
	};
	const back = () => {
		setCurrentVendorDetailIndex((i) => {
			if (i <= 0) return i;
			return i - 1;
		});
	};
	const specification = vendorSpecifications[currentVendorDetailIndex];
	console.log(specification);
	return {
		next,
		back,
		specification,
		vendorSpecifications,
		currentVendorDetailIndex,
		step: vendorDetails[specification],
		isFirstStep: currentVendorDetailIndex === 0,
		isLastStep: currentVendorDetailIndex === Object.keys(vendorDetails).length - 2, //we dont want handle "isVendorDetailsSubmitted"
	};
};

export default useDefaultQuestion;
