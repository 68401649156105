import React from 'react';
import './index.css';
import HeaderComponent from '../../components/Header';
import FooterNew from '../../components/FooterNew';

const Ewaste = () => {
    return (
        <div>
            <HeaderComponent />
            <div className='ewaste-innerdiv'>
                <h1>What is E-waste ?</h1>
                <div className='content-div'>
                    <p>As per E-Waste (Management) Rules, 2016.</p>
                    <p> ‘E-waste’ means electrical and electronic equipment, whole or in part discarded as waste by the consumer or bulk consumer as well as rejects from manufacturing, refurbishment and repair processes;</p>
                    <p>E-waste is a popular, informal name for electronic products nearing the end of their “useful life.” Computers, televisions, VCRs, stereos, copiers, and fax machines are common electronic products. Many of these products can be reused, refurbished, or recycled.</p>
                </div>
                <h3>As per Global E-waste Monitor 2017 report</h3>
                <div className='content-div'>
                    <ul>
                        <li>India is the 4th largest E-waste producer in the world after China, the United States, and Japan.</li>
                        <li>Total E-waste produced in India was 2 million metric tons in 2016 which amounts to 1.5 kg per capita.</li>
                        <li>The E-waste growth rate in India is forecasted at 30% per annum (Assocham-kinetics study)</li>
                        <li>In India, Mumbai is the highest producer of e-waste with Bangalore at 3rd place behind New Delhi.</li>
                        <li>% of e-waste recycled in India is 1.5% as compared to 20% globally.</li>
                    </ul>
                </div>
                <div className='content-div div1'>We take care of the entire e-waste recycling process right from the pickup of e-waste from your company’s office to refurbishing and transportation of the processed recycled material to the re-manufacturers and material-specific recyclers.
                </div>

                <h3>Our Procedure</h3>
                <div className='content-div'>
                    <ul>
                        <li>A corporate or government entity connects with us and ask for our E-waste service</li>
                        <li>We get the location of the corporate and the time at which the scheduled pickup is needed and arrange for e-waste pickup</li>
                        <li>We pick up e-waste directly from the company’s office location through our well-equipped pickup staff.</li>
                        <li>Using trolley, bins, and crates provided by us our staff load the e-waste in the transport vehicle and it is taken to our recycling plant for further processing.</li>
                        <li>At our recycling plant, the e-waste is either refurbished or dismantled depending on its condition and sent to
                            re-manufacturers or material recyclers.</li>
                        <li>We also provide the corporate with a recycling certificate and handhold the company through the entire regulatory compliance process.</li>
                    </ul>
                </div>

                <h3>WEEE Recycling & Recovery</h3>
                <div className='content-div'>
                    <ul>
                        <li>The Government of India released the E-waste (Management) Rules in 2016 to control the disposal of Waste Electrical and Electronic Equipment (WEEE) along with the Restriction of Hazardous Substances (RoHS) directive.</li>
                        <li>WEEE holds the producers and distributors of electrical and electronic equipment (EEE) responsible for the safe disposal of end-of-life products by the collection and recycling of their products. On the other hand, RoHS restricts the use of certain hazardous substances like lead mercury, cadmium, brominated flame retardants, and others in EEE. We help manufacturers and distributors fulfill their mandatory WEEE obligation by complete end-to-end processing of end-of-life products including collection, dismantling, and recovery. Our e-waste recycling process consists of a mixture of cutting-edge automated machinery and manual sorting and dismantling by a well-trained and professional staff. This process enables the recycling of WEEE in the most environmentally friendly manner possible with the highest recovery from products at the end of their life cycle.</li>
                    </ul>
                </div>
            </div>
            <FooterNew />
        </div>
    )
}

export default Ewaste