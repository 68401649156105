import React, { useRef, useState } from 'react';
import './index.css';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { display } from '@mui/system';
import useVendor from './useVendor';
import { useDispatch, useSelector } from 'react-redux';
import { Strings } from '../../screens/vendors/config';
import DescriptiveComponent from './DescriptiveComponent';
import McqComponent from './McqComponent';
import UploadComponent from './UploadComponent';
import ArrowButton from './ArrowButton';
import { UPDATE_FILE_UPLOAD } from '../../redux/actions';
import { showSnack } from 'react-redux-snackbar';
import { postApi, uploadToS3 } from '../../services/api';

const FileUploadComponent = ({ sectionData, nextHandler, backHandler, dataShare, error, setError }) => {
	console.log('dataShare', dataShare);
	const {
		question,
		questions,
		next,
		back,
		section,
		currentSectionIndex,
		currentQuestionIndex,
		isFirstStep,
		isLastStep,
		sections,
	} = dataShare;
	const dispatch = useDispatch();
	const hideFileInput = useRef(null);
	const [answer, setAnswer] = useState(question?.answer);
	const [fileName, setFileName] = useState(null);

	const [fileData, setFileData] = useState();
	const [fileUrl, setFileUrl] = useState('');
	console.log(currentQuestionIndex);
	console.log('currentSectionIndex, currentQuestionIndex', currentSectionIndex, currentQuestionIndex);
	console.log(question);
	const handleFileInput = async (e) => {
		hideFileInput.current.click();
	};
	const fileChangeHandler = async (e) => {
		console.log(e.target.files[0]);
		const file = e.target.files[0];
		setAnswer(fileUrl);
		// const fileName = file.name.replace(/[&\/\\#, +()$~%'":*?<>{}]/g, "");
		const fileType = file.type;
		// console.log("newFile", newFile);

		if (file.size > 2e6) {
			console.log('fieSize', file.size);
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'File Size should be smaller than 2MB',
					timeout: 7000,
				})
			);
		} else {
			await uploadVendorFile(file);
		}
	};

	const uploadVendorFile = async (file) => {
		console.log(question._id);
		try {
			const resp = await postApi(
				{ vendorFileName: question._id, mimetype: file.type },
				'/vendorForm/upload-vendor-file/signedurl'
			);
			if (resp.type === 'success') {
				console.log(resp);
				setFileUrl(resp.url);
				await uploadToS3(resp.url, file.type, file);
				console.log('fileType', file.type, file, resp.url);
				dispatch({
					type: UPDATE_FILE_UPLOAD,
					payload: {
						sectionId: section._id,
						qId: question._id,
						qtype: question.qtype,
						answer: resp.url,
					},
				});
				setFileName(file.name);
				dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: 'file uploaded successfully',
						timeout: 7000,
					})
				);
			} else {
				return dispatch(
					showSnack('VENDOR_RESPONSE_FORM_ADDED', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
		} catch (error) {
			return;
			dispatch(
				showSnack('VENDOR_RESPONSE_FORM_ADDED', {
					label: error && error.message,
					timeout: 7000,
				})
			);
		}
	};
	console.log('fileType', fileData);
	console.log(fileUrl);

	return (
		<div className="descriptiveContainer">
			<div className="sectionDetailsContainer">
				<div className="sectionCountIndex">
					Section
					<span>
						{currentSectionIndex + 1}/{sections?.length}
					</span>
				</div>
				<div className="sectionName">{section.name}</div>
				<div className="sectionDescription">{section.description}</div>
			</div>
			<div className="descriptiveForm">
				<h4>{question?.question}</h4>
				{fileName}
				<div className="question-arrow">
					<br />
					<div className="labelContainer">
						<label className="uploadLabel">
							<button className="upload-btn" onClick={handleFileInput}>
								<input
									// accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
									style={{ display: 'none' }}
									ref={hideFileInput}
									type="file"
									onChange={fileChangeHandler}
									required
								/>
								Upload
							</button>
							{/* <input type="file" style={{ display: "none" }} /> */}
							<p>Allow files for upload documents, images, PDF,jpeg , png with max file size of 2MB</p>
							{/* <div className="uploadQuery">
                <div className="nonUploadOptions">
                  <input type="radio" />
                  Not registered
                </div>
                <div className="nonUploadOptions">
                  <input type="radio" />
                  Applied,waiting for approval
                </div>
              </div> */}
						</label>
					</div>
					<ArrowButton
						isFirstStep={isFirstStep}
						isLastStep={isLastStep}
						nextHandler={() => {
							nextHandler();
							setFileUrl('');
						}}
						backHandler={backHandler}
					/>
				</div>

				<button
					className="next-btn"
					onClick={() => {
						if (!fileUrl) {
							setError(true);
							return;
						}
						nextHandler();
					}}
				>
					{isLastStep ? 'Submit' : 'Next'}
				</button>
			</div>

			{error && !fileUrl ? (
				<h6 className="responseErrorMessage">Please answer this question before heading to next</h6>
			) : null}

			<div className="progressiveContainer">
				<p>
					Questions {currentQuestionIndex + 1} of {questions?.length}
				</p>
				<div className="progressExtension">
					<div
						className="progress"
						style={{
							backgroundColor: '#33D7DB',
							width: ((currentQuestionIndex + 1) / questions.length) * 100 + '%',
						}}
					></div>
				</div>
			</div>
		</div>
	);
};

export default FileUploadComponent;
