import React, { useEffect } from 'react';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { isDate } from 'date-fns';
import { postApi } from '../../services/api';
import { showSnack, dismissSnack } from 'react-redux-snackbar';
import { useSelector, useDispatch } from 'react-redux';

const columns = [
	{ id: 'serialNumber', label: 'Serial Number', minWidth: 170 },
	{ id: 'type', label: 'Asset Type', minWidth: 100 },
	{
		id: 'name',
		label: 'Name',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'id',
		label: 'ID',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'department',
		label: 'Department',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'dateOfPurchase',
		label: 'Date Of Purchase',
		minWidth: 170,
		align: 'right',
		format: (value) => value.toFixed(2),
	},
	{
		id: 'price',
		label: 'Price',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'vendor',
		label: 'Vendor',
		minWidth: 170,
		align: 'right',
	},
	{
		id: 'purchaseOrder',
		label: 'Purchase Order',
		minWidth: 170,
		align: 'right',
	},
];

export default function ReplaceAssetsData({ repeatedAssets, closeModal, gotToNext, submitForm }) {
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const [allColumns, setColumns] = React.useState(columns);
	const [allAssets, setAllAssets] = React.useState([]);
	const dispatch = useDispatch();
	useEffect(() => {
		let cList = allColumns;
		const columnsObj = {};
		for (let x = 0; x < cList.length; x++) {
			columnsObj[cList[x].id] = true;
		}
		for (let x = 0; x < repeatedAssets.assetsInFile.length; x++) {
			const selectedAsset = repeatedAssets.assetsInDB.find(
				(el) => el.serialNumber === repeatedAssets.assetsInFile[x].serialNumber
			);
			for (let y = 0; y < repeatedAssets.assetsInFile[x].custom.length; y++) {
				const eachCustom = repeatedAssets.assetsInFile[x].custom[y];
				if (!columnsObj[eachCustom.key]) {
					columnsObj[eachCustom.key] = true;
					cList = cList.concat([
						{
							id: eachCustom.key,
							label: eachCustom.key,
							minWidth: 170,
							align: 'right',
							isCustom: true,
						},
					]);
				}
				const columnFound = selectedAsset.custom.find((el) => el.key === eachCustom.key);
				if (columnFound) {
					if (columnFound.value !== eachCustom.value) {
						eachCustom.isModified = true;
					}
				}
			}
		}

		setAllAssets([...repeatedAssets.assetsInFile]);
		setColumns(cList);
	}, [repeatedAssets]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	// const submitForm = async function () {
	// 	try {
	// 		const resp = await postApi(repeatedAssets, '/assets/reupload-assets');
	// 		if (resp.type === 'success') {
	// 			closeModal();
	// 			dispatch(
	// 				showSnack('REUPLOAD_ASSETS_SUCCESS', {
	// 					label: 'Successfully reupload assets',
	// 					timeout: 7000,
	// 				})
	// 			);
	// 		} else {
	// 			dispatch(
	// 				showSnack('REUPLOAD_ASSETS_ERROR', {
	// 					label: resp.error,
	// 					timeout: 7000,
	// 				})
	// 			);
	// 		}
	// 	} catch (e) {
	// 		console.error(e);
	// 	}
	// };

	const rows = allAssets || repeatedAssets.assetsInFile;

	return (
		<div className="replace-assets-container">
			<h1>Replace Assets</h1>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 440 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								{allColumns.map((column) => (
									<TableCell
										key={column.id}
										align={column.align}
										style={{ minWidth: column.minWidth }}
									>
										{column.label}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
								const dbFound = repeatedAssets.assetsInDB.find(
									(el) => el.serialNumber === row.serialNumber
								);
								return (
									<TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
										{allColumns.map((column) => {
											let value = row[column.id];

											const keyFound = column.isCustom
												? row.custom.find((el) => el.key === column.id)
												: null;

											const isModified =
												keyFound && keyFound.isModified ? true : value != dbFound[column.id];

											return (
												<TableCell key={column.id} align={column.align}>
													<span className={isModified ? 'modified-data' : ''}>
														{keyFound
															? keyFound.value
															: column.format && isDate(value)
															? column.format(value)
															: value}
													</span>
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			<div className="actions">
				<button
					onClick={() => {
						closeModal();
					}}
					className="no"
				>
					Cancel
				</button>
				<button onClick={submitForm} className="yes">
					ReUpload Assets
				</button>
			</div>
		</div>
	);
}
