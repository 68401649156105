/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { useForm } from 'react-hook-form';
import './index.css';
import EarlyAccessImage from '../../images/signup.png';
import { useDispatch } from 'react-redux';
import { earlyAccessSignup, LOGIN_SUCCESS, showLoginModal, verifyToken } from '../../redux/actions';
import { showSnack } from 'react-redux-snackbar';
import { postApi } from '../../services/api';
import { useNavigate, useLocation } from 'react-router-dom';
import FooterNew from '../FooterNew';
import LandingPageHeader from '../LandingpageHeader';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import LoginModal from '../Login';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		padding: '0px',
		boxShadow: '0 0 5px #7f7f7f',
	},
	overlay: {
		backgroundColor: '#4e4e4e65',
	},
};

const EarlyAccessModel = function ({ className, content = 'Sign Up' }) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
		getValues,
		setValue,
	} = useForm({
		defaultValues: {
			firstName: '',
			lastName: '',
			company: '',
			phoneNumber: '',
			email: '',
			password: '',
			couponCode: 'AZ',
		},
	});
	const [signupStage, setSignupStage] = useState('0');
	const [otpId, setOtpId] = useState(null);
	const dispatch = useDispatch();
	const [modalIsOpen, setModalState] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('/idfc-connect')) {
			setValue('couponCode', 'IDFC50');
		}
	}, [location, setValue]);

	const resendOtp = async () => {
		try {
			const values = getValues();
			const resp = await postApi({ ...values, otpId, type: 'resend-otp' }, '/auth/signup/otp');
			if (resp.type === 'success') {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'OTP is resent',
						timeout: 7000,
					})
				);
			}
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: resp.error,
					timeout: 7000,
				})
			);
		} catch (e) {
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		}
	};

	const onSubmit = async (data) => {
		if (signupStage === '0') {
			const resp = await postApi({ ...data, type: 'signup-otp' }, '/auth/signup/otp');
			if (resp.type === 'error') {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
			setOtpId(resp.data);
			setSignupStage('1');
		} else if (signupStage === '1') {
			const resp = await postApi({ ...data, otpId }, '/auth/signup/complete');
			if (resp.type === 'error') {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}

			// const response = await postApi({ ...data }, '/auth/login');
			// if (response.type === 'error') {
			// 	return dispatch(
			// 		showSnack('INVALID_HEALTH', {
			// 			label: resp.error,
			// 			timeout: 7000,
			// 		})
			// 	);
			// }
			dispatch({ type: LOGIN_SUCCESS });
			localStorage.setItem('jwt-token', resp.token);
			dispatch(verifyToken());
			navigate('/dashboard');
			console.log('successfully registered user');

			setSignupStage('0');
			reset({
				firstName: '',
				lastName: '',
				company: '',
				phoneNumber: '',
				email: '',
				otp: '',
				password: '',
			});

			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'You have successfully registered',
					timeout: 7000,
				})
			);
		}
		return;
	};

	const couponCode = getValues('couponCode');

	return (
		<>
			<button onClick={() => navigate('/register-assetze')} className={className}>
				{content}
			</button>
			<Modal
				isOpen={modalIsOpen}
				onRequestClose={() => {
					setModalState(false);
				}}
				style={customStyles}
				contentLabel="Write Post"
				ariaHideApp={false}
			>
				<div className="early-access-modal-conatiner">
					<div className="section-1">
						<h3 className="header-1">
							Try Assetze Now At <br /> The Price of Your Waste
						</h3>
						<div className="header-2">Pay through your office waste</div>
						<img src={EarlyAccessImage} className="earlyAccessImage" />
					</div>

					<form className="section-2" onSubmit={handleSubmit(onSubmit)}>
						<div className="input-container-name">
							<input type={'text'} placeholder="First Name" {...register('firstName')} />
							<input type={'text'} placeholder="Last Name" {...register('lastName')} />
						</div>
						<div className="input-container">
							<input type={'email'} placeholder="Bussiness Email" {...register('email')} />
						</div>
						<div className="input-container">
							<input placeholder="Company" type={'text'} {...register('company')} />
						</div>

						<div className="input-container">
							<input placeholder="Phone Number" type={'tel'} {...register('phoneNumber')} />
						</div>
						<div className="input-container">
							<input placeholder="Password" type={'password'} {...register('password')} />
						</div>
						{couponCode === 'IDFC50' ? (
							<div className="input-container">
								<input placeholder="Coupon Code" type={'text'} {...register('couponCode')} />
							</div>
						) : null}

						{signupStage === '1' ? (
							<div className="input-container">
								<input placeholder="OTP" type={'text'} {...register('otp')} />
							</div>
						) : null}

						<button className="submit-button">
							{signupStage === '0' ? 'Verify Email' : 'Get Early Access'}
						</button>

						{signupStage !== '0' ? (
							<div onClick={() => resendOtp()} className="resend-otp-text">
								Resend OTP
							</div>
						) : null}

						<p className="terms-accept">
							By clicking on "GET EARLY ACCESS" you agree to our Terms and acknowledge having read our
							Privacy Notice
						</p>
						<div className="already-have-account">
							Already have an account?{' '}
							<span
								onClick={() => {
									setModalState(false);
									dispatch(showLoginModal());
								}}
								className="login-btn"
							>
								Login here
							</span>
						</div>
					</form>
				</div>
			</Modal>
		</>
	);
};

export const RegisterScreenNew = function ({ className, content = 'Sign Up' }) {
	const {
		register,
		handleSubmit,
		watch,
		formState: { errors },
		reset,
		getValues,
		setValue,
	} = useForm({
		defaultValues: {
			phoneNumber: '',
			email: '',
		},
	});
	const [signupStage, setSignupStage] = useState('0');
	const [otpId, setOtpId] = useState(null);
	const dispatch = useDispatch();
	const [modalIsOpen, setModalState] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname.includes('/idfc-connect')) {
			setValue('couponCode', 'IDFC50');
		}
	}, [location, setValue]);

	const resendOtp = async () => {
		try {
			const values = getValues();
			const resp = await postApi({ ...values, otpId, type: 'resend-otp' }, '/auth/signup/otp');
			if (resp.type === 'success') {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: 'OTP is resent',
						timeout: 7000,
					})
				);
			}
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: resp.error,
					timeout: 7000,
				})
			);
		} catch (e) {
			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'Something went wrong',
					timeout: 7000,
				})
			);
		}
	};

	const onSubmit = async (data) => {
		if (!(data.email && data.companyName)) {
			return dispatch(
				showSnack('VALID_API_RESPONSE', {
					label: 'Please provide Email and Company Name.',
					timeout: 7000,
				})
			);
		}

		const resp = await postApi({ ...data }, '/auth/create-demo');
		if (resp.type === 'error') {
			return dispatch(
				showSnack('INVALID_API_RESPONSE', {
					label: resp.error,
					timeout: 7000,
				})
			);
		}

		reset({
			phoneNumber: '',
			email: '',
			companyName: '',
		});

		return dispatch(
			showSnack('VALID_API_RESPONSE', {
				label: 'Thanks for requesting demo, Our team will connect with you.',
				timeout: 7000,
			})
		);

		return;

		if (signupStage === '0') {
			const resp = await postApi({ ...data, type: 'signup-otp' }, '/auth/signup/otp');
			if (resp.type === 'error') {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}
			setOtpId(resp.data);
			setSignupStage('1');
		} else if (signupStage === '1') {
			const resp = await postApi({ ...data, otpId }, '/auth/signup/complete');
			if (resp.type === 'error') {
				return dispatch(
					showSnack('INVALID_HEALTH', {
						label: resp.error,
						timeout: 7000,
					})
				);
			}

			dispatch({ type: LOGIN_SUCCESS });
			localStorage.setItem('jwt-token', resp.token);
			dispatch(verifyToken());
			navigate('/dashboard');
			console.log('successfully registered user');

			setSignupStage('0');
			reset({
				firstName: '',
				lastName: '',
				company: '',
				phoneNumber: '',
				email: '',
				otp: '',
				password: '',
			});

			dispatch(
				showSnack('INVALID_HEALTH', {
					label: 'You have successfully registered',
					timeout: 7000,
				})
			);
		}
		return;
	};

	const couponCode = getValues('couponCode');

	return (
		<div>
			<div className="register-screen">
				{/* <LandingPageHeader /> */}
				<LoginModal />
				<img
					onClick={() => navigate('/')}
					style={{ paddingLeft: '200px', paddingBottom: '50px', cursor: 'pointer' }}
					src={require('../../images/new-landing/logo-2.png')}
				/>
				<div className="content-2">
					<div className="form-container">
						<div className="head-1">Get Started</div>
						<form className="section-2" onSubmit={handleSubmit(onSubmit)}>
							<InputBox
								label={'Company Name'}
								placeholder={'Your Company Name'}
								keyType={'text'}
								formInput={{ ...register('companyName') }}
							/>

							<InputBox
								label={'Email'}
								placeholder={'Your Company Email'}
								keyType={'text'}
								formInput={{ ...register('email') }}
							/>

							<InputBox
								label={'Phone Number (Optional)'}
								placeholder={'Your Phone Number'}
								keyType={'tel'}
								formInput={{ ...register('phoneNumber') }}
							/>

							<button className="submit-button">Book A Demo</button>

							<div className="already-have-account">
								Already have an account?{' '}
								<span
									onClick={() => {
										dispatch(showLoginModal());
									}}
									className="login-btn"
								>
									Login here
								</span>
							</div>
						</form>
					</div>
					<div className={'testimonials'}>
						<div className="each-testimonials">
							<div className="image-container">
								<img src={require('../../images/new-landing/testimonials/madhukar.png')} />
							</div>
							<div className="text-content">
								<div className="image-container">
									<img src={require('../../images/new-landing/testimonials/quotes.png')} />
								</div>
								<div className="content-1">
									Assetze has helped us streamline our IT Process. Their software along with managed
									IT services helped us scale rapidly without any hassle in IT. We are happy to be
									using Assetze and its services{' '}
								</div>
								<div className="name">Madhukar</div>
								<div className="company-name">Vahan Inc.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="portfolio-companies">
				<img src={require('../../images/new-landing/register-used-portfolio.png')} />
			</div>
		</div>
	);
};

function InputBox({ label, placeholder, keyType, formInput }) {
	return (
		<div className="custom-input">
			<div className="label">{label}</div>
			<input className="text-box" type={keyType} placeholder={placeholder} {...formInput} />
		</div>
	);
}

export default EarlyAccessModel;
