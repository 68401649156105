import React from 'react';
import HeaderComponent from '../../components/Header/index.js';
import './index.css';
import CartImage from '../../images/Cart-Image.png';
import Slider from 'react-slick';
import FooterDashboard from '../../components/FooterDashboard/index.js';

const ConfirmOrder = function () {
	return (
		<div>
			<HeaderComponent />
			<div className="order-confirm-container">
				<div className="order-confirm">Order Confirmed</div>
				<div className="orderer-name">Hello Puneeth Reddy, Assetze</div>
				<div className="order-confirm-msg">
					Thank you for shopping with us, we’ll send a confirmation once your item has shipped. Your order
					details are mentioned below. The payment details of your transaction can be found on the invoice
					section. If you would like to view the status of your oder or make any changes to it, please visit
					your order section.
				</div>
				<div className="show-item">
					<div className="eta-shipping">
						<div className="eta-delivery">Estimated delivery:</div>
						<div className="eta-addr">Monday, 29th March 2022</div>
						<div className="eta-delivery">Shipping Address:</div>
						<div className="eta-addr">Jayanagar, Bengaluru - 560070</div>
					</div>
					<div className="cart-item">
						<img src={CartImage} alt="product" />
						<div className="device-details">
							<div className="device-name">13-inch MacBook Pro</div>
							<div className="device-color">Color: Space Grey</div>
							<div className="device-memory">8GB unified memory</div>
							<div className="device-storage">256GB SSD storage</div>
						</div>
					</div>
					<div className="shop-track">
						<button className="to-shopping">Continue Shopping</button>
						<button className="to-track">Track Order</button>
					</div>
				</div>
				<div className="view-products-message">View Similar Products</div>
				<ProductListCarousel />
			</div>
			<FooterDashboard />
		</div>
	);
};

const settings = {
	infinite: true,
	speed: 500,
	autoplay: false,
	autoplaySpeed: 2000,
	slidesToShow: 3,
	slidesToScroll: 1,
	arrows: false,
	dots: false,
};

const productList = [
	{
		name: 'Apple Macbook Pro 14” M1 Pro',
		image: require('../../images/footer/product-6.png'),
		price: '₹ 188,800',
	},
	{
		name: 'Apple Macbook Pro 16” M1 Pro',
		image: require('../../images/footer/product-7.png'),
		price: '₹ 225,500',
	},
	{
		name: 'Apple Macbook Pro 13” M1',
		image: require('../../images/footer/product-8.png'),
		price: '₹ 112,000',
	},
];

const ProductListCarousel = function () {
	return (
		<Slider {...settings}>
			{productList.map((el, index) => (
				<EachProduct key={index} product={el} />
			))}
		</Slider>
	);
};

const EachProduct = function ({ product }) {
	return (
		<div>
			<div className="each-product-container">
				<div className="image-container">
					<img src={product.image} />
				</div>
				<div className="product-name">{product.name}</div>
				<div className="product-actions">
					<div className="price">{product.price}</div>
					<button>Buy Now</button>
				</div>
			</div>
		</div>
	);
};

export default ConfirmOrder;
