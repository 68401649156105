import * as React from 'react';

const ProductSvg = (props) => (
	<svg width={28} height={28} fill={props.fill} xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M14.102 27.998c-3.27 0-6.539.004-9.809-.001C2.415 27.993.86 26.734.55 24.882c-.104-.622-.022-1.286.046-1.923.245-2.297.52-4.59.786-6.886.262-2.263.526-4.527.787-6.79.217-1.892.453-3.783.643-5.677C3.028 1.464 4.596.002 6.746.002c4.925-.003 9.85-.003 14.775 0 2.12.001 3.682 1.445 3.914 3.557.313 2.865.657 5.727.985 8.59.343 2.988.683 5.976 1.024 8.965.106.92.236 1.837.31 2.76.184 2.277-1.524 4.12-3.813 4.123-3.278.005-6.558.001-9.839.001Zm.01-2.002h4.78c1.687 0 3.374.004 5.06-.001.856-.003 1.574-.518 1.753-1.322.082-.364.05-.765.008-1.143-.25-2.255-.516-4.507-.776-6.76-.24-2.068-.476-4.136-.715-6.203-.26-2.253-.532-4.506-.78-6.76-.126-1.158-.81-1.804-1.977-1.804-2.624-.002-5.248 0-7.872 0-2.291 0-4.582-.002-6.873 0-1.08.002-1.8.668-1.903 1.747-.028.29-.067.579-.1.87-.258 2.243-.518 4.486-.777 6.728l-.716 6.203c-.241 2.098-.45 4.201-.741 6.293-.146 1.052.603 2.187 2.006 2.168 3.208-.044 6.416-.016 9.623-.016Z" />
		<path d="M8.127 5.686c0-.22-.004-.437 0-.657.012-.588.437-1.024.995-1.027.557-.003.981.434 1.008 1.016.03.642-.007 1.302.13 1.923.422 1.93 2.335 3.268 4.255 3.041 2.045-.24 3.575-1.891 3.608-3.9.007-.374-.008-.75.007-1.124A.992.992 0 0 1 19.096 4c.507-.013.987.363 1.017.87.08 1.353-.014 2.678-.671 3.915-1.21 2.279-3.747 3.557-6.277 3.14-2.538-.418-4.559-2.435-4.958-4.964-.067-.42-.072-.849-.105-1.274.007 0 .016 0 .025-.002Z" />
	</svg>
);

export default ProductSvg;
